import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './StartTestimonialsPanel.scss';
import meliorLogo from 'images/testimonials/melior.svg';
import meliorAuthorAvatar from 'images/testimonials/melior-author.png';
import ncfxLogo from 'images/testimonials/ncfx.svg';
import ncfxAuthorAvatar from 'images/testimonials/ncfx-author.png';
import { CSSTransition } from 'react-transition-group';
import { randomArrayElement } from '../../../functions/randomArrayElement';

const useAdoptechAnimation = (callback: () => void, timeout = 15_000) => {
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);

  useEffect(() => {
    setInterval(() => {
      setInProp(false);
      setInProp(true);
      callback();
    }, timeout);
  }, []);

  return { inProp, nodeRef };
};

export const StartTestimonialsPanel: React.FC = () => {
  const baseCss = 'startTestimonialsPanel';

  const testimonials = [
    {
      id: 'melior',
      logo: meliorLogo,
      text: '“We needed to put together InfoSec and Compliance policies and procedures in a very short period of time and I found these guys called adoptech.co.uk. Not sure if any of you came across them but they saved my life! It took minutes to put some of the policies together and all ISO 27001 compliant. Add to that a very responsive UK-based customer service. And super affordable. We will be doing our certification with them next year for sure. Honestly, I can’t praise them enough.”',
      authorAvatar: meliorAuthorAvatar,
    },
    {
      id: 'ncfx',
      logo: ncfxLogo,
      text: '“Relying on Adoptech’s intuitive functionality and helpful team has made the process of implementing ISO 27001 far smoother than we had expected. We feel confident about migration to the 2022 standards, which Adoptech is managing seamlessly.”',
      authorAvatar: ncfxAuthorAvatar,
    },
  ];

  const [testimonial, setTestimonial] = useState<(typeof testimonials)[number]>(
    randomArrayElement(testimonials)
  );
  const { inProp, nodeRef } = useAdoptechAnimation(() => {
    setTestimonial(previousTestimonial => {
      const anotherItems = testimonials.filter(
        item => item.id !== previousTestimonial.id
      );
      return randomArrayElement(anotherItems);
    });
  });

  return (
    <CSSTransition
      in={inProp}
      timeout={1000}
      classNames="my-node"
      nodeRef={nodeRef}
    >
      <div className={baseCss} ref={nodeRef}>
        <img className={baseCss + '--logo'} src={testimonial.logo} />
        <div className={baseCss + '--text'}>{testimonial.text}</div>
        <img
          className={baseCss + '--authorAvatar'}
          src={testimonial.authorAvatar}
        />
      </div>
    </CSSTransition>
  );
};
