import { createSelector } from '@reduxjs/toolkit';
import { CompanyIdentifierType } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { SelectionOption } from '../types/selectionOption';

export const selectIdentifierTypesOptions = createSelector(
  (state: ApplicationState) =>
    state.vendors.companyDetailsState.identifierTypes,
  (identifierTypes: CompanyIdentifierType[]) =>
    identifierTypes?.map(
      (idType: CompanyIdentifierType): SelectionOption => ({
        value: idType.type,
        label: idType.displayName,
      })
    )
);
