import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hidePolicyDrawer,
  openAttestationManagement,
} from '../../store/policies/policiesSlice';
import {
  patchPolicyAttestationGroups,
  patchVendorPolicy,
  skipAttestation,
} from '../../store/policies/policiesThunks';
import { patchVendorDocument } from '../../store/vendors/vendorsThunks';
import {
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
  VendorPolicyDetails,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechHS from '../AdoptechHS/AdoptechHS';
import { PolicyOverviewSection } from './PolicyOverviewSection/PolicyOverviewSection';
import { PolicyFrameworksList } from '../PolicyFrameworksList/PolicyFrameworksList';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { PolicyReadRequestsSection } from './PolicyReadRequestsSection/PolicyReadRequestsSection';
import { canFeature } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { PolicyVersionSection } from './PolicyVersionSection/PolicyVersionSection';
import { showGlobalToast } from '../../store/global/globalSlice';

interface PolicyAccordionProps {
  title: string;
  defaultActiveKey?: string;
}
const PolicyAccordion: React.FC<
  React.PropsWithChildren<PolicyAccordionProps>
> = props => {
  return (
    <Accordion defaultActiveKey={props.defaultActiveKey}>
      <AdoptechAccordionCard
        index="0"
        title={props.title}
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        {props.children}
      </AdoptechAccordionCard>
    </Accordion>
  );
};
export const PolicyDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isPolicyDrawerShowing,
    isPolicyDrawerShowingForReadRequest,
    patchPolicyAttestationGroupsStatus,
    isPatchingVendorPolicy,
  } = useSelector((state: ApplicationState) => state.policies);

  const {
    documentState: { isPatchingVendorDocument },
  } = useSelector((state: ApplicationState) => state.vendors);
  const policy = useSelector(
    (state: ApplicationState) => state.policies.policyToEdit
  );
  const [policyCopy, setPolicyCopy] = useState(null);
  const onPolicyDrawerClose = () => {
    dispatch(hidePolicyDrawer());
  };
  const isADocument = (thing: VendorPolicyDetails | VendorDocumentExtended) =>
    Object.values(VendorDocumentStatusEnum).includes(
      (thing as VendorDocumentExtended).status
    );

  const canManageAttestations = canFeature(AccessObject.manage_read_requests);
  const isVendorDoc = policy && isADocument(policy);

  const showReadRequestsSection = isVendorDoc && canManageAttestations;
  useEffect(() => {
    const policyCopyData = { ...policy };
    setPolicyCopy(policyCopyData);
  }, [policy?.id, policy?.policyId, policy?.initialTeamsFetched]);

  const handleSkipReadRequest = () => {
    dispatch(
      skipAttestation({
        policyId: policy.id,
      })
    );
  };

  const handleSave = async () => {
    // send only changed attributes
    const params: VendorDocumentExtended | VendorPolicyDetails = {};

    if (policy.reviewFrequency !== policyCopy?.reviewFrequency) {
      params.reviewFrequency = policy.reviewFrequency;
    }

    if (policy.ownerId !== policyCopy?.ownerId) {
      params.ownerId = policy.ownerId;
    }

    if (isVendorDoc) {
      if (
        (policy as VendorDocumentExtended).attestationFrequency !==
        policyCopy?.attestationFrequency
      ) {
        (params as VendorDocumentExtended).attestationFrequency = (
          policy as VendorDocumentExtended
        ).attestationFrequency;
      }
      const hasFormChanges = Object.keys(params).length > 0;

      hasFormChanges &&
        (await dispatch(
          patchVendorDocument({
            documentId: policy.id,
            vendorDocument: (params || {}) as VendorDocumentExtended,
          })
        ));

      const teamsChanged = policy.selectedTeams !== policyCopy?.selectedTeams;

      if (teamsChanged) {
        await dispatch(
          patchPolicyAttestationGroups({
            id: policy.id,
            body: {
              groupIds: (policy.selectedTeams || []).map(team => team.id),
            },
          })
        );
      }

      if (isPolicyDrawerShowingForReadRequest) {
        handleSkipReadRequest();
      }

      dispatch(
        showGlobalToast('Policy document has been successfully updated.')
      );
    } else {
      const hasFormChanges = Object.keys(params).length > 0;
      hasFormChanges &&
        (await dispatch(
          patchVendorPolicy(
            policy.id,
            (params || {}) as VendorPolicyDetails,
            false
          )
        ));
    }
    dispatch(hidePolicyDrawer());
  };

  const Footer: React.FC = () => {
    const isBusy =
      patchPolicyAttestationGroupsStatus === 'loading' ||
      isPatchingVendorDocument ||
      isPatchingVendorPolicy;
    return (
      <>
        <AdoptechButton
          onClick={onPolicyDrawerClose}
          variant={AdoptechButtonVariant.White}
          disabled={isBusy}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={handleSave}
          variant={AdoptechButtonVariant.Primary}
          busy={isBusy}
        >
          Save
        </AdoptechButton>
      </>
    );
  };

  return isPolicyDrawerShowing && policy ? (
    <AdoptechHS
      show
      onClose={onPolicyDrawerClose}
      footer={<Footer />}
      title={`${policy.name} Settings`}
      extraClass="adoptechHS--medium"
      showConfirmationWarning
      noFormPadding
    >
      <PolicyAccordion
        defaultActiveKey={isPolicyDrawerShowingForReadRequest ? null : '0'}
        title="Overview"
      >
        <PolicyOverviewSection />
      </PolicyAccordion>
      {showReadRequestsSection && (
        <PolicyAccordion
          defaultActiveKey={isPolicyDrawerShowingForReadRequest ? '0' : null}
          title="Read Requests"
        >
          <PolicyReadRequestsSection />
        </PolicyAccordion>
      )}
      {isVendorDoc && (
        <PolicyAccordion title="Version">
          <PolicyVersionSection />
        </PolicyAccordion>
      )}
      {isVendorDoc && (
        <PolicyAccordion title="Frameworks">
          <PolicyFrameworksList />
        </PolicyAccordion>
      )}
    </AdoptechHS>
  ) : null;
};
