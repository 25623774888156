import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../../../../components/AdoptechAccordionCard/AdoptechAccordionCard';
import {
  fetchComplianceControls,
  fetchControlExtended,
} from '../../../../store/compliance/complianceThunks';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { ControlRow, useControlClick } from './ControlRow';
import { ControlTasks } from './ControlTasks';
import { ControlExtended, ControlModel } from '../../../../swagger';
import { SortableTableHeader } from '../../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../../types/grid';
import {
  cleanupCompliance,
  setSelectedControlId,
} from '../../../../store/compliance/complianceSlice';

import './ControlsList.scss';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';

const baseCss = 'controlsList';

interface Props {
  controls: ControlExtended[];
}

export const ControlsList: React.FC<Props> = ({ controls }) => {
  const dispatch = useAppDispatch();

  const vendorId = useSelector(selectCurrentVendor)?.id;
  const controlClicker = useControlClick();
  const params = useParams() as { id: string };
  const { fetchControlsStatus } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  useEffect(() => {
    dispatch(fetchComplianceControls({ vendorId }));

    return () => {
      dispatch(cleanupCompliance());
    };
  }, []);

  useEffect(() => {
    const fetchExtendedControl = () => {
      const control = controls.find(c => c.id == params?.id);
      if (!params?.id || !control) return;

      const editControlHandler = controlClicker(control);
      editControlHandler();
    };
    fetchControlsStatus === 'succeeded' && fetchExtendedControl();
  }, [params?.id, fetchControlsStatus]);

  const applicable = useSelector(
    (state: ApplicationState) => state.compliance.controlsFilters.applicable
  );

  const loadTasksFor = (control: ControlExtended) => () => {
    dispatch(setSelectedControlId(control.id));
    if (control.tasks) return;
    dispatch(fetchControlExtended(control.id));
  };

  const isFetchingControls = useSelector(
    (state: ApplicationState) =>
      state.compliance.fetchControlsStatus === 'loading'
  );

  if (isFetchingControls) return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      {applicable == 'notApplicable' && (
        <p className={baseCss + '--notApplicable'}>
          Controls marked as out of scope
        </p>
      )}
      <div className={baseCss + '--headers'}>
        <div> </div>
        <SortableTableHeader
          notInTable
          columnName="name"
          label="Control"
          grid={Grid.Controls}
        />
        <SortableTableHeader
          notInTable
          columnName="frameworks"
          label="Frameworks"
          grid={Grid.Controls}
          disabledSort
        />
        <SortableTableHeader
          notInTable
          columnName="assignee"
          label="Owner"
          grid={Grid.Controls}
        />
        <SortableTableHeader
          notInTable
          columnName="nextReviewDate"
          label="Next review"
          grid={Grid.Controls}
        />
        <SortableTableHeader
          notInTable
          columnName="actions"
          label="Actions"
          grid={Grid.Controls}
          disabledSort
        />
      </div>
      <Accordion>
        {controls.map(control => {
          const editControlHandler = controlClicker(control);
          return (
            <AdoptechAccordionCard
              key={control.id}
              index={control.id}
              headerHandleClick={editControlHandler}
              headerClass={
                control.reviewOverdue ? 'card-header--overdue' : null
              }
              callbackOnOpen={loadTasksFor(control)}
              title={<ControlRow control={control} />}
            >
              <ControlTasks control={control} />
            </AdoptechAccordionCard>
          );
        })}
      </Accordion>
    </div>
  );
};
