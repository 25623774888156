import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { formatShortDate } from '../../functions/formatShortDate';
import { markControlApplicable } from '../../store/compliance/complianceThunks';
import { ApplicationState } from '../../types/applicationState';
import { Panel } from '../Panel/Panel';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './NotApplicableControls.scss';
import {
  setControlFormModel,
  setEditControlMode,
  setSelectedControlId,
  setShowControlDrawer,
} from '../../store/compliance/complianceSlice';
import { EditControl } from '../compliance/Types/complianceTypes';
import { EditControlMode } from '../../store/compliance/complianceState';

interface NotApplicableControlsProps {
  vendorId: string;
}

export const NotApplicableControls: React.FC<
  NotApplicableControlsProps
> = () => {
  const baseCss = 'notApplicableControls';
  const { notApplicableControls, controlIdBeingMarkedNotApplicable } =
    useSelector((state: ApplicationState) => state.compliance);

  const dispatch = useDispatch();

  const defaultStyle = {
    transition: 'transform 1s ease-in-out',
    transform: 'translateX(0)',
  };

  const transitionStyle = {
    entering: { transform: 'translateX(2000px)' },
    entered: { transform: 'translateX(0)' },
    exiting: { transform: 'translateX(0)' },
    exited: { transform: 'translateX(0)' },
    unmounted: { transform: 'translateX(0)' },
  };

  if (notApplicableControls.length < 1) return <></>;

  return (
    <div className={baseCss}>
      <div className={`${baseCss}--title`}>
        Controls that have been marked as not applicable
      </div>
      <div className={`${baseCss}--table`}>
        {notApplicableControls.map(control => (
          <Transition
            appear
            key={control.id}
            in={control.id === controlIdBeingMarkedNotApplicable}
            timeout={150}
          >
            {state => (
              <div
                className={`${baseCss}--panel`}
                style={{ ...defaultStyle, ...transitionStyle[state] }}
              >
                <Panel key={control.id}>
                  <div
                    className={`${baseCss}--row`}
                    onClick={e => {
                      e.stopPropagation();
                      dispatch(setSelectedControlId(control.id));
                      dispatch(
                        setControlFormModel(
                          new EditControl(control).simpleObject()
                        )
                      );
                      dispatch(setEditControlMode(EditControlMode.View));
                      dispatch(setShowControlDrawer(true));
                    }}
                  >
                    <div className={`${baseCss}--rowGrid`}>
                      <FontAwesomeIcon
                        className={`${baseCss}--rowBars`}
                        icon={faBars}
                      />
                      <div className={`${baseCss}--rowControlName`}>
                        {control.name}
                      </div>
                      <div className={`${baseCss}--rowFrameworks`}>
                        {control.frameworksRelations.map(fr => (
                          <div
                            className={`${baseCss}--tag`}
                            key={fr.framework.id}
                          >
                            {fr.framework.name}
                          </div>
                        ))}
                      </div>
                      <div className={`${baseCss}--rowAssignee`}>
                        <UserAvatar user={control.assignee} size="small" />
                      </div>
                      <div className={`${baseCss}--rowReviewDate`}>
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className={baseCss + '--timeIcon'}
                        />
                        {formatShortDate(control.nextReviewDate)}
                      </div>
                    </div>
                    <div
                      className={`${baseCss}--rowToggle`}
                      onClick={e => e.stopPropagation()}
                    >
                      <Form.Switch
                        id={control.id}
                        onChange={e => {
                          setTimeout(() => {
                            dispatch(markControlApplicable(control.id));
                          }, 500);
                        }}
                      />
                    </div>
                  </div>
                </Panel>
              </div>
            )}
          </Transition>
        ))}
      </div>
    </div>
  );
};
