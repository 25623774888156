import React from 'react';
import { useDispatch } from 'react-redux';
import './ControlRow.scss';
import { ControlExtended } from '../../../../swagger';
import { formatShortDate } from '../../../../functions/formatShortDate';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { MeatballMenu } from '../../../../components/MeatballMenu/MeatballMenu';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import {
  setControlFormModel,
  setEditControlMode,
  setSelectedControlId,
  setShowControlDrawer,
} from '../../../../store/compliance/complianceSlice';
import { EditControl } from '../../../../components/compliance/Types/complianceTypes';
import { FrameworksPills } from '../../../../components/Frameworks/Pills';
import { AccessObject } from '../../../../types/accessObject';
import { canFeature } from '../../../../functions/access';
import { EditControlMode } from '../../../../store/compliance/complianceState';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import {
  controlIcon,
  controlIconClass,
} from '../EditControlCheckDrawer/ControlCheckControls/ControlCheckControls';

const baseCss = 'controlRow';

interface Props {
  control: ControlExtended;
}

export const useControlClick = () => {
  const dispatch = useDispatch();
  const canEditControls = canFeature(AccessObject.controls_update);
  return (control: ControlExtended) => {
    return (e?: { stopPropagation: () => void }) => {
      e?.stopPropagation();
      dispatch(setSelectedControlId(control.id));
      dispatch(setControlFormModel(new EditControl(control).simpleObject()));
      if (!canEditControls) {
        dispatch(setEditControlMode(EditControlMode.View));
      }
      // toggle state to always run useEffect with fetchControlExtended in ControlDrawer
      dispatch(setShowControlDrawer(false));
      dispatch(setShowControlDrawer(true));
    };
  };
};

const Menu: React.FC<Props> = ({ control }) => {
  const controlClicker = useControlClick();
  const editControlHandler = controlClicker(control);

  return (
    <MeatballMenu>
      <Dropdown.Item onClick={editControlHandler}>
        <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
        Edit
      </Dropdown.Item>
    </MeatballMenu>
  );
};

export const ControlRow: React.FC<Props> = ({ control }) => {
  const frameworks = (control.frameworksRelations || []).map(
    fr => fr.framework
  );
  // avoid dup ids
  const controlPillsFrameworks = [...frameworks].map(framework => {
    return {
      ...framework,
      ...{ id: `${control.id}-${framework.id}` },
    };
  });

  return (
    <div className={baseCss}>
      <div>
        <FontAwesomeIcon
          icon={controlIcon(control)}
          className={controlIconClass(control, baseCss)}
        />
      </div>
      <div>{control.name}</div>
      <div>
        <FrameworksPills frameworks={controlPillsFrameworks} />
      </div>
      {control.assignee ? (
        <div className={baseCss + '--avatarWrapper'}>
          <UserAvatar showTooltip user={control.assignee} size="tag" hideText />
        </div>
      ) : (
        <div>-</div>
      )}
      <div>
        {control.reviewOverdue ? (
          <div className={baseCss + '--overdueIcon'}>
            <FontAwesomeIcon size="lg" icon={faClock} className="mr-2" />
            <span className="font-weight-bold">Overdue</span>
          </div>
        ) : (
          formatShortDate(control.nextReviewDate) || '-'
        )}
      </div>
      <Menu control={control} />
    </div>
  );
};
