import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import './PeopleTabs.scss';
import classNames from 'classnames';
import {
  peopleChartRoute,
  peopleComplianceRoute,
  peopleRoute,
} from '../../../components/Routes/Routes';
import {
  fetchComplianceUsers,
  fetchVendorUsers,
} from '../../../store/vendors/vendorsThunks';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';

interface Props {
  currentTab: 'profile' | 'compliance' | 'chart';
}

export const PeopleTabs: React.FC<Props> = ({ currentTab }) => {
  const dispatch = useDispatch();

  const goToProfile = () => {
    dispatch(push(peopleRoute));
  };

  const goToCompliance = () => {
    dispatch(push(peopleComplianceRoute));
  };

  const goToChart = () => {
    dispatch(push(peopleChartRoute));
  };
  const currentVendor = useSelector(selectCurrentVendor);
  useEffect(() => {
    currentTab === 'profile' && dispatch(fetchVendorUsers(currentVendor?.id));
    currentTab === 'compliance' && dispatch(fetchComplianceUsers());
  }, [currentTab]);

  return (
    <div className="peopleTabs">
      <div
        className={classNames('peopleTabs--tab', {
          active: currentTab == 'profile',
        })}
        onClick={goToProfile}
      >
        Profile
      </div>
      <div
        className={classNames('peopleTabs--tab', {
          active: currentTab == 'compliance',
        })}
        onClick={goToCompliance}
      >
        Compliance
      </div>

      <div
        className={classNames('peopleTabs--tab', {
          active: currentTab == 'chart',
        })}
        onClick={goToChart}
      >
        Org Chart
      </div>
    </div>
  );
};
