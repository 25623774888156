import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsers } from '../../selectors/selectUsers';
import DatePicker from 'react-datepicker';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { JobTitleSelect } from '../JobTitleSelect/JobTitleSelect';
import { SharedEmploymentStatus, SharedUserType } from '../../swagger';
import { SelectionOption } from '../../types/selectionOption';
import 'react-datepicker/dist/react-datepicker.css';
import './RoleInformation.scss';
import { PeopleDrawerPayload } from '../../features/people/ManagePeopleDrawer/ManagePeopleDrawer';
import { ApplicationState } from '../../types/applicationState';

interface RoleInformationProps {
  user: PeopleDrawerPayload;
  onChange: (roleInformation: PeopleDrawerPayload) => void;
}

export const RoleInformation: React.FC<RoleInformationProps> = ({
  onChange,
  user,
}) => {
  const users = useSelector((state: ApplicationState) =>
    selectUsers(state, { excludeLeft: false })
  );

  const [roleInformation, setRoleInformation] = useState({
    position: null,
    employmentStartedAt: null,
    employmentEndedAt: null,
    lineManagerId: null,
    employmentStatus: null,
    userType: null,
  });

  const isSystemUser = (userObject: PeopleDrawerPayload) =>
    userObject?.userType == SharedUserType.SystemUser;

  useEffect(() => {
    if (!user) return;
    setRoleInformation({
      position: user.position,
      employmentStartedAt: user.employmentStartedAt
        ? new Date(user.employmentStartedAt)
        : null,
      employmentEndedAt: user.employmentEndedAt
        ? new Date(user.employmentEndedAt)
        : null,
      lineManagerId: user.lineManagerId,
      employmentStatus: user.employmentStatus,
      userType: user.userType,
    });
  }, [user]);

  const options = useMemo(
    () => users.map(u => ({ value: u.id, label: u.fullName })),
    [users]
  );
  const statuses = [
    { value: SharedEmploymentStatus.Pending, label: 'Pending' },
    { value: SharedEmploymentStatus.Current, label: 'Current' },
    { value: SharedEmploymentStatus.Leaving, label: 'Leaving' },
    { value: SharedEmploymentStatus.Left, label: 'Left' },
  ];
  const userTypes = [
    { value: SharedUserType.Employee, label: 'Employee' },
    { value: SharedUserType.Consultant, label: 'Consultant' },
    { value: SharedUserType.Stakeholder, label: 'Stakeholder' },
    { value: SharedUserType.Guest, label: 'Guest' },
    { value: SharedUserType.SystemUser, label: 'System User' },
  ];

  const handleChange = (data: typeof roleInformation) => {
    setRoleInformation(data);
    onChange(data);
  };

  const updateReminderDate = (date: Date, id: string) => {
    const updatedData = { ...roleInformation, [id]: date };

    handleChange(updatedData);
  };

  const handlePositionChange = (position: string) => {
    const updatedData = {
      ...roleInformation,
      position,
    };
    handleChange(updatedData);
  };

  const handleLineManagerChange = (option: SelectionOption) => {
    const updatedData = {
      ...roleInformation,
      lineManagerId: option.value,
    };
    handleChange(updatedData);
  };

  const handleEmploymentStatusChange = (option: SelectionOption) => {
    const updatedData = {
      ...roleInformation,
      employmentStatus: option.value as SharedEmploymentStatus,
    };
    handleChange(updatedData);
  };

  const handleUserTypeChange = (option: SelectionOption) => {
    const updatedData = {
      ...roleInformation,
      userType: option.value as SharedUserType,
    };
    handleChange(updatedData);
  };

  return (
    <div className="roleInformation">
      <div className="roleInformation--fieldRow">
        <div className="roleInformation--field">
          <div className="roleInformation--label">Job title</div>
          <JobTitleSelect
            position={roleInformation?.position}
            onChange={handlePositionChange}
          />
        </div>
      </div>
      <div className="roleInformation--fieldRow">
        <div className="roleInformation--field">
          <div className="roleInformation--label">Line manager</div>
          <AdoptechReactSelect
            id="lineManagerSelect"
            onChange={handleLineManagerChange}
            options={options}
            value={options.find(o => o.value === user?.lineManagerId)}
            showUserAvatar
          />
        </div>
      </div>
      <div className="roleInformation--fieldRow">
        <div className="roleInformation--field">
          <div className="roleInformation--label">Start date</div>
          <DatePicker
            className="roleInformation--datepicker"
            dateFormat="dd/MM/yyyy"
            onChange={date => updateReminderDate(date, 'employmentStartedAt')}
            selected={roleInformation.employmentStartedAt}
            autoComplete="off"
            placeholderText="Select date"
          />
        </div>
        <div className="roleInformation--field">
          <div className="roleInformation--label">End date</div>
          <DatePicker
            className="roleInformation--datepicker"
            dateFormat="dd/MM/yyyy"
            onChange={date => updateReminderDate(date, 'employmentEndedAt')}
            selected={roleInformation.employmentEndedAt}
            autoComplete="off"
            placeholderText="Select date"
          />
        </div>
      </div>
      <div className="roleInformation--fieldRow">
        <div className="roleInformation--field">
          <div className="roleInformation--label">Status</div>
          <AdoptechReactSelect
            id="statusSelect"
            onChange={handleEmploymentStatusChange}
            isDisabled={isSystemUser(user)}
            options={statuses}
            value={statuses.find(
              s => s.value === roleInformation.employmentStatus
            )}
          />
        </div>
      </div>
      <div className="roleInformation--fieldRow">
        <div className="roleInformation--field">
          <div className="roleInformation--label">User type</div>
          <AdoptechReactSelect
            id="statusSelect"
            onChange={handleUserTypeChange}
            options={userTypes}
            value={userTypes.find(
              userType => userType.value === roleInformation.userType
            )}
          />
        </div>
      </div>
    </div>
  );
};
