import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DocumentsSection.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { TrustHubSectionProps } from '../../CompanyDetailsSection/CompanyDetailsSection';
import { useTrustHubSection } from '../../useTrustHubSection';
import { DocumentsEditSection } from '../../admin/InformationSecuritySection/DocumentsEditSection/DocumentsEditSection';
import { capitalize } from '../../../../functions/capitalize';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';

export interface DocumentSectionProps extends TrustHubSectionProps {
  identifier: string;
}
export const DocumentsSection: React.FC<DocumentSectionProps> = ({
  isEditable,
  sections,
  identifier,
  onDocumentClick,
  showDocumentDownloadIcon,
}) => {
  const { isEditMode, setIsEditMode, section, publicDocs, baseCss } =
    useTrustHubSection({
      identifier,
      sections,
      isEditable,
    });

  const { fetchVendorDocumentFileStatus, fetchingVendorDocumentIdFile } =
    useSelector((state: ApplicationState) => state.trustHub);
  if (!section) return null;

  if (isEditMode) {
    return (
      <DocumentsEditSection
        section={section}
        onClose={() => setIsEditMode(false)}
        identifier={identifier}
      />
    );
  }

  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <div className={baseCss + '--title'}>
          <div className={baseCss + '--titleInfo'}>
            <FontAwesomeIcon
              icon={identifier === 'reports' ? faFileCheck : faLockAlt}
            />
            <div>{capitalize(identifier)}</div>
          </div>
          {isEditable && (
            <div>
              <AdoptechButton
                extraClass="editButton"
                icon={faPencil}
                onClick={() => setIsEditMode(true)}
                variant={AdoptechButtonVariant.White}
              >
                Edit
              </AdoptechButton>
            </div>
          )}
        </div>
        {publicDocs.length == 0 && (
          <div className={baseCss + '--noContent'}>
            {`No ${identifier} selected to display.`}
          </div>
        )}
        {publicDocs.length > 0 && (
          <div className={baseCss + '--documents'}>
            {publicDocs.map(doc => {
              return (
                <div
                  key={doc.name}
                  className={baseCss + '--document'}
                  onClick={() => onDocumentClick(doc)}
                >
                  <div className={baseCss + '--documentTitle'}>{doc.name}</div>
                  {fetchVendorDocumentFileStatus === 'loading' &&
                  fetchingVendorDocumentIdFile === doc.id ? (
                    <LoadingSpinner inlineSmall />
                  ) : (
                    <FontAwesomeIcon
                      icon={doc.restricted ? faLockAlt : faCloudDownloadAlt}
                      className={classNames({
                        invisible: !showDocumentDownloadIcon,
                      })}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
