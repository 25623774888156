import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { hideCancelSignUpModal } from '../../store/welcome/welcomeSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './CancelSignUpModal.scss';
import { logout } from '../../functions/logout';

export const CancelSignUpModal: React.FC = () => {
  const dispatch = useDispatch();

  const isCancelSignUpModalShowing = useSelector(
    (state: ApplicationState) => state.welcome.isCancelSignUpModalShowing
  );
  const currentVendor = useSelector(selectCurrentVendor);

  const handleHide = () => {
    dispatch(hideCancelSignUpModal());
  };

  const handleCancel = () => {
    logout();
    handleHide();
  };
  return (
    <AdoptechModal
      backdrop="static"
      onHide={handleHide}
      show={isCancelSignUpModalShowing}
    >
      <Modal.Header>Cancel Sign Up</Modal.Header>
      <Modal.Body>
        <div className="cancelSignUpModal--body">
          Are you sure you want to cancel this process? If you click 'CANCEL
          SIGN UP', you will
          {!currentVendor && ' be redirected to the login page and will'} have
          to restart the sign up process.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={() => handleHide()}>Close</AdoptechButton>
        <AdoptechButton
          onClick={handleCancel}
          variant={AdoptechButtonVariant.Primary}
        >
          CANCEL SIGN UP
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
