import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../../functions/formatShortDate';
import {
  fetchEntitiesTree,
  uploadFile,
} from '../../../store/dataRoom/dataRoomThunks';
import {
  setCursor,
  uploadToFolderRequest,
} from '../../../store/dataRoom/dataRoomSlice';
import {
  DataRoomEntityModel,
  DataRoomEntityModelTypeEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import {
  EntityRow,
  selectEntityRows,
} from '../../../selectors/selectEntityRows';
import { DataRoomEntityMeatballMenu } from '../DataRoomEntityMeatballMenu/DataRoomEntityMeatballMenu';
import { dataRoomCss } from '../DataRoom/DataRoom';
import './DataRoomEntitiesTree.scss';
import '../DataRoom/DataRoom.scss';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { dataRoomPathExists } from '../../../functions/dataRoomPathExists';

export const DataRoomEntitiesTree: React.FC = () => {
  const baseCss = 'dataRoomEntitiesTree';
  const css = (...names: any[]): string =>
    names.map(name => `${dataRoomCss}--${name}`).join(' ');

  const dispatch = useDispatch();

  const vendor = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor
  );

  const currentEntityIdProcessing = useSelector(
    (state: ApplicationState) => state.dataRoom.currentEntityIdProcessing
  );

  const isUploadingFile = useSelector(
    (state: ApplicationState) => state.dataRoom.isUploadingFile
  );

  const folders = useSelector(
    (state: ApplicationState) => state.dataRoom.folders
  );

  const entitiesTree = useSelector(
    (state: ApplicationState) => state.dataRoom.entitiesTree
  );

  const entities = useSelector(selectEntityRows(/* no custom cursor*/));

  useEffect(() => {
    dispatch(
      fetchEntitiesTree(vendor.id, null, (tree: DataRoomEntityModel) => {
        dispatch(setCursor([tree.id]));
      })
    );
  }, [vendor.id]);

  const expandToggle = ({ expanded, path }: EntityRow) => {
    if (expanded) {
      dispatch(setCursor(path.slice(0, -1)));
    } else {
      dispatch(setCursor(path));
    }
  };

  const expandArrow = (entity: EntityRow) => {
    const icon =
      entity.type == DataRoomEntityModelTypeEnum.Folder ? (
        <FontAwesomeIcon icon={entity.expanded ? faAngleDown : faAngleRight} />
      ) : null;

    return (
      <div
        className={baseCss + '--expandArrow '}
        onClick={() => expandToggle(entity)}
      >
        {icon}
      </div>
    );
  };

  const entityIcon = ({ type, expanded }: EntityRow) =>
    type === DataRoomEntityModelTypeEnum.File
      ? faFile
      : expanded
        ? faFolderOpen
        : faFolder;

  const renderPath = (fs: EntityRow[], [head, ...tail]: string[]): string => {
    const entity: EntityRow = fs.find(e => e.id === head);
    if (!entity) return '';
    if (tail.length < 1) return entity.name;
    return entity.name + ' \u00BB ' + renderPath(fs, tail);
  };

  const uploadToSelectedFolder = (file: File) => {
    if (
      dataRoomPathExists(
        entitiesTree,
        folders,
        file.name,
        currentEntityIdProcessing
      )
    ) {
      dispatch(
        showGlobalToast(
          'A file or folder named "' +
            file.name +
            '" already exists in this directory'
        )
      );
      dispatch(uploadToFolderRequest([undefined]));
    } else {
      dispatch(
        uploadFile(vendor.id, currentEntityIdProcessing, file, file.name)
      );
    }
  };

  const hiddenFileInput = useRef<HTMLInputElement>();

  return (
    <div className={dataRoomCss}>
      <input
        type="file"
        hidden
        onChange={e => {
          uploadToSelectedFolder(e.currentTarget.files[0]);
          e.target.value = null;
        }}
        ref={hiddenFileInput}
        accept=".txt, .png, .zip, .pptx, .jpg, .gif, .html, .eml, .mp4, .ts, .webp, .jpeg, .json, .mov, .ai, .pdf, .csv, .xls, .xlsx, .xml, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />

      <div className={css('header')}>
        <div className={css('title')}>All files</div>
      </div>
      <div className={css('table')}>
        <div className={css('tableHeader')}>
          <SortableTableHeader<DataRoomEntityModel>
            columnName="name"
            grid={Grid.DataRoomTree}
            label="Name"
            className={css('nameHeader')}
            notInTable
          />
          <SortableTableHeader<DataRoomEntityModel>
            columnName="fileType"
            grid={Grid.DataRoomTree}
            label="File"
            className={css('fileTypeHeader')}
            notInTable
          />
          <SortableTableHeader<DataRoomEntityModel>
            columnName="updatedAt"
            grid={Grid.DataRoomTree}
            label="Last edited"
            className={css('updateDateHeader')}
            notInTable
          />
          <SortableTableHeader<DataRoomEntityModel>
            columnName="creator"
            grid={Grid.DataRoomTree}
            label="Created by"
            className={css('createdByHeader')}
            notInTable
          />
          <div className={css('actionsHeader')}>Actions</div>
        </div>
        {entities.length ? (
          <div className={css('files')}>
            {entities.map(entity => {
              return (
                <div key={entity.id} className={css('fileRow')}>
                  <div
                    className={
                      dataRoomCss +
                      '--name ' +
                      `${baseCss}--level-${Math.min(entity.level, 16)}`
                    }
                  >
                    {expandArrow(entity)}
                    <FontAwesomeIcon icon={entityIcon(entity)} />
                    &nbsp;&nbsp;
                    {entity.name === '/' ? 'Home' : entity.name}
                  </div>
                  <div className={css('fileType')}>
                    {entity.fileType || '-'}
                  </div>
                  <div className={css('updatedAt')}>
                    {formatShortDate(entity.updatedAt)}
                  </div>
                  <div className={css('creator')}>
                    <UserAvatar user={entity.creator} size="small" />
                  </div>
                  <div className={css('actions')}>
                    {isUploadingFile &&
                    currentEntityIdProcessing === entity.id ? (
                      <LoadingSpinner inlineSmall />
                    ) : (
                      <DataRoomEntityMeatballMenu
                        entity={{
                          entityId: entity.id,
                          entityType: entity.type,
                          entityName: entity.name,
                          fileType: entity.fileType,
                          entityAccess: entity.access,
                        }}
                        startUpload={() => hiddenFileInput.current.click()}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={css('label')}>No files to display</div>
        )}
      </div>
    </div>
  );
};
