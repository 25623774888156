import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { Vendor } from '../../swagger';
import { setCurrentVendor } from '../../store/vendors/vendorsSlice';
import { push } from 'connected-react-router';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import './VendorSelector.scss';
import { initialise } from '../../store/global/globalThunks';
import { sort } from '../../functions/sort';

const VendorSelector: React.FC = () => {
  const currentVendor = useSelector(selectCurrentVendor);
  const vendors = useSelector(
    (state: ApplicationState) => state.vendors.vendors
  );

  const vendorsOptions: SelectionOption[] = vendors
    ?.map((vendor: Vendor) => ({
      label: vendor.name,
      value: vendor.id,
    }))
    .sort((a, b) => sort(a.label, b.label, 1));

  const dispatch = useDispatch();

  const handleAddCompany = () => {
    dispatch(push('/r/welcome/company'));
  };

  const onVendorChange = (newVendorOption: SelectionOption) => {
    const newVendor = vendors.find(
      (vendor: Vendor) => vendor.id === newVendorOption.value
    );
    dispatch(setCurrentVendor(newVendor));
    dispatch(initialise());
  };

  return (
    <div className="vendorSelector">
      <AdoptechReactSelect
        ariaLabel="Select a company, or add a new company"
        id="vendorSelector"
        options={vendorsOptions}
        onChange={onVendorChange}
        value={vendorsOptions.find(
          (vendorOption: SelectionOption) =>
            vendorOption.value === currentVendor?.id
        )}
        allowAdd
        onAdd={handleAddCompany}
        addText="+ Add new company"
      />
    </div>
  );
};

export default VendorSelector;
