import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideCompanyValidationFailureModal } from '../../store/welcome/welcomeSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';

export const CompanyValidationFailureModal: React.FC = () => {
  const dispatch = useDispatch();

  const isShowingCompanyValidationFailureModal = useSelector(
    (state: ApplicationState) =>
      state.welcome.isShowingCompanyValidationFailureModal
  );

  const errorText = useSelector(
    (state: ApplicationState) => state.welcome.companyValidationError
  );

  const specialMessages = [
    [
      'Company already exists',
      'An account already exists for this company. To request access please contact the administrator.',
    ],
  ];

  const handleHide = () => {
    dispatch(hideCompanyValidationFailureModal());
  };

  let title = 'Form validation error';
  let message = errorText;
  specialMessages.forEach(([special_title, special_message]) => {
    if (errorText?.startsWith(special_title)) {
      title = special_title;
      message = special_message;
    }
  });

  return (
    <AdoptechModal
      backdrop="static"
      onHide={handleHide}
      show={isShowingCompanyValidationFailureModal}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={handleHide}>Go back</AdoptechButton>
        <AdoptechButton
          onClick={handleHide}
          variant={AdoptechButtonVariant.Primary}
        >
          Ok, got it
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
