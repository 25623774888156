import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canCreate } from '../../functions/access';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
  addTwoFactorAuthentication,
  changePassword,
  closeProfileMenu,
} from '../../store/user/userSlice';
import { showManageBillingModal } from '../../store/vendors/vendorsSlice';
import { AccessObject } from '../../types/accessObject';
import { ApplicationState } from '../../types/applicationState';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './ProfileMenu.scss';
import { manageSubscriptionRoute } from '../Routes/Routes';
import { push } from 'connected-react-router';
import { logout } from '../../functions/logout';

interface ProfileMenuProps {
  onEditProfileClick(): void;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  onEditProfileClick,
}) => {
  const { userDetails } = useSelector((state: ApplicationState) => state.user);

  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>();
  useOnClickOutside(containerRef, () => {
    dispatch(closeProfileMenu());
  });

  const canCreateSubscriptions = canCreate(AccessObject.payment_plan_vendors);

  return (
    <div className="profileMenu" ref={el => (containerRef.current = el)}>
      <div className="profileMenu--email">
        <UserAvatar user={userDetails} size="large" />
      </div>
      <div className="profileMenu--link" onClick={onEditProfileClick}>
        Your profile
      </div>
      <div
        className="profileMenu--link"
        onClick={() => dispatch(changePassword())}
      >
        Change password
      </div>
      {!userDetails.authyUserid && (
        <div
          className="profileMenu--link"
          onClick={() => dispatch(addTwoFactorAuthentication())}
        >
          Two Factor Authentication
        </div>
      )}
      {canCreateSubscriptions && (
        <div
          className="profileMenu--link"
          onClick={() => dispatch(push(manageSubscriptionRoute))}
        >
          Manage subscription
        </div>
      )}
      {canCreateSubscriptions && (
        <div
          className="profileMenu--link"
          onClick={() => dispatch(showManageBillingModal())}
        >
          Manage billing
        </div>
      )}
      <div className="profileMenu--link" onClick={() => logout()}>
        Logout
      </div>
    </div>
  );
};
