import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './UkCompanySearch.scss';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  clearRegisteredCompany,
  setRegisteredCompany,
} from '../../store/welcome/welcomeSlice';
import { searchCompanies } from '../../store/welcome/welcomeThunks';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Panel } from '../Panel/Panel';
import classNames from 'classnames';
import { openChatWidget } from '../../functions/chatWidgetHelper';
import { useAdoptechDebounce } from '../../hooks/useAdoptechDebounce';

const UkCompanySearch: React.FC<{ showError: boolean }> = props => {
  const [searchString, setSearchString] = useState('');
  const checkWithDebounce = useAdoptechDebounce();
  const { registeredCompany, searchCompaniesResults, isSearchingCompanies } =
    useSelector((state: ApplicationState) => state.welcome);

  useEffect(() => {
    if (searchString && searchString.length > 2) {
      checkWithDebounce(() => {
        dispatch(searchCompanies(searchString));
      }, 500);
    }
  }, [searchString]);

  const dispatch = useDispatch();
  const baseCss = 'ukCompanySearch';
  const noResults =
    !isSearchingCompanies &&
    !!searchString &&
    searchCompaniesResults?.length === 0;
  const registeredCompanyValue = registeredCompany
    ? registeredCompany.name
    : searchString;
  return (
    <div className="ukCompanySearch">
      <div className="welcomePanel--prompt">
        Start to type your company name as registered at Companies House.
      </div>
      <AdoptechTextInput
        id="companyName"
        onChange={e => {
          setSearchString(e.currentTarget.value);
        }}
        autoFocus
        onFocus={() => {
          dispatch(clearRegisteredCompany());
        }}
        type="text"
        value={registeredCompanyValue}
        additionalClass={
          props.showError && !registeredCompany ? 'red-field' : ''
        }
        bottomElement={
          props.showError && !registeredCompany ? (
            <div className="error-badge">
              You must add a registered company.
            </div>
          ) : null
        }
      />
      <div className="ukCompanySearch--companiesHouseLabel">
        Companies House information
      </div>
      <div
        className={classNames('ukCompanySearch--searchResults', {
          red: props.showError && !registeredCompany,
        })}
      >
        {isSearchingCompanies && <LoadingSpinner delayed />}
        {noResults && (
          <div className="ukCompanySearch--noResults">No results</div>
        )}
        {!isSearchingCompanies &&
          searchCompaniesResults?.map(rc => {
            return (
              <Panel
                clickable
                key={`${rc.identifier}|${rc.name}`}
                onClick={() => {
                  dispatch(setRegisteredCompany(rc));
                }}
                selected={registeredCompany === rc}
              >
                <div className="ukCompanySearch--searchResult">
                  <div className="ukCompanySearch--searchResultLeft">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  <div className="ukCompanySearch--searchResultRight">
                    <div className="ukCompanySearch--searchResultRightTop">
                      <div className="ukCompanySearch--name">{rc.name}</div>
                      <div className="ukCompanySearch--identifier">
                        {rc.identifier}
                      </div>
                      <div className="ukCompanySearch--incorporated">
                        {' '}
                        - incorporated on{' '}
                        {formatShortDate(rc.dateOfIncorporation)}
                      </div>
                    </div>
                    <div className="ukCompanySearch--searchResultRightBottom">
                      {rc.registeredAddress}
                    </div>
                  </div>
                </div>
              </Panel>
            );
          })}
      </div>

      <div className={baseCss + '--hintNoResults'}>
        Can’t find your company?{' '}
        <a
          href={baseCss + 'car'}
          onClick={e => {
            e.preventDefault();
            openChatWidget();
          }}
        >
          Chat with the team.
        </a>
      </div>
    </div>
  );
};

export default UkCompanySearch;
