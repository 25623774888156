import { createSelector } from '@reduxjs/toolkit';
import { VendorDetails } from '../swagger';
import { selectVendorDetails } from './selectVendorDetails';

export const selectCurrentCompany = createSelector(
  selectVendorDetails,
  (vendorDetails: VendorDetails) => {
    if (vendorDetails && vendorDetails.companies.length > 0) {
      return vendorDetails.companies[0];
    }

    return undefined;
  }
);
