import { SharedUserType } from '../../../swagger';

export type userTypeOption = {
  label: string;
  value: string | null;
};

export const userTypeOptions: userTypeOption[] = [
  { label: 'No selection', value: null },
  { label: 'Employee', value: SharedUserType.Employee },
  { label: 'Consultant', value: SharedUserType.Consultant },
  { label: 'Stakeholder', value: SharedUserType.Stakeholder },
  { label: 'Guest', value: SharedUserType.Guest },
  { label: 'System User', value: SharedUserType.SystemUser },
];

type userComplianceOption = {
  label: string;
  value: string | null;
};

export const userComplianceOptions: userComplianceOption[] = [
  { label: 'No selection', value: null },
  { label: 'Compliant', value: 'yes' },
  { label: 'Not compliant', value: 'not' },
];
