import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  VendorUserCreatePayloadVendorUsers,
  VendorUserRoles,
} from '../../../swagger';
import { ConfirmResendInvitationModal } from '../../../components/ConfirmResendInvitationModal/ConfirmResendInvitationModal';
import { RoleTag } from '../RoleTag/RoleTag';
import AdoptechTable from '../../../components/AdoptechTable/AdoptechTable';
import { rolesViewConfig } from '../../../constants/rolesViewConfig';
import { ToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { ApplicationState } from '../../../types/applicationState';
import './EditRoles.scss';
import {
  AddTabsEnum,
  PeopleDrawerPayload,
} from '../ManagePeopleDrawer/ManagePeopleDrawer';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';
import { AdoptechButtonVariant } from '../../../components/AdoptechButton/AdoptechButton';

interface EditRolesProps {
  onChange: (roles: VendorUserRolesValues[]) => void;
  selectedTab: AddTabsEnum;
  user: PeopleDrawerPayload;
}

export type VendorUserRolesValues = `${VendorUserRoles}`;

export const EditRoles = ({ onChange, selectedTab, user }: EditRolesProps) => {
  const [roles, setRoles] = useState<VendorUserRolesValues[]>([]);
  const [currentCommand, command] = useState<CommandConfirmation>(null);

  useEffect(() => {
    setRoles(user?.roles || []);
  }, [user?.roles]);

  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userDetails
  );

  const isRoleChecked = (role: VendorUserRolesValues): boolean =>
    Boolean(roles.find(userRole => userRole === role));

  const isRoleOptional = (role: VendorUserRolesValues): boolean =>
    rolesViewConfig[role]?.optional || false;

  // How it works:

  // User can have only one basic role: admin, security, employee, auditor, guest, signatory, system_user
  // and optional additional roles: signatory, trusthub admin role
  //
  // Only if admin , security or employee or external consultant selected, we can add optional role
  // else signatory role disabled
  // Edge case: if admin edit himself => Can only enabled/disable optional role, no possible to add/remove another roles
  // If any base role selected ( not optional ) => disable switcher

  const mutuallyExclusiveRoles = [
    VendorUserRoles.Admin,
    VendorUserRoles.SecurityManager,
    VendorUserRoles.SecurityEditor,
    VendorUserRoles.Employee,
    VendorUserRoles.Auditor,
    VendorUserRoles.ExternalConsultant,
  ] as VendorUserRolesValues[];

  const canAddOptionalRoles = [
    VendorUserRoles.Admin,
    VendorUserRoles.SecurityManager,
    VendorUserRoles.SecurityEditor,
    VendorUserRoles.Employee,
    VendorUserRoles.ExternalConsultant,
  ];

  const isSwitchDisabled = (role: VendorUserRolesValues): boolean => {
    const canAddOptionalRole = roles.some(currentRole =>
      canAddOptionalRoles.includes(currentRole as VendorUserRoles)
    );
    const hasAdminRole = roles.includes(VendorUserRoles.Admin);
    const editHimself =
      currentUser.email === (user as VendorUserCreatePayloadVendorUsers)?.email;
    const isOptionalRole = isRoleOptional(role);
    if (hasAdminRole && editHimself && !isOptionalRole) return true;

    if (!canAddOptionalRole && isOptionalRole) return true;
    if (isRoleChecked(role) && !isOptionalRole) return true;
    return false;
  };

  const handleRoleUpdate = (newRoles: VendorUserRolesValues[]) => {
    setRoles(newRoles);
    onChange(newRoles);
  };

  const handleAddRole = (role: VendorUserRolesValues) => {
    const targetChecked = !isRoleChecked(role);

    if (mutuallyExclusiveRoles.includes(role) && roles.length > 0) {
      const existingRoles = roles
        .filter(currentRole => {
          return canAddOptionalRoles.includes(role as VendorUserRoles)
            ? !isRoleOptional(currentRole)
            : true;
        })
        .map(currentRole => {
          const config = rolesViewConfig[currentRole];
          return ['the', config?.label, 'role'].join(' ');
        })
        .join(', ');
      command({
        title: 'Warning',
        description: `If you select the ${rolesViewConfig[role]?.label} role, ${existingRoles} will be removed
        from this user. Select OK to continue or Cancel to undo.`,
        buttonText: 'OK',
        buttonVariant: AdoptechButtonVariant.Primary,
        onConfirm: () => {
          const newRoles = [role];
          const canAddOptionalRole = canAddOptionalRoles.includes(
            role as VendorUserRoles
          );

          type Roles = VendorUserRoles;
          const optionalRoles: VendorUserRoles[] = Object.keys(
            rolesViewConfig
          ).filter((key: Roles) => isRoleOptional(key)) as VendorUserRoles[];

          optionalRoles.forEach(optionalRole => {
            if (roles.includes(optionalRole) && canAddOptionalRole) {
              newRoles.push(optionalRole);
            }
          });

          handleRoleUpdate(newRoles);
        },
      });

      return;
    }

    const newRoles = targetChecked
      ? [...roles, role]
      : roles.filter(currentRole => currentRole !== role);

    handleRoleUpdate(newRoles);
  };

  type ValueOf<T> = T[keyof T];

  const RolesRow = ({
    role,
    config,
  }: {
    role: VendorUserRolesValues;
    config: ValueOf<typeof rolesViewConfig>;
  }) => {
    return (
      <tr key={role}>
        <td className="editRoles--roleTag">
          <RoleTag role={role} />
        </td>
        <td>
          <div className="editRoles--description">
            <div>{config.description}</div>
          </div>
        </td>
        <td>
          <ToggleSwitch
            disabled={isSwitchDisabled(role)}
            checked={isRoleChecked(role)}
            onChange={() => handleAddRole(role)}
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      {selectedTab === AddTabsEnum.Bulk && (
        <div className="editRoles--bulkAddMessage">
          The permissions selected will be applied to all users in this bulk add
          and can be tailored by editing the users' profiles.
        </div>
      )}
      <AdoptechTable embedded>
        <thead>
          <tr>
            <th className="editRoles--roleCell">Role</th>
            <th className="editRoles--descriptionCell">Description</th>
            <th className="editRoles--actionsCell" />
          </tr>
        </thead>
        <tbody>
          {Object.entries(rolesViewConfig)
            .filter(([_, config]) => config.active && !config.optional)
            .map(
              ([role, config]: [
                VendorUserRolesValues,
                ValueOf<typeof rolesViewConfig>,
              ]) => {
                return <RolesRow key={role} role={role} config={config} />;
              }
            )}
        </tbody>
      </AdoptechTable>
      <AdoptechTable embedded className="editRoles--permissionsTable">
        <thead>
          <tr>
            <th className="editRoles--roleCell">Permission</th>
            <th className="editRoles--descriptionCell">Description</th>
            <th className="editRoles--actionsCell" />
          </tr>
        </thead>
        <tbody>
          {Object.entries(rolesViewConfig)
            .filter(([_, config]) => config.active && config.optional)
            .map(
              ([role, config]: [
                VendorUserRolesValues,
                ValueOf<typeof rolesViewConfig>,
              ]) => {
                return <RolesRow key={role} role={role} config={config} />;
              }
            )}
        </tbody>
      </AdoptechTable>
      <ConfirmResendInvitationModal />
      <ConfirmationModal
        command={currentCommand}
        onCancel={_confirmed => command(null)}
      />
    </>
  );
};
