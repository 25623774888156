import React, { useEffect, useState } from 'react';
import './AdoptechProgressRing.scss';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

export interface AdoptechProgressRingProps {
  label?: string;
  progress: number;
}

const AdoptechProgressRing: React.FC<AdoptechProgressRingProps> = props => {
  const [value, setValue] = useState(0);

  const pathColour = (progress: number) => {
    if (progress < 25) return '#999995';
    if (progress < 100) return '#efa82c';
    return '#6caa45';
  };

  useEffect(() => {
    setValue(props.progress);
  }, []);

  return (
    <div className="adoptechProgressRing--container">
      <div className="adoptechProgressRing--progress">
        <CircularProgressbarWithChildren
          value={value}
          strokeWidth={5}
          styles={buildStyles({
            pathColor: pathColour(props.progress),
            pathTransitionDuration: 1,
          })}
        >
          <div className="adoptechProgressRing--amount">{props.progress}</div>
          <div className="adoptechProgressRing--symbol">%</div>
        </CircularProgressbarWithChildren>
      </div>
      {props.label && (
        <div className="adoptechProgressRing--label">{props.label}</div>
      )}
    </div>
  );
};

export default AdoptechProgressRing;
