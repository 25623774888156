import './ManageSubscriptionPage.scss';
import React, { useEffect } from 'react';
import { ManageSubscriptionPageDetails } from './ManageSubscriptionPageDetails';
import { PaymentPlanCards } from './PaymentPlanCards';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomAppContentClasses,
  setPageHeaderLabel,
} from '../../../store/global/globalSlice';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { canCreate } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import NoValidRoleText from '../NoValidRoleText/NoValidRoleText';
import { fetchPaymentPlans } from '../store/pricingThunks';
import { hideBottomFooterClass } from '../../../components/Routes/Routes';
import { selectPaymentPlans } from '../../../selectors/selectPaymentPlans';
import { selectActiveSubscription } from '../../../selectors/selectActiveSubscription';
import { setSubscriptionDetails } from '../store/pricingSlice';

export const NoVendorWarning = (
  <div className="mt-2">
    There is no vendor. May be you didn't finished your signup ? Please contact
    to administrator
  </div>
);
export const ManageSubscriptionPage: React.FC = () => {
  const baseCss = 'manageSubscriptionPage';
  const vendor = useSelector(selectCurrentVendor);
  const dispatch = useDispatch();

  const isFetchingPaymentPlans = useSelector(
    (state: ApplicationState) => state.pricing.isFetchingPaymentPlans
  );

  const currentVendor = useSelector(selectCurrentVendor);
  const activeSubscription = useSelector(selectActiveSubscription);

  useEffect(() => {
    activeSubscription &&
      dispatch(
        setSubscriptionDetails({
          paymentPeriod: activeSubscription.paymentPeriod,
        })
      );
    dispatch(fetchPaymentPlans());
    dispatch(setCustomAppContentClasses([hideBottomFooterClass]));
    dispatch(setPageHeaderLabel(vendor?.name));
    return () => {
      dispatch(setCustomAppContentClasses());
    };
  }, [currentVendor?.id]);

  const canManageSubscriptions = canCreate(AccessObject.payment_plan_vendors);
  const { isInitialised } = useSelector(
    (state: ApplicationState) => state.global
  );
  const paymentPlans = useSelector(selectPaymentPlans);

  if (isInitialised && !vendor) return NoVendorWarning;
  if (isFetchingPaymentPlans || paymentPlans.length === 0)
    return <LoadingSpinner />;
  if (!canManageSubscriptions) return <NoValidRoleText role="admin" />;
  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Manage subscription</div>
      <ManageSubscriptionPageDetails />
      <PaymentPlanCards />
    </div>
  );
};
