import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ControlsPage.scss';
import { ControlsHeader } from './ControlsHeader';
import { ControlsList } from './ControlsList';
import { ComplianceEditControlDrawer } from '../EditControlDrawer/ComplianceEditControlDrawer/ComplianceEditControlDrawer';
import {
  cleanupCompliance,
  setShowControlDrawer,
  setShowTaskDrawer,
} from '../../../../store/compliance/complianceSlice';
import { ComplianceEditTaskDrawer } from '../EditControlCheckDrawer/ComplianceEditTaskDrawer/ComplianceEditTaskDrawer';
import { ApplicationState } from '../../../../types/applicationState';
import {
  fetchComplianceCategories,
  fetchComplianceFrameworks,
  fetchComplianceTasks,
} from '../../../../store/compliance/complianceThunks';
import { selectCurrentVendorUser } from '../../../../selectors/selectCurrentVendorUser';
import { selectControls } from '../../../../selectors/selectControls';
import { controlsPageRoute } from '../../../../components/Routes/Routes';

const Drawers: React.FC = () => {
  // TODO: Move somewhere into global layout

  const dispatch = useDispatch();

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor?.id
  );
  const vendorUser = useSelector(selectCurrentVendorUser);

  const { showControlDrawer, showTaskDrawer } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  useEffect(() => {
    if (!showControlDrawer) return;
    dispatch(fetchComplianceFrameworks(vendorId));
    dispatch(fetchComplianceTasks({ vendorId }));
  }, [showControlDrawer, vendorId, vendorUser.roles]);

  const allFrameworks = useSelector(
    (state: ApplicationState) => state.compliance.frameworks
  );

  useEffect(() => {
    // FIXME: Categories should be loaded dynamically for each framework in Control Drawer
    if (!allFrameworks.length) return;
    dispatch(fetchComplianceCategories(vendorId, allFrameworks[0]?.id));
  }, [allFrameworks]);

  const selectedControl = useSelector((state: ApplicationState) =>
    state.compliance.controls.find(
      c => c.id === state.compliance.selectedControlId
    )
  );

  return (
    <>
      <ComplianceEditControlDrawer
        show={showControlDrawer}
        control={selectedControl}
        onClose={() => {
          // silently change URL without redirect
          window.history.pushState(null, '', controlsPageRoute);
          dispatch(setShowControlDrawer(false));
        }}
      />
      <ComplianceEditTaskDrawer
        show={showTaskDrawer}
        onClose={() => dispatch(setShowTaskDrawer(false))}
      />
    </>
  );
};

export const ControlsPage: React.FC = () => {
  const controls = useSelector(selectControls);
  return (
    <div className="controlsPage">
      <ControlsHeader controls={controls} />
      <ControlsList controls={controls} />
      <Drawers />
    </div>
  );
};
