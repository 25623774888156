import React, { useEffect, useState } from 'react';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Accordion, Dropdown, Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import {
  cleanCurrentRisk,
  hideAutoSaveToast,
  updateCurrentRisk,
} from '../../../store/riskRegistry/riskRegistrySlice';
import {
  approveRisk,
  commitCurrentRisk,
  deleteRisk,
  fetchRisk,
  fetchRiskControls,
  fetchVendorActions,
} from '../../../store/riskRegistry/riskRegistryThunks';
import {
  RiskExtended,
  RiskModel,
  RiskModelInformationSecurityPropertiesEnum,
  RiskModelStatusEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechAccordionCard } from '../../AdoptechAccordionCard/AdoptechAccordionCard';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { AdoptechReactSelect } from '../../AdoptechReactSelect/AdoptechReactSelect';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { MeatballMenu } from '../../MeatballMenu/MeatballMenu';
import { Panel } from '../../Panel/Panel';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { riskRegistryRoute } from '../../Routes/Routes';
import './RiskRegisterEditor.scss';
import { AdoptechTextArea } from '../../AdoptechTextArea/AdoptechTextArea';
import {
  RiskInfo,
  VendorRegisterRiskProfile,
} from '../../VendorRegisterRiskProfile/VendorRegisterRiskProfile';
import { getPestelRiskText } from '../../pestel/PestelSection/PestelRow';
import { RiskRegisterTreatment } from '../RiskRegistryTreatment/RiskRegisterTreatment';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../ConfirmationModal/ConfirmationModal';
import { UpdateModelFunction } from '../../../types/UpdateModelFunction';
import { AdoptechTooltip } from '../../AdoptechTooltip/AdoptechTooltip';
import { NoDataText } from '../../NoDataText/NoDataText';
import { canFeature, hasFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { AdoptechCheckbox } from '../../AdoptechCheckbox/AdoptechCheckbox';
import { SecurityEditorOwnerWarning } from '../../../features/compliance/controls/EditControlDrawer/ComplianceEditControlForm/ComplianceEditControlForm';

const baseCss = 'riskRegisterEditor';

interface TooltipProps {
  text: string;
  identifier: string;
}
const InformationSecurityTooltip: React.FC<TooltipProps> = props => {
  return (
    <AdoptechTooltip
      showTooltip
      identifier={props.identifier}
      text={props.text}
      placement="right"
    >
      <div style={{ width: 'fit-content' }}>{props.children}</div>
    </AdoptechTooltip>
  );
};
export const RiskRegisterEditor: React.FC = () => {
  const { riskRegisterId } = useParams() as { riskRegisterId: string };

  const dispatch = useDispatch();

  const model = useSelector(
    (state: ApplicationState) => state.riskRegistry.currentRisk
  );
  const canCreateRisks = canFeature(AccessObject.risk_register_create);
  const canUpdateRisks = canFeature(AccessObject.risk_register_update);

  useEffect(() => {
    dispatch(fetchRisk(riskRegisterId));
    return () => {
      dispatch(cleanCurrentRisk());
    };
  }, [riskRegisterId]);

  useEffect(() => {
    dispatch(fetchRiskControls());
    dispatch(fetchVendorActions());
  }, []);

  const riskOwnerCompletion = [!!model?.owner];

  const inherentRiskCompletion = [
    !!model?.inherentRiskLikelihood,
    !!model?.inherentRiskConsequence,
  ];

  const treatmentCompletion = [!!model?.treatmentStrategy];

  const residualRiskCompletion = [
    !!model?.residualRiskLikelihood,
    !!model?.residualRiskConsequence,
  ];

  const compromisedCompletion = [
    !!(model?.informationSecurityProperties || [])[0],
  ];

  const overallCompletion: boolean[] = [
    ...riskOwnerCompletion,
    ...inherentRiskCompletion,
    ...treatmentCompletion,
    ...residualRiskCompletion,
  ];

  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const showAutoSaveToast = useSelector(
    (state: ApplicationState) => state.riskRegistry.showAutoSaveToast
  );

  const cardTitle = (complete: boolean, title: string) => (
    <>
      <FontAwesomeIcon
        icon={complete ? faCheckCircle : faCircle}
        className="mr-4"
      />
      <div className="flex1">{title}</div>
    </>
  );

  const updateModel: UpdateModelFunction<RiskExtended> = (
    update,
    dry = false
  ) => {
    const updateCommand = () => {
      const newModel = {
        ...update(model),
        status: RiskModelStatusEnum.ReviewPending,
      } as RiskExtended;
      if (JSON.stringify(model) != JSON.stringify(newModel))
        dispatch(updateCurrentRisk(newModel));
      if (dry) return;
      dispatch(commitCurrentRisk());
    };
    if (model.status == RiskModelStatusEnum.Approved) {
      command({
        title: 'Continue to editing',
        description: `
          Are you sure you wish to continue to editing? The risk will need to
          be re-approved. To return to viewing click Cancel or continue to
          editing by selecting Edit.
        `,
        action: 'Edit',
        buttonVariant: AdoptechButtonVariant.SuccessFilled,
        onConfirm: updateCommand,
      });
    } else {
      updateCommand();
    }
  };

  const { users, ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });

  const rangeOfFiveOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];

  const progress =
    overallCompletion.filter(Boolean).length / overallCompletion.length;

  if (!model) {
    return <LoadingSpinner />;
  }

  const riskInfo = (
    risk: RiskModel,
    type: 'inherent' | 'residual'
  ): RiskInfo => {
    const riskSeverity =
      (risk[`${type}RiskConsequence`] || 1) *
      (risk[`${type}RiskLikelihood`] || 1);
    return {
      riskSeverity,
      riskLevel: getPestelRiskText(riskSeverity),
    };
  };

  const approveCommand: CommandConfirmation = {
    subject: { name: model.name, type: 'Risk' },
    action: 'Approve',
    buttonVariant: AdoptechButtonVariant.SuccessFilled,
    onConfirm: () =>
      dispatch(approveRisk(model.id, () => dispatch(push(riskRegistryRoute)))),
  };

  const removeCommand: CommandConfirmation = {
    title: 'Confirm Remove',
    subject: { name: model.name, type: 'Risk' },
    description: 'Click CONFIRM to remove this risk from the register.',
    buttonVariant: AdoptechButtonVariant.Warning,
    onConfirm: () =>
      dispatch(deleteRisk(model.id, () => dispatch(push(riskRegistryRoute)))),
  };

  if (!canUpdateRisks)
    return (
      <div style={{ marginTop: '10px' }}>
        <NoDataText
          text="There are no abilities to edit the risk. Please contact to the
        admin"
        />
      </div>
    );

  const onChangeCompromisedProperty = (
    e: React.SyntheticEvent<HTMLInputElement, Event>
  ) => {
    const name = (e.target as HTMLInputElement).id.split(
      '-'
    )[1] as RiskModelInformationSecurityPropertiesEnum;
    const informationSecurityPropertiesCopy = (
      model.informationSecurityProperties || []
    ).filter(item => item !== name);
    if (e.currentTarget.checked) informationSecurityPropertiesCopy.push(name);

    updateModel(m => ({
      ...m,
      informationSecurityProperties: informationSecurityPropertiesCopy,
    }));
  };

  const create = !model?.id;

  const canManageOwner = create
    ? true
    : hasFeature(AccessObject.manage_ownership, model?.access);

  const ownerTitle =
    create || canManageOwner ? null : SecurityEditorOwnerWarning;
  return (
    <div className={baseCss}>
      <div className={baseCss + '--summary'}>
        <Panel>
          <div className={baseCss + '--progressBar'}>
            <ProgressBar progress={progress * 100} />
          </div>
          <div className={baseCss + '--summaryBody'}>
            <div
              className={baseCss + '--back'}
              onClick={() => {
                dispatch(push(riskRegistryRoute));
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className={baseCss + '--summaryDescription'}>
              <div>
                <div className={baseCss + '--name'}>{model.name}</div>
              </div>
            </div>
            <AdoptechButton
              icon={faFileContract}
              disabled={
                progress < 1 || model.status === RiskModelStatusEnum.Approved
              }
              rounded
              variant={AdoptechButtonVariant.Success}
              onClick={() => command(approveCommand)}
            >
              Approve
            </AdoptechButton>
            {canCreateRisks && (
              <MeatballMenu>
                <br />
                <Dropdown.Item onClick={() => command(removeCommand)}>
                  <FontAwesomeIcon
                    className="meatballMenu--icon"
                    icon={faTrashAlt}
                  />
                  Remove
                </Dropdown.Item>
              </MeatballMenu>
            )}
          </div>
        </Panel>
      </div>
      <div className={baseCss + '--grid'}>
        <Accordion defaultActiveKey="owner">
          <AdoptechAccordionCard
            index="owner"
            title={cardTitle(
              riskOwnerCompletion.every(Boolean),
              'Set Risk Owner'
            )}
          >
            <div className={baseCss + '--title'}>Set Risk Owner</div>
            <div className={baseCss + '--guidance'}>
              This individual is responsible for approving the risk treatment
              plan(s), and tracking the completion of the respective tasks. They
              are also responsible for accepting\signing-off the residual risk.
              The risk owner may be different from related control, task or
              asset owners.
            </div>
            <div className={baseCss + '--fieldRow'} title={ownerTitle}>
              <AdoptechReactSelect
                id="riskOwner"
                options={ownerOptions}
                isDisabled={!canManageOwner}
                onChange={(option: SelectionOption) => {
                  const selectedOwner = users.find(
                    user => user.id === option.value
                  );
                  // HACK: Remove focus from select, to keep it sensitive to click after overlay close
                  (document.activeElement as HTMLElement).blur();
                  updateModel(m => ({ ...m, owner: selectedOwner }));
                }}
                value={ownerOptions.find(
                  option => option.value === model.owner?.id
                )}
                placeholder="Select owner"
                showUserAvatar
              />
            </div>
          </AdoptechAccordionCard>
          <AdoptechAccordionCard
            index="informationSecurityProperties"
            title={cardTitle(
              compromisedCompletion.every(Boolean),
              'Confidentiality Integrity and Availability'
            )}
          >
            <div className={baseCss + '--title'}>
              Confidentiality Integrity and Availability
            </div>
            <div className={baseCss + '--guidance'}>
              Which of the following key information security properties could
              be compromised by this risk?
            </div>
            <div className={baseCss + '--fieldRowCheckboxGroup'}>
              <div className={baseCss + '--fieldRowCheckbox'}>
                <InformationSecurityTooltip
                  identifier="confidentiality-tooltip"
                  text="Information is only available or disclosed to authorised individuals, entities or processes"
                >
                  <AdoptechCheckbox
                    id={`informationSecurityProperties-${RiskModelInformationSecurityPropertiesEnum.Confidentiality}`}
                    checked={(
                      model.informationSecurityProperties || []
                    ).includes(
                      RiskModelInformationSecurityPropertiesEnum.Confidentiality
                    )}
                    color="white"
                    onChange={e => onChangeCompromisedProperty(e)}
                    label="Confidentiality"
                  />
                </InformationSecurityTooltip>
              </div>
              <div className={baseCss + '--fieldRowCheckbox'}>
                <InformationSecurityTooltip
                  identifier="integrity-tooltip"
                  text="Information is accurate and correct"
                >
                  <AdoptechCheckbox
                    id={`informationSecurityProperties-${RiskModelInformationSecurityPropertiesEnum.Integrity}`}
                    checked={(
                      model.informationSecurityProperties || []
                    ).includes(
                      RiskModelInformationSecurityPropertiesEnum.Integrity
                    )}
                    color="white"
                    onChange={e => onChangeCompromisedProperty(e)}
                    label="Integrity"
                  />
                </InformationSecurityTooltip>
              </div>
              <div className={baseCss + '--fieldRowCheckbox'}>
                <InformationSecurityTooltip
                  identifier="availability-tooltip"
                  text="Information, and services are accessible and usable on demand by the authorised individuals, entities or processes"
                >
                  <AdoptechCheckbox
                    id={`informationSecurityProperties-${RiskModelInformationSecurityPropertiesEnum.Availability}`}
                    checked={(
                      model.informationSecurityProperties || []
                    ).includes(
                      RiskModelInformationSecurityPropertiesEnum.Availability
                    )}
                    color="white"
                    onChange={e => onChangeCompromisedProperty(e)}
                    label="Availability"
                  />
                </InformationSecurityTooltip>
              </div>
            </div>
          </AdoptechAccordionCard>
          <AdoptechAccordionCard
            index="inherent"
            title={cardTitle(
              inherentRiskCompletion.every(Boolean),
              'Assess Inherent Risk'
            )}
          >
            <div className={baseCss + '--title'}>Assess Inherent Risk</div>
            <div className={baseCss + '--guidance'}>
              Estimate the likelihood and impact to determine an overall
              inherent risk score.
            </div>
            <div className={baseCss + '--fieldRow'}>
              <AdoptechReactSelect
                id="riskInherentRiskLikelihood"
                options={rangeOfFiveOptions}
                onChange={(option: SelectionOption) => {
                  updateModel(m => ({
                    ...m,
                    inherentRiskLikelihood: +option.value,
                  }));
                }}
                value={rangeOfFiveOptions.find(
                  option =>
                    option.value === model.inherentRiskLikelihood?.toString()
                )}
                label="Likelihood"
                labelTooltipText="The probability the risk will occur"
                placeholder="Please select"
              />
              <AdoptechReactSelect
                id="riskInherentRiskConsequence"
                options={rangeOfFiveOptions}
                onChange={(option: SelectionOption) => {
                  updateModel(m => ({
                    ...m,
                    inherentRiskConsequence: +option.value,
                  }));
                }}
                labelTooltipText="The impact of the risk if it occurs"
                value={rangeOfFiveOptions.find(
                  option =>
                    option.value === model.inherentRiskConsequence?.toString()
                )}
                label="Consequence"
                placeholder="Please select"
              />
              <div className={baseCss + '--riskScore'}>
                <>
                  <AdoptechTooltip
                    showTooltip
                    text="Calculated by multiplying the likelihood and consequence"
                    identifier="risk-score"
                  >
                    <label>Risk Score</label>
                  </AdoptechTooltip>
                </>
                <VendorRegisterRiskProfile
                  riskInfo={riskInfo(model, 'inherent')}
                  scale={25}
                />
              </div>
            </div>
            <p>
              Provide any additional comments (optional). These comments will
              appear in any risk reports that you generate.
            </p>
            <div className={baseCss + '--fieldRow'}>
              <AdoptechTextArea
                id="riskInherentComment"
                value={model.inherentRiskComment}
                onChange={e => {
                  const value = `${e.currentTarget.value}`;
                  updateModel(
                    m => ({ ...m, inherentRiskComment: value }),
                    true
                  );
                }}
                onBlur={() => dispatch(commitCurrentRisk())}
              />
            </div>
          </AdoptechAccordionCard>
          <AdoptechAccordionCard
            index="treatment"
            title={cardTitle(
              treatmentCompletion.every(Boolean),
              'Risk Treatment'
            )}
          >
            <RiskRegisterTreatment
              risk={model}
              update={updateModel}
              command={command}
            />
          </AdoptechAccordionCard>
          <AdoptechAccordionCard
            index="residual"
            title={cardTitle(
              residualRiskCompletion.every(Boolean),
              'Assess Residual Risk'
            )}
          >
            <div className={baseCss + '--title'}>Assess Residual Risk</div>
            <div className={baseCss + '--guidance'}>
              Estimate the likelihood and impact to determine an overall
              residual risk score. Take into consideration the controls that
              have been applied.
            </div>
            <div className={baseCss + '--fieldRow'}>
              <AdoptechReactSelect
                id="riskResidualRiskLikelihood"
                options={rangeOfFiveOptions}
                labelTooltipText="The probability the risk will occur"
                onChange={(option: SelectionOption) => {
                  updateModel(m => ({
                    ...m,
                    residualRiskLikelihood: +option.value,
                  }));
                }}
                value={rangeOfFiveOptions.find(
                  option =>
                    option.value === model.residualRiskLikelihood?.toString()
                )}
                label="Likelihood"
                placeholder="Please select"
              />
              <AdoptechReactSelect
                id="riskResidualRiskConsequence"
                options={rangeOfFiveOptions}
                onChange={(option: SelectionOption) => {
                  updateModel(m => ({
                    ...m,
                    residualRiskConsequence: +option.value,
                  }));
                }}
                value={rangeOfFiveOptions.find(
                  option =>
                    option.value === model.residualRiskConsequence?.toString()
                )}
                label="Consequence"
                placeholder="Please select"
                labelTooltipText="The impact of the risk if it occurs"
              />
              <div className={baseCss + '--riskScore'}>
                <AdoptechTooltip
                  showTooltip
                  text="Calculated by multiplying the likelihood and consequence"
                  identifier="risk-score"
                >
                  <label>Risk Score</label>
                </AdoptechTooltip>
                <VendorRegisterRiskProfile
                  riskInfo={riskInfo(model, 'residual')}
                  scale={25}
                />
              </div>
            </div>
            <p>
              Provide any additional comments (optional). These comments will
              appear in any risk reports that you generate.
            </p>
            <div className={baseCss + '--fieldRow'}>
              <AdoptechTextArea
                id="riskResidualComment"
                value={model.residualRiskComment}
                onChange={e => {
                  const value = `${e.currentTarget.value}`;
                  updateModel(
                    m => ({ ...m, residualRiskComment: value }),
                    true
                  );
                }}
                onBlur={() => dispatch(commitCurrentRisk())}
              />
            </div>
          </AdoptechAccordionCard>
        </Accordion>
      </div>
      <ConfirmationModal
        command={currentCommand}
        onCancel={() => command(null)}
      />
      <div className="autoSaveToast">
        <Toast
          autohide
          delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
          onClose={() => {
            dispatch(hideAutoSaveToast());
          }}
          show={showAutoSaveToast}
        >
          <Toast.Body>Autosave complete</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};
