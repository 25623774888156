import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { denyAccessRequest } from '../../../store/trusthubThunks';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { TrusthubAccessRequestModel } from '../../../../../swagger/trusthub';
import { AdoptechTextArea2 } from '../../../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AdoptechRadioButtonsGroup } from '../../../../../components/AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { ApplicationState } from '../../../../../types/applicationState';
import './TrustHubUserAdminDenyRequestDrawer.scss';

interface DenyRequestFormData {
  notify: boolean;
  comment: string;
}

interface DenyRequestFormProps {
  request: TrusthubAccessRequestModel;
  onClose: () => void;
}

export const TrustHubUserAdminDenyRequestDrawer: React.FC<
  DenyRequestFormProps
> = ({ request, onClose }) => {
  const baseCss = 'trustHubUserAdminDenyRequestDrawer';

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<DenyRequestFormData>({
    notify: true,
    comment: undefined,
  });
  const { denyingAccessRequestStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const dispatchSubmit = () => {
    dispatch(
      denyAccessRequest(
        {
          accessRequestId: request.id,
          notify: formData.notify,
          comment: formData.comment,
        },
        () => onClose()
      )
    );
  };

  return (
    <AdoptechHS
      title="Deny Access Request"
      show
      showConfirmationWarning={false}
      onClose={() => onClose()}
      footer={
        <>
          <AdoptechButton
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={dispatchSubmit}
            variant={AdoptechButtonVariant.Primary}
            busy={denyingAccessRequestStatus === 'loading'}
          >
            Deny
          </AdoptechButton>
        </>
      }
      extraClass="adoptechHS--medium denyAccessRequestHS"
    >
      <div className={baseCss + '--formContainer'}>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            {`You are declining the access request by ${request.trusthubVendorUser?.fullName}.`}
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechRadioButtonsGroup
              value={formData.notify ? 'yes' : 'no'}
              values={['yes', 'no']}
              labels={['Notify', 'Do not notify']}
              onChange={(value: 'yes' | 'no') => {
                setFormData({
                  ...formData,
                  notify: value === 'yes',
                });
              }}
            />
          </div>
        </div>
        {formData.notify && (
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--label'}>
              Include an optional message to the recipient.
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                rounded
                id="denyFormMessage"
                placeholder="Message&#10;E.g. a reason for denying the access request."
                value={formData.comment}
                onChange={e =>
                  setFormData({
                    ...formData,
                    comment: e.currentTarget.value,
                  })
                }
                rows={4}
                icon={faPen}
              />
            </div>
          </div>
        )}
      </div>
    </AdoptechHS>
  );
};
