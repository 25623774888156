import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';

interface Props {
  status: boolean | null;
}

export const Status: React.FC<Props> = ({ status }) => {
  if (status === true) {
    return <FontAwesomeIcon className="statusCompliant" icon={faCheckCircle} />;
  }

  if (status === false) {
    return (
      <FontAwesomeIcon className="statusFailed" icon={faExclamationCircle} />
    );
  }

  return <FontAwesomeIcon className="statusNone" icon={faCircle} />;
};
