import { History } from 'history';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useQueryParams } from '../../hooks/useQueryParams';
import { resetScrollToY } from '../../store/global/globalSlice';
import { initialise } from '../../store/global/globalThunks';
import { setCurrentVendor } from '../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import { AddOfficeAddressModal } from '../AddOfficeAddressModal/AddOfficeAddressModal';
import { AddTeamModal } from '../AddTeamModal/AddTeamModal';
import { AddUserModal } from '../AddUserModal/AddUserModal';
import { AgreementEditor } from '../Agreements/AgreementEditor/AgreementEditor';
import { ApprovalManagementModal } from '../ApprovalManagementModal/ApprovalManagementModal';
import { AssessmentsPage } from '../Assessments/Assessments';
import { AssessmentTour } from '../AssessmentTour/AssessmentTour';
import { Checklist } from '../Checklist/Checklist';
import { Checklists } from '../Checklists/Checklists';
import { ChecklistTemplate } from '../ChecklistTemplate/ChecklistTemplate';
import { FrameworkControlsPage } from '../../features/compliance/controls/FrameworkControlsPage/FrameworkControlsPage';
import { ControlsPage } from '../../features/compliance/controls/Controls/ControlsPage';
import { ComplianceFrameworks } from '../compliance/ComplianceFrameworks/ComplianceFrameworks';
import { ComplianceTasks } from '../compliance/ComplianceTasks/ComplianceTasks';
import { ConfirmSendInvitationModal } from '../ConfirmSendInvitationModal/ConfirmSendInvitationModal';
import { DeleteProfileModal } from '../DeleteProfileModal/DeleteProfileModal';
import { Download } from '../Download/Download';
import { EditOwnerModal } from '../EditOwnerModal/EditOwnerModal';
import { Enable2FAModal } from '../Enable2FAModal/Enable2FAModal';
import EscrowAccountsPage from '../EscrowAccounts/EscrowAccounts';
import FileUploadedToast from '../FileUploadedToast/FileUploadedToast';
import { TestLogPage } from '../../features/compliance/tests/TestLogPage';
import { GlobalToast } from '../GlobalToast/GlobalToast';
import { Home } from '../Home/Home';
import { InvitationSentModal } from '../InvitationSentModal/InvitationSentModal';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';
import MainMenu from '../MainMenu/MainMenu';
import PageHeader from '../PageHeader/PageHeader';
import { PaymentLandingPage } from '../payment/PaymentLandingPage/PaymentLandingPage';
import { PaymentSuccessfulToast } from '../PaymentSuccessfulToast/PaymentSuccessfulToast';
import { PolicyEditor } from '../../features/policies/PolicyEditor/PolicyEditor';
import { PolicyTour } from '../PolicyTour/PolicyTour';
import { ShareModal } from '../ShareModal/ShareModal';
import { StripeSignupError } from '../StripeSignupError/StripeSignupError';
import { StripeSignupSuccess } from '../../features/pricing/StripeSignupSuccess/StripeSignupSuccess';
import { TableAnswerDeleteModal } from '../TableAnswerDeleteModal/TableAnswerDeleteModal';
import { TableAnswerEditModal } from '../TableAnswerEditModal/TableAnswerEditModal';
import { VendorRegisterPage } from '../../features/risk/vendor/VendorRegisterPage';
import { LegalRegisterPage } from '../LegalRegister/LegalRegisterPage';
import { PestelPage } from '../pestel/PestelPage/PestelPage';
import { AuditsPage } from '../../features/compliance/audits/AuditsPage';
import { AuditsTemplates } from '../../features/compliance/audits/AuditsTemplates';
import { AuditsEditor } from '../../features/compliance/audits/AuditsEditor';
import Welcome from '../Welcome/Welcome';
import StartProfile from '../Start/StartProfile/StartProfile';
import StartCompany from '../Start/StartCompany/StartCompany';
import AgreementsTemplates from '../Agreements/AgreementsTemplates/AgreementsTemplates';
import { RiskRegistryPage } from '../RiskRegistry/RiskRegistryPage/RiskRegistryPage';
import { RiskRegistryLibrary } from '../RiskRegistry/RiskRegistryLibrary/RiskRegistryLibrary';
import { RiskRegisterEditor } from '../RiskRegistry/RiskRegistryEditor/RiskRegisterEditor';
import { RiskRegistryCategoryPage } from '../RiskRegistry/RiskRegistryCategoryPage/RiskRegistryCategoryPage';
import { CalendarPage } from '../../features/calendar/CalendarPage/CalendarPage';
import classNames from 'classnames';
import { ReportsPage } from '../Reports/ReportsPage/ReportsPage';
import { ReportsPreviewPage } from '../Reports/ReportsPreviewPage/ReportsPreviewPage';
import { ManageSubscriptionPage } from '../../features/pricing/ManageSubscriptionPage/ManageSubscriptionPage';
import { PaymentLandingPageChecker } from '../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';
import { FrameworkRequirementsPage } from '../Frameworks/Requirements/FrameworkRequirementsPage';
import { SubscriptionRequiredModal } from '../../features/pricing/SubscriptionRequiredModal/SubscriptionRequiredModal';
import { CompanyCertificatesPreviewPage } from '../../features/companyProfile/CompanyCertificatesPreviewPage/CompanyCertificatesPreviewPage';
import { TrustHubAdminPage } from '../../features/trusthub/admin/TrustHubAdminPage/TrustHubAdminPage';
import { TrustHubUserAdminPage } from '../../features/trusthub/admin/UserAdmin/TrustHubUserAdminPage/TrustHubUserAdminPage';
import { TrustHubUserAdminCompanyPage } from '../../features/trusthub/admin/UserAdmin/TrustHubUserAdminPage/TrustHubUserAdminCompanyPage/TrustHubUserAdminCompanyPage';
import { TrustHubUserAdminUserPage } from '../../features/trusthub/admin/UserAdmin/TrustHubUserAdminPage/TrustHubUserAdminUserPage/TrustHubUserAdminUserPage';
import { TrustHubAdminLayout } from '../../features/trusthub/admin/TrustHubAdminLayout/TrustHubAdminLayout';
import useChatWidgetToken from '../../hooks/useChatWidgetToken';
import { DataRoomPage } from '../../features/compliance/dataRoom/DataRoomPage/DataRoomPage';
import { DataRoomEvidencePage } from '../../features/compliance/dataRoom/DataRoomEvidencePage/DataRoomEvidencePage';
import PeoplePage from '../../features/people/PeoplePage/PeoplePage';
import { PoliciesPage } from '../../features/policies/PoliciesPage/PoliciesPage';
import { ArchivedDocumentsPage } from '../../features/policies/ArchivedDocumentsPage/ArchivedDocumentsPage';
import { AllDocumentsPage } from '../../features/policies/AllDocumentsPage/AllDocumentsPage';
import { DraftPoliciesPage } from '../../features/policies/DraftPoliciesPage/DraftPoliciesPage';

interface RoutesProps {
  history: History;
}

const ChangePasswordModal = lazy(
  () => import('../ChangePasswordModal/ChangePasswordModal')
);

const AgreementsPage = lazy(
  () => import('../Agreements/AgreementsPage/AgreementsPage')
);

const CompanyDetailsSummary = lazy(
  () => import('../CompanyDetailsSummary/CompanyDetailsSummary')
);

const IntegrationsPage = lazy(() => import('../Integrations/IntegrationsPage'));

const CompanyPolicies = lazy(
  () => import('../../features/policies/PoliciesPageTables/PoliciesPageTables')
);

const Dashboard = lazy(() => import('../Dashboard/Dashboard'));

const PdfPreviewModal = lazy(
  () => import('../PdfPreviewModal/PdfPreviewModal')
);

const HelpMenu = lazy(() => import('../HelpMenu/HelpMenu'));

export const auditsPageRoute = '/r/compliance/audits';
export const auditStartRoute = auditsPageRoute + '/new';
export const agreementsPageRoute = '/r/governance/agreements';
export const agreementShowPage = agreementsPageRoute + '/:id';
export const agreementsStartRoute = agreementsPageRoute + '/new';
export const controlsPageRoute = '/r/compliance/controls';
export const controlPageRoute = controlsPageRoute + '/:id';
export const complianceFrameworksRoute = '/r/compliance/frameworks';
export const complianceControlsRoute = '/r/compliance/frameworks/:id/controls';
export const complianceFrameworkTasksRoute =
  '/r/compliance/frameworks/:id/checks';
export const complianceTasksRoute = '/r/compliance/checks';
export const frameworkRequirementsRoute =
  '/r/compliance/frameworks/:id/requirements';
export const complianceTestsRoute = '/r/compliance/tests';
export const dataRoomRoute = '/r/compliance/dataRoom';
export const dataRoomEvidenceRoute = `${dataRoomRoute}/evidence`;
export const reportsRoute = `${dataRoomRoute}/reports`;
export const reportsPreviewRoute = reportsRoute + '/:id';

export const paymentLandingPageRoute = '/r/payment-landing-page';
export const riskRegistryRoute = '/r/riskManagement/riskRegister';
export const riskRegistryStartRoute = riskRegistryRoute + '/new';
export const calendarRoute = '/r/calendar';
export const calendarItemShowRoute = '/r/calendar/:type/:id';
export const archivedDocumentsPageRoute = '/r/governance/archived';

// TODO: remove after deploy to prod
export const oldAllDocumentsPageRoute = '/r/companyInformation/documents';
export const oldAllDocumentsPageItemRoute = `${oldAllDocumentsPageRoute}/:documentId?`;

export const allDocumentsPageRoute = '/r/governance/documents';
export const allDocumentsPageItemRoute = `${allDocumentsPageRoute}/:documentId?`;

export const policiesPageRoute = '/r/governance/policies';
const policyPageItemRoute = `${policiesPageRoute}/:vendorPolicyId`;
export const policyPageItemRouteURL = (id: string) =>
  policyPageItemRoute.replace(':vendorPolicyId', id);
export const draftPoliciesPageRoute = `${policiesPageRoute}/draft`;
export const governancePoliciesLibraryRoute = `${draftPoliciesPageRoute}/library`;
export const policyRoutes = [
  policiesPageRoute,
  allDocumentsPageRoute,
  allDocumentsPageItemRoute,
  archivedDocumentsPageRoute,
  oldAllDocumentsPageRoute,
  oldAllDocumentsPageItemRoute,
  draftPoliciesPageRoute,
];

export const riskRegistryCategoryRoute =
  '/r/riskManagement/riskRegister/categories/:id?';

export const companyProfileRoute = '/r/companyInformation/profile';
export const certificatesPreviewRoute =
  companyProfileRoute + '/certificates/:id';

export const manageSubscriptionRoute = '/r/subscription';
const portalUrl = 'https://adoptech.co.uk';
export const portalRoutes = {
  home: portalUrl,
  privacy: portalUrl + '/privacy-policy',
  terms: portalUrl + '/terms-of-service',
  prices: portalUrl + '/pricing/#pricingdetail',
  myDocument: portalUrl + '/my-document', // outdated URL
};

export const trusthubAdminPageRoute = '/r/trusthub';
export const trusthubAdminPageRequestsRoute = '/r/trusthub/requests';
export const trusthubAdminPageCompanyRoute = '/r/trusthub/companies/:id';
export const trusthubAdminPageCompaniesRoute = '/r/trusthub/companies';
export const trusthubAdminPageUserRoute = '/r/trusthub/users/:id';
export const trusthubAdminPageUsersRoute = '/r/trusthub/users';

export const trusthubRoute = '/r/trusthub';
export const isTrusthubAdminRoute = () =>
  window.location.href.includes(trusthubRoute);

export const hideBottomFooterClass = 'app--content-no-explicit-padding-bottom';
export const externalProvidersRoutes = {
  google: '/users/auth/google_oauth2',
  microsoft: '/users/auth/azure_activedirectory_v2',
};
export const welcomeRoute = '/r/welcome';
export const dashboardRoute = '/r/dashboard';
export const signupRoute = '/r/createAccount';
export const scrollContainer = 'appContentScrollArea';
export const peopleRoute = '/r/governance/people';
export const peopleComplianceRoute = peopleRoute + '/compliance';
export const peopleChartRoute = peopleRoute + '/chart';
const pestelPageRoute = '/r/riskManagement/pestel';
const vendorRegisterRoute = '/r/riskManagement/vendorRegister';
const escrowPageRoute = '/r/continuity/escrow';
const legalRegisterPageRoute = '/r/riskManagement/legalRegister';
export const riskRegistryItemRoute = riskRegistryRoute + '/:riskRegisterId';
export const Routes: React.FC<RoutesProps> = () => {
  const contentRef = useRef<HTMLDivElement>(null);

  const router = useSelector((state: ApplicationState) => state.router);
  const vendors = useSelector(
    (state: ApplicationState) => state.vendors.vendors
  );

  const {
    isInitialised,
    downloadUrl,
    scrollToY,
    backgroundColor,
    customAppContentClasses,
    isHelpMenuShowing,
  } = useSelector((state: ApplicationState) => state.global);
  const isApprovalManagementModalShowing = useSelector(
    (state: ApplicationState) => state.policies.isApprovalManagementModalShowing
  );

  const dispatch = useDispatch();

  const queryParams = useQueryParams();
  const vendorIdParam = queryParams.get('vendorId');

  const isInlinePdf = useSelector((state: ApplicationState) => {
    // When open inline we don't have "preview modal" query params, like vendorDocumentId, mode, etc
    const pdfModalAlreadyOpened = queryParams.get('basePath');
    return state.global.pdfPreviewInline && !pdfModalAlreadyOpened;
  });

  const isPreviewingPdfModal = useSelector(
    (state: ApplicationState) => state.global.isPreviewingPdf
  );

  useEffect(() => {
    if (!isInitialised) {
      dispatch(initialise());
    }
  }, [isInitialised]);

  useEffect(() => {
    if (router.action === 'POP') {
      return;
    }

    if (!contentRef.current) {
      return;
    }

    contentRef.current.scrollTo({ behavior: 'auto', left: 0, top: 0 });
  }, [router]);

  useEffect(() => {
    if (scrollToY === undefined) {
      return;
    }

    if (!contentRef.current) {
      return;
    }

    contentRef.current.scrollTo({ behavior: 'smooth', top: scrollToY });
    dispatch(resetScrollToY());
  }, [scrollToY]);

  useEffect(() => {
    if (vendorIdParam && vendors?.length) {
      const vendorToSet = vendors.find(vendor => vendor.id === vendorIdParam);
      dispatch(setCurrentVendor(vendorToSet));
    }
  }, [vendorIdParam, vendors]);

  useChatWidgetToken();

  if (!isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route path="/r/home" component={Home} />
        <Route exact path={welcomeRoute} component={Welcome} />
        <Route
          exact
          path={welcomeRoute + '/profile'}
          component={StartProfile}
        />
        <Route
          exact
          path={welcomeRoute + '/company'}
          component={StartCompany}
        />

        <Route path={trusthubAdminPageCompanyRoute}>
          <TrustHubAdminLayout>
            <TrustHubUserAdminCompanyPage />
          </TrustHubAdminLayout>
        </Route>

        <Route path={trusthubAdminPageUserRoute}>
          <TrustHubAdminLayout>
            <TrustHubUserAdminUserPage />
          </TrustHubAdminLayout>
        </Route>

        <Route
          path={[
            trusthubAdminPageRequestsRoute,
            trusthubAdminPageCompaniesRoute,
            trusthubAdminPageUsersRoute,
          ]}
        >
          <TrustHubAdminLayout>
            <TrustHubUserAdminPage />
          </TrustHubAdminLayout>
        </Route>

        <Route path={trusthubAdminPageRoute}>
          <TrustHubAdminLayout showHeaderBackButton>
            <TrustHubAdminPage />
          </TrustHubAdminLayout>
        </Route>

        <Route>
          <div className="app--lhs">
            <MainMenu />
          </div>
          <div className="app--rhs">
            <div
              // Example usage: custom classes for hide white bottom footer on landing/subscription pages
              className={classNames('app--content', customAppContentClasses, {
                'app--content-grey': backgroundColor === 'grey',
              })}
              id="app--content"
            >
              <PageHeader />
              <div id={scrollContainer} ref={contentRef}>
                <Switch>
                  <Route
                    path={manageSubscriptionRoute}
                    component={ManageSubscriptionPage}
                  />
                  <Route path={dashboardRoute}>
                    <PaymentLandingPageChecker type="dashboard">
                      <Dashboard />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route
                    path={paymentLandingPageRoute}
                    component={PaymentLandingPage}
                  />
                  <Route exact path={agreementsStartRoute}>
                    <PaymentLandingPageChecker type="agreements">
                      <AgreementsTemplates />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={agreementShowPage} component={AgreementEditor} />
                  <Route exact path={agreementsPageRoute}>
                    <PaymentLandingPageChecker type="agreements">
                      <AgreementsPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route
                    path={certificatesPreviewRoute}
                    component={CompanyCertificatesPreviewPage}
                  />
                  <Route
                    path={companyProfileRoute}
                    component={CompanyDetailsSummary}
                  />
                  <Route
                    path={reportsPreviewRoute}
                    component={ReportsPreviewPage}
                  />
                  <Route
                    path="/r/companyInformation/integrations"
                    component={IntegrationsPage}
                  />

                  <Route
                    path="/r/companyInformation/checklists/:checklistId"
                    component={Checklist}
                  />
                  <Route
                    path="/r/companyInformation/checklistTemplates/:checklistTemplateId"
                    component={ChecklistTemplate}
                  />
                  <Route
                    path="/r/companyInformation/checklists"
                    component={Checklists}
                  />
                  <Route
                    path={reportsPreviewRoute}
                    component={ReportsPreviewPage}
                  />
                  <Route path={reportsRoute}>
                    <PaymentLandingPageChecker type="reports">
                      <ReportsPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={dataRoomEvidenceRoute}>
                    <PaymentLandingPageChecker type="reports">
                      <DataRoomEvidencePage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={dataRoomRoute}>
                    <PaymentLandingPageChecker type="dataRoom">
                      <DataRoomPage />
                    </PaymentLandingPageChecker>
                  </Route>

                  <Route exact path={draftPoliciesPageRoute}>
                    <PaymentLandingPageChecker type="policies">
                      <DraftPoliciesPage />
                    </PaymentLandingPageChecker>
                  </Route>

                  <Route exact path={governancePoliciesLibraryRoute}>
                    <PaymentLandingPageChecker type="policies">
                      <PoliciesPage />
                    </PaymentLandingPageChecker>
                  </Route>

                  <Route
                    exact
                    path={policyPageItemRoute}
                    component={PolicyEditor}
                  />

                  <Route
                    path={[
                      allDocumentsPageItemRoute,
                      allDocumentsPageRoute,
                      oldAllDocumentsPageItemRoute,
                      oldAllDocumentsPageRoute,
                    ]}
                  >
                    <PaymentLandingPageChecker type="companyDocuments">
                      <AllDocumentsPage />
                    </PaymentLandingPageChecker>
                  </Route>

                  <Route path={archivedDocumentsPageRoute}>
                    <PaymentLandingPageChecker type="companyDocuments">
                      <ArchivedDocumentsPage />
                    </PaymentLandingPageChecker>
                  </Route>

                  <Route path={policiesPageRoute}>
                    <PaymentLandingPageChecker type="policies">
                      <PoliciesPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={peopleRoute} component={PeoplePage} />
                  <Route
                    exact
                    path="/r/purchase/:purchasableId/success"
                    component={StripeSignupSuccess}
                  />
                  <Route
                    exact
                    path="/r/purchase//success"
                    component={StripeSignupSuccess}
                  />
                  <Route
                    exact
                    path="/r/purchase//failure"
                    component={StripeSignupError}
                  />
                  <Route
                    path="/r/riskManagement/assessments/response/:id/tour"
                    component={AssessmentTour}
                  />
                  <Route
                    path="/r/riskManagement/assessments"
                    component={AssessmentsPage}
                  />
                  <Route path={pestelPageRoute}>
                    <PaymentLandingPageChecker type="pestel">
                      <PestelPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={vendorRegisterRoute}>
                    <PaymentLandingPageChecker type="vendorRegister">
                      <VendorRegisterPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={legalRegisterPageRoute}>
                    <LegalRegisterPage />
                  </Route>
                  <Route
                    path={riskRegistryCategoryRoute}
                    component={RiskRegistryCategoryPage}
                  />
                  <Route
                    path={riskRegistryStartRoute}
                    component={RiskRegistryLibrary}
                  />
                  <Route
                    path={riskRegistryItemRoute}
                    component={RiskRegisterEditor}
                  />
                  <Route path={riskRegistryRoute}>
                    <PaymentLandingPageChecker type="riskRegister">
                      <RiskRegistryPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={auditStartRoute} component={AuditsTemplates} />
                  <Route
                    path={auditsPageRoute + '/:id'}
                    component={AuditsEditor}
                  />
                  <Route path={auditsPageRoute}>
                    <PaymentLandingPageChecker type="audits">
                      <AuditsPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={[calendarItemShowRoute, calendarRoute]}>
                    <PaymentLandingPageChecker type="calendar">
                      <CalendarPage />
                    </PaymentLandingPageChecker>
                  </Route>

                  <Route
                    path={complianceControlsRoute}
                    component={FrameworkControlsPage}
                  />
                  <Route
                    path={frameworkRequirementsRoute}
                    component={FrameworkRequirementsPage}
                  />
                  <Route path={[controlPageRoute, controlsPageRoute]}>
                    <PaymentLandingPageChecker type="controls">
                      <ControlsPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route
                    path={[complianceFrameworkTasksRoute, complianceTasksRoute]}
                  >
                    <PaymentLandingPageChecker type="tasks">
                      <ComplianceTasks />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route
                    path={complianceFrameworksRoute}
                    component={ComplianceFrameworks}
                  />
                  <Route path={complianceTestsRoute}>
                    <PaymentLandingPageChecker type="tasks">
                      <TestLogPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Route path={escrowPageRoute}>
                    <PaymentLandingPageChecker type="escrow">
                      <EscrowAccountsPage />
                    </PaymentLandingPageChecker>
                  </Route>
                  <Redirect to={process.env.REACT_APP_DEFAULT_ROUTE} />
                </Switch>
              </div>
            </div>
          </div>
          <DeleteProfileModal />
          <Enable2FAModal />
          <AddOfficeAddressModal />
          <AddUserModal />
          <ChangePasswordModal />
          <ConfirmSendInvitationModal />
          <DeleteProfileModal />
          <ShareModal />
          <GlobalToast />
          <InvitationSentModal />
          <PaymentSuccessfulToast />
          {downloadUrl && <Download url={downloadUrl} />}
          {isPreviewingPdfModal && !isInlinePdf && <PdfPreviewModal />}
          <FileUploadedToast />
          <PolicyTour />
          <AssessmentTour />
          <TableAnswerEditModal />
          <TableAnswerDeleteModal />
          <EditOwnerModal />
          <AddTeamModal />
          <SubscriptionRequiredModal />
          {isApprovalManagementModalShowing && <ApprovalManagementModal />}
          {isHelpMenuShowing && <HelpMenu />}
        </Route>
      </Switch>
    </Suspense>
  );
};
