import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RiskCategoryModel,
  RiskModel,
  RiskTemplateModel,
  RiskExtended,
  RiskReportModel,
  ControlModel,
  VendorActionModel,
} from '../../swagger';
import { RiskRegistryState } from './riskRegistryState';

const initialState = {
  isFetchingRisks: false,
  isFetchingRisk: false,
  isFetchingCategories: false,
  isFetchingTemplates: false,
  isFetchingReports: false,
  controls: [],
  vendorActions: [],
  risks: [],
  categories: [],
  reports: [],
  currentReport: null,
  currentRisk: null,
  currentCategory: null,
  templates: [],
  isDeletingRisk: false,
  isApprovingRisk: false,
  showAutoSaveToast: false,
} as RiskRegistryState;

const risksSlice = createSlice({
  initialState,
  name: 'risksSlice',
  reducers: {
    fetchRiskControlsRequest: state => {
      state.controls = [];
    },
    fetchRiskControlsSuccess: (
      state,
      action: PayloadAction<ControlModel[]>
    ) => {
      state.controls = action.payload;
    },
    fetchRiskActionsRequest: state => {
      state.vendorActions = [];
    },
    fetchRiskActionsSuccess: (
      state,
      action: PayloadAction<VendorActionModel[]>
    ) => {
      state.vendorActions = action.payload;
    },
    fetchRisksRequest: state => {
      state.isFetchingRisks = true;
    },
    fetchRisksSuccess: (state, action: PayloadAction<RiskModel[]>) => {
      state.isFetchingRisks = false;
      state.risks = action.payload;
    },
    fetchRiskRequest: state => {
      state.isFetchingRisk = true;
    },
    fetchRiskSuccess: (state, action: PayloadAction<RiskExtended>) => {
      state.isFetchingRisk = false;
      state.currentRisk = action.payload;
    },
    fetchRisksFailure: (state, _) => {
      state.isFetchingRisks = false;
    },
    fetchRiskFailure: (state, _) => {
      state.isFetchingRisk = false;
    },
    updateCurrentRisk: (state, action: PayloadAction<RiskExtended>) => {
      state.currentRisk = action.payload;
    },
    updateCurrentRiskFailure: (state, action: PayloadAction<string>) => {
      state.currentRiskError = action.payload;
    },
    cleanCurrentRisk: state => {
      state.currentRisk = null;
    },
    fetchRisksCategoriesRequest: state => {
      state.isFetchingCategories = true;
    },
    fetchRisksCategoriesSuccess: (
      state,
      action: PayloadAction<RiskCategoryModel[]>
    ) => {
      state.isFetchingCategories = false;
      state.categories = action.payload;
    },
    fetchRisksCategoriesFailure: (state, _) => {
      state.isFetchingCategories = false;
    },
    createCustomRiskRequest: state => {
      state.isCreatingCustomRisk = true;
    },
    createCustomRiskSuccess: state => {
      state.isCreatingCustomRisk = false;
    },
    createCustomRiskFailure: (state, _) => {
      state.isCreatingCustomRisk = false;
    },

    createRiskFromTemplateRequest: state => {
      state.isCreatingRiskFromTemplate = true;
    },
    createRiskFromTemplateSuccess: (
      state,
      action: PayloadAction<RiskModel>
    ) => {
      state.isCreatingRiskFromTemplate = false;
      state.risks.push(action.payload);
    },
    createRiskFromTemplateFailure: (state, _) => {
      state.isCreatingRiskFromTemplate = false;
    },
    fetchRisksTemplatesRequest: state => {
      state.isFetchingTemplates = true;
    },
    fetchRisksTemplatesSuccess: (
      state,
      action: PayloadAction<RiskTemplateModel[]>
    ) => {
      state.isFetchingTemplates = false;
      state.templates = action.payload;
    },
    fetchRisksTemplatesFailure: (state, _) => {
      state.isFetchingTemplates = false;
    },
    deleteRiskFailure: (state, _) => {
      state.isDeletingRisk = false;
    },
    deleteRiskRequest: state => {
      state.isDeletingRisk = true;
    },
    deleteRiskSuccess: (state, action: PayloadAction<RiskModel['id']>) => {
      state.isDeletingRisk = false;
      state.risks = state.risks.filter(risk => risk.id !== action.payload);
    },
    approveRiskRequest: state => {
      state.isApprovingRisk = true;
    },
    approveRiskFailure: (state, _) => {
      state.isApprovingRisk = false;
    },
    approveRiskSuccess: (state, _) => {
      state.isApprovingRisk = false;
    },

    showAutoSaveToast: state => {
      state.showAutoSaveToast = true;
    },
    hideAutoSaveToast: state => {
      state.showAutoSaveToast = false;
    },
  },
});
export const {
  fetchRiskControlsRequest,
  fetchRiskControlsSuccess,
  fetchRiskActionsRequest,
  fetchRiskActionsSuccess,
  fetchRisksRequest,
  fetchRisksSuccess,
  fetchRisksFailure,
  fetchRiskRequest,
  fetchRiskSuccess,
  fetchRiskFailure,
  fetchRisksCategoriesFailure,
  fetchRisksCategoriesRequest,
  fetchRisksCategoriesSuccess,
  createCustomRiskFailure,
  createCustomRiskRequest,
  createCustomRiskSuccess,
  updateCurrentRisk,
  updateCurrentRiskFailure,
  cleanCurrentRisk,
  fetchRisksTemplatesRequest,
  fetchRisksTemplatesSuccess,
  fetchRisksTemplatesFailure,
  deleteRiskFailure,
  deleteRiskRequest,
  deleteRiskSuccess,
  createRiskFromTemplateFailure,
  createRiskFromTemplateRequest,
  createRiskFromTemplateSuccess,
  approveRiskFailure,
  approveRiskRequest,
  approveRiskSuccess,
  showAutoSaveToast,
  hideAutoSaveToast,
} = risksSlice.actions;

export default risksSlice.reducer;
