import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components, OptionProps } from 'react-select';
import { FolderModel } from '../../../functions/getFlatFoldersList';
import './DataRoomSelectOption.scss';

const baseCss = 'dataRoomSelectOption';

export const DataRoomSelectOption = (
  props: OptionProps<FolderModel, false>
) => {
  return (
    <div className={baseCss + `--level-${props.data.level}`}>
      <div className={baseCss + '--option'}>
        <FontAwesomeIcon className={baseCss + '--folderIcon'} icon={faFolder} />
        <components.Option {...props} />
      </div>
    </div>
  );
};
