import React from 'react';
import { PoliciesTabs } from '../PoliciesTabs/PoliciesTabs';
import PoliciesPageTables from '../PoliciesPageTables/PoliciesPageTables';

export const PoliciesPage: React.FC = () => {
  return (
    <div>
      <PoliciesTabs currentTab="live" />
      <PoliciesPageTables />
    </div>
  );
};
