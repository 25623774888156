import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../../functions/sort';
import { sortByOwner } from '../../../../functions/sortByOwner';
import {
  TrusthubVendorUserModel,
  TrusthubAccessRequestModel,
  VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum,
} from '../../../../swagger/trusthub';
import { sortByDate } from '../../../../functions/sortByDate';
import { ColumnSort } from '../../../../types/columnSort';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { ApplicationState } from '../../../../types/applicationState';
import { Grid } from '../../../../types/grid';
import Fuse from 'fuse.js';
import {
  completedAccessRequestStatuses,
  pendingAccessRequestStatuses,
} from './TrustHubUserAdminPage/TrustHubUserAdminRequestsPage/TrustHubUserAdminRequestsPage';
import { requestAsset } from './TrustHubUserAdminPage/TrustHubUserAdminRequestsPage/AccessRequestTableRow/AccessRequestTableRow';
import { VendorUser } from '../../../../swagger';

const sortAccessRequests = (
  a: TrusthubAccessRequestModel,
  b: TrusthubAccessRequestModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof TrusthubAccessRequestModel;

  if (['email'].includes(typedColumn)) {
    return sort(
      a.trusthubVendorUser?.email || '',
      b.trusthubVendorUser?.email || '',
      columnSort.direction
    );
  }

  if (['asset'].includes(typedColumn)) {
    return sort(
      requestAsset(a) || '',
      requestAsset(b) || '',
      columnSort.direction
    );
  }

  if (['company'].includes(typedColumn)) {
    return sort(
      a.trusthubVendorUser?.trusthubCompany?.name || '',
      b.trusthubVendorUser?.trusthubCompany?.name || '',
      columnSort.direction
    );
  }

  if (['trusthubVendorUser'].includes(typedColumn)) {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      columnSort.direction
    );
  }
  if (['createdAt'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'createdAt';
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

const searchAccessRequests = (
  requests: TrusthubAccessRequestModel[],
  search: string
) => {
  let filteredRequests = requests;
  const fuse = new Fuse(requests, {
    ignoreLocation: true,
    includeScore: true,
    keys: [
      'status',
      'trusthubVendorUser?.trusthubCompany?.name',
      'trusthubVendorUser.fullName',
      'trusthubVendorUser.email',
      'trusthubDocuments.name',
      'createdAt',
    ],
    threshold: 0,
  });

  if (search) {
    filteredRequests = fuse.search(search).map(x => x.item);
  }

  return [...filteredRequests];
};
// filter by showCompleted on the FE
// For example: deny request but don't fetch again all requests
const filterAccessRequests = (
  requests: TrusthubAccessRequestModel[],
  filters: { showCompleted: boolean }
) => {
  return requests.filter(request => {
    const statuses = filters.showCompleted
      ? completedAccessRequestStatuses
      : pendingAccessRequestStatuses;

    return statuses.includes(
      request.status as unknown as VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum
    );
  });
};

export const selectAccessRequests = createSelector(
  (state: ApplicationState) => state.trustHub.accessRequests,
  selectSortSettings(Grid.TrustHubUserAdminAccessRequests),
  (state: ApplicationState) => state.trustHub.accessRequestsSearch,
  (state: ApplicationState) => state.trustHub.showCompletedRequests,
  (
    requests: TrusthubAccessRequestModel[],
    sortSetting,
    search,
    showCompleted
  ) => {
    return [
      ...filterAccessRequests(searchAccessRequests(requests, search), {
        showCompleted,
      }),
    ].sort((a, b) => sortAccessRequests(a, b, sortSetting.columnSort));
  }
);
