import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrustHubState } from './trusthubState';
import {
  TrusthubVendorUserActivityModel,
  TrusthubSectionModel,
  TrusthubCompanyModel,
  TrusthubVendorUserModel,
  TrusthubDocumentPermissionModel,
  TrusthubAccessRequestModel,
  TrusthubSettingsModel,
  TrusthubUserModel,
  TrusthubDocumentModel,
  TrusthubInvitationModel,
} from '../../../swagger/trusthub';
import { postTrustHubInvitation } from './trusthubThunks';

const initialState = {
  settings: {},
  fetchingSettingsStatus: 'idle',
  updateSettingsStatus: 'idle',
  fetchingSectionsStatus: 'idle',
  sections: [],
  companies: [],
  fetchingAccessRequestsStatus: 'idle',
  fetchingSectionStatus: 'idle',
  approvingAccessRequestStatus: 'idle',
  denyingAccessRequestStatus: 'idle',
  showCompletedRequests: false,
  accessRequestsSearch: '',
  accessRequests: [],
  currentCompany: null,
  fetchingCurrentCompanyStatus: 'idle',
  patchCurrentCompanyStatus: 'idle',
  deletingCurrentCompanyStatus: 'idle',
  fetchingActivitiesStatus: 'idle',
  activities: [],
  activitiesSearch: '',
  vendorUsers: [],
  vendorUsersSearch: '',
  fetchingCompanyVendorUsers: 'idle',
  companyVendorUsers: [],
  auth: {
    modal: undefined,
    status: 'idle',
    user: undefined,
  },
  currentVendorUser: null,
  fetchingCurrentVendorUserStatus: 'idle',
  createAccessRequestStatus: 'idle',
  vendorUserDocumentPermissions: [],
  vendorUserActivities: [],
  fetchingVendorUserDocumentPermissionsStatus: 'idle',
  deletingVendorUserDocumentPermissionStatus: 'idle',
  fetchVendorDocumentFileStatus: 'idle',
  adminAuth: {
    status: 'idle',
    user: undefined,
  },
  fetchingTrusthubAdminVendorUserDetails: 'idle',
  fetchTrustHubUserDetailsRequestStatus: 'idle',
  postTrusthubInvitationStatus: 'idle',
  showRequestDocumentModal: false,
  showSettingsDrawer: false,
} as TrustHubState;

const trusthubSlice = createSlice({
  initialState,
  name: 'trusthubSlice',
  reducers: {
    fetchTrustHubSettingsRequest: state => {
      state.fetchingSettingsStatus = 'loading';
    },
    fetchTrustHubSettingsSuccess: (
      state,
      action: PayloadAction<TrusthubSettingsModel>
    ) => {
      state.fetchingSettingsStatus = 'idle';
      state.settings = action.payload;
    },
    fetchTrustHubSettingsFailure: (state, _) => {
      state.fetchingSettingsStatus = 'failed';
    },
    fetchTrustHubSettingsFailureCode: (
      state,
      action: PayloadAction<number>
    ) => {
      state.fetchingSettingsStatusCode = action.payload;
    },
    updateTrustHubSettingsRequest: state => {
      state.updateSettingsStatus = 'loading';
    },
    updateTrustHubSettingsSuccess: (
      state,
      action: PayloadAction<TrusthubSettingsModel>
    ) => {
      state.settings = { ...action.payload };
      state.updateSettingsStatus = 'idle';
    },
    updateTrustHubSettingsFailure: (state, _) => {
      state.updateSettingsStatus = 'failed';
    },

    updateSettingsColor: (state, action: PayloadAction<string>) => {
      state.settings = {
        ...state.settings,
        ...{ brandingColor: action.payload },
      };
    },

    fetchTrustHubSectionsRequest: state => {
      state.fetchingSectionsStatus = 'loading';
    },
    fetchTrustHubSectionsSuccess: (
      state,
      action: PayloadAction<TrusthubSectionModel[]>
    ) => {
      state.fetchingSectionsStatus = 'idle';
      state.sections = action.payload;
    },
    fetchTrustHubSectionsFailure: (state, _) => {
      state.fetchingSectionsStatus = 'failed';
    },
    fetchTrustHubSectionRequest: state => {
      state.fetchingSectionStatus = 'loading';
    },
    fetchTrustHubSectionSuccess: (
      state,
      action: PayloadAction<TrusthubSectionModel>
    ) => {
      state.fetchingSectionStatus = 'idle';
      state.sections = state.sections.map(section => {
        if (section.id === action.payload.id) {
          return { ...section, ...action.payload };
        }
        return section;
      });
    },
    fetchTrustHubSectionFailure: (state, _) => {
      state.fetchingSectionStatus = 'failed';
    },
    updateTrustHubSectionRequest: state => {
      state.updateSectionStatus = 'loading';
    },
    updateTrustHubSectionSuccess: (
      state,
      action: PayloadAction<TrusthubSectionModel>
    ) => {
      state.updateSectionStatus = 'idle';
      state.sections = state.sections.map(section => {
        if (section.id === action.payload.id) {
          return { ...section, ...action.payload };
        }
        return section;
      });
    },
    updateTrustHubSectionFailure: (state, _) => {
      state.updateSectionStatus = 'failed';
    },
    updateShowCompletedAccessRequests: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showCompletedRequests = action.payload;
    },
    fetchAccessRequestsRequest: state => {
      state.fetchingAccessRequestsStatus = 'loading';
    },
    fetchAccessRequestsSuccess: (
      state,
      action: PayloadAction<TrusthubAccessRequestModel[]>
    ) => {
      state.fetchingAccessRequestsStatus = 'idle';
      state.accessRequests = action.payload;
    },
    fetchAccessRequestsFailure: (state, _) => {
      state.fetchingAccessRequestsStatus = 'failed';
    },
    setTrustHubAccessRequestsSearch: (state, action: PayloadAction<string>) => {
      state.accessRequestsSearch = action.payload;
    },
    setTrustHubShowCompletedAccessRequests: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showCompletedRequests = action.payload;
    },
    denyAccessRequestRequest: state => {
      state.denyingAccessRequestStatus = 'loading';
    },
    denyAccessRequestSuccess: (
      state,
      action: PayloadAction<TrusthubAccessRequestModel>
    ) => {
      state.accessRequests = state.accessRequests.map(accessRequest => {
        if (accessRequest.id === action.payload.id) {
          return { ...accessRequest, ...action.payload };
        }
        return accessRequest;
      });
      state.denyingAccessRequestStatus = 'idle';
    },
    denyAccessRequestFailure: (state, _) => {
      state.denyingAccessRequestStatus = 'failed';
    },
    approveAccessRequestRequest: state => {
      state.approvingAccessRequestStatus = 'loading';
    },
    approveAccessRequestSuccess: (
      state,
      action: PayloadAction<TrusthubAccessRequestModel>
    ) => {
      state.accessRequests = state.accessRequests.map(accessRequest => {
        if (accessRequest.id === action.payload.id) {
          return { ...accessRequest, ...action.payload };
        }
        return accessRequest;
      });
      state.approvingAccessRequestStatus = 'idle';
    },
    approveAccessRequestFailure: (state, _) => {
      state.approvingAccessRequestStatus = 'failed';
    },
    fetchTrustHubCompaniesRequest: state => {
      state.fetchingCompaniesStatus = 'loading';
    },
    fetchTrustHubCompaniesSuccess: (
      state,
      action: PayloadAction<TrusthubCompanyModel[]>
    ) => {
      state.fetchingCompaniesStatus = 'idle';
      state.companies = action.payload;
    },
    fetchTrustHubCompaniesFailure: (state, _) => {
      state.fetchingCompaniesStatus = 'failed';
    },

    patchTrustHubVendorUserRequest: state => {
      state.patchVendorUserStatus = 'loading';
    },
    patchTrustHubVendorUserSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel>
    ) => {
      state.patchVendorUserStatus = 'idle';

      // if on /users/:id page
      if (state.currentVendorUser)
        state.currentVendorUser = {
          ...state.currentVendorUser,
          ...action.payload,
        };
    },
    patchTrustHubVendorUserFailure: (state, _) => {
      state.patchVendorUserStatus = 'failed';
    },
    fetchTrustHubVendorUsersRequest: state => {
      state.fetchingVendorUsersStatus = 'loading';
    },
    fetchTrustHubVendorUsersSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel[]>
    ) => {
      state.fetchingVendorUsersStatus = 'idle';
      state.vendorUsers = action.payload;
    },
    fetchTrustHubVendorUsersFailure: (state, _) => {
      state.fetchingVendorUsersStatus = 'failed';
    },
    postTrustHubCompanyRequest: state => {
      state.createCompanyStatus = 'loading';
    },
    postTrustHubCompanySuccess: (
      state,
      action: PayloadAction<TrusthubCompanyModel>
    ) => {
      state.createCompanyStatus = 'idle';
      state.companies.push(action.payload);
    },
    postTrustHubCompanyFailure: (state, _) => {
      state.createCompanyStatus = 'failed';
    },
    setTrustHubCompaniesSearch: (state, action: PayloadAction<string>) => {
      state.companiesSearch = action.payload;
    },
    fetchTrustHubCurrentCompanyRequest: state => {
      state.fetchingCurrentCompanyStatus = 'loading';
    },
    fetchTrustHubCurrentCompanySuccess: (
      state,
      action: PayloadAction<TrusthubCompanyModel>
    ) => {
      state.fetchingCurrentCompanyStatus = 'idle';
      state.currentCompany = action.payload;
    },
    fetchTrustHubCurrentCompanyFailure: (state, _) => {
      state.fetchingCurrentCompanyStatus = 'failed';
    },

    patchTrustHubCompanyRequest: state => {
      state.patchCurrentCompanyStatus = 'loading';
    },
    patchTrustHubCompanySuccess: (
      state,
      action: PayloadAction<TrusthubCompanyModel>
    ) => {
      state.currentCompany = { ...state.currentCompany, ...action.payload };
      state.patchCurrentCompanyStatus = 'idle';
    },
    patchTrustHubCompanyFailure: (state, _) => {
      state.patchCurrentCompanyStatus = 'failed';
    },

    deleteTrustHubCompanyRequest: state => {
      state.deletingCurrentCompanyStatus = 'loading';
    },
    deleteTrustHubCompanySuccess: state => {
      state.currentCompany = null;
      state.deletingCurrentCompanyStatus = 'idle';
    },
    deleteTrustHubCompanyFailure: (state, _) => {
      state.deletingCurrentCompanyStatus = 'failed';
    },
    fetchTrustHubActivitiesRequest: state => {
      state.fetchingActivitiesStatus = 'loading';
    },
    fetchTrustHubActivitiesSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserActivityModel[]>
    ) => {
      state.fetchingActivitiesStatus = 'idle';
      state.activities = action.payload;
    },
    fetchTrustHubActivitiesFailure: (state, _) => {
      state.fetchingActivitiesStatus = 'failed';
    },
    setTrustHubActivitiesSearch: (state, action: PayloadAction<string>) => {
      state.activitiesSearch = action.payload;
    },
    showTrustHubAuthModal: (
      state,
      action: PayloadAction<TrustHubState['auth']['modal']>
    ) => {
      state.auth.modal = action.payload;
    },
    hideTrustHubAuthModal: state => {
      state.auth.modal = undefined;
    },
    authTrustHubRequest: state => {
      state.auth.status = 'loading';
      state.auth.error = '';
    },
    authTrustHubSuccess: state => {
      state.auth.status = 'succeeded';
      state.auth.error = '';
    },
    authTrustHubIdle: state => {
      state.auth.status = 'idle';
      state.auth.error = '';
      state.auth.user = undefined;
    },
    authTrustHubFailure: (state, action: PayloadAction<string>) => {
      state.auth.status = 'failed';
      state.auth.error = action.payload;
    },

    setShowRequestDocumentModal: (state, action: PayloadAction<boolean>) => {
      state.showRequestDocumentModal = action.payload;
    },

    fetchTrustHubUserDetailsRequest: state => {
      state.fetchTrustHubUserDetailsRequestStatus = 'loading';
    },
    // use Finished instead of Failure to avoid show toast message on error
    fetchTrustHubUserDetailsFinished: state => {
      state.fetchTrustHubUserDetailsRequestStatus = 'failed';
    },
    fetchTrustHubUserDetailsSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel>
    ) => {
      state.fetchTrustHubUserDetailsRequestStatus = 'succeeded';
      state.auth.status = 'succeeded';
      state.auth.error = '';
      state.auth.user = action.payload;
    },
    setTrustHubVendorUsersSearch: (state, action: PayloadAction<string>) => {
      state.vendorUsersSearch = action.payload;
    },
    postTrustHubVendorUserRequest: state => {
      state.createVendorUserStatus = 'loading';
    },
    postTrustHubVendorUserSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel>
    ) => {
      state.createVendorUserStatus = 'idle';
      state.vendorUsers.push(action.payload);
    },
    postTrustHubVendorUserFailure: (state, _) => {
      state.createVendorUserStatus = 'failed';
    },
    fetchTrustHubCompanyVendorUsersRequest: state => {
      state.fetchingCompanyVendorUsers = 'loading';
    },
    fetchTrustHubCompanyVendorUsersSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel[]>
    ) => {
      state.fetchingCompanyVendorUsers = 'idle';
      state.companyVendorUsers = action.payload;
    },
    fetchTrustHubCompanyVendorUsersFailure: (state, _) => {
      state.fetchingCompanyVendorUsers = 'failed';
    },

    fetchTrustHubVendorUserRequest: state => {
      state.fetchingCurrentVendorUserStatus = 'loading';
    },
    fetchTrustHubVendorUserSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel>
    ) => {
      state.fetchingCurrentVendorUserStatus = 'idle';
      state.currentVendorUser = action.payload;
    },
    fetchTrustHubVendorUserFailure: (state, _) => {
      state.fetchingCurrentVendorUserStatus = 'failed';
    },
    postTrustHubAccessRequestRequest: state => {
      state.createAccessRequestStatus = 'loading';
    },
    postTrustHubAccessRequestSuccess: (
      state,
      action: PayloadAction<TrusthubAccessRequestModel>
    ) => {
      state.createAccessRequestStatus = 'idle';
      state.accessRequests.push(action.payload);
      // Set "requested" for updated documents
      const docIds = action.payload.trusthubDocuments.map(doc => doc.id);
      state.sections = state.sections.map(section => ({
        ...section,
        trusthubDocuments: section.trusthubDocuments.map(doc => ({
          ...doc,
          requested: docIds.includes(doc.id) || doc.requested,
        })),
      }));
    },
    postTrustHubAccessRequestFailure: (state, _) => {
      state.createAccessRequestStatus = 'failed';
    },
    fetchTrustHubVendorUserDocumentPermissionsRequest: state => {
      state.fetchingVendorUserDocumentPermissionsStatus = 'loading';
    },
    fetchTrustHubVendorUserDocumentPermissionsSuccess: (
      state,
      action: PayloadAction<TrusthubDocumentPermissionModel[]>
    ) => {
      state.fetchingVendorUserDocumentPermissionsStatus = 'idle';
      state.vendorUserDocumentPermissions = action.payload;
    },
    fetchTrustHubVendorUserDocumentPermissionsFailure: (state, _) => {
      state.fetchingVendorUserDocumentPermissionsStatus = 'failed';
    },

    deleteTrustHubVendorUserDocumentPermissionRequest: state => {
      state.deletingVendorUserDocumentPermissionStatus = 'loading';
    },
    deleteTrustHubVendorUserDocumentPermissionSuccess: (
      state,
      action: PayloadAction<TrusthubDocumentPermissionModel['id']>
    ) => {
      state.deletingVendorUserDocumentPermissionStatus = 'idle';
      state.vendorUserDocumentPermissions =
        state.vendorUserDocumentPermissions.filter(
          permission => permission.id !== action.payload
        );
    },
    deleteTrustHubVendorUserDocumentPermissionFailure: (state, _) => {
      state.deletingVendorUserDocumentPermissionStatus = 'failed';
    },
    fetchVendorDocumentFileRequest: state => {
      state.fetchVendorDocumentFileStatus = 'loading';
    },
    fetchVendorDocumentFileSuccess: state => {
      state.fetchVendorDocumentFileStatus = 'idle';
    },
    fetchVendorDocumentFileFailure: (state, _) => {
      state.fetchVendorDocumentFileStatus = 'failed';
    },

    setFetchingVendorDocumentIdFile: (
      state,
      action: PayloadAction<TrusthubDocumentModel['id']>
    ) => {
      state.fetchingVendorDocumentIdFile = action.payload;
    },

    fetchTrustHubVendorUserActivitiesSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserActivityModel[]>
    ) => {
      state.fetchingActivitiesStatus = 'idle';
      state.vendorUserActivities = action.payload;
    },
    fetchTrusthubAdminCurrentVendorUserRequest: state => {
      state.adminAuth.status = 'loading';
    },

    fetchTrusthubAdminCurrentVendorUserSuccess: state => {
      state.adminAuth = {
        status: 'succeeded',
        error: null,
        user: null,
      };
    },
    fetchTrusthubAdminCurrentVendorUserFailure: (state, _) => {
      state.adminAuth.status = 'failed';
    },

    fetchTrustHubInvitationStatusRequest: state => {
      state.fetchingInvitationStatus = 'loading';
    },

    fetchTrustHubInvitationStatusSuccess: (
      state,
      _action: PayloadAction<TrusthubInvitationModel>
    ) => {
      state.fetchingInvitationStatus = 'succeeded';
    },
    fetchTrustHubInvitationStatusFailure: (state, _) => {
      state.fetchingInvitationStatus = 'failed';
    },

    patchTrustHubInvitationStatusRequest: state => {
      state.patchingInvitationStatus = 'loading';
    },

    patchTrustHubInvitationStatusSuccess: state => {
      state.patchingInvitationStatus = 'succeeded';
    },
    patchTrustHubInvitationStatusFailure: (state, _) => {
      state.patchingInvitationStatus = 'failed';
    },

    fetchTrustHubAdminVendorUserDetailsRequest: state => {
      state.fetchingTrusthubAdminVendorUserDetails = 'loading';
    },

    setShowSettingsDrawer: (state, action: PayloadAction<boolean>) => {
      state.showSettingsDrawer = action.payload;
    },

    fetchTrustHubAdminVendorUserDetailsSuccess: (
      state,
      action: PayloadAction<TrusthubVendorUserModel>
    ) => {
      state.fetchingTrusthubAdminVendorUserDetails = 'succeeded';
      state.adminAuth = {
        ...state.adminAuth,
        user: action.payload,
      };
    },
    fetchTrustHubAdminVendorUserDetailsFailure: (state, _) => {
      state.fetchingTrusthubAdminVendorUserDetails = 'failed';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postTrustHubInvitation.pending, state => {
        state.postTrusthubInvitationStatus = 'loading';
      })
      .addCase(postTrustHubInvitation.fulfilled, state => {
        state.postTrusthubInvitationStatus = 'succeeded';
      })
      .addCase(postTrustHubInvitation.rejected, state => {
        state.postTrusthubInvitationStatus = 'failed';
      });
  },
});
export const {
  authTrustHubRequest,
  authTrustHubFailure,
  authTrustHubSuccess,
  authTrustHubIdle,
  fetchTrustHubUserDetailsRequest,
  fetchTrustHubUserDetailsSuccess,
  fetchTrustHubUserDetailsFinished,
  fetchTrustHubSettingsRequest,
  fetchTrustHubSettingsSuccess,
  fetchTrustHubSettingsFailure,
  updateTrustHubSettingsRequest,
  updateTrustHubSettingsSuccess,
  updateTrustHubSettingsFailure,
  updateSettingsColor,
  fetchTrustHubSectionsRequest,
  fetchTrustHubSectionsSuccess,
  fetchTrustHubSectionsFailure,
  fetchTrustHubSectionRequest,
  fetchTrustHubSectionSuccess,
  fetchTrustHubSectionFailure,
  updateTrustHubSectionRequest,
  updateTrustHubSectionSuccess,
  updateTrustHubSectionFailure,
  updateShowCompletedAccessRequests,
  fetchAccessRequestsRequest,
  fetchAccessRequestsSuccess,
  fetchAccessRequestsFailure,
  setTrustHubAccessRequestsSearch,
  setTrustHubShowCompletedAccessRequests,
  denyAccessRequestRequest,
  denyAccessRequestSuccess,
  denyAccessRequestFailure,
  approveAccessRequestRequest,
  approveAccessRequestSuccess,
  approveAccessRequestFailure,
  fetchTrustHubCompaniesRequest,
  fetchTrustHubCompaniesSuccess,
  fetchTrustHubCompaniesFailure,
  patchTrustHubVendorUserRequest,
  patchTrustHubVendorUserSuccess,
  patchTrustHubVendorUserFailure,
  fetchTrustHubVendorUsersRequest,
  fetchTrustHubVendorUsersSuccess,
  fetchTrustHubVendorUsersFailure,
  postTrustHubCompanyRequest,
  postTrustHubCompanySuccess,
  postTrustHubCompanyFailure,
  setTrustHubCompaniesSearch,
  fetchTrustHubCurrentCompanyFailure,
  fetchTrustHubCurrentCompanyRequest,
  fetchTrustHubCurrentCompanySuccess,
  patchTrustHubCompanyRequest,
  patchTrustHubCompanySuccess,
  patchTrustHubCompanyFailure,
  deleteTrustHubCompanyRequest,
  deleteTrustHubCompanySuccess,
  deleteTrustHubCompanyFailure,
  fetchTrustHubActivitiesRequest,
  fetchTrustHubActivitiesSuccess,
  fetchTrustHubActivitiesFailure,
  setTrustHubActivitiesSearch,
  showTrustHubAuthModal,
  hideTrustHubAuthModal,
  setTrustHubVendorUsersSearch,
  postTrustHubVendorUserRequest,
  postTrustHubVendorUserSuccess,
  postTrustHubVendorUserFailure,
  fetchTrustHubCompanyVendorUsersRequest,
  fetchTrustHubCompanyVendorUsersSuccess,
  fetchTrustHubCompanyVendorUsersFailure,
  fetchTrustHubVendorUserRequest,
  fetchTrustHubVendorUserSuccess,
  fetchTrustHubVendorUserFailure,
  postTrustHubAccessRequestRequest,
  postTrustHubAccessRequestSuccess,
  postTrustHubAccessRequestFailure,
  fetchTrustHubVendorUserDocumentPermissionsRequest,
  fetchTrustHubVendorUserDocumentPermissionsSuccess,
  fetchTrustHubVendorUserDocumentPermissionsFailure,
  deleteTrustHubVendorUserDocumentPermissionRequest,
  deleteTrustHubVendorUserDocumentPermissionSuccess,
  deleteTrustHubVendorUserDocumentPermissionFailure,
  fetchVendorDocumentFileRequest,
  fetchVendorDocumentFileSuccess,
  fetchVendorDocumentFileFailure,
  setFetchingVendorDocumentIdFile,
  fetchTrustHubSettingsFailureCode,
  fetchTrustHubVendorUserActivitiesSuccess,
  fetchTrusthubAdminCurrentVendorUserRequest,
  fetchTrusthubAdminCurrentVendorUserSuccess,
  fetchTrusthubAdminCurrentVendorUserFailure,
  fetchTrustHubInvitationStatusRequest,
  fetchTrustHubInvitationStatusSuccess,
  fetchTrustHubInvitationStatusFailure,
  patchTrustHubInvitationStatusRequest,
  patchTrustHubInvitationStatusSuccess,
  patchTrustHubInvitationStatusFailure,
  fetchTrustHubAdminVendorUserDetailsRequest,
  fetchTrustHubAdminVendorUserDetailsSuccess,
  fetchTrustHubAdminVendorUserDetailsFailure,
  setShowRequestDocumentModal,
  setShowSettingsDrawer,
} = trusthubSlice.actions;

export default trusthubSlice.reducer;
