import { FolderModel } from './getFlatFoldersList';
import { DataRoomEntityModel, DataRoomEntityModelTypeEnum } from '../swagger';
import { getEntityName } from '../selectors/getEntityName';

export const dataRoomPathExists = (
  entitiesTree: DataRoomEntityModel,
  folderList: FolderModel[],
  name: string,
  parentFolderId: string
) => {
  const parentPath = folderList.find(x => x.id == parentFolderId)?.path;

  if (!parentPath) return false;

  const pathSplit = parentPath.split('/').filter(p => p);

  let currentFolder = entitiesTree;
  let currentPath = '';

  for (let level = 0; level < pathSplit.length; level++) {
    currentPath = currentPath + '/' + pathSplit[level];
    currentFolder = currentFolder.children.find(
      x =>
        x.type == DataRoomEntityModelTypeEnum.Folder &&
        x.filePath == currentPath
    );
  }
  const alreadyExists = currentFolder?.children.some(x => {
    const entityName = getEntityName({
      entityName: x.name,
      entityType: x.type,
      fileType: x.fileType,
    });

    return entityName == name;
  });

  return alreadyExists;
};
