import React from 'react';
import { useDispatch } from 'react-redux';
import { PestelItemItemType, PestelItemModel } from '../../../swagger';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import {
  setPestelIdToBeDeleted,
  setShowEditDrawer,
  updatePestelItemForm,
} from '../../../store/pestel/pestelSlice';
import {
  RiskInfo,
  RiskLevel,
  VendorRegisterRiskProfile,
} from '../../VendorRegisterRiskProfile/VendorRegisterRiskProfile';
import { devLog } from '../../../functions/devLog';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { AdoptechCircleIcon } from '../../AdoptechCircleIcon/AdoptecCircleIcon';

type pestelItemFields = Pick<PestelItemModel, 'consequence' | 'likelihood'>;

export const getPestelRating = (pestelItem: pestelItemFields) => {
  return pestelItem.consequence * pestelItem.likelihood;
};

export const getPestelRiskInfo = (pestelItem: pestelItemFields): RiskInfo => {
  const severity = getPestelRating(pestelItem);
  const risk: RiskInfo = {
    riskSeverity: severity,
    riskLevel: getPestelRiskText(severity),
  };
  return risk;
};

export const getPestelRiskText = (rating: number): keyof RiskLevel => {
  if (rating >= 0 && rating <= 5) return 'Low' as keyof RiskLevel;
  if (rating >= 6 && rating <= 8) return 'Medium' as keyof RiskLevel;
  if (rating >= 9 && rating <= 15) return 'High' as keyof RiskLevel;
  if (rating >= 16 && rating <= 25) return 'Critical' as keyof RiskLevel;

  devLog('Unexpected risk RiskLevel');
  return undefined;
};

interface PestelRowProps {
  pestel: PestelItemModel;
  baseCss: string;
  baseTableCss: string;
}

export const PestelRow: React.FC<PestelRowProps> = ({
  baseCss,
  baseTableCss,
  pestel,
}) => {
  const dispatch = useDispatch();
  const canManagePestel = canFeature(AccessObject.pestel_manage);
  const isOpportunity = pestel.itemType === PestelItemItemType.Opportunity;
  return (
    <div
      key={pestel.id}
      onClick={e => {
        e.stopPropagation();
        if (!canManagePestel) return;
        dispatch(
          updatePestelItemForm({ id: pestel.id, itemType: pestel.itemType })
        );
        dispatch(setShowEditDrawer(true));
      }}
      className={classNames(
        baseCss,
        baseTableCss + '--highlighted',
        baseTableCss + '--row',
        { disabled: !canManagePestel }
      )}
    >
      <div className={baseCss + '--itemType'}>
        <AdoptechCircleIcon variant={isOpportunity ? 'lightBlue2' : 'red'}>
          {isOpportunity ? 'op' : 'ri'}
        </AdoptechCircleIcon>
      </div>
      <div>{pestel.description}</div>
      <VendorRegisterRiskProfile
        riskInfo={getPestelRiskInfo(pestel)}
        scale={25}
      />

      <div className="adoptechTable--actions">
        {canManagePestel && (
          <FontAwesomeIcon
            onClick={e => {
              e.stopPropagation();
              dispatch(setPestelIdToBeDeleted(pestel.id));
            }}
            icon={faTrashAlt}
          />
        )}
      </div>
    </div>
  );
};
