import React from 'react';
import { formatShortDate } from '../../../../../../../functions/formatShortDate';
import './CompanyTableRow.scss';
import {
  TrusthubCompanyModel,
  TrusthubCompanyStatus,
} from '../../../../../../../swagger/trusthub';
import { UserAvatar } from '../../../../../../../components/UserAvatar/UserAvatar';
import { useDispatch } from 'react-redux';
import { trusthubAdminPageCompanyRoute } from '../../../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import AdoptechOverflowLine from '../../../../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { SharedVendorUserStatus } from '../../../../../../../swagger';

interface CompanyTableRowProps {
  company: TrusthubCompanyModel;
}

export const CompanyTableRow: React.FC<CompanyTableRowProps> = ({
  company,
}) => {
  const baseCss = 'companyTableRow panelTableRow';
  const dispatch = useDispatch();
  return (
    <div
      className={baseCss}
      onClick={() =>
        dispatch(push(trusthubAdminPageCompanyRoute.replace(':id', company.id)))
      }
    >
      <AdoptechOverflowLine className={baseCss + '--name'}>
        {company.name}
      </AdoptechOverflowLine>
      <div className={baseCss + '--ndaStatus'}>
        {company.status === TrusthubCompanyStatus.WaitingForNda ? 'No' : 'Yes'}
      </div>
      <div className={baseCss + '--owner'}>
        {(company.relationshipOwners || []).map(owner => {
          return (
            <UserAvatar
              key={owner.id}
              user={{ ...owner, status: SharedVendorUserStatus.Null }}
              size="small"
              hideText
            />
          );
        }) || '-'}
      </div>
      <div className={baseCss + '--createdAt'}>
        {formatShortDate(company.createdAt)}
      </div>
      <AdoptechOverflowLine className={baseCss + '--createdBy'}>
        {company.createdBy?.fullName || '-'}
      </AdoptechOverflowLine>
      <div className={baseCss + '--status'}>
        {company.status === TrusthubCompanyStatus.Approved
          ? 'Enabled'
          : 'Disabled'}
      </div>
    </div>
  );
};
