import { TRUSTHUB_BASE_PATH, trusthubApi } from '../../../api/api';
import { api as mainProjectApi } from '../../../../src/api/api';
import {
  getErrorMessage,
  handleThunkError,
} from '../../../store/helpers/thunkHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AccessRequestsAccessRequestIdApprovePatchRequest,
  AccessRequestsAccessRequestIdDenyPatchRequest,
  CompaniesCompanyIdDeleteRequest,
  CompaniesCompanyIdGetRequest,
  CompaniesCompanyIdPatchRequest,
  DocumentPermissionsDocumentPermissionIdDeleteRequest,
  DocumentsDocumentIdDownloadGetRequest,
  SectionsSectionIdGetRequest,
  SectionsSectionIdPatchRequest,
  TrusthubAccessRequestModel,
  TrusthubCompanyModel,
  TrusthubSectionModel,
  VendorTrusthubsVendorIdOrDomainAccessRequestsGetRequest,
  VendorTrusthubsVendorIdOrDomainAccessRequestsPostRequest,
  VendorTrusthubsVendorIdOrDomainCompaniesGetRequest,
  VendorTrusthubsVendorIdOrDomainCompaniesPostRequest,
  VendorTrusthubsVendorIdOrDomainSectionsGetRequest,
  VendorTrusthubsVendorIdOrDomainSettingsGetRequest,
  VendorTrusthubsVendorIdOrDomainSettingsPatchRequest,
  VendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRequest,
  VendorTrusthubsVendorIdOrDomainVendorUsersGetRequest,
  VendorTrusthubsVendorIdOrDomainVendorUsersPostRequest,
  VendorUsersVendorUserIdDocumentPermissionsGetRequest,
  VendorUsersVendorUserIdGetRequest,
  VendorUsersVendorUserIdPatchRequest,
  TrusthubSettingsModel,
  TrusthubVendorUserModel,
  TrusthubInvitationModel,
  UsersInvitationPatchRequest,
  UsersInvitationsIdGetRequest,
  TrusthubInvitationCreatePayload,
} from '../../../swagger/trusthub';
import { VoidThunk } from '../../../types/voidThunk';
import {
  authTrustHubFailure,
  authTrustHubRequest,
  authTrustHubSuccess,
  authTrustHubIdle,
  fetchTrustHubUserDetailsSuccess,
  denyAccessRequestFailure,
  denyAccessRequestRequest,
  denyAccessRequestSuccess,
  fetchAccessRequestsFailure,
  fetchAccessRequestsRequest,
  fetchAccessRequestsSuccess,
  fetchTrustHubCompaniesFailure,
  fetchTrustHubCompaniesRequest,
  fetchTrustHubCompaniesSuccess,
  fetchTrustHubSectionFailure,
  fetchTrustHubSectionRequest,
  fetchTrustHubSectionSuccess,
  fetchTrustHubSectionsFailure,
  fetchTrustHubSectionsRequest,
  fetchTrustHubSectionsSuccess,
  fetchTrustHubSettingsFailure,
  fetchTrustHubSettingsRequest,
  fetchTrustHubSettingsSuccess,
  fetchTrustHubVendorUsersFailure,
  fetchTrustHubVendorUsersRequest,
  fetchTrustHubVendorUsersSuccess,
  patchTrustHubVendorUserFailure,
  patchTrustHubVendorUserRequest,
  patchTrustHubVendorUserSuccess,
  postTrustHubCompanyFailure,
  postTrustHubCompanyRequest,
  postTrustHubCompanySuccess,
  updateTrustHubSectionFailure,
  updateTrustHubSectionRequest,
  updateTrustHubSectionSuccess,
  updateTrustHubSettingsFailure,
  updateTrustHubSettingsRequest,
  updateTrustHubSettingsSuccess,
  approveAccessRequestRequest,
  approveAccessRequestSuccess,
  approveAccessRequestFailure,
  fetchTrustHubCurrentCompanyRequest,
  fetchTrustHubCurrentCompanySuccess,
  fetchTrustHubCurrentCompanyFailure,
  patchTrustHubCompanySuccess,
  patchTrustHubCompanyRequest,
  patchTrustHubCompanyFailure,
  deleteTrustHubCompanySuccess,
  deleteTrustHubCompanyFailure,
  deleteTrustHubCompanyRequest,
  fetchTrustHubActivitiesFailure,
  fetchTrustHubActivitiesRequest,
  fetchTrustHubActivitiesSuccess,
  postTrustHubVendorUserRequest,
  postTrustHubVendorUserSuccess,
  postTrustHubVendorUserFailure,
  fetchTrustHubCompanyVendorUsersRequest,
  fetchTrustHubCompanyVendorUsersSuccess,
  fetchTrustHubCompanyVendorUsersFailure,
  fetchTrustHubVendorUserRequest,
  fetchTrustHubVendorUserSuccess,
  fetchTrustHubVendorUserFailure,
  postTrustHubAccessRequestRequest,
  postTrustHubAccessRequestSuccess,
  postTrustHubAccessRequestFailure,
  fetchTrustHubVendorUserDocumentPermissionsRequest,
  fetchTrustHubVendorUserDocumentPermissionsSuccess,
  fetchTrustHubVendorUserDocumentPermissionsFailure,
  deleteTrustHubVendorUserDocumentPermissionRequest,
  deleteTrustHubVendorUserDocumentPermissionSuccess,
  deleteTrustHubVendorUserDocumentPermissionFailure,
  hideTrustHubAuthModal,
  fetchVendorDocumentFileFailure,
  fetchVendorDocumentFileSuccess,
  fetchVendorDocumentFileRequest,
  setFetchingVendorDocumentIdFile,
  fetchTrustHubSettingsFailureCode,
  fetchTrustHubVendorUserActivitiesSuccess,
  fetchTrusthubAdminCurrentVendorUserRequest,
  fetchTrusthubAdminCurrentVendorUserSuccess,
  fetchTrusthubAdminCurrentVendorUserFailure,
  fetchTrustHubInvitationStatusRequest,
  fetchTrustHubInvitationStatusSuccess,
  fetchTrustHubInvitationStatusFailure,
  patchTrustHubInvitationStatusRequest,
  patchTrustHubInvitationStatusSuccess,
  patchTrustHubInvitationStatusFailure,
  fetchTrustHubAdminVendorUserDetailsRequest,
  fetchTrustHubAdminVendorUserDetailsSuccess,
  fetchTrustHubAdminVendorUserDetailsFailure,
  fetchTrustHubUserDetailsRequest,
  fetchTrustHubUserDetailsFinished,
  showTrustHubAuthModal,
} from './trusthubSlice';
import { getMetaContent } from '../../../functions/getMetaContent';
import { getApiErrorMessage } from '../../../functions/getApiErrorMessage';
import { getRequest } from '../../../functions/getRequest';
import { VendorsIdVendorUsersTrusthubLoginPostRequest } from '../../../swagger';
import { trustHubRoutes } from '../user/TrustHubUserRoutes';

export const loginTrustHub =
  (email: string, password: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(authTrustHubRequest());
      const payload = JSON.stringify({
        trusthub_user: {
          email,
          password,
        },
      });
      const response = await fetch('/trusthub/api/v1/users/sign_in', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });

      if (response.status === 201) {
        dispatch(fetchTrustHubUserDetails());
        dispatch(hideTrustHubAuthModal());
      } else {
        const message = await getApiErrorMessage(response);
        dispatch(authTrustHubFailure(message));
      }
      // TODO: update CSRF-token
      window.location.reload();
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred logging in. Please try again or contact support.'
        );
        console.log(err);
        dispatch(authTrustHubFailure(apiErrorMessage));
      }
    }
  };

export const logoutTrustHub = (): VoidThunk => async dispatch => {
  try {
    dispatch(authTrustHubRequest());
    await fetch('/trusthub/api/v1/users/sign_out', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaContent('csrf-token'),
      },
      method: 'DELETE',
    });
    dispatch(authTrustHubIdle());
    // TODO: update CSRF-token
    window.location.reload();
  } catch (err) {
    if (err instanceof Response) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred logging out. Please try again or contact support.'
      );
      console.log(err);
      dispatch(authTrustHubFailure(apiErrorMessage));
    }
  }
};

export const registerTrustHub =
  (
    {
      firstName,
      lastName,
      email,
      password,
    }: { firstName: string; lastName: string; email: string; password: string },
    onSuccess: () => void
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(authTrustHubRequest());
      const domain = getState().trustHub.settings.domain;
      const payload = JSON.stringify({
        trusthub_user: {
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          password_confirmation: password,
          domain,
        },
      });
      const response = await fetch('/trusthub/api/v1/users', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });
      if (response.status === 201) {
        dispatch(showTrustHubAuthModal(null));
        dispatch(authTrustHubIdle());
        onSuccess();
      } else {
        const message = await getApiErrorMessage(response);
        dispatch(authTrustHubFailure(message));
      }
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred requesting account. Please try again or contact support.'
        );
        console.log(err);
        dispatch(authTrustHubFailure(apiErrorMessage));
      }
    }
  };

export const fetchTrustHubUserDetails = (): VoidThunk => async dispatch => {
  try {
    dispatch(authTrustHubRequest());
    dispatch(fetchTrustHubUserDetailsRequest());
    const response = await trusthubApi().userDetailsGet();
    dispatch(fetchTrustHubUserDetailsSuccess(response));
    dispatch(authTrustHubSuccess());
  } catch (err) {
    dispatch(fetchTrustHubUserDetailsFinished());
    if (err instanceof Response && err.status === 401) {
      dispatch(authTrustHubIdle());
      return;
    }
    const apiErrorMessage = await getErrorMessage(
      err,
      'An error occurred while fetching trusthub user details. Please try again or contact support.'
    );
    console.log(err);
    dispatch(authTrustHubFailure);
  }
};
// user flow
export const fetchTrustHubVendorUserDetails =
  (vendorTrusthubId: TrusthubSettingsModel['domain']): VoidThunk =>
  async dispatch => {
    try {
      dispatch(authTrustHubRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGet(
          { vendorTrusthubId }
        );
      dispatch(fetchTrustHubUserDetailsSuccess(response));
      dispatch(authTrustHubSuccess());
    } catch (err) {
      if (err instanceof Response) {
        if (err.status === 401) {
          dispatch(authTrustHubIdle());
          return;
        }
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching trusthub settings. Please try again or contact support.'
        );
      }
      dispatch(authTrustHubFailure);
    }
  };

// admin flow
export const fetchTrustHubAdminVendorUserDetails =
  (
    vendorTrusthubId: TrusthubSettingsModel['domain'],
    onSuccess?: (response: TrusthubVendorUserModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubAdminVendorUserDetailsRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGet(
          { vendorTrusthubId }
        );
      dispatch(fetchTrustHubAdminVendorUserDetailsSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub user details. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubAdminVendorUserDetailsFailure);
    }
  };

export const recoveryTrustHubUserPassword =
  (email: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const domain = getState().trustHub.settings.domain;
      dispatch(authTrustHubRequest());
      const response = await trusthubApi().usersPasswordPost({
        body: { trusthubUser: { email, domain } },
      });
      dispatch(authTrustHubIdle());
      dispatch(hideTrustHubAuthModal());
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while recovery trusthub password. Please try again or contact support.'
        );
        console.log(err);
      }
      dispatch(hideTrustHubAuthModal());
      dispatch(authTrustHubFailure());
    }
  };

export const resetTrustHubUserPassword =
  (password: string, token: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(authTrustHubRequest());
      const payload = JSON.stringify({
        trusthub_user: {
          password,
          password_confirmation: password,
          reset_password_token: token,
        },
      });
      const response = await fetch('/trusthub/api/v1/users/password', {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'PATCH',
      });

      if (response.status === 204) {
        // TODO: update CSRF-token
        // redirect to user root page. ex: /trusthub/july_22
        window.location.href = window.location.href.split(
          trustHubRoutes.resetPassword
        )[0];
      } else {
        const message = await getApiErrorMessage(response);
        dispatch(authTrustHubFailure(message));
      }
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while resetting trusthub password. Please try again or contact support.'
        );
        console.log(err);
      }
      dispatch(authTrustHubFailure());
    }
  };

export const fetchTrustHubSettings =
  (
    params: VendorTrusthubsVendorIdOrDomainSettingsGetRequest,
    onSuccess?: (settings: TrusthubSettingsModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubSettingsRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainSettingsGet(params);
      dispatch(fetchTrustHubSettingsSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub settings. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubSettingsFailure(apiErrorMessage));
      dispatch(fetchTrustHubSettingsFailureCode(err.status));
    }
  };

export const updateTrustHubSettings =
  (
    payload: VendorTrusthubsVendorIdOrDomainSettingsPatchRequest,
    onSuccess: (settings: TrusthubSettingsModel) => void,
    setLoadingStatus = true // don't show spinner on changing toggle switcher "Publish"
  ): VoidThunk =>
  async dispatch => {
    try {
      if (setLoadingStatus) dispatch(updateTrustHubSettingsRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainSettingsPatch(
          payload
        );
      dispatch(updateTrustHubSettingsSuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating trusthub settings. Please try again or contact support.'
      );
      console.log(err);
      dispatch(updateTrustHubSettingsFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubSections =
  (
    params: VendorTrusthubsVendorIdOrDomainSectionsGetRequest,
    onSuccess: (sections: TrusthubSectionModel[]) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubSectionsRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainSectionsGet(params);
      dispatch(fetchTrustHubSectionsSuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub sections. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubSectionsFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubSection =
  (
    params: SectionsSectionIdGetRequest,
    onSuccess: (section: TrusthubSectionModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubSectionRequest());
      const response = await trusthubApi().sectionsSectionIdGet(params);
      dispatch(fetchTrustHubSectionSuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub section. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubSectionFailure(apiErrorMessage));
    }
  };

export const updateTrustHubSection =
  (
    params: SectionsSectionIdPatchRequest,
    onSuccess: (section: TrusthubSectionModel) => void,
    setLoadingStatus = true // don't show spinner on changing toggle switcher "section.visible"
  ): VoidThunk =>
  async dispatch => {
    try {
      if (setLoadingStatus) dispatch(updateTrustHubSectionRequest());
      const response = await trusthubApi().sectionsSectionIdPatch(params);
      dispatch(updateTrustHubSectionSuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating trusthub section. Please try again or contact support.'
      );
      console.log(err);
      dispatch(updateTrustHubSectionFailure(apiErrorMessage));
    }
  };

export const fetchAccessRequests =
  (
    params: VendorTrusthubsVendorIdOrDomainAccessRequestsGetRequest
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAccessRequestsRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainAccessRequestsGet(
          params
        );
      dispatch(fetchAccessRequestsSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching access request. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchAccessRequestsFailure(apiErrorMessage));
    }
  };

export const denyAccessRequest =
  (
    params: AccessRequestsAccessRequestIdDenyPatchRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(denyAccessRequestRequest());
      const response =
        await trusthubApi().accessRequestsAccessRequestIdDenyPatch(params);
      dispatch(denyAccessRequestSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while denying access request. Please try again or contact support.'
      );
      console.log(err);
      dispatch(denyAccessRequestFailure(apiErrorMessage));
    }
  };
export const approveAccessRequest =
  (
    params: AccessRequestsAccessRequestIdApprovePatchRequest,
    onSuccess?: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(approveAccessRequestRequest());
      const response =
        await trusthubApi().accessRequestsAccessRequestIdApprovePatch(params);
      dispatch(approveAccessRequestSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while approving access request. Please try again or contact support.'
      );
      console.log(err);
      dispatch(approveAccessRequestFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubCompanies =
  (
    params: VendorTrusthubsVendorIdOrDomainCompaniesGetRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubCompaniesRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainCompaniesGet(params);
      dispatch(fetchTrustHubCompaniesSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching companies. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubCompaniesFailure(apiErrorMessage));
    }
  };

// Use cases:
// 1) During approving request on /requests page
// 2) During removing company user on /companies/:id page
// 3) During patching on /users/:id

export const patchTrusthubVendorUser =
  (
    params: VendorUsersVendorUserIdPatchRequest,
    onSuccess?: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchTrustHubVendorUserRequest());
      const response = await trusthubApi().vendorUsersVendorUserIdPatch(params);
      dispatch(patchTrustHubVendorUserSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while patching trusthub vendor user. Please try again or contact support.'
      );
      console.log(err);
      dispatch(patchTrustHubVendorUserFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubVendorUsers =
  (
    params: VendorTrusthubsVendorIdOrDomainVendorUsersGetRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubVendorUsersRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainVendorUsersGet({
          ...params,
          perPage: '100',
        });
      dispatch(fetchTrustHubVendorUsersSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub vendor users. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubVendorUsersFailure(apiErrorMessage));
    }
  };

export const postTrusthubCompany =
  (
    params: VendorTrusthubsVendorIdOrDomainCompaniesPostRequest,
    onSuccess?: (company: TrusthubCompanyModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postTrustHubCompanyRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainCompaniesPost(
          params
        );
      dispatch(postTrustHubCompanySuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while posting trusthub company. Please try again or contact support.'
      );
      console.log(err);
      dispatch(postTrustHubCompanyFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubCompany =
  (
    params: CompaniesCompanyIdGetRequest,
    onSuccess: (company: TrusthubCompanyModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubCurrentCompanyRequest());
      const response = await trusthubApi().companiesCompanyIdGet(params);
      dispatch(fetchTrustHubCurrentCompanySuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub company. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubCurrentCompanyFailure(apiErrorMessage));
    }
  };

export const patchTrusthubCompany =
  (params: CompaniesCompanyIdPatchRequest, onSuccess: () => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchTrustHubCompanyRequest());
      const response = await trusthubApi().companiesCompanyIdPatch(params);
      dispatch(patchTrustHubCompanySuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while patching trusthub company. Please try again or contact support.'
      );
      console.log(err);
      dispatch(patchTrustHubCompanyFailure(apiErrorMessage));
    }
  };

export const deleteTrusthubCompany =
  (params: CompaniesCompanyIdDeleteRequest, onSuccess: () => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteTrustHubCompanyRequest());
      await trusthubApi().companiesCompanyIdDelete(params);
      dispatch(deleteTrustHubCompanySuccess());
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting trusthub company. Please try again or contact support.'
      );
      console.log(err);
      dispatch(deleteTrustHubCompanyFailure(apiErrorMessage));
    }
  };

// 2 cases
// on /requests page - all activites
// on users/:id page for user activity

export const fetchTrustHubActivities =
  (
    params: VendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubActivitiesRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainVendorUsersActivityGet(
          params
        );

      if (params.trusthubVendorUserId) {
        dispatch(fetchTrustHubVendorUserActivitiesSuccess(response.data));
      } else {
        dispatch(fetchTrustHubActivitiesSuccess(response.data));
      }
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub activities. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubActivitiesFailure(apiErrorMessage));
    }
  };

export const postTrusthubVendorUser =
  (
    params: VendorTrusthubsVendorIdOrDomainVendorUsersPostRequest,
    onSuccess: (user: TrusthubVendorUserModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postTrustHubVendorUserRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainVendorUsersPost(
          params
        );
      dispatch(postTrustHubVendorUserSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while posting trusthub user. Please try again or contact support.'
      );
      console.log(err);
      dispatch(postTrustHubVendorUserFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubCompanyVendorUsers =
  (
    params: VendorTrusthubsVendorIdOrDomainVendorUsersGetRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubCompanyVendorUsersRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainVendorUsersGet(
          params
        );
      dispatch(fetchTrustHubCompanyVendorUsersSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub company vendor users. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubCompanyVendorUsersFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubVendorUser =
  (
    params: VendorUsersVendorUserIdGetRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubVendorUserRequest());
      const response = await trusthubApi().vendorUsersVendorUserIdGet(params);
      dispatch(fetchTrustHubVendorUserSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching trusthub vendor user. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubVendorUserFailure(apiErrorMessage));
    }
  };

export const postTrustHubAccessRequest =
  (
    params: VendorTrusthubsVendorIdOrDomainAccessRequestsPostRequest,
    onSuccess: (user: TrusthubAccessRequestModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postTrustHubAccessRequestRequest());
      const response =
        await trusthubApi().vendorTrusthubsVendorIdOrDomainAccessRequestsPost(
          params
        );

      dispatch(postTrustHubAccessRequestSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while posting trusthub access request. Please try again or contact support.'
      );
      console.log(err);
      dispatch(postTrustHubAccessRequestFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubVendorUserDocumentPermissions =
  (params: VendorUsersVendorUserIdDocumentPermissionsGetRequest): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubVendorUserDocumentPermissionsRequest());
      const response =
        await trusthubApi().vendorUsersVendorUserIdDocumentPermissionsGet(
          params
        );
      dispatch(fetchTrustHubVendorUserDocumentPermissionsSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching vendor user document permissions. Please try again or contact support.'
      );
      console.log(err);
      dispatch(
        fetchTrustHubVendorUserDocumentPermissionsFailure(apiErrorMessage)
      );
    }
  };

export const deleteTrustHubVendorUserDocumentPermission =
  (
    params: DocumentPermissionsDocumentPermissionIdDeleteRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteTrustHubVendorUserDocumentPermissionRequest());
      await trusthubApi().documentPermissionsDocumentPermissionIdDelete(params);
      dispatch(
        deleteTrustHubVendorUserDocumentPermissionSuccess(
          params.documentPermissionId
        )
      );
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while removing user document permission. Please try again or contact support.'
      );
      console.log(err);
      dispatch(
        deleteTrustHubVendorUserDocumentPermissionFailure(apiErrorMessage)
      );
    }
  };

export const fetchTrustHubVendorDocumentFile =
  (
    params: DocumentsDocumentIdDownloadGetRequest,
    onSuccess: (url: string) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setFetchingVendorDocumentIdFile(params.documentId));
      dispatch(fetchVendorDocumentFileRequest());
      // TODO: fix BE swag void type issue
      const response = await getRequest(
        `${TRUSTHUB_BASE_PATH}/documents/${params.documentId}/download`
      );
      const convertedData = await response.blob();
      const url = URL.createObjectURL(convertedData);
      dispatch(fetchVendorDocumentFileSuccess());
      onSuccess(url);
      dispatch(setFetchingVendorDocumentIdFile(null));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while downloading vendor document file Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchVendorDocumentFileFailure(apiErrorMessage));
      dispatch(setFetchingVendorDocumentIdFile(null));
    }
  };

export const fetchTrusthubAdminCurrentVendorUser =
  (
    params: VendorsIdVendorUsersTrusthubLoginPostRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrusthubAdminCurrentVendorUserRequest());

      await mainProjectApi().vendorsIdVendorUsersTrusthubLoginPost(params);

      dispatch(fetchTrusthubAdminCurrentVendorUserSuccess());
      onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred during trusthub admin vendor user login. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrusthubAdminCurrentVendorUserFailure(apiErrorMessage));
    }
  };

export const fetchTrustHubInvitation =
  (
    params: UsersInvitationsIdGetRequest,
    onSuccess: (model: TrusthubInvitationModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchTrustHubInvitationStatusRequest());
      const response = await trusthubApi().usersInvitationsIdGet(params);

      dispatch(fetchTrustHubInvitationStatusSuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred during fetching trusthub invitation details. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchTrustHubInvitationStatusFailure(apiErrorMessage));
      // redirect to root page
      await new Promise(resolve => setTimeout(resolve, 1000));
      window.location.href = window.location.href.split(
        trustHubRoutes.registerInvite
      )[0];
    }
  };

export const patchTrustHubInvitation =
  (params: UsersInvitationPatchRequest, onSuccess: () => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchTrustHubInvitationStatusRequest());
      await trusthubApi().usersInvitationPatch(params);

      dispatch(patchTrustHubInvitationStatusSuccess());
      onSuccess();
      // TODO: update CSRF-token
      // redirect to user root page. ex: /trusthub/july_22
      window.location.href = window.location.href.split(
        trustHubRoutes.registerInvite
      )[0];
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred during patching trusthub invitation. Please try again or contact support.'
      );
      console.log(err);
      dispatch(patchTrustHubInvitationStatusFailure(apiErrorMessage));
    }
  };

export const postTrustHubInvitation = createAsyncThunk(
  'trusthub/postTrustHubInvitation',
  async (params: TrusthubInvitationCreatePayload, { dispatch }) => {
    try {
      await trusthubApi().usersInvitationPost({
        body: params,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting trusthub invitation. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
