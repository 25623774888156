import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmailAddress } from '../../../functions/isValidEmailAddress';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import {
  createUser,
  fetchVendorUser,
  fetchVendorUsers,
  updateUser,
} from '../../../store/vendors/vendorsThunks';
import {
  VendorUser,
  VendorUserCreatePayloadVendorUsers,
  VendorUserUpdatePayloadVendorUser,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { EditUser } from '../../../components/EditUser/EditUser';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { UserForm } from '../../../components/UserForm/UserForm';
import './ManagePeopleDrawer.scss';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import { EditUserCompliance } from './EditUserCompliance/EditUserCompliance';

interface ManagePeopleDrawerProps {
  user: VendorUser;
  onClose(): void;
  editTab?: EditTabsEnum;
}

export enum AddTabsEnum {
  Bulk = 'Bulk add people',
  Single = 'Add person',
}

export enum EditTabsEnum {
  Profile = 'Profile',
  Compliance = 'Compliance',
}

export type PeopleDrawerPayload =
  | VendorUserCreatePayloadVendorUsers
  | VendorUserUpdatePayloadVendorUser;

export const ManagePeopleDrawer: React.FC<ManagePeopleDrawerProps> = ({
  user,
  onClose,
  editTab,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState<PeopleDrawerPayload>();
  const [selectedAddTab, setSelectedAddTab] = useState<AddTabsEnum>(
    AddTabsEnum.Single
  );
  const [selectedEditTab, setSelectedEditTab] = useState<EditTabsEnum>(
    editTab || EditTabsEnum.Profile
  );
  const [formTouched, setFormTouchedStatus] = useState<boolean>(false);

  const currentVendor = useSelector(selectCurrentVendor);
  const vendorId = currentVendor?.id;
  const { isFetchingVendorUser } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userDetails
  );
  const emailsSplitterRegexp = /[, \n]+/;
  // ref needed to toggle confirm close dialog logic
  const drawerBackgroundRef = useRef<HTMLDivElement>();
  const convertEmailsListToArray = (emailsList: string | null) => {
    return (emailsList || '').split(emailsSplitterRegexp);
  };

  const isEmailsListValid = (emails: string | null) => {
    return emails
      ?.split(emailsSplitterRegexp)
      .every(email => isValidEmailAddress(email));
  };

  const emailOrEmailsList = (data as VendorUserCreatePayloadVendorUsers)?.email;

  const formValid = !!(formTouched &&
  emailOrEmailsList &&
  selectedAddTab === AddTabsEnum.Single
    ? isValidEmailAddress(emailOrEmailsList)
    : isEmailsListValid(emailOrEmailsList));

  const dispatch = useDispatch();

  useEffect(() => {
    setFormTouchedStatus(false);
  }, [selectedAddTab]);

  useEffect(() => {
    if (user) {
      const onSuccess = (userExtended: VendorUser) => {
        const {
          email,
          firstName,
          lastName,
          position,
          roles,
          externalIdentifiers,
          employmentStartedAt,
          employmentEndedAt,
          employmentStatus,
          userType,
        } = userExtended;
        setData({
          email,
          firstName,
          lastName,
          position,
          // @ts-ignore
          roles, // TODO
          externalIdentifiers,
          userType,
          employmentStatus,
          employmentStartedAt,
          employmentEndedAt,
          lineManagerId: userExtended.lineManager?.id,
          vendorTeamsIds: userExtended.vendorTeams?.map(team => team.id),
        });
      };
      dispatch(fetchVendorUser(user.id, onSuccess));
    }
  }, [user?.id]);

  const submit = () => {
    if (!formTouched) {
      setFormTouchedStatus(true);
    }

    if (formValid) {
      setButtonDisabled(true);
      user
        ? dispatch(
            updateUser({
              vendorUserId: user.id,
              vendorUser: data as VendorUserUpdatePayloadVendorUser,
              onSuccess: () => {
                // if role changed, reload left menu
                if (user.userId === currentUser.id) {
                  window.location.reload();
                }
                dispatch(fetchVendorUsers(currentVendor.id));
                onClose();
              },
            })
          )
        : dispatch(
            createUser({
              vendorId,
              //@ts-ignore
              vendorUsers:
                selectedAddTab === AddTabsEnum.Single
                  ? [data as VendorUserCreatePayloadVendorUsers]
                  : [
                      ...new Set(convertEmailsListToArray(emailOrEmailsList)),
                    ].map((userEmail: string) => ({
                      ...data,
                      email: userEmail,
                    })),
              onSuccess: onClose,
              onError: () => setButtonDisabled(false),
            })
          );
    }
  };

  const Footer = (
    <>
      <AdoptechButton
        variant={AdoptechButtonVariant.White}
        onClick={() => drawerBackgroundRef.current.click()}
      >
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={buttonDisabled}
        onClick={submit}
        variant={AdoptechButtonVariant.Primary}
      >
        {user ? 'DONE' : 'ADD'}
      </AdoptechButton>
    </>
  );

  return (
    <AdoptechHS
      noFormPadding
      title={user ? user.fullName : 'Add people'}
      show
      ref={drawerBackgroundRef}
      onClose={onClose}
      showConfirmationWarning={formTouched}
      footer={Footer}
      extraClass="managePeopleDrawer"
    >
      {isFetchingVendorUser ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="managePeopleDrawer--formContainer">
            {!user ? (
              <Tabs
                activeKey={selectedAddTab}
                onSelect={(key: string) => {
                  setSelectedAddTab(key as AddTabsEnum);
                }}
              >
                <Tab title={AddTabsEnum.Single} eventKey={AddTabsEnum.Single}>
                  {selectedAddTab === AddTabsEnum.Single && (
                    <UserForm
                      user={data}
                      userId={user?.id}
                      selectedTab={selectedAddTab}
                      formValid={formValid}
                      formTouched={formTouched}
                      onChange={(payload: typeof data) => {
                        setFormTouchedStatus(true);
                        setData(payload);
                      }}
                    />
                  )}
                </Tab>
                <Tab title={AddTabsEnum.Bulk} eventKey={AddTabsEnum.Bulk}>
                  {selectedAddTab === AddTabsEnum.Bulk && (
                    <UserForm
                      user={data}
                      userId={user?.id}
                      selectedTab={selectedAddTab}
                      formValid={formValid}
                      formTouched={formTouched}
                      onChange={(payload: typeof data) => {
                        setFormTouchedStatus(true);
                        setData(payload);
                      }}
                    />
                  )}
                </Tab>
              </Tabs>
            ) : (
              <Tabs
                activeKey={selectedEditTab}
                onSelect={(key: string) => {
                  setSelectedEditTab(key as unknown as EditTabsEnum);
                }}
              >
                <Tab
                  title={EditTabsEnum.Profile}
                  eventKey={EditTabsEnum.Profile}
                >
                  {selectedEditTab === EditTabsEnum.Profile && (
                    <EditUser
                      user={data}
                      userId={user?.id}
                      onChangeUser={(payload: typeof data) => {
                        setFormTouchedStatus(true);
                        setData(payload);
                      }}
                    />
                  )}
                </Tab>
                <Tab
                  title={EditTabsEnum.Compliance}
                  eventKey={EditTabsEnum.Compliance}
                >
                  {selectedEditTab === EditTabsEnum.Compliance && (
                    <EditUserCompliance userId={user?.id} />
                  )}
                </Tab>
              </Tabs>
            )}
          </div>
        </>
      )}
    </AdoptechHS>
  );
};
