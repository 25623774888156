import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import { ToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import {
  VendorIntegrationModelServiceStatusEnum,
  VendorIntegrationSyncCreatePayloadVendorIntegrationSyncDirectionEnum,
} from '../../../swagger';
import {
  createIntegrationSync,
  removeIntegrationSync,
} from '../../../store/integrations/integrationsSlice';
import { push } from 'connected-react-router';
import './SyncUserDrawer.scss';
import { AdoptechRadioButtonsGroup } from '../../../components/AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { selectIntegrations } from '../../../selectors/selectIntegrations';
import { selectCurrentUsersProviderAndSync } from '../../../selectors/selectCurrentUsersSync';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { formatShortDate } from '../../../functions/formatShortDate';
import { formatTime } from '../../../functions/formatTime';

const baseCss = 'syncUserDrawer';

interface Props {
  show: boolean;
  close: () => void;
  inProgress: boolean;
}

export const SyncUserDrawer: React.FC<Props> = ({
  show,
  close,
  inProgress,
}) => {
  const dispatch = useDispatch();

  const integrations = useSelector(selectIntegrations);
  const [currentProvider, currentSync] = useSelector(
    selectCurrentUsersProviderAndSync
  );

  const [provider, setProvider] = useState<string>(currentProvider?.type);
  const [syncEnabled, setSyncEnabled] = useState<boolean>(!!currentSync);
  const [syncFrequency, setSyncFrequency] = useState<string>(
    currentSync?.daily ? 'daily' : 'one-off'
  );

  useEffect(() => {
    setProvider(currentProvider?.type);
    setSyncEnabled(!!currentProvider);
    setSyncFrequency(currentSync?.daily ? 'daily' : 'one-off');
  }, [currentProvider, currentSync]);

  const availableProvider = integrations.find(i => i.type === provider);

  const providerOk =
    !!availableProvider?.id &&
    availableProvider?.serviceStatus ===
      VendorIntegrationModelServiceStatusEnum.Ok;

  const enableSync = () => {
    dispatch(
      createIntegrationSync(
        availableProvider?.id,
        {
          direction:
            VendorIntegrationSyncCreatePayloadVendorIntegrationSyncDirectionEnum.Incoming,
          subtype: 'users',
          daily: syncFrequency === 'daily',
        },
        close,
        close
      )
    );
  };

  const disableSync = () =>
    dispatch(removeIntegrationSync(currentSync?.id, close, close));

  const submit = () => {
    (syncEnabled ? enableSync : disableSync)();
  };

  const handleAnchor = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');
    dispatch(push(url));
  };

  const syncMessage = currentSync?.payload?.base;
  const syncFailed = currentSync?.state === 'failed';

  return (
    <AdoptechHS
      title="Sync users"
      extraClass={baseCss}
      show={show}
      onClose={close}
      footer={
        <Footer
          close={close}
          submit={submit}
          enable={syncEnabled}
          disabled={
            !availableProvider ||
            (!syncEnabled && !currentSync) ||
            (syncEnabled && !syncFrequency)
          }
          busy={inProgress}
        />
      }
      showConfirmationWarning
    >
      <ProviderSection value={provider} setValue={setProvider} />
      {provider &&
        (providerOk ? (
          <SwitchSection value={syncEnabled} setValue={setSyncEnabled} />
        ) : (
          <p>
            This sync source is not yet configured, to set it up in Apps &
            Integrations click&nbsp;
            <a
              href="/r/companyInformation/integrations#IdentityProviders"
              onClick={handleAnchor}
            >
              here.
            </a>
          </p>
        ))}
      {providerOk && syncEnabled && (
        <FrequencySection value={syncFrequency} setValue={setSyncFrequency} />
      )}
      <br />
      {availableProvider && currentSync?.lastSyncedAt && (
        <div className={`${baseCss}--info`}>
          <FontAwesomeIcon icon={faCircleInfo} />
          <p>
            Last sync on {formatShortDate(currentSync?.lastSyncedAt)} at{' '}
            {formatTime(currentSync?.lastSyncedAt)}
          </p>
        </div>
      )}
      {syncMessage && syncFailed && (
        <div className={`${baseCss}--warning`}>
          <FontAwesomeIcon icon={faCircleInfo} />
          <p>{syncMessage}</p>
        </div>
      )}
    </AdoptechHS>
  );
};

interface SectionProps<T> {
  value: T;
  setValue(source: T): void;
}

const ProviderSection: React.FC<SectionProps<string>> = ({
  value: provider,
  setValue: setProvider,
}) => (
  <div className={`${baseCss}--section`}>
    <div className={`${baseCss}--subtitle`}>
      1. Select the source you want to synchronise with
    </div>
    <AdoptechRadioButtonsGroup
      labels={['Google', 'Azure']}
      values={['google_workspace', 'azure']}
      value={provider}
      onChange={setProvider}
    />
  </div>
);

const SwitchSection: React.FC<SectionProps<boolean>> = ({
  value: syncEnabled,
  setValue: setSyncEnabled,
}) => (
  <div className={`${baseCss}--section`}>
    <div className={`${baseCss}--subtitle`}>2. Enable user sync</div>
    <p>
      Syncing will automatically add and delete users from the portal to match
      the sync source. Users with Admin, Auditor or External Consultant Role
      permissions, or User type of Guest, will not be affected.
    </p>
    <ToggleSwitch
      label="Enable user sync"
      checked={syncEnabled}
      onChange={() => setSyncEnabled(!syncEnabled)}
    />
  </div>
);

const FrequencySection: React.FC<SectionProps<string>> = ({
  value: frequency,
  setValue: setFrequency,
}) => (
  <div className={`${baseCss}--section`}>
    <div className={`${baseCss}--subtitle`}>3. Choose the sync frequency</div>
    <AdoptechRadioButtonsGroup
      labels={['One-off', 'Daily']}
      values={['one-off', 'daily']}
      value={frequency}
      onChange={setFrequency}
    />
  </div>
);

interface FooterProps {
  close: () => void;
  submit: () => void;
  enable: boolean;
  disabled: boolean;
  busy: boolean;
}

const Footer: React.FC<FooterProps> = ({
  close,
  submit,
  enable,
  disabled,
  busy,
}) => {
  return (
    <>
      <AdoptechButton onClick={close} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={disabled || busy}
        onClick={submit}
        variant={AdoptechButtonVariant.Primary}
      >
        {enable ? (
          busy ? (
            <>
              Syncing <LoadingSpinner inlineSmall />
            </>
          ) : (
            'Sync'
          )
        ) : (
          'Save'
        )}
      </AdoptechButton>
    </>
  );
};
