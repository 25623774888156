import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrgChart } from '../../../store/vendors/vendorsThunks';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { DropZone } from '../../../components/DropZone/DropZone';
import './UploadOrgChartModal.scss';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';

export interface UploadOrgChartModalProps {
  onSubmit(): void;
  onCancel(): void;
}

export const UploadOrgChartModal: React.FC<UploadOrgChartModalProps> = ({
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const isUpdatingOrgChart = useSelector(
    (state: ApplicationState) => state.vendors.isUpdatingOrgChart
  );
  const currentVendor = useSelector(selectCurrentVendor);
  const [file, setFile] = useState<File>(null);

  const upload = () => {
    dispatch(
      updateOrgChart({
        id: currentVendor.id,
        image: file,
        onSuccess: () => {
          showGlobalToast('Org chart has been sucessfully uploaded.');
          onSubmit();
        },
      })
    );
  };

  return (
    <>
      <AdoptechModal className="uploadOrgChartModal" onHide={() => {}} show>
        <Modal.Header>Upload your Org chart</Modal.Header>
        <Modal.Body className="uploadOrgChartModal--body">
          <DropZone
            accept=".jpg,.jpeg,.png"
            onChange={setFile}
            additionalDescription="We accept .jpg and .png formats and files should be no larger than 2mb"
          />
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={onCancel}>Cancel</AdoptechButton>
          <AdoptechButton
            busy={isUpdatingOrgChart}
            disabled={!file}
            uppercase
            onClick={upload}
            variant={AdoptechButtonVariant.Primary}
          >
            upload
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </>
  );
};
