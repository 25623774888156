import { createSelector } from '@reduxjs/toolkit';

import { TaskExtended } from '../swagger';
import { complianceTasksSort } from '../functions/complianceTasksSort';
import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { getComplianceTasks } from '../functions/getComplianceTasks';

export const selectFilteredTasks = (search: string) =>
  createSelector(
    getComplianceTasks,
    selectSortSettings(Grid.ComplianceTasks),
    (tasks: TaskExtended[], sortSetting) => {
      const filteredTasks = [...tasks];
      return filteredTasks.sort((a, b) =>
        complianceTasksSort(a, b, sortSetting.columnSort)
      );
    }
  );
