import React from 'react';
import {
  TrusthubAccessRequestModel,
  TrusthubSharedStatus,
} from '../../../../../../../swagger/trusthub';
import { formatShortDate } from '../../../../../../../functions/formatShortDate';
import { capitalize } from '../../../../../../../functions/capitalize';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AccessRequestTableRow.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import {
  trusthubAdminPageCompanyRoute,
  trusthubAdminPageUserRoute,
} from '../../../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import AdoptechOverflowLine from '../../../../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';

interface AccessRequestTableRowProps {
  request: TrusthubAccessRequestModel;
  setShowDenyRequestDrawer: React.Dispatch<any>;
  handleApprove: (request: TrusthubAccessRequestModel) => void;
}

export const requestAsset = (request: TrusthubAccessRequestModel) => {
  return request.all
    ? 'All'
    : (request.trusthubDocuments || []).map(doc => doc.name).join(', ');
};

export const AccessRequestTableRow: React.FC<AccessRequestTableRowProps> = ({
  request,
  setShowDenyRequestDrawer,
  handleApprove,
}) => {
  const baseCss = 'panelTableRow';
  const {
    denyingAccessRequestStatus,
    approvingAccessRequestStatus,
    showCompletedRequests,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const dispatch = useDispatch();
  const isPending = request.status === TrusthubSharedStatus.Pending;
  return (
    <div
      className={baseCss}
      style={{ cursor: isPending ? 'pointer' : '' }}
      onClick={() => {
        if (isPending) {
          handleApprove(request);
          return;
        }
      }}
    >
      {request.trusthubVendorUser?.trusthubCompany ? (
        <AdoptechOverflowLine className={baseCss + '--company'}>
          <div
            onClick={e => {
              if (isPending) {
                return;
              }

              e.stopPropagation();

              const url = trusthubAdminPageCompanyRoute.replace(
                ':id',
                request.trusthubVendorUser?.trusthubCompany?.id
              );
              dispatch(push(url));
            }}
          >
            {request.trusthubVendorUser?.trusthubCompany?.name}
          </div>
        </AdoptechOverflowLine>
      ) : (
        <div>-</div>
      )}
      <AdoptechOverflowLine className={baseCss + '--user'}>
        <div
          onClick={e => {
            if (isPending) {
              return;
            }

            e.stopPropagation();
            const url = trusthubAdminPageUserRoute.replace(
              ':id',
              request.trusthubVendorUser?.id
            );
            dispatch(push(url));
          }}
        >
          {request.trusthubVendorUser?.fullName || '-'}
        </div>
      </AdoptechOverflowLine>
      <AdoptechOverflowLine className={baseCss + '--asset'}>
        {requestAsset(request)}
      </AdoptechOverflowLine>
      <div className={baseCss + '--requestOn'}>
        {formatShortDate(request.createdAt)}
      </div>
      <div className={baseCss + '--status'}>{capitalize(request.status)}</div>
      <div className={baseCss + '--actions'}>
        {showCompletedRequests ? (
          '-'
        ) : (
          <>
            {denyingAccessRequestStatus === 'loading' ||
            approvingAccessRequestStatus === 'loading' ? (
              <LoadingSpinner inlineSmall />
            ) : (
              <>
                <a className={baseCss + '--approveButton'}>
                  <FontAwesomeIcon icon={faCheck} />
                </a>
                <a
                  className={baseCss + '--denyButton'}
                  onClick={e => {
                    e.stopPropagation();
                    setShowDenyRequestDrawer(request);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </a>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
