import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../../functions/sort';
import { sortByDate } from '../../../../functions/sortByDate';
import { ColumnSort } from '../../../../types/columnSort';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { ApplicationState } from '../../../../types/applicationState';
import { Grid } from '../../../../types/grid';
import Fuse from 'fuse.js';
import { TrusthubVendorUserActivityModel } from '../../../../swagger/trusthub/models/TrusthubVendorUserActivityModel';

const sortActivities = (
  a: TrusthubVendorUserActivityModel,
  b: TrusthubVendorUserActivityModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof TrusthubVendorUserActivityModel;

  if (['company'].includes(typedColumn)) {
    return sort(
      a.trusthubVendorUser?.trusthubCompany?.name || '',
      b.trusthubVendorUser?.trusthubCompany?.name || '',
      columnSort.direction
    );
  }

  if (['trusthubVendorUser'].includes(typedColumn)) {
    return sort(
      a.trusthubVendorUser?.fullName || '',
      b.trusthubVendorUser?.fullName || '',
      columnSort.direction
    );
  }

  if (['createdAt'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'createdAt';
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

const searchActivities = (
  activities: TrusthubVendorUserActivityModel[],
  search: string
) => {
  let filteredActivities = activities;
  const fuse = new Fuse(activities, {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name', 'createdAt'],
    threshold: 0,
  });

  if (search) {
    filteredActivities = fuse.search(search).map(x => x.item);
  }

  return [...filteredActivities];
};

export const selectActivities = createSelector(
  (state: ApplicationState) => state.trustHub.activities,
  selectSortSettings(Grid.TrustHubUserAdminActivities),
  (state: ApplicationState) => state.trustHub.activitiesSearch,
  (Activities: TrusthubVendorUserActivityModel[], sortSetting, search) => {
    return [...searchActivities(Activities, search)].sort((a, b) =>
      sortActivities(a, b, sortSetting.columnSort)
    );
  }
);
