import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadOrgChartModal } from '../UploadOrgChartModal/UploadOrgChartModal';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import './PeopleChart.scss';
import { ApplicationState } from '../../../types/applicationState';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { canCreate } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { faCloudUploadAlt } from '@fortawesome/pro-light-svg-icons/faCloudUploadAlt';
import { PeopleTabs } from '../PeopleTabs/PeopleTabs';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import classNames from 'classnames';
import {
  downloadOrgChart,
  fetchOrgChart,
} from '../../../store/vendors/vendorsThunks';

export const PeopleChart: React.FC = () => {
  const dispatch = useDispatch();
  const { orgChart, fetchOrgChartStatus, downloadOrgChartStatus } = useSelector(
    (state: ApplicationState) => state.vendors
  );
  const isFetchingOrgChart = fetchOrgChartStatus === 'loading';
  const isDownloadingFile = downloadOrgChartStatus === 'loading';
  const currentVendor = useSelector(selectCurrentVendor);
  const [showUploadOrgChartModal, setShowUploadOrgChartModal] = useState(false);
  const canEdit = canCreate(AccessObject.vendor_details);
  useEffect(() => {
    dispatch(fetchOrgChart(currentVendor?.id));
  }, [currentVendor?.id]);

  const hasOrgChart = orgChart?.filename;
  const handleDownloadClick = async () => {
    dispatch(downloadOrgChart(currentVendor?.id));
  };
  const fetchOrgChartRequestFinished = fetchOrgChartStatus === 'succeeded';
  return (
    <div className="people">
      <div className="people--sticky">
        <PeopleTabs currentTab="chart" />
        <div className="people--header">
          <div className="people--title">Org Chart</div>
          {fetchOrgChartRequestFinished && (
            <>
              <FontAwesomeIcon
                className={classNames({ statusCompliant: hasOrgChart })}
                icon={faCheckCircle}
              />
              <div className="people--controls">
                {hasOrgChart && (
                  <AdoptechButton
                    onClick={handleDownloadClick}
                    icon={faCloudDownloadAlt}
                    iconSize="large"
                    rounded
                    busy={isDownloadingFile}
                  >
                    Download
                  </AdoptechButton>
                )}
                {canEdit && (
                  <AdoptechButton
                    onClick={() => setShowUploadOrgChartModal(true)}
                    variant={AdoptechButtonVariant.PrimaryOrange}
                    icon={faCloudUploadAlt}
                    iconSize="large"
                    rounded
                  >
                    Upload
                  </AdoptechButton>
                )}{' '}
              </div>
            </>
          )}
        </div>
        <div className="orgChart">
          {showUploadOrgChartModal && (
            <UploadOrgChartModal
              onSubmit={() => setShowUploadOrgChartModal(false)}
              onCancel={() => setShowUploadOrgChartModal(false)}
            />
          )}
          <div className="orgChart--content">
            {isFetchingOrgChart ? (
              <LoadingSpinner />
            ) : !hasOrgChart ? (
              'No org chart loaded'
            ) : (
              <>
                {orgChart?.urls.full && (
                  <img
                    className="orgChart--image"
                    src={orgChart?.urls.full}
                    alt="Organization Chart"
                  />
                )}
                <div className="orgChart--details">
                  <div className="orgChart--fileName">{orgChart.filename}</div>
                  <div className="orgChart--actions"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
