import './RiskRegistryCategoryPage.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Lozenge } from '../../Lozenge/Lozenge';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import {
  createRiskFromTemplate,
  deleteRisk,
  fetchRisks,
  fetchRisksCategories,
  fetchRisksTemplates,
} from '../../../store/riskRegistry/riskRegistryThunks';
import { selectRisks } from '../../../selectors/riskRegistry/selectRisks';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { RiskModel } from '../../../swagger/models/RiskModel';
import {
  riskRegistryCategoryRoute,
  riskRegistryRoute,
  riskRegistryStartRoute,
} from '../../Routes/Routes';
import { push } from 'connected-react-router';
import { AdoptechPageNavBar } from '../../AdoptechPageNavBar/AdoptechPageNavBar';
import { useParams } from 'react-router';
import { RiskCategoryModel, RiskTemplateModel } from '../../../swagger';
import classNames from 'classnames';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import arrowLeftIcon from 'images/icons/arrowLeftIcon.svg';

import AdoptechGridTable from '../../AdoptechGridTable/AdoptechGridTable';
import { Grid } from '../../../types/grid';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { NoDataText } from '../../NoDataText/NoDataText';
import { ConfirmationModal } from '../../ConfirmationModal/ConfirmationModal';
import { selectRisksTemplates } from '../../../selectors/riskRegistry/selectRisksTemplates';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

const useRiskIdFromParams = () => {
  const params = useParams() as { id: RiskCategoryModel['id'] };
  return params.id;
};

const RiskCategoryFilter: React.FC = () => {
  const baseCss = 'riskCategoryFilter';

  const categories = useSelector(
    (state: ApplicationState) => state.riskRegistry.categories
  );
  const paramsId = useRiskIdFromParams();
  const selectedCategory = categories.find(
    category => category.id === paramsId
  );
  const dispatch = useDispatch();
  const handleClick = (id?: string) => {
    const uri = riskRegistryCategoryRoute.replace(':id', id || '');
    dispatch(push(uri));
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Filter</div>
      <div className={baseCss + '--categoryFilter'}>
        <div
          className={classNames(baseCss + '--categoryFilterItem', {
            selected: !selectedCategory,
          })}
          onClick={() => handleClick()}
        >
          All risks
        </div>
        {categories.map(category => (
          <div
            className={classNames(baseCss + '--categoryFilterItem', {
              selected: selectedCategory?.id === category.id,
            })}
            key={category.id}
            onClick={() => handleClick(category.id)}
          >
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );
};

interface RiskTableProps {
  risks: RiskModel[];
}

const RisksTable: React.FC<RiskTableProps> = ({ risks }) => {
  const baseCss = 'riskCategoryPage';
  const dispatch = useDispatch();
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  // using such naming to preserve opportunity refactor it into currentCommand in redux store
  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const removeCommand = (model: RiskModel): CommandConfirmation => ({
    title: 'Confirm Remove',
    subject: { name: model.name, type: 'Risk' },
    description: 'Click CONFIRM to remove this risk from the register.',
    buttonVariant: AdoptechButtonVariant.Warning,
    onConfirm: () =>
      dispatch(deleteRisk(model.id, () => dispatch(fetchRisks(vendorId)))),
  });

  return (
    <div className={baseCss + '--3-columns'}>
      <AdoptechGridTable
        header={
          <div className="adoptechGridTable--header">
            <SortableTableHeader<RiskModel>
              columnName="name"
              grid={Grid.Risks}
              label="Risk name"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="description"
              grid={Grid.Risks}
              label="Description"
              notInTable
            />
          </div>
        }
      >
        {risks.length === 0 && (
          <NoDataText text="Select + Add to add risks to your register." />
        )}
        {risks.map(risk => {
          return (
            <div key={risk.id} className="adoptechGridTable--row">
              <div>{risk.name}</div>
              <div>{risk.description}</div>
              <div className="adoptechGridTable--row-button">
                <AdoptechButton
                  onClick={() => command(removeCommand(risk))}
                  variant={AdoptechButtonVariant.White}
                  iconSize="large"
                  icon={faMinus}
                  rounded
                >
                  Remove
                </AdoptechButton>
              </div>
            </div>
          );
        })}
      </AdoptechGridTable>

      <ConfirmationModal
        command={currentCommand}
        onCancel={() => command(null)}
      />
    </div>
  );
};

interface RegisterRisksProps {
  risks: RiskModel[];
}

const RegisterRisks: React.FC<RegisterRisksProps> = ({ risks }) => {
  const baseCss = 'riskCategoryPage';
  const dispatch = useDispatch();
  return (
    <div className={baseCss + '--registerRisks'}>
      <div className={baseCss + '--header'}>
        <div className={baseCss + '--title'}>
          Added to register
          <Lozenge
            value={risks.length}
            className="lozenge lozenge--light-grey"
          />
        </div>

        <AdoptechButton
          onClick={() => dispatch(push(riskRegistryRoute))}
          variant={AdoptechButtonVariant.PrimaryOrange}
          rawIcon={arrowLeftIcon}
          iconSize="large"
          rounded
        >
          Return to risk register
        </AdoptechButton>
      </div>
      <RisksTable risks={risks} />
    </div>
  );
};

interface TemplatesTableProps {
  templates: RiskTemplateModel[];
}
const TemplatesTable: React.FC<TemplatesTableProps> = ({ templates }) => {
  const baseCss = 'riskCategoryPage';
  const dispatch = useDispatch();
  return (
    <div className={baseCss + '--3-columns'}>
      <AdoptechGridTable
        header={
          <div className="adoptechGridTable--header">
            <SortableTableHeader<RiskTemplateModel>
              columnName="name"
              grid={Grid.RisksTemplates}
              label="Risk name"
              notInTable
            />
            <SortableTableHeader<RiskTemplateModel>
              columnName="description"
              grid={Grid.RisksTemplates}
              label="Description"
              notInTable
            />
          </div>
        }
      >
        {templates.length === 0 && (
          <NoDataText text="No templates available. Please contact administator or remove existing risks." />
        )}
        {templates.map(template => {
          return (
            <div key={template.id} className="adoptechGridTable--row">
              <div>{template.name}</div>
              <div>{template.description}</div>
              <div className="adoptechGridTable--row-button">
                <AdoptechButton
                  onClick={() =>
                    dispatch(
                      createRiskFromTemplate({ riskTemplateId: template.id })
                    )
                  }
                  variant={AdoptechButtonVariant.OrangeOnWhite}
                  icon={faPlus}
                  iconSize="large"
                  rounded
                >
                  Add
                </AdoptechButton>
              </div>
            </div>
          );
        })}
      </AdoptechGridTable>
    </div>
  );
};

export const RiskTemplates: React.FC<TemplatesTableProps> = ({ templates }) => {
  const baseCss = 'riskCategoryPage';

  const paramsId = useRiskIdFromParams();
  const { categories } = useSelector(
    (state: ApplicationState) => state.riskRegistry
  );

  const categoryTitle = !paramsId
    ? 'All risks'
    : categories.find(category => category.id === paramsId)?.name + ' risks';

  return (
    <>
      <div className={baseCss + '--header'}>
        <div className={baseCss + '--title'}>
          {categoryTitle}
          <Lozenge
            value={templates.length}
            className="lozenge lozenge--light-grey"
          />
        </div>
      </div>
      <TemplatesTable templates={templates} />
    </>
  );
};

export const RiskRegistryCategoryPage: React.FC = () => {
  const baseCss = 'riskCategoryPage';

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const vendorUser = useSelector(selectCurrentVendorUser);
  const [searchText, setSearchText] = useState<string>('');
  const canManageRegister = canFeature(AccessObject.risk_register_update);
  const paramsId = useRiskIdFromParams();
  const filteredRisks: RiskModel[] = useSelector(
    selectRisks(searchText, ['name', 'description'])
  ).filter(risk => !paramsId || risk.riskCategoryId === paramsId);
  const {
    isFetchingRisks,
    categories,
    isFetchingCategories,
    isFetchingTemplates,
    isDeletingRisk,
    isCreatingRiskFromTemplate,
  } = useSelector((state: ApplicationState) => state.riskRegistry);
  const templates = useSelector(selectRisksTemplates(searchText, paramsId));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRisks(vendorId));
    dispatch(fetchRisksCategories());
    dispatch(fetchRisksTemplates());
  }, [vendorId, vendorUser.roles]);

  const goBack = () => dispatch(push(riskRegistryStartRoute));
  const categoryTitle = !paramsId
    ? 'All risks'
    : categories.find(category => category.id === paramsId)?.name + ' risks';
  if (
    isFetchingRisks ||
    isFetchingCategories ||
    isFetchingTemplates ||
    isDeletingRisk ||
    isCreatingRiskFromTemplate
  ) {
    return <LoadingSpinner />;
  }

  if (!canManageRegister)
    return (
      <div style={{ marginTop: '10px' }}>
        <NoDataText
          text="There are no abilities to change the risk category. Please contact to the
        admin"
        />
      </div>
    );
  return (
    <div className={baseCss}>
      <AdoptechPageNavBar
        goBack={goBack}
        content={
          <>
            <div className={baseCss + '--title'}>{categoryTitle}</div>
            <div className={baseCss + '--search'}>
              <AdoptechTextInput
                id="search"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search for risks"
                icon={faSearch}
                additionalClass="adoptechTextInput-search"
              />
            </div>
            <RiskCategoryFilter />
          </>
        }
      />
      <RegisterRisks risks={filteredRisks} />
      <RiskTemplates templates={templates} />
    </div>
  );
};
