import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyProfileState } from './companyProfileState';
import { CertificateModel } from '../../../swagger/models/CertificateModel';

const companyProfileSlice = createSlice({
  initialState: {
    // company profile page
    certificates: [],
    fetchingCertificatesStatus: 'idle',
    deletingCertificateStatus: 'idle',
    creatingCertificateStatus: 'idle',
    // preview page
    currentCertificate: null,
    fetchingCertificateStatus: 'idle',
    fetchingCertificateDocumentStatus: 'idle',
  } as unknown as CompanyProfileState,
  name: 'companyProfileSlice',
  reducers: {
    fetchCertificatesRequest: state => {
      state.fetchingCertificatesStatus = 'idle';
    },
    fetchCertificatesSuccess: (
      state,
      action: PayloadAction<CertificateModel[]>
    ) => {
      state.certificates = action.payload;
      state.fetchingCertificatesStatus = 'succeeded';
    },
    fetchCertificatesFailure: (state, action: PayloadAction<string>) => {
      state.fetchingCertificatesStatus = 'failed';
    },
    deleteCertificateRequest: state => {
      state.deletingCertificateStatus = 'loading';
    },
    deleteCertificateSuccess: (
      state,
      action: PayloadAction<CertificateModel['id']>
    ) => {
      state.certificates = state.certificates.filter(
        certificate => certificate.id !== action.payload
      );
      state.deletingCertificateStatus = 'succeeded';
    },
    deleteCertificateFailure: (state, action: PayloadAction<string>) => {
      state.deletingCertificateStatus = 'failed';
    },
    createCertificateRequest: state => {
      state.creatingCertificateStatus = 'loading';
    },
    createCertificateSuccess: (
      state,
      action: PayloadAction<CertificateModel>
    ) => {
      let existing = false;
      state.certificates = state.certificates.map(certificate => {
        if (certificate.id === action.payload.id) {
          existing = true;
          return action.payload;
        }
        return certificate;
      });

      if (!existing) {
        state.certificates.push(action.payload);
      }

      state.creatingCertificateStatus = 'succeeded';
    },

    createCertificateFailure: (state, action: PayloadAction<string>) => {
      state.deletingCertificateStatus = 'failed';
    },

    fetchCertificateRequest: state => {
      state.fetchingCertificateStatus = 'loading';
    },
    fetchCertificateSuccess: (
      state,
      action: PayloadAction<CertificateModel>
    ) => {
      state.currentCertificate = action.payload;
      state.fetchingCertificateStatus = 'succeeded';
    },
    fetchCertificateFailure: (state, action: PayloadAction<string>) => {
      state.fetchingCertificateStatus = 'failed';
    },
    cleanCurrentCertificate: state => {
      state.currentCertificate = null;
    },

    fetchCertificateDocumentRequest: state => {
      state.fetchingCertificateDocumentStatus = 'loading';
    },
    fetchCertificateDocumentSuccess: state => {
      state.fetchingCertificateDocumentStatus = 'succeeded';
    },
    fetchCertificateDocumentFailure: (state, action: PayloadAction<string>) => {
      state.fetchingCertificateDocumentStatus = 'failed';
    },
  },
});

export const {
  fetchCertificatesRequest,
  fetchCertificatesSuccess,
  fetchCertificatesFailure,
  deleteCertificateRequest,
  deleteCertificateSuccess,
  deleteCertificateFailure,
  createCertificateRequest,
  createCertificateSuccess,
  createCertificateFailure,
  fetchCertificateRequest,
  fetchCertificateSuccess,
  fetchCertificateFailure,
  cleanCurrentCertificate,
  fetchCertificateDocumentRequest,
  fetchCertificateDocumentSuccess,
  fetchCertificateDocumentFailure,
} = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
