import React from 'react';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechCheckbox } from '../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { TrusthubSectionModel } from '../../../../swagger/trusthub/models/TrusthubSectionModel';
import { AdoptechTextInput } from '../../../../components/AdoptechTextInput/AdoptechTextInput';
import logoIso27001 from 'images/certificates/iso27001.png';
import soc2 from 'images/certificates/soc2.png';
import cyber from 'images/certificates/cyberessentials_certification_mark_colour_small.png';
import cyberEssentialsPlusLogo from 'images/certificates/cyberessentials_certification_mark_plus_colour_small.png';
import icoRegistrationCertificate from 'images/certificates/ico_registration_certificate.png';
import iso9001 from 'images/certificates/iso_9001.png';
import iso20252 from 'images/certificates/iso_20252.png';

import { useEditTrustHubSection } from '../useEditTrustHubSection';

import './CertificationsEditSection.scss';

interface CertificatesEditSectionProps {
  section: TrusthubSectionModel;
  onClose: () => void;
}

export const certificatesLogos = {
  ['ISO 27001 Certificate']: logoIso27001,
  ['SOC 2 Type II Report']: soc2,
  ['Cyber Essentials Certificate']: cyber,
  ['Cyber Essentials Plus Certificate']: cyberEssentialsPlusLogo,
  ['ICO Registration Certificate']: icoRegistrationCertificate,
  ['ISO 9001']: iso9001,
  ['ISO 20252']: iso20252,
};

type certificatesTypes = keyof typeof certificatesLogos;

export const CertificationsEditSection: React.FC<
  CertificatesEditSectionProps
> = ({ section, onClose }) => {
  const {
    fetchingCurrentSection,
    sectionDescription,
    setSectionDescription,
    handleCheckboxChange,
    updateSectionStatus,
    checkedIds,
    sortedDocsToEdit,
    handleSave,
  } = useEditTrustHubSection({
    section,
    onClose,
  });
  const baseCss = 'certificatesSection';

  return (
    <div className={baseCss + '--panel' + ' editMode'}>
      <div className={baseCss + '--descriptionRowInput'}>
        <AdoptechTextInput
          value={sectionDescription}
          id="certificatesSection-description"
          type="text"
          placeholder={section.description}
          onChange={e => {
            setSectionDescription(e.currentTarget.value);
          }}
        />
        <div className={baseCss + '--companyActions'}>
          <AdoptechButton
            disabled={updateSectionStatus === 'loading'}
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={updateSectionStatus === 'loading'}
            disabled={!sectionDescription}
            onClick={handleSave}
            variant={AdoptechButtonVariant.White}
          >
            Save
          </AdoptechButton>
        </div>
      </div>
      {fetchingCurrentSection && <LoadingSpinner />}
      {!fetchingCurrentSection && (
        <>
          {section.trusthubDocuments.length === 0 && (
            <div className={baseCss + '--noContent'}>
              No certificates templates to display. Please create at least one
              certificate template.
            </div>
          )}
          <div className={baseCss + '--documents'}>
            {sortedDocsToEdit.map(doc => {
              return (
                <div key={doc.name} className={baseCss + '--document'}>
                  <AdoptechCheckbox
                    id={doc.name}
                    checked={checkedIds.includes(doc.documentId)}
                    onChange={(e: { currentTarget: { checked: boolean } }) =>
                      handleCheckboxChange(e.currentTarget.checked, doc)
                    }
                    label={<div className="emptyLabel">-</div>}
                  />
                  <div className={baseCss + '--documentLogoWithTitle'}>
                    <img
                      src={certificatesLogos[doc.name as certificatesTypes]}
                      className={baseCss + '--documentLogo'}
                    />
                    <div className={baseCss + '--documentTitle'}>
                      {doc.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
