/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
 */
export interface TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes {
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
   */
  destroy?: boolean;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
   */
  documentId?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
   */
  documentType?: TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesDocumentTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes
   */
  restricted?: boolean;
}

export function TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesFromJSON(
  json: any
): TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes {
  return TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesFromJSONTyped(
    json,
    false
  );
}

export function TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    destroy: !exists(json, '_destroy') ? undefined : json['_destroy'],
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    documentType: !exists(json, 'document_type')
      ? undefined
      : json['document_type'],
    restricted: !exists(json, 'restricted') ? undefined : json['restricted'],
  };
}

export function TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesToJSON(
  value?: TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    _destroy: value.destroy,
    id: value.id,
    name: value.name,
    document_id: value.documentId,
    document_type: value.documentType,
    restricted: value.restricted,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesDocumentTypeEnum {
  VendorDocument = 'VendorDocument',
  Certificate = 'Certificate',
}
