import React from 'react';
import './TrustHubUserAdminPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { TrustHubUserAdminRequestsPage } from './TrustHubUserAdminRequestsPage/TrustHubUserAdminRequestsPage';
import {
  trusthubAdminPageCompaniesRoute,
  trusthubAdminPageRequestsRoute,
  trusthubAdminPageRoute,
  trusthubAdminPageUsersRoute,
} from '../../../../../components/Routes/Routes';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { TrustHubUserAdminUsersPage } from '../TrustHubUserAdminUsersPage/TrustHubUserAdminUsersPage';
import { TrustHubUserAdminCompaniesPage } from './TrustHubUserAdminCompaniesPage/TrustHubUserAdminCompaniesPage';

export const TrustHubUserAdminPage: React.FC = () => {
  const baseCss = 'trusthubUserAdminPage';
  const { fetchingSettingsStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const dispatch = useDispatch();

  const location = useLocation();

  if (fetchingSettingsStatus === 'loading') {
    return <LoadingSpinner />;
  }

  const baseHeaderCss = baseCss + '--header';
  return (
    <div className={baseCss}>
      <div className={baseCss + '--header'}>
        <div className={baseHeaderCss + '--info'}>
          <div className={baseHeaderCss + '--backSection'}>
            <FontAwesomeIcon
              onClick={() => dispatch(push(trusthubAdminPageRoute))}
              className={baseHeaderCss + '--backSectionLink'}
              icon={faAngleLeft}
            />
            <div className={baseHeaderCss + '--backSectionTitle'}>Admin</div>
          </div>
          <div className={baseHeaderCss + '--switcher'}>
            <div
              className={classNames({
                active: location.pathname.endsWith('requests'),
              })}
              onClick={() => dispatch(push(trusthubAdminPageRequestsRoute))}
            >
              Admin
            </div>
            <div
              className={classNames({
                active: location.pathname.endsWith('companies'),
              })}
              onClick={() => dispatch(push(trusthubAdminPageCompaniesRoute))}
            >
              Companies
            </div>
            <div
              className={classNames({
                active: location.pathname.endsWith('users'),
              })}
              onClick={() => dispatch(push(trusthubAdminPageUsersRoute))}
            >
              Users
            </div>
          </div>
        </div>
      </div>
      {location.pathname.endsWith('requests') && (
        <TrustHubUserAdminRequestsPage />
      )}
      {location.pathname.endsWith('companies') && (
        <TrustHubUserAdminCompaniesPage />
      )}
      {location.pathname.endsWith('users') && <TrustHubUserAdminUsersPage />}
    </div>
  );
};
