import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/pro-light-svg-icons/faLeaf';
import { ToggleSwitch } from '../../components/ToggleSwitch/ToggleSwitch';
import { useDispatch } from 'react-redux';
import { updateTrustHubSection } from './store/trusthubThunks';
import { SectionsSectionIdPatchRequest } from '../../swagger/trusthub/apis/DefaultApi';
import { TrusthubSectionModel } from '../../swagger/trusthub/models/TrusthubSectionModel';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';

export interface UseTrustHubSectionProps {
  identifier: string;
  sections: TrusthubSectionModel[];
  isEditable: boolean;
}

export const useTrustHubSection = ({
  identifier,
  sections,
  isEditable,
}: UseTrustHubSectionProps) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const section = (sections || []).find(
    sectionModel => sectionModel.identifier === identifier
  );
  const baseCss = `${
    identifier.startsWith('environmental') ? 'esg' : identifier
  }Section`;
  const publicDocs = section
    ? [...section.trusthubDocuments.filter(doc => doc.id)].sort(
        (a, b) => a.rowOrder - b.rowOrder
      )
    : [];

  const titleBar = section && (
    <div className={baseCss + '--title'}>
      <div className={baseCss + '--titleInfo'}>
        <FontAwesomeIcon
          icon={
            identifier === 'compliance'
              ? faFileCheck
              : identifier === 'certificates'
                ? faLockAlt
                : faLeaf
          }
        />
        <div>{section.name}</div>
      </div>
      {isEditable && (
        <ToggleSwitch
          label="Slide to exclude section"
          onChange={() => {
            const payload: SectionsSectionIdPatchRequest = {
              sectionId: section.id,
              body: {
                trusthubSection: {
                  visible: !section.visible,
                },
              },
            };

            dispatch(updateTrustHubSection(payload, () => {}, false));
          }}
          switchStyles={{
            width: 35,
            height: 18,
            offHandleColor: '#fff',
            onColor: '#666665',
          }}
          checked={!section.visible}
        />
      )}
    </div>
  );

  return {
    isEditMode,
    setIsEditMode,
    section,
    publicDocs,
    titleBar,
    baseCss,
  };
};
