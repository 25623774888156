import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsViewModeTaskDrawer } from '../../../../../selectors/compliance/selectIsViewModeEditTaskDrawer';
import AdoptechIcon from '../../AdoptechIcon/AdoptechIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import './ControlCheckTests.scss';
import { EditAssertionModal } from '../EditAssertionModal/EditAssertionModal';
import { ComplianceEditTaskFormProps } from '../ComplianceEditTaskForm/ComplianceEditTaskForm';
import { ApplicationState } from '../../../../../types/applicationState';
import {
  AssertionModel,
  AssertionModelStatusEnum,
  AssertionModelTypeEnum,
} from '../../../../../swagger/models/AssertionModel';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { AdoptechButtonVariant } from '../../../../../components/AdoptechButton/AdoptechButton';
import { CommandConfirmation } from '../../../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';
import {
  AssertionExtended,
  TasksTaskIdAvailableAssertionsPatchRequest,
} from '../../../../../swagger';
import { EditTestDrawer } from '../EditTestDrawer/EditTestDrawer';
import { formatDate } from '../../../../../functions/formatDate';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { patchControlCheckAssertions } from '../../../../../store/compliance/complianceThunks';
import { calculateTaskStatus } from '../EditTestDrawer/EditTestForm/EditTestFormLastResultsSection/EditTestFormLastResultsList/EditTestFormLastResultsList';
import {
  setDrawerOnTop,
  updateTaskInTheTree,
} from '../../../../../store/compliance/complianceSlice';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { DrawerType } from '../../../../../components/compliance/Types/complianceTypes';

export const ControlCheckTests: React.FC<
  ComplianceEditTaskFormProps
> = props => {
  const baseCss = 'controlCheckTests';
  const isViewMode = useSelector(selectIsViewModeTaskDrawer);
  const [showAddModal, setShowAddModal] = useState(false);
  const { tempTask } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  const allAssertions = tempTask?.assertions || [];
  // for table
  const assertions = allAssertions.filter(assertion => assertion.available);
  const childClass = (name: string) => `${baseCss}--${name}`;
  const assertionStatusClass = (assertion: AssertionModel) => {
    switch (assertion.status) {
      case AssertionModelStatusEnum.Compliant:
        return 'greenTick';
        break;
      case AssertionModelStatusEnum.Pending:
        return 'greyTick';
        break;
      case AssertionModelStatusEnum.Failed:
        return 'redTick';
        break;
      case AssertionModelStatusEnum.InProgress:
        return 'yellowTick';
        break;
    }
  };
  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const dispatch = useDispatch();
  const handleRemoveClick = (assertion: AssertionModel) => {
    const handleRemoveConfirm = async () => {
      setDeletingAssertionId(assertion.id);
      const newAssertions = allAssertions.map(taskAssertion => {
        if (taskAssertion.id !== assertion.id) return taskAssertion;

        return {
          ...taskAssertion,
          ...{ available: false },
        };
      });

      const assertionsPayload: TasksTaskIdAvailableAssertionsPatchRequest = {
        taskId: tempTask.id,
        body: {
          availableAssertionIds: newAssertions
            .filter(newAssertion => newAssertion.available)
            .map(model => model.id),
        },
      };
      await dispatch(patchControlCheckAssertions(assertionsPayload));
      const taskPayload = {
        id: tempTask.id,
        assertions: newAssertions,
        status: calculateTaskStatus(newAssertions),
        partialUpdate: true,
      };

      dispatch(updateTaskInTheTree(taskPayload));

      props.onChange({
        ...tempTask,
        ...{
          assertions: taskPayload.assertions,
          status: taskPayload.status,
        },
      });
      setDeletingAssertionId(null);
    };
    command({
      title: 'Confirm Delete',
      subject: { name: assertion.name, type: 'Action' },
      description: 'Click CONFIRM to delete the test.',
      buttonVariant: AdoptechButtonVariant.Warning,
      onConfirm: handleRemoveConfirm,
    });
  };

  const [showTestDrawer, setShowTestDrawer] = useState<
    AssertionExtended['id'] | false
  >(false);

  const [deletingAssertionId, setDeletingAssertionId] = useState(null);

  return (
    <>
      <div className={childClass('header')}>
        <div>Associated tests</div>
        {!isViewMode && (
          <AdoptechIcon shape="square" onClick={() => setShowAddModal(true)}>
            <FontAwesomeIcon icon={faPlus} />
          </AdoptechIcon>
        )}
        {showAddModal && tempTask && (
          <EditAssertionModal
            show={showAddModal}
            onChange={props.onChange}
            onHide={() => setShowAddModal(false)}
          />
        )}
      </div>
      {assertions.length > 0 && (
        <div className={childClass('assertions')}>
          <div className={childClass('tableHeaders')}>
            <div className={childClass('label')}>Status</div>
            <div className={childClass('label')}>Type</div>
            <div className={childClass('label')}>Name</div>
            <div className={childClass('label')}>Last run</div>
            <div className={childClass('label')}>Action</div>
          </div>
          {assertions.map(assertion => {
            if (assertion.id === deletingAssertionId)
              return <LoadingSpinner inlineSmall />;

            const isAuto = assertion.type === AssertionModelTypeEnum.Automatic;
            return (
              <div
                className={
                  childClass('tableRow') + ' ' + childClass('tableRow--large')
                }
                title={isAuto ? 'Automated Test' : 'Manual Test'}
                key={assertion.id}
                onClick={e => {
                  e.stopPropagation();
                  dispatch(setDrawerOnTop(DrawerType.Test));
                  setShowTestDrawer(assertion.id);
                }}
              >
                <div className={childClass('status')}>
                  <FontAwesomeIcon
                    icon={
                      assertion.status === AssertionModelStatusEnum.Failed
                        ? faExclamationCircle
                        : faCheckCircle
                    }
                    className={childClass(assertionStatusClass(assertion))}
                  />
                </div>
                <div className={childClass('type')}>
                  <FontAwesomeIcon
                    icon={isAuto ? faSyncAlt : faPencil}
                    className={childClass('type')}
                  />
                </div>
                <div className={childClass('name')}>{assertion.name}</div>
                <div className={childClass('lastRun')}>
                  {formatDate(assertion.lastRunAt, 'longDateTime2')}
                </div>
                <div className={childClass('icon')}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={e => {
                      handleRemoveClick(assertion);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            );
          })}
          <ConfirmationModal
            command={currentCommand}
            onCancel={_confirmed => command(null)}
          />
          {showTestDrawer && (
            <EditTestDrawer
              id={showTestDrawer}
              onClose={() => setShowTestDrawer(false)}
              name={
                assertions.find(assertion => assertion.id === showTestDrawer)
                  .name
              }
              showBackButton
            />
          )}
        </div>
      )}
    </>
  );
};
