import React from 'react';
import { DataRoomTabs } from '../DataRoomTabs/DataRoomTabs';
import { DataRoom } from '../../../../components/dataRoom/DataRoom/DataRoom';

export const DataRoomPage: React.FC = () => {
  return (
    <div>
      <DataRoomTabs currentTab="repository" />
      <DataRoom />
    </div>
  );
};
