import { VendorUserRoles } from '../swagger';
import { rolesViewConfig } from '../constants/rolesViewConfig';

export const sortVendorUserRoles = (
  a: VendorUserRoles,
  b: VendorUserRoles
): number => {
  const aIndex = Object.keys(rolesViewConfig).indexOf(a);
  const bIndex = Object.keys(rolesViewConfig).indexOf(b);
  if (aIndex > bIndex) return 1;
  if (aIndex < bIndex) return -1;
  return 0;
};
