import {
  SharedEmploymentStatus,
  SharedUserType,
  VendorUser,
} from '../../../swagger';

export const filterPeople = (
  people: VendorUser[],
  showLeft: boolean,
  filterUserTypes: SharedUserType[],
  filterUserCompliance: string = null
) => {
  let filteredPeople = people;

  if (!showLeft) {
    filteredPeople = filteredPeople.filter(
      person => person.employmentStatus !== SharedEmploymentStatus.Left
    );
  }

  if (filterUserTypes && filterUserTypes.length > 0) {
    filteredPeople = filteredPeople.filter(person =>
      filterUserTypes.includes(person.userType)
    );
  }

  if (filterUserCompliance === 'yes') {
    filteredPeople = filteredPeople.filter(
      person => person.complianceStats?.overall === true
    );
  } else if (filterUserCompliance === 'not') {
    filteredPeople = filteredPeople.filter(
      person => person.complianceStats?.overall === false
    );
  }

  return filteredPeople;
};
