import { createSelector } from '@reduxjs/toolkit';
import { compliancePoliciesSort } from '../functions/compliancePoliciesSort';
import { formatShortDate } from '../functions/formatShortDate';
import {
  Policy,
  VendorDocument,
  VendorUser,
  VendorPolicyDetails,
  VendorPolicyDetailsStatusEnum,
} from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export interface ComplianceFrameworkPolicy {
  live: boolean;
  overdue: boolean;
  name: string;
  approvedAt: string;
  owner: string;
  ownerObject: VendorUser;
  path: string;
  code: string;
}

const complianceFrameworkPolicyApproval = (policy: VendorDocument) => {
  if (policy.vendorDocumentApproval?.status === 'requested') {
    return 'Awaiting approval';
  }
  return formatShortDate(policy.vendorDocumentApproval?.lastApprovedAt);
};

const previouslyWentLive = (
  vendorPolicies: VendorPolicyDetails[],
  document: VendorDocument
): boolean => {
  if (!document.vendorDocumentApproval?.status) return false;
  return vendorPolicies.some(
    vp =>
      vp.policyId === document.policyId &&
      vp.status === VendorPolicyDetailsStatusEnum.Completed
  );
};

export const selectComplianceFrameworkPolicies = createSelector(
  (state: ApplicationState) => state.policies.policies,
  (state: ApplicationState) => state.policies.vendorPolicies,
  (state: ApplicationState) => state.compliance.frameworkPolicies,
  selectSortSettings(Grid.FrameworkPolicies),
  (
    policies: Policy[],
    vendorPolicies: VendorPolicyDetails[],
    documents: VendorDocument[],
    sortSetting
  ) => {
    if (!policies.length || !documents.length) return [];
    const compliancePolicies: ComplianceFrameworkPolicy[] = documents.map(
      document => ({
        live: document.live || previouslyWentLive(vendorPolicies, document),
        overdue: document.reviewOverdue,
        name: document.name,
        approvedAt: complianceFrameworkPolicyApproval(document),
        owner: document.owner?.fullName || document.owner?.email,
        ownerObject: document.owner,
        path: document.relativePath,
        code: policies.find(policy => policy.id === document.policyId)?.code,
      })
    );
    return compliancePolicies.sort((a, b) =>
      compliancePoliciesSort(a, b, sortSetting.columnSort)
    );
  }
);
