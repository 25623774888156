import React from 'react';
import './DataRoomSelectedEntitiesList.scss';
import { EntityDetails } from '../DataRoomEntityMeatballMenu/DataRoomEntityMeatballMenu';

interface DataRoomSelectedEntitiesList {
  entities: EntityDetails[];
}

const DataRoomSelectedEntitiesList: React.FC<DataRoomSelectedEntitiesList> = ({
  entities,
}) => {
  const baseCss = 'dataRoomSelectedEntitiesList';

  return (
    <div className={baseCss}>
      <div className={baseCss + '--label'}>Selected documents</div>
      <div className={baseCss + '--card'}>
        {entities.map(({ entityId, entityName, entityType }) => (
          <div key={entityId} className={baseCss + '--line'}>
            <div className={baseCss + '--fileName'}>{entityName}</div>
            <div className={baseCss + '--fileExtension'}>-</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataRoomSelectedEntitiesList;
