import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import './TrustHubUserAdminAddUserDrawer.scss';
import { AdoptechReactSelect2 } from '../../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { SelectionOption } from '../../../../../types/selectionOption';
import {
  fetchTrustHubCompanies,
  postTrusthubVendorUser,
} from '../../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechTextInput2 } from '../../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { ApplicationState } from '../../../../../types/applicationState';
import { showGlobalToast } from '../../../../../store/global/globalSlice';
import {
  TrusthubCompanyStatus,
  TrusthubVendorUserModel,
  TrusthubCompanyModel,
} from '../../../../../swagger/trusthub';
import { isValidEmailAddress } from '../../../../../functions/isValidEmailAddress';

interface AddUserFormData {
  firstName?: TrusthubVendorUserModel['firstName'];
  lastName?: TrusthubVendorUserModel['lastName'];
  email?: TrusthubVendorUserModel['email'];
  companyId?: TrusthubCompanyModel['id'];
}

interface TrustHubUserAdminAddUserDrawerProps {
  onClose: () => void;
}

export const TrustHubUserAdminAddUserDrawer: React.FC<
  TrustHubUserAdminAddUserDrawerProps
> = ({ onClose }) => {
  const baseCss = 'trustHubUserAddUserDrawer';
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<AddUserFormData>({});
  const { createVendorUserStatus, companies, fetchingCompaniesStatus } =
    useSelector((state: ApplicationState) => state.trustHub);

  const currentVendor = useSelector(selectCurrentVendor);
  const dispatchSubmit = () => {
    dispatch(
      postTrusthubVendorUser(
        {
          vendorIdOrDomain: currentVendor?.id,
          body: {
            trusthubVendorUser: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              trusthubCompanyId: formData.companyId,
            },
          },
        },
        _user => {
          dispatch(showGlobalToast('User has been successfully created'));
          onClose();
        }
      )
    );
  };
  const companyOptions = (companies || []).map(company => {
    return {
      label: company.name,
      value: company.id,
    };
  });

  // step 1 - user fill first name, last name, email
  // step 2 - user select a company and submit
  const [currentStep, setCurrentStep] = useState<number>(1);
  useEffect(() => {
    dispatch(
      fetchTrustHubCompanies(
        {
          vendorIdOrDomain: currentVendor?.id,
          statuses: [TrusthubCompanyStatus.Approved],
        },
        () => {}
      )
    );
  }, []);
  return (
    <AdoptechHS
      title="Add New User"
      show
      showConfirmationWarning={false}
      onClose={() => onClose()}
      footer={
        <>
          <AdoptechButton
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          {currentStep === 1 ? (
            <AdoptechButton
              onClick={() => setCurrentStep(prevStep => prevStep + 1)}
              variant={AdoptechButtonVariant.Primary}
              disabled={
                ![formData.firstName, formData.lastName, formData.email].every(
                  value => value
                ) || !isValidEmailAddress(formData.email)
              }
            >
              Next
            </AdoptechButton>
          ) : (
            <AdoptechButton
              onClick={dispatchSubmit}
              variant={AdoptechButtonVariant.Primary}
              busy={createVendorUserStatus === 'loading'}
              disabled={!formData.companyId}
            >
              Confirm
            </AdoptechButton>
          )}
        </>
      }
      extraClass="adoptechHS--medium addUserHS"
    >
      {fetchingCompaniesStatus === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <div className={baseCss + '--formContainer'}>
          {currentStep === 1 && (
            <>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--label'}>
                  Provide the following user information.
                </div>
                <div className={baseCss + '--field'}>
                  <AdoptechTextInput2
                    rounded
                    type="text"
                    id="firstName"
                    label="First name"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        firstName: e.currentTarget.value,
                      })
                    }
                    icon={faPen}
                  />
                </div>
              </div>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--field'}>
                  <AdoptechTextInput2
                    rounded
                    type="text"
                    id="lastName"
                    label="Last name"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        lastName: e.currentTarget.value,
                      })
                    }
                    icon={faPen}
                  />
                </div>
              </div>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--field'}>
                  <AdoptechTextInput2
                    rounded
                    type="text"
                    id="Email"
                    label="Email address"
                    placeholder="Enter email address"
                    value={formData.email}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        email: e.currentTarget.value,
                      })
                    }
                    icon={faPen}
                  />
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--label'}>
                  Select the company to associated the user to.
                </div>
                <div className={baseCss + '--field'}>
                  <AdoptechReactSelect2
                    id="userCompany"
                    options={companyOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        companyId: option.value,
                      });
                    }}
                    value={companyOptions.find(
                      option => option.value === formData.companyId
                    )}
                    placeholder="Please select"
                    label="Company"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </AdoptechHS>
  );
};
