/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubDocumentExtendedAllOf,
  TrusthubDocumentExtendedAllOfFromJSON,
  TrusthubDocumentExtendedAllOfFromJSONTyped,
  TrusthubDocumentExtendedAllOfToJSON,
  TrusthubDocumentModel,
  TrusthubDocumentModelFromJSON,
  TrusthubDocumentModelFromJSONTyped,
  TrusthubDocumentModelToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubDocumentExtended
 */
export interface TrusthubDocumentExtended extends TrusthubDocumentModel {
  /**
   *
   * @type {boolean}
   * @memberof TrusthubDocumentExtended
   */
  requested: boolean;
}

export function TrusthubDocumentExtendedFromJSON(
  json: any
): TrusthubDocumentExtended {
  return TrusthubDocumentExtendedFromJSONTyped(json, false);
}

export function TrusthubDocumentExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubDocumentExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...TrusthubDocumentModelFromJSONTyped(json, ignoreDiscriminator),
    requested: json['requested'],
  };
}

export function TrusthubDocumentExtendedToJSON(
  value?: TrusthubDocumentExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...TrusthubDocumentModelToJSON(value),
    requested: value.requested,
  };
}
