import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVendorSuppliers } from '../../../selectors/selectVendorSuppliers';
import {
  fetchVendorSupplierCategories,
  fetchVendorSuppliers,
} from '../../../store/vendors/vendorsThunks';
import {
  VendorActionExtended,
  VendorEventEventType,
  VendorSupplierModel,
  VendorSupplierStatus,
} from '../../../swagger';
import { Grid } from '../../../types/grid';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { VendorRegisterRow } from '../../../components/VendorRegisterRow/VendorRegisterRow';
import './VendorRegister.scss';
import { ManageSupplierDrawer } from '../../../components/ManageSupplierDrawer/ManageSupplierDrawer';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import Fuse from 'fuse.js';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { AccessObject } from '../../../types/accessObject';
import { canFeature } from '../../../functions/access';
import { useBackground } from '../../../hooks/useBackground';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { Dropdown } from 'react-bootstrap';
import { AdoptechDropdownToggle } from '../../../components/AdoptechDropdownToggle/AdoptechDropdownToggle';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { push } from 'connected-react-router';
import {
  reportsRouteWithAddType,
  reportsRouteWithType,
} from '../../../components/Reports/ReportsPage/ReportsPage';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { EditActionModal } from '../../../components/EditActionModal/EditActionModal';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { createNewAction } from '../../calendar/store/calendarThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { mapVendorActonFormToPostPayload } from '../../calendar/CalendarPage/CalendarPageModals';

export const useRegistryCreateActionModal = () => {
  const [showCreateAction, setShowCreateAction] = useState(false);
  const dispatch = useAppDispatch();
  const afterCreate = (action: VendorActionExtended) => {
    dispatch(
      showGlobalToast(
        'Action has been successfully created. Please visit the Actions & Events page to see it.'
      )
    );
  };

  const createAction = async (params: VendorActionExtended) => {
    const createPayload = mapVendorActonFormToPostPayload(params);

    const newAction = await dispatch(
      createNewAction({ payload: createPayload })
    ).unwrap();

    afterCreate(newAction);

    setShowCreateAction(false);
  };
  return { createAction, setShowCreateAction, showCreateAction };
};

export const VendorRegisterPage: React.FC = () => {
  const currentVendor = useSelector(selectCurrentVendor);
  const dispatch = useDispatch();

  useBackground('grey');

  const [vendorSupplierDrawerShowReason, setVendorSupplierDrawerShowReason] =
    useState<VendorSupplierModel>(null);

  const openAddForm = () => {
    setVendorSupplierDrawerShowReason({} as VendorSupplierModel);
  };

  const { createAction, setShowCreateAction, showCreateAction } =
    useRegistryCreateActionModal();

  const openAddAction = () => setShowCreateAction(true);

  const openEditForm = (supplier: VendorSupplierModel) => {
    setVendorSupplierDrawerShowReason(supplier);
  };

  const closeDrawer = (updateNeeded: boolean) => {
    setVendorSupplierDrawerShowReason(null);
    if (updateNeeded) dispatch(fetchVendorSuppliers());
  };

  useEffect(() => {
    dispatch(fetchVendorSuppliers());
    dispatch(fetchVendorSupplierCategories());
  }, [currentVendor]);

  const fullVendorsList = useSelector(selectVendorSuppliers);
  const [search, setSearch] = useState('');
  const [showInactive, setShowInactive] = useState(false);
  const visibleVendors = showInactive
    ? fullVendorsList
    : fullVendorsList.filter(v => v.status === VendorSupplierStatus.Active);
  const fuse = new Fuse(visibleVendors, {
    ignoreLocation: true,
    includeScore: true,
    keys: [
      'name',
      'accessControl',
      'owner.fullName',
      'recurring',
      'owner.email',
      'reviewDate',
      'status',
    ],
    threshold: 0,
  });

  const canViewReports = canFeature(AccessObject.reports);
  const canManageReports = canFeature(AccessObject.reports_manage);
  const canManageRegister = canFeature(AccessObject.vendor_register_manage);

  const vendorsList = search
    ? fuse.search(search).map(x => x.item)
    : visibleVendors;

  const baseCss = 'vendorRegister';
  const baseTableCss = 'adoptechGridTable';

  return (
    <div className={baseCss}>
      <div className={baseCss + '--sticky'}>
        <div className={baseCss + '--header'}>
          <div className={baseCss + '--title'}>
            Vendors
            <Lozenge value={fullVendorsList.length} />
          </div>
          <div className={baseCss + '--divider'} />
          <AdoptechCheckbox
            color="white"
            checked={showInactive}
            id="checkbox-vendors-show-inactive"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowInactive(e.target.checked)
            }
            label="Show inactive Vendors"
            className="checkbox-show-inactive"
          />
          <div className={baseCss + '--divider'} />
          <div className={baseCss + '--headerButtons'}>
            {canViewReports && (
              <Dropdown className={baseCss + '--reportsButton'}>
                <Dropdown.Toggle as={AdoptechDropdownToggle}>
                  <AdoptechButton
                    variant={AdoptechButtonVariant.White}
                    icon={faEye}
                    iconSize="large"
                    rounded
                  >
                    Reports
                  </AdoptechButton>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {canManageReports && (
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(push(reportsRouteWithAddType('vendor')))
                      }
                    >
                      Add report
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => dispatch(push(reportsRouteWithType('all')))}
                  >
                    View reports
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {canManageRegister && (
              <Dropdown className={baseCss + '--addButton'}>
                <Dropdown.Toggle as={AdoptechDropdownToggle}>
                  <AdoptechButton
                    variant={AdoptechButtonVariant.PrimaryOrange}
                    icon={faPlus}
                    iconSize="large"
                    rounded
                  >
                    Add new
                  </AdoptechButton>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={openAddForm}>Vendor</Dropdown.Item>
                  <Dropdown.Item onClick={openAddAction}>Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
            type="text"
            placeholder="Search for vendors"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
      </div>
      <div className={baseCss + '--list'}>
        <div className={baseCss + ' ' + baseCss + '--7-columns'}>
          {vendorsList.length <= 0 ? (
            <NoDataText text="No vendors to show" />
          ) : (
            <AdoptechGridTable
              header={
                <div className={baseTableCss + '--header'}>
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="name"
                    grid={Grid.VendorRegister}
                    label="Vendor"
                    notInTable
                  />
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="riskProfile"
                    grid={Grid.VendorRegister}
                    label="Risk Profile"
                    notInTable
                  />
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="accessControl"
                    grid={Grid.VendorRegister}
                    label="Access control"
                    notInTable
                  />
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="owner"
                    grid={Grid.VendorRegister}
                    label="Owner"
                    notInTable
                  />
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="reviewDate"
                    grid={Grid.VendorRegister}
                    label="Review date"
                    notInTable
                  />
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="contractRecurring"
                    grid={Grid.VendorRegister}
                    label="Contract renewal"
                    notInTable
                  />
                  <SortableTableHeader<VendorSupplierModel>
                    columnName="status"
                    grid={Grid.VendorRegister}
                    label="Status"
                    notInTable
                  />
                </div>
              }
            >
              {vendorsList.map((vendorSupplier: VendorSupplierModel) => {
                const { id } = vendorSupplier;
                return (
                  <VendorRegisterRow
                    vendorSupplier={vendorSupplier}
                    onClick={() =>
                      canManageRegister ? openEditForm(vendorSupplier) : null
                    }
                    key={id}
                  />
                );
              })}
            </AdoptechGridTable>
          )}
        </div>
      </div>
      <ManageSupplierDrawer
        showReason={vendorSupplierDrawerShowReason}
        onClose={closeDrawer}
      />

      {showCreateAction && (
        <EditActionModal
          confirm={createAction}
          init={{ actionType: VendorEventEventType.VendorRegisterReview }}
          show={showCreateAction}
          disabledFields={['actionType']}
          close={() => setShowCreateAction(false)}
        />
      )}
    </div>
  );
};
