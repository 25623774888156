import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';
import { SelectionOption } from '../../types/selectionOption';
import { selectJobTitles } from '../../selectors/selectJobTitles';
import { reactSelectCustomStyles } from '../../functions/reactSelectCustomStyles';
import { reactSelectCustomTheme } from '../../functions/reactSelectCustomTheme';

interface JobTitleSelectProps {
  onChange: (position: string) => void;
  position: string;
}

export const JobTitleSelect: React.FC<JobTitleSelectProps> = ({
  onChange,
  position,
}) => {
  const jobTitles = useSelector(selectJobTitles);

  const options = useMemo(
    () => jobTitles.map(jt => ({ value: jt, label: jt })),
    [jobTitles]
  );

  return (
    <Creatable
      id="jobTitleSelect"
      className="jobTitleSelect"
      onChange={(e: SelectionOption) => {
        onChange(e.value);
      }}
      placeholder="Add job title"
      options={options}
      value={options.find(o => o.value === position)}
      styles={reactSelectCustomStyles()}
      theme={reactSelectCustomTheme}
    />
  );
};
