import React, { useEffect, useState } from 'react';
import { VendorUser } from '../../../../swagger';
import { fetchVendorUserAttestations } from '../../../../store/vendors/vendorsThunks';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { SentAttestationsTable } from '../../../../components/SentAttestationsTable/SentAttestationsTable';
import './EditUserCompliance.scss';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { ApplicationState } from '../../../../types/applicationState';

interface EditUserComplianceProps {
  userId: VendorUser['id'];
}

export const EditUserCompliance: React.FC<EditUserComplianceProps> = props => {
  const baseCss = 'editUserCompliance';
  const dispatch = useAppDispatch();

  const [userAttestations, setUserAttestations] = useState([]);
  useEffect(() => {
    const fetchAttestations = async () => {
      const attestations = await dispatch(
        fetchVendorUserAttestations(props.userId)
      ).unwrap();
      setUserAttestations(attestations);
    };

    fetchAttestations();
  }, []);

  const { fetchVendorUserAttestationsStatus } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Policies</div>
      {fetchVendorUserAttestationsStatus == 'loading' ? (
        <LoadingSpinner />
      ) : (
        <SentAttestationsTable
          tableClass="smallPadding"
          attestations={userAttestations}
          mode="vendorUser"
        />
      )}
    </div>
  );
};
