import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { push } from 'connected-react-router';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanupAgreementsCreateForm,
  toggleAddAgreementDrawer,
  updateAgreementsCreateFormField,
} from '../../../store/agreements/agreementsSlice';
import {
  fetchAgreementTemplateCategories,
  fetchAgreementTemplates,
} from '../../../store/agreements/agreementsThunks';
import {
  AgreementTemplateCategoryModel,
  AgreementTemplateModel,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { AddNewAgreementForm } from '../AddNewAgreementForm/AddNewAgreementForm';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { AdoptechTile } from '../../AdoptechTile/AdoptechTile';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { NoDataText } from '../../NoDataText/NoDataText';
import { agreementsPageRoute } from '../../Routes/Routes';
import './AgreementsTemplates.scss';
import { AdoptechPageNavBar } from '../../AdoptechPageNavBar/AdoptechPageNavBar';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faFileUser } from '@fortawesome/pro-light-svg-icons/faFileUser';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { selectActiveSubscription } from '../../../selectors/selectActiveSubscription';
import { showSubscriptionRequiredModal } from '../../../store/global/globalSlice';
import PartOfMyPlanBanner from '../../PartOfMyPlanBanner/PartOfMyPlanBanner';

interface AgreementsTemplatesListProps {
  categories: AgreementTemplateCategoryModel[];
  baseCss: string;
  templates: AgreementTemplateModel[];
}

const AgreementsTemplatesList: React.FC<AgreementsTemplatesListProps> = ({
  categories,
  baseCss,
  templates,
}) => {
  const agreementIcons = new Map([
    ['consultancy_agreement', faFileInvoice],
    ['data_processing_contract', faFileChartLine],
    ['employee_agreement', faFileUser],
    ['nda', faFileContract],
  ]);

  const sortedCategories = [...categories].sort(
    (a, b) => a.rowOrder - b.rowOrder
  );
  const dispatch = useDispatch();

  const onFreePlan = !useSelector(selectActiveSubscription);
  const availableForFree = ['nda', 'data_processing_contract'];

  const isAgreementPartOfPlan = (template: AgreementTemplateModel) =>
    !onFreePlan || availableForFree.includes(template.agreementType);

  const onAdd = (template: AgreementTemplateModel) => {
    if (!isAgreementPartOfPlan(template)) {
      dispatch(showSubscriptionRequiredModal());
      return;
    }

    dispatch(
      updateAgreementsCreateFormField({
        standardTemplateId: template.id,
      })
    );
    dispatch(toggleAddAgreementDrawer());
  };
  const isShowingAddAgreementDrawer = useSelector(
    (state: ApplicationState) => state.agreements.isShowingAddAgreementDrawer
  );

  interface ExtendedCategory extends AgreementTemplateCategoryModel {
    templates: AgreementTemplateModel[];
  }

  const categoriesWithTemplates = (sortedCategories as ExtendedCategory[])
    .map(category => {
      const categoryTemplates = templates.filter(
        template =>
          template.available &&
          template.agreementTemplateCategoryId === category.id
      );
      return categoryTemplates.length > 0
        ? { ...category, templates: categoryTemplates }
        : null;
    })
    .filter(category => category);

  return (
    <>
      {isShowingAddAgreementDrawer && (
        <AddNewAgreementForm
          onClose={() => {
            dispatch(toggleAddAgreementDrawer());
            dispatch(cleanupAgreementsCreateForm());
          }}
          show={isShowingAddAgreementDrawer}
        />
      )}
      {categoriesWithTemplates.length === 0 && (
        <div className={baseCss + '--noDataPlaceholder'}>
          <NoDataText text="No Agreements templates found." />
        </div>
      )}
      <PartOfMyPlanBanner />
      {categoriesWithTemplates.map(category => {
        return (
          <div key={category.id}>
            <div className={baseCss + '--categoryTitle'}>
              {category.summary}
            </div>
            <div className={baseCss + '--categoryTemplates'}>
              {category.templates.map(template => {
                return (
                  <AdoptechTile
                    key={template.id}
                    size="medium"
                    buttonText="Start new"
                    name={template.name}
                    description={template.summary}
                    icon={agreementIcons.get(template.agreementType)}
                    onClick={() => onAdd(template)}
                    available={isAgreementPartOfPlan(template)}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

const AgreementsTemplates: React.FC = () => {
  const dispatch = useDispatch();

  const {
    agreementTemplateCategories: categories,
    agreementTemplates: templates,
    isRequestingAgreementTemplateCategories,
    isRequestingAgreementTemplates,
  } = useSelector((state: ApplicationState) => state.agreements);

  const isRequesting =
    isRequestingAgreementTemplateCategories || isRequestingAgreementTemplates;
  useEffect(() => {
    dispatch(fetchAgreementTemplateCategories());
    dispatch(fetchAgreementTemplates());
  }, []);
  const [search, setSearch] = useState('');
  const baseCss = 'agreementsTemplates';

  const fuse = new Fuse(templates, {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name', 'summary'],
    threshold: 0,
  });

  const templatesList = search
    ? fuse.search(search).map(x => x.item)
    : templates;
  const canManageAgreements = canFeature(AccessObject.agreements_manage);
  if (isRequesting) {
    return <LoadingSpinner />;
  }

  const goBack = () => dispatch(push(agreementsPageRoute));

  if (!canManageAgreements)
    return (
      <div style={{ marginTop: '10px' }}>
        <NoDataText
          text="There are no abilities to create an agreement. Please contact to the
      admin"
        />
      </div>
    );

  return (
    <div className={baseCss}>
      <AdoptechPageNavBar
        goBack={goBack}
        content={
          <>
            Start new
            <div className={baseCss + '--search'}>
              <AdoptechTextInput
                id="search"
                value={search}
                onChange={e => setSearch(e.currentTarget.value)}
                type="text"
                placeholder="Search for agreements"
                icon={faSearch}
                additionalClass="adoptechTextInput-search"
              />
            </div>
          </>
        }
      />
      <AgreementsTemplatesList
        categories={categories}
        templates={templatesList}
        baseCss={baseCss}
      />
    </div>
  );
};

export default AgreementsTemplates;
