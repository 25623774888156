import { DataRoomEntityModel } from '../swagger';

export const dataRoomGetParentFolderId = (
  entitiesTree: DataRoomEntityModel,
  entityId: string
) => {
  let parentFolderId = '';
  const search = (entity: DataRoomEntityModel) => {
    if (entity.children.find(e => e.id == entityId)) {
      parentFolderId = entity.id;
    } else {
      entity.children.forEach(e => search(e));
    }
  };

  search(entitiesTree);

  return parentFolderId;
};
