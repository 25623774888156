import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canCreate, canFeature } from '../../../functions/access';
import {
  getPdfDocumentPath,
  PdfPreviewEntityPaths,
} from '../../../functions/routePathsHelpers';
import { VendorDocumentWithCategoryName } from '../../../selectors/selectLivePolicies';
import { showEditOwnerModal } from '../../../store/global/globalSlice';
import {
  VendorDocumentApprovalStatusEnum,
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
} from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { OwnerObjectType } from '../../../types/OwnerObjectType';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import {
  openApprovalManagement,
  openManageRejectionModal,
  showExistingPolicyWarningModal,
} from '../../../store/policies/policiesSlice';
import classNames from 'classnames';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { ExistingPolicyWarningModalMode } from '../../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { selectInProgressPolicies } from '../../../selectors/selectInProgressPolicies';
import { MeatballMenu } from '../../../components/MeatballMenu/MeatballMenu';
import { fetchVendorDocumentDownload } from '../../../store/vendors/vendorsThunks';

interface PendingApprovalPolicyRowProps {
  vendorDocument: VendorDocumentWithCategoryName;
  baseCss: string;
  baseTableCss: string;
}
export const isRejected = (vd: VendorDocumentExtended): boolean =>
  vd.status === VendorDocumentStatusEnum.Rejected;

export const PendingApprovalPolicyRow: React.FC<
  PendingApprovalPolicyRowProps
> = ({ vendorDocument, baseCss, baseTableCss }) => {
  const dispatch = useDispatch();
  const canManageApprovals = canFeature(AccessObject.manage_approvals);
  const canManageDocuments = canCreate(AccessObject.vendor_documents);

  const handleManageClick = (
    e: React.MouseEvent<HTMLElement>,
    vendorDoc: VendorDocumentExtended
  ): void => {
    e.stopPropagation();
    if (isRejected(vendorDoc)) {
      dispatch(openManageRejectionModal(vendorDoc));
      return;
    }
    dispatch(
      openApprovalManagement({
        id: vendorDoc.id,
        name: vendorDoc.name,
        isCancelable: true,
      })
    );
  };
  const inProgressPolicies = useSelector(selectInProgressPolicies);
  const hasPolicyInProgress = (vendorDoc: VendorDocumentExtended): boolean => {
    return inProgressPolicies.some(p => p.policyId === vendorDoc.policyId);
  };

  const openPdf = (vendorDoc: VendorDocumentExtended) => {
    dispatch(
      push(
        getPdfDocumentPath({
          vendorDocumentId: vendorDoc.id,
          mode:
            vendorDoc.vendorDocumentApproval.status ===
            VendorDocumentApprovalStatusEnum.Requested
              ? PdfOpenMode.Approve
              : PdfOpenMode.Preview,
          basePath: PdfPreviewEntityPaths.policies,
        })
      )
    );
  };
  const canUpdateVersion = canFeature(AccessObject.manage_versions);
  return (
    <div
      key={vendorDocument.id}
      onClick={e => {
        e.stopPropagation();
        openPdf(vendorDocument);
      }}
      className={classNames(
        baseCss,
        baseTableCss + '--row ',
        baseTableCss + '--slim',
        {
          [`${baseTableCss}--alert`]: isRejected(vendorDocument),
        }
      )}
    >
      <div
        className="pendingApprovalPolicies--clickableColumn"
        onClick={e => {
          e.stopPropagation();
          openPdf(vendorDocument);
        }}
      >
        {`${vendorDocument.name} ${vendorDocument.version}`}
      </div>
      <div>{vendorDocument.categoryName}</div>
      <div
        onClick={e => {
          if (!canManageDocuments) return;
          e.stopPropagation();
          dispatch(
            showEditOwnerModal({
              object: vendorDocument,
              type: OwnerObjectType.VendorDocument,
            })
          );
        }}
      >
        <UserAvatar
          showTooltip
          hideText
          user={vendorDocument.owner}
          size="tag"
        />
      </div>
      <div>
        <div>
          {isRejected(vendorDocument) ? 'Rejected' : 'Pending approval'}
        </div>
      </div>
      <div onClick={e => handleManageClick(e, vendorDocument)}>
        {`${vendorDocument.approvalStats.approved} / ${vendorDocument.approvalStats.total}`}
      </div>
      <div>
        {vendorDocument.vendorDocumentApproval.status ===
          VendorDocumentApprovalStatusEnum.Requested && (
          <AdoptechButton
            icon={faFileCheck}
            onClick={e => {
              dispatch(
                push(
                  getPdfDocumentPath({
                    vendorDocumentId: vendorDocument.id,
                    mode: PdfOpenMode.Approve,
                    basePath: PdfPreviewEntityPaths.policies,
                  })
                )
              );
              e.stopPropagation();
            }}
            variant={AdoptechButtonVariant.PrimaryTransparent}
          >
            Approve
          </AdoptechButton>
        )}
      </div>
      <div className="adoptechTable--actions">
        <MeatballMenu>
          <Dropdown.Item
            onClick={e => {
              dispatch(
                push(
                  getPdfDocumentPath({
                    vendorDocumentId: vendorDocument.id,
                    mode: PdfOpenMode.Preview,
                    basePath: PdfPreviewEntityPaths.policies,
                  })
                )
              );
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
            Preview
          </Dropdown.Item>
          <Dropdown.Item
            onClick={e => {
              dispatch(
                fetchVendorDocumentDownload({
                  id: vendorDocument.id,
                  successCallback: vendorDocumentDownload => {
                    window.location.href = vendorDocumentDownload.document;
                  },
                })
              );

              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon
              className="meatballMenu--icon"
              icon={faCloudDownload}
            />
            Download
          </Dropdown.Item>
          {canManageApprovals && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={(e: any) => handleManageClick(e, vendorDocument)}
              >
                <FontAwesomeIcon
                  className="meatballMenu--icon"
                  icon={faFileCheck}
                />
                {isRejected(vendorDocument)
                  ? 'Manage rejection'
                  : 'Manage approvals'}
              </Dropdown.Item>
            </>
          )}
          {canUpdateVersion &&
            vendorDocument.policyId &&
            !isRejected(vendorDocument) && (
              <Dropdown.Item
                disabled={hasPolicyInProgress(vendorDocument)}
                onClick={e => {
                  dispatch(
                    showExistingPolicyWarningModal({
                      policyId: vendorDocument.policyId,
                      existingPolicyWarningModalMode:
                        ExistingPolicyWarningModalMode.Update,
                    })
                  );
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon className="meatballMenu--icon" icon={faSync} />
                Update current version
              </Dropdown.Item>
            )}
          {canManageDocuments && !isRejected(vendorDocument) && (
            <Dropdown.Item
              onClick={e => {
                dispatch(
                  showEditOwnerModal({
                    object: vendorDocument,
                    type: OwnerObjectType.VendorDocument,
                  })
                );
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon
                className="meatballMenu--icon"
                icon={faFileContract}
              />
              Edit owner
            </Dropdown.Item>
          )}
        </MeatballMenu>
      </div>
    </div>
  );
};
