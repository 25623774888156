import { createSelector } from '@reduxjs/toolkit';
import {
  FolderModel,
  getFlatFoldersList,
} from '../functions/getFlatFoldersList';
import { DataRoomEntityModel } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectAvailableFolders = (rights: string[]) =>
  createSelector(
    (state: ApplicationState) => state.dataRoom.entitiesTree,
    (entitiesTree: DataRoomEntityModel) => {
      const folderList: FolderModel[] = [];
      getFlatFoldersList(entitiesTree, folderList, rights);
      return folderList;
    }
  );
