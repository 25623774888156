import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState } from 'react';
import { Accordion, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { canFeature } from '../../../functions/access';
import { formatShortDate } from '../../../functions/formatShortDate';
import { selectSelectedFramework } from '../../../selectors/selectSelectedFramework';
import {
  setControlFormModel,
  setEditControlMode,
  setSelectedControlId,
  setShowControlDrawer,
} from '../../../store/compliance/complianceSlice';
import {
  markControlNotApplicable,
  patchComplianceControl,
} from '../../../store/compliance/complianceThunks';

import { ControlCategoryExtended, ControlExtended } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { AdoptechAccordionCard } from '../../AdoptechAccordionCard/AdoptechAccordionCard';
import { InfoCard } from '../../InfoCard/InfoCard';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { MeatballMenu } from '../../MeatballMenu/MeatballMenu';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { ComplianceControlTasksList } from '../ComplianceControlTasksList/ComplianceControlTasksList';
import { createPayload, EditControl } from '../Types/complianceTypes';
import './ComplianceControlsList.scss';
import { Transition } from 'react-transition-group';
import { EditControlMode } from '../../../store/compliance/complianceState';
import { useCategoryDescriptionCache } from '../../../hooks/compliance/useCategoryDescriptionCache';
import { AdoptechTooltip } from '../../AdoptechTooltip/AdoptechTooltip';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import classNames from 'classnames';
import { FrameworksPills } from '../../Frameworks/Pills';
import { useControlClick } from '../../../features/compliance/controls/Controls/ControlRow';
import {
  controlIcon,
  controlIconClass,
} from '../../../features/compliance/controls/EditControlCheckDrawer/ControlCheckControls/ControlCheckControls';

interface ComplianceControlsListProps {
  category: ControlCategoryExtended;
}

export const ComplianceControlsList: React.FC<ComplianceControlsListProps> = ({
  category,
}) => {
  const dispatch = useDispatch();
  const baseCss = 'complianceControlsList';
  const [
    controlIdBeingMarkedNotApplicable,
    setControlIdBeingMarkedNotApplicable,
  ] = useState('');
  const selectedFramework = useSelector(selectSelectedFramework);
  const { descriptionVisible, hideDescription } = useCategoryDescriptionCache(
    category.id
  );

  const isFetchingControls = useSelector(
    (state: ApplicationState) =>
      state.compliance.fetchControlsStatus === 'loading'
  );

  const categoryControls = category.controls;

  const canUpdateControls = canFeature(AccessObject.controls_update);

  const handleTaskRemoval = (control: ControlExtended, taskId: string) => {
    const updatedControl = new EditControl({
      ...control,
      tasks: control.tasks.filter(t => t.id !== taskId),
    });

    dispatch(
      patchComplianceControl(control?.id, createPayload(updatedControl))
    );
  };
  const controlClicker = useControlClick();

  if (isFetchingControls) {
    return <LoadingSpinner />;
  }

  const handleMarkNotApplicable = (catId: string, control: ControlExtended) => {
    setControlIdBeingMarkedNotApplicable(control.id);
    setTimeout(() => {
      dispatch(markControlNotApplicable(catId, control.id));
      setControlIdBeingMarkedNotApplicable('');
      setTimeout(() => {
        dispatch(setSelectedControlId(control.id));
        dispatch(setEditControlMode(EditControlMode.MarkNotApplicable));
        dispatch(setControlFormModel(new EditControl(control).simpleObject()));
        dispatch(setShowControlDrawer(true));
      }, 1500);
    }, 1000);
  };

  const defaultStyle = {
    transition: 'transform 1s ease-in-out',
    transform: 'translateX: 0',
    zIndex: 1,
  };

  const transitionStyle = {
    entering: { transform: 'translateX(0)' },
    entered: { transform: 'translateX(2000px)' },
    exiting: { transform: 'translateX(0)' },
    exited: { transform: 'translateX(0)' },
    unmounted: { transform: 'translateX(0)' },
  };
  return (
    <>
      <div className={baseCss}>
        {descriptionVisible && (
          <InfoCard
            description={category.description}
            onClose={() => hideDescription()}
          />
        )}
        <Accordion key={selectedFramework.id}>
          {categoryControls.map(control => {
            const editControlHandler = controlClicker(control);
            return (
              <Transition
                appear
                in={control.id === controlIdBeingMarkedNotApplicable}
                key={control.id}
                timeout={150}
              >
                {state => (
                  <AdoptechAccordionCard
                    scrollDisabled
                    headerHandleClick={editControlHandler}
                    headerClass={classNames(
                      baseCss + '--accordionControlHeader',
                      { 'card-header--overdue': control.reviewOverdue }
                    )}
                    key={control.id}
                    index={control.id}
                    style={{ ...defaultStyle, ...transitionStyle[state] }}
                    title={
                      <div className={baseCss + '--controlHeader'}>
                        <div>
                          <FontAwesomeIcon
                            icon={controlIcon(control)}
                            className={controlIconClass(control, baseCss)}
                          />
                        </div>
                        <div className={baseCss + '--name'}>{control.name}</div>
                        <div className={baseCss + '--tag'}>
                          <FrameworksPills frameworks={[selectedFramework]} />
                        </div>
                        <div className={baseCss + '--avatarWrapper'}>
                          <UserAvatar
                            showTooltip
                            user={control.assignee}
                            size="tag"
                            hideText
                          />
                        </div>

                        <div>
                          {control.reviewOverdue ? (
                            <div className={baseCss + '--overdueIcon'}>
                              <FontAwesomeIcon
                                size="lg"
                                icon={faClock}
                                className="mr-2"
                              />
                              <span className="font-weight-bold">Overdue</span>
                            </div>
                          ) : (
                            <AdoptechTooltip
                              showTooltip
                              text="Review date"
                              identifier={`${control.id}--review-date`}
                            >
                              <div className={baseCss + '--reviewDateWrapper'}>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className={baseCss + '--timeIcon'}
                                />
                                {formatShortDate(control.nextReviewDate)}
                              </div>
                            </AdoptechTooltip>
                          )}
                        </div>
                        <MeatballMenu>
                          <Dropdown.Item
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(setSelectedControlId(control.id));
                              dispatch(
                                setControlFormModel(
                                  new EditControl(control).simpleObject()
                                )
                              );
                              if (!canUpdateControls) {
                                dispatch(
                                  setEditControlMode(EditControlMode.View)
                                );
                              }
                              dispatch(setShowControlDrawer(true));
                            }}
                          >
                            <FontAwesomeIcon
                              className="meatballMenu--icon"
                              icon={faPen}
                            />
                            Edit
                          </Dropdown.Item>
                          {canUpdateControls && (
                            <>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                onClick={e => {
                                  e.stopPropagation();

                                  dispatch(setSelectedControlId(control.id));
                                  handleMarkNotApplicable(category.id, control);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="meatballMenu--icon"
                                  icon={faBan}
                                />
                                Not applicable
                              </Dropdown.Item>
                            </>
                          )}
                        </MeatballMenu>
                      </div>
                    }
                    nested
                  >
                    <ComplianceControlTasksList
                      control={control}
                      onRemoveTask={handleTaskRemoval}
                    />
                  </AdoptechAccordionCard>
                )}
              </Transition>
            );
          })}
        </Accordion>
      </div>
    </>
  );
};
