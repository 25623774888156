import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentVendorUser } from '../../selectors/selectCurrentVendorUser';

export const useCategoryDescriptionCache = (categoryId: string) => {
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  const CACHE_KEY = `hiddenCategoryDescriptions_${currentVendorUser.id}`;
  const getCache = () => JSON.parse(localStorage.getItem(CACHE_KEY)) || [];
  const setCache = (value: string[]) =>
    localStorage.setItem(CACHE_KEY, JSON.stringify(value));

  const hiddenIds = getCache();
  const [visible, setVisible] = useState(!hiddenIds.includes(categoryId));

  const hideDescription = () => {
    setVisible(false);
    const newOptions = [...hiddenIds, categoryId];
    setCache(newOptions);
  };

  return { descriptionVisible: visible, hideDescription };
};
