import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendorUserPermissions } from '../../store/vendors/vendorsThunks';
import {
  SharedDocumentListData,
  SharedDocumentListDataAccessLevelEnum,
  VendorUserCreatePayloadVendorUsers,
  VendorUserUpdatePayloadVendorUser,
} from '../../swagger/models';
import { ApplicationState } from '../../types/applicationState';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { NoDataText } from '../NoDataText/NoDataText';
import './UserDataPermissions.scss';

interface userDataPermissionsProps {
  userId: string;
}

export const UserDataPermissions = ({ userId }: userDataPermissionsProps) => {
  const dispatch = useDispatch();
  const vendorUserPermissions = useSelector(
    (state: ApplicationState) => state.vendors.vendorUserPermissions
  );
  const isRequestingVendorUserPermissions = useSelector(
    (state: ApplicationState) => state.vendors.isRequestingVendorUserPermissions
  );
  const userDocuments: SharedDocumentListData[] = vendorUserPermissions?.data;

  useEffect(() => {
    dispatch(fetchVendorUserPermissions(userId));
  }, []);

  const Yes = () => (
    <td className="userDataPermissions--checkCell">
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="userDataPermissions--checkCell--yes"
      />
    </td>
  );

  const No = () => (
    <td className="userDataPermissions--checkCell">
      <FontAwesomeIcon
        icon={faTimesCircle}
        className="userDataPermissions--checkCell--no"
      />
    </td>
  );

  const PermissionsTable = () =>
    userDocuments?.length ? (
      <AdoptechTable>
        <thead>
          <tr>
            <th>Document name</th>
            <th>Type</th>
            <th className="userDataPermissions--checkCell">Manager</th>
            <th>Contributor</th>
            <th>Viewer</th>
          </tr>
        </thead>
        <tbody>
          {userDocuments.map(doc => (
            <tr key={doc.title + doc.type}>
              <td>{doc.title}</td>
              <td>{doc.type}</td>
              {doc.accessLevel ===
              SharedDocumentListDataAccessLevelEnum.Manager ? (
                <Yes />
              ) : (
                <No />
              )}
              {doc.accessLevel ===
              SharedDocumentListDataAccessLevelEnum.Contributor ? (
                <Yes />
              ) : (
                <No />
              )}
              {doc.accessLevel ===
              SharedDocumentListDataAccessLevelEnum.Viewer ? (
                <Yes />
              ) : (
                <No />
              )}
            </tr>
          ))}
        </tbody>
      </AdoptechTable>
    ) : (
      <NoDataText text="No shared documents" />
    );

  return (
    <div className="userDataPermissions">
      {isRequestingVendorUserPermissions ? (
        <LoadingSpinner />
      ) : (
        <PermissionsTable />
      )}
    </div>
  );
};
