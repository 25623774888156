import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../../functions/sort';
import { sortByDate } from '../../../../functions/sortByDate';
import { ColumnSort } from '../../../../types/columnSort';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { ApplicationState } from '../../../../types/applicationState';
import { Grid } from '../../../../types/grid';
import Fuse from 'fuse.js';
import {
  TrusthubVendorUserModel,
  TrusthubVendorUserStatus,
} from '../../../../swagger/trusthub';

const sortTrusthubVendorUsers = (
  a: TrusthubVendorUserModel,
  b: TrusthubVendorUserModel,
  columnSort: ColumnSort
) => {
  // display unconfirmed always on the top
  if (a.status === TrusthubVendorUserStatus.Unconfirmed) {
    return -1;
  }
  const typedColumn = columnSort.name as keyof TrusthubVendorUserModel;

  if (['company'].includes(typedColumn)) {
    return sort(
      a.trusthubCompany?.name || '',
      b.trusthubCompany?.name || '',
      columnSort.direction
    );
  }

  if (['lastLoginAt'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'lastLoginAt';
    // TODO: check when BE will add this field
    // @ts-ignore
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

const searchTrusthubVendorUsers = (
  users: TrusthubVendorUserModel[],
  search: string
) => {
  let filteredTrusthubVendorUsers = users;
  const fuse = new Fuse(users, {
    ignoreLocation: true,
    includeScore: true,
    keys: [
      'fullName',
      'trusthubCompany.name',
      'email',
      'status',
      'lastLoginAt',
    ],
    threshold: 0,
  });

  if (search) {
    filteredTrusthubVendorUsers = fuse.search(search).map(x => x.item);
  }

  return [...filteredTrusthubVendorUsers];
};

export const selectTrusthubVendorUsers = createSelector(
  (state: ApplicationState) => state.trustHub.vendorUsers,
  selectSortSettings(Grid.TrustHubUserAdminVendorUsers),
  (state: ApplicationState) => state.trustHub.vendorUsersSearch,
  (vendorUsers: TrusthubVendorUserModel[], sortSetting, search) => {
    return [...searchTrusthubVendorUsers(vendorUsers, search)].sort((a, b) =>
      sortTrusthubVendorUsers(a, b, sortSetting.columnSort)
    );
  }
);
