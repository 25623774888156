import { ControlModel, VendorUser } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sortByOwner } from './sortByOwner';
import { sortByDate } from './sortByDate';
import { sort } from './sort';

export const controlSort = (
  a: ControlModel,
  b: ControlModel,
  columnSort: ColumnSort
): number => {
  let direction: 1 | -1 = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof ControlModel = columnSort.name as keyof ControlModel;

  if (typedColumn === 'assignee') {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      direction
    );
  }

  if (typedColumn === 'nextReviewDate') {
    if (!a[typedColumn]) return 1;
    if (!b[typedColumn]) return -1;

    return sortByDate(
      a[typedColumn] as string,
      b[typedColumn] as string,
      direction
    );
  }

  return sort(a[typedColumn], b[typedColumn], direction);
};
