import { VendorPolicyDetails } from '../swagger';
import { dentaku } from './dentaku';

export const vendorPolicyCalculations = (
  policy: VendorPolicyDetails
): { stats: [string, number][]; visibleQuestionIds?: string[] } => {
  if (!policy?.vendorPolicyClauses) return { stats: [] };
  if (!policy?.vendorPolicyAnswers) return { stats: [] };

  const answeredQuestionIds = new Set<string>();
  let visibleQuestionIds: string[] = [];

  const context = policy.vendorPolicyAnswers.reduce(
    (acc: Record<string, string>, answer) => ({
      ...acc,
      [answer.propertyName]: answer.value,
    }),
    {}
  );

  const isVisible = (expression: string) => dentaku(expression, context);

  policy.vendorPolicyAnswers.forEach(answer => {
    if (!answer.closestQuestion) {
      console.error(
        'An answer object should always have a closestQuestion property. It is required for policies stats calculation',
        answer.propertyName
      );
    }
    const questionId = answer.closestQuestion?.id;
    const isCheckbox = answer.closestQuestion?.layout == 'check_boxes';
    if (isCheckbox) {
      if (answer.value === 't') answeredQuestionIds.add(questionId); // empty checkbox has 'f' value, so if uncheck/check skip them
    } else {
      if (answer.value != '') answeredQuestionIds.add(questionId);
    }
  });

  const stats: [string, number][] = [];
  policy.vendorPolicyClauses.forEach(({ id, questions, leaveOut }) => {
    const questionsIds = questions
      .filter(q => isVisible(q.showWhen))
      .map(q => q.id);
    if (leaveOut) {
      stats.push([id, 1]);
    } else {
      const answeredQuestionsIds = questionsIds.filter(qn =>
        answeredQuestionIds.has(qn)
      );
      stats.push([
        id,
        questionsIds.length > 0
          ? answeredQuestionsIds.length / questionsIds.length
          : 1,
      ]);
    }
    visibleQuestionIds = visibleQuestionIds.concat(questionsIds);
  });

  return { stats, visibleQuestionIds };
};
