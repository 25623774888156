import { push } from 'connected-react-router';
import { setGoogleAnalyticsUser } from '../../../packs/googleAnalytics';
import { setSentryUser } from '../../../packs/sentry';
import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import { getMetaContent } from '../../functions/getMetaContent';
import {
  PaymentMethodsIdPatchRequest,
  PaymentMethodsPostRequest,
  UserDetails,
  UserPassword,
} from '../../swagger';
import { UNKNOWN_ERROR } from '../../types/constants';
import { VoidThunk } from '../../types/voidThunk';
import {
  completeGetStartedChecklistItem,
  GettingStartedChecklistItems,
} from '../checklists/checklistsThunks';
import { showGlobalToast } from '../global/globalSlice';
import {
  fetchVendorUsers,
  fetchVendors,
  getVendorProducts,
} from '../vendors/vendorsThunks';
import {
  addPaymentMethodFailure,
  addPaymentMethodRequest,
  addPaymentMethodSuccess,
  updatePaymentMethodSuccess,
  updatePaymentMethodFailure,
  addTwoFactorAuthenticationFailure,
  addTwoFactorAuthenticationRequest,
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  closePasswordModal,
  closeTwoFactorAuthentication,
  deletePaymentMethodFailure,
  deletePaymentMethodRequest,
  deletePaymentMethodSuccess,
  deleteSubscriptionFailure,
  deleteSubscriptionRequest,
  deleteSubscriptionSuccess,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  fetchInvoicesFailure,
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  fetchPaymentMethodsFailure,
  fetchPaymentMethodsRequest,
  fetchPaymentMethodsSuccess,
  fetchSubscriptionsFailure,
  fetchSubscriptionsRequest,
  fetchSubscriptionsSuccess,
  fetchUserDetailsFailure,
  fetchUserDetailsRequest,
  fetchUserDetailsSuccess,
  hideAddPaymentMethodModal,
  showPasswordChangeSuccessfulToast,
  showProfileSavedToast,
  showTwoFactorSavedToast,
  updatePaymentMethodRequest,
  updateProfileFailure,
  updateProfileRequest,
  updateProfileSuccess,
  userSignatureRequest,
  userSignatureRequestFailure,
  userSignatureRequestSuccess,
} from './userSlice';
import { getErrorMessage } from '../helpers/thunkHelpers';

export const deleteSubscription =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteSubscriptionRequest());
      await api().subscriptionsIdDelete({ id });
      await Promise.all([
        dispatch(fetchVendors()),
        dispatch(fetchSubscriptions()),
        dispatch(getVendorProducts(true)),
      ]);

      dispatch(deleteSubscriptionSuccess());
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred deleting your subscription. Please try again or contact support.'
      );
      dispatch(deleteSubscriptionFailure(apiErrorMessage));
    }
  };

export const deleteUser = (): VoidThunk => async dispatch => {
  try {
    dispatch(deleteUserRequest());
    await api({ Accept: 'application/json' }).usersDelete();
    dispatch(deleteUserSuccess());
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(deleteUserFailure(apiErrorMessage));
      } else {
        dispatch(
          deleteUserFailure(
            'An error occurred deleting your account. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(deleteUserFailure(UNKNOWN_ERROR));
    }
  }
};

export const deletePaymentMethod =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deletePaymentMethodRequest());
      await api().paymentMethodsIdDelete({ id });
      dispatch(deletePaymentMethodSuccess());
      dispatch(fetchPaymentMethods());
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred deleting the payment method. Please try again or contact support.'
        );
        console.log(err);
        dispatch(deletePaymentMethodFailure(apiErrorMessage));
      }
    }
  };

export const fetchPaymentMethods = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchPaymentMethodsRequest());
    const response = await api().paymentMethodsGet();
    dispatch(fetchPaymentMethodsSuccess(response));
  } catch (err) {
    if (err instanceof Response) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred fetching payment methods. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchPaymentMethodsFailure(apiErrorMessage));
    }
  }
};

export const addPaymentMethod =
  (paymentMethod: PaymentMethodsPostRequest): VoidThunk =>
  async dispatch => {
    try {
      dispatch(addPaymentMethodRequest());
      const response = await api().paymentMethodsPost(paymentMethod);
      dispatch(addPaymentMethodSuccess(response));
      dispatch(showGlobalToast('Payment method was successfully added.'));
      dispatch(hideAddPaymentMethodModal());
      dispatch(fetchPaymentMethods());
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred adding a payment method. Please try again or contact support.'
        );
        console.log(err);
        dispatch(addPaymentMethodFailure(apiErrorMessage));
      }
    }
  };

export const updatePaymentMethod =
  (params: PaymentMethodsIdPatchRequest): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updatePaymentMethodRequest());
      const response = await api().paymentMethodsIdPatch(params);
      dispatch(updatePaymentMethodSuccess(response));
      dispatch(showGlobalToast('Payment method was successfully updated.'));
      dispatch(hideAddPaymentMethodModal());
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred updating a payment method. Please try again or contact support.'
        );
        console.log(err);
        dispatch(updatePaymentMethodFailure(apiErrorMessage));
      }
    }
  };

export const fetchSubscriptions =
  (onSuccess?: () => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchSubscriptionsRequest());
      const response = await api().subscriptionsGet();
      dispatch(fetchSubscriptionsSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred fetching subscriptions. Please try again or contact support.'
      );
      dispatch(fetchSubscriptionsFailure(apiErrorMessage));
    }
  };

export const fetchInvoices = (): VoidThunk => async (dispatch, getState) => {
  try {
    dispatch(fetchInvoicesRequest());
    const vendorId = getState().vendors.currentVendor?.id;
    const response = await api().vendorsIdInvoicesGet({ id: vendorId });
    dispatch(fetchInvoicesSuccess(response));
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(fetchInvoicesFailure(apiErrorMessage));
      } else {
        dispatch(
          fetchInvoicesFailure(
            'An error occurred fetching invoices. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(fetchInvoicesFailure(UNKNOWN_ERROR));
    }
  }
};

export const fetchUserDetails = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchUserDetailsRequest());
    const response = await api().userDetailsGet();
    setSentryUser(response);
    setGoogleAnalyticsUser(response);
    dispatch(fetchUserDetailsSuccess(response));
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(fetchUserDetailsFailure(apiErrorMessage));
      } else {
        dispatch(
          fetchUserDetailsFailure(
            'An error occurred fetching user details. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(fetchUserDetailsFailure(UNKNOWN_ERROR));
    }
  }
};

export const fetchUserDetailsByEmail =
  ({
    email,
    onSuccess,
  }: {
    email: string;
    onSuccess?(users: UserDetails[]): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      const response = await api().usersGetRaw({ queryEmail: email });
      const data = await response.value();
      onSuccess && onSuccess(data);
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchUserDetailsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchUserDetailsFailure(
              'An error occurred fetching user details by email. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchUserDetailsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const updatePassword =
  (userPassword: UserPassword): VoidThunk =>
  async dispatch => {
    try {
      dispatch(changePasswordRequest());
      await api({ Accept: 'application/json' }).usersPatch({
        password: userPassword,
      });
      dispatch(changePasswordSuccess());
      dispatch(closePasswordModal());
      dispatch(showPasswordChangeSuccessfulToast());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(changePasswordFailure(apiErrorMessage));
        } else {
          dispatch(
            changePasswordFailure(
              'An error occurred changing your password. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(changePasswordFailure(UNKNOWN_ERROR));
      }
    }
  };

export const updateProfile =
  (userDetails: UserDetails, fromWelcome: boolean): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(updateProfileRequest());
      await api().userDetailsPatch({ userDetails });
      dispatch(updateProfileSuccess());
      dispatch(fetchUserDetails());
      if (vendorId) {
        dispatch(fetchVendorUsers(vendorId));
      }

      if (
        userDetails.firstName &&
        userDetails.lastName &&
        (userDetails.mobile || userDetails.phone)
      ) {
        dispatch(
          completeGetStartedChecklistItem(
            GettingStartedChecklistItems.complete_profile
          )
        );
      }

      if (fromWelcome) {
        dispatch(push('/r/welcome/company'));
      } else {
        dispatch(showProfileSavedToast());
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(updateProfileFailure(apiErrorMessage));
        } else {
          dispatch(
            updateProfileFailure(
              'An error occurred updating user details. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(updateProfileFailure(UNKNOWN_ERROR));
      }
    }
  };

export const registerTwoFA =
  (mobile: string, country_code: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(addTwoFactorAuthenticationRequest());
      const payload = JSON.stringify({
        country_code,
        mobile,
      });

      const response = await fetch(`/api/v1/users/enable_twofactor`, {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });

      if (response.status === 200) {
        dispatch(fetchUserDetails());
        dispatch(closeTwoFactorAuthentication());
        dispatch(showTwoFactorSavedToast());
      } else {
        dispatch(
          addTwoFactorAuthenticationFailure(
            'An error has occurred with registering two FA'
          )
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(addTwoFactorAuthenticationFailure(apiErrorMessage));
        } else {
          dispatch(
            addTwoFactorAuthenticationFailure(`Error: ${err.statusText}`)
          );
        }
      } else {
        console.log(err);
        dispatch(addTwoFactorAuthenticationFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchUserSignature =
  (userId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(userSignatureRequest());
      const response = await api().usersIdSignatureGet({
        id: userId,
      });
      dispatch(userSignatureRequestSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(userSignatureRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            userSignatureRequestFailure(
              'An error occurred while fetching user signature. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(userSignatureRequestFailure(UNKNOWN_ERROR));
      }
    }
  };
