import { createSelector } from '@reduxjs/toolkit';
import { selectIntegrations } from './selectIntegrations';
import {
  VendorIntegrationModel,
  VendorIntegrationSyncModel,
  VendorIntegrationSyncModelDirectionEnum,
} from '../swagger';

export const hasUserSync = (sync: VendorIntegrationSyncModel) => {
  return (
    sync.subtype == 'users' &&
    sync.direction == VendorIntegrationSyncModelDirectionEnum.Incoming
  );
};
export const selectCurrentUsersProviderAndSync = createSelector(
  selectIntegrations,
  (
    integrations: VendorIntegrationModel[]
  ): [VendorIntegrationModel, VendorIntegrationSyncModel] => {
    let integration, sync;
    integrations.forEach(i => {
      i.vendorIntegrationSyncs.forEach(s => {
        if (hasUserSync(s)) {
          integration = i;
          sync = s;
        }
      });
    });

    return [integration, sync];
  }
);
