import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechCheckbox } from '../../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { useEditTrustHubSection } from '../../useEditTrustHubSection';
import { capitalize } from '../../../../../functions/capitalize';
import { TrusthubSectionModel } from '../../../../../swagger/trusthub/models/TrusthubSectionModel';
import {
  policiesPageRoute,
  reportsRoute,
} from '../../../../../components/Routes/Routes';

interface DocumentEditSectionProps {
  section: TrusthubSectionModel;
  onClose: () => void;
  identifier: string;
}
export const DocumentsEditSection: React.FC<DocumentEditSectionProps> = ({
  section,
  onClose,
  identifier,
}) => {
  const baseCss = `${identifier}Section`;

  const {
    fetchingCurrentSection,
    sectionDescription,
    handleCheckboxChange,
    updateSectionStatus,
    checkedIds,
    sortedDocsToEdit,
    handleSave,
  } = useEditTrustHubSection({
    section,
    onClose,
  });
  return (
    <div className={baseCss + '--panel'}>
      <div className={baseCss + '--title'}>
        <div className={baseCss + '--titleInfo'}>
          <FontAwesomeIcon icon={faLockAlt} />
          <div>{capitalize(identifier)}</div>
        </div>

        <div className={baseCss + '--companyActions'}>
          <AdoptechButton
            disabled={updateSectionStatus === 'loading'}
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={updateSectionStatus === 'loading'}
            disabled={
              ['policies', 'reports'].includes(identifier)
                ? false
                : !sectionDescription
            }
            onClick={handleSave}
            variant={AdoptechButtonVariant.White}
          >
            Save
          </AdoptechButton>
        </div>
      </div>

      {fetchingCurrentSection && <LoadingSpinner />}
      {!fetchingCurrentSection && (
        <>
          {section.trusthubDocuments.length === 0 && (
            <div className={baseCss + '--noContent'}>
              {`No templates to display. Please create at least one live ${
                identifier === 'policies' ? 'policy' : 'report'
              }`}{' '}
              <a
                href={
                  identifier === 'policies' ? policiesPageRoute : reportsRoute
                }
              >
                {' '}
                here.
              </a>
            </div>
          )}

          <div className={baseCss + '--documents'}>
            {sortedDocsToEdit.map(doc => {
              return (
                <div key={doc.name} className={baseCss + '--document'}>
                  <div className={baseCss + '--documentCheckboxSection'}>
                    <AdoptechCheckbox
                      id={doc.name}
                      checked={checkedIds.includes(doc.documentId)}
                      onChange={e =>
                        handleCheckboxChange(e.currentTarget.checked, doc)
                      }
                      label={<div className="emptyLabel">-</div>}
                    />
                    <div className={baseCss + '--documentTitle' + ' editMode'}>
                      {doc.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
