import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canFeature, canIndex } from '../../../functions/access';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { selectUsers } from '../../../selectors/selectUsers';
import {
  fetchVendorTeams,
  fetchVendorUsers,
} from '../../../store/vendors/vendorsThunks';
import {
  VendorIntegrationModelStatusEnum,
  VendorIntegrationSyncModelStateEnum,
  VendorUser,
} from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { DeleteUserModal } from '../../../components/DeleteUserModal/DeleteUserModal';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../../../components/Lozenge/Lozenge';
import {
  EditTabsEnum,
  ManagePeopleDrawer,
} from '../ManagePeopleDrawer/ManagePeopleDrawer';
import { ManageTeamsDrawer } from '../ManageTeamsDrawer/ManageTeamsDrawer';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import './PeopleCompliance.scss';
import { fetchIntegrations } from '../../../store/integrations/integrationsSlice';
import { SyncUserDrawer } from '../SyncUserDrawer/SyncUserDrawer';
import { hasUserSync } from '../../../selectors/selectCurrentUsersSync';
import { peopleRoute } from '../../../components/Routes/Routes';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { reactSelectLightCheckboxStyle } from '../../../functions/reactSelectCustomTheme';
import { PeopleTabs } from '../PeopleTabs/PeopleTabs';
import { Status } from './Status';
import {
  userTypeOptions,
  userComplianceOptions,
} from '../PeopleFilters/options';
import { filterPeople } from '../PeopleFilters/functions';
import useFilterByUserTypes from '../People/useFilterByUserType';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { capitalize } from '../../../functions/capitalize';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';

const options = {
  ignoreLocation: true,
  includeScore: true,
  keys: ['fullName', 'position', 'roles', 'vendorTeams.name'],
  threshold: 0,
};

interface PeopleComplianceRowProps {
  user: VendorUser;
  handleRowClick: (e: React.MouseEvent, user: VendorUser) => void;
  handleStatusClick: (e: React.MouseEvent, user: VendorUser) => void;
}

export const PeopleComplianceRow: React.FC<PeopleComplianceRowProps> = ({
  user,
  handleRowClick,
  handleStatusClick,
}) => {
  const { id, complianceStats, position, userType } = user;

  let overall: boolean | null = null;
  let policies: boolean | null = null;
  if (complianceStats) {
    overall = complianceStats.overall;
    policies = complianceStats.policies;
  }

  return (
    <div
      key={id}
      onClick={e => handleRowClick(e, user)}
      className="peopleTable adoptechGridTable--row"
    >
      <div className="peopleTable--complianceStatus">
        <Status status={overall} />
      </div>
      <AdoptechOverflowLine>
        <UserAvatar user={user} size="large" />
      </AdoptechOverflowLine>
      <AdoptechOverflowLine>{position}</AdoptechOverflowLine>
      <div>{capitalize(userType)}</div>
      <div
        className="peopleTable--policiesStatus"
        onClick={e => handleStatusClick(e, user)}
      >
        <Status status={policies} />
      </div>
    </div>
  );
};

export const PeopleCompliance: React.FC = () => {
  const dispatch = useDispatch();

  const { complianceUsers, fetchComplianceUsersStatus } = useSelector(
    (state: ApplicationState) => state.vendors
  );
  const users = useSelector((state: ApplicationState) =>
    selectUsers(state, { excludeLeft: false })
  )
    .map(user => {
      const complianceUser = complianceUsers.find(
        complianceCurrentUser => complianceCurrentUser.id === user.id
      );
      if (!complianceUser) return null;
      return { ...user, ...complianceUser };
    })
    .filter(user => user);

  const canManageUsers = canFeature(AccessObject.people_manage);
  const currentVendor = useSelector(selectCurrentVendor);
  const [selectedUser, setSelectedUser] = useState<VendorUser>(null);
  const [search, setSearch] = useState('');
  const [isShowingTeamsDrawer, setIsShowingTeamsDrawer] = useState(false);
  const [isShowingPeopleDrawer, setIsShowingPeopleDrawer] = useState(false);
  const [isShowingDeleteUserModal, setIsShowingDeleteUserModal] =
    useState(false);
  const [isShowingSyncUserDrawer, setIsShowingSyncUserDrawer] = useState(false);
  const [selectedEditTab, setSelectedEditTab] = useState<EditTabsEnum>(
    EditTabsEnum.Profile
  );
  const canIndexTeams = canIndex(AccessObject.vendor_teams);

  const baseCss = 'peopleTable';
  const baseTableCss = 'adoptechGridTable';

  useEffect(() => {
    canIndexTeams && dispatch(fetchVendorTeams(true));
    dispatch(fetchIntegrations());
  }, [currentVendor?.id]);

  let filteredPeople: VendorUser[] = users;
  const fuseAp = new Fuse(filteredPeople, options);
  filteredPeople = search
    ? fuseAp.search(search).map(x => x.item)
    : filteredPeople;

  const { isFetchingVendorUsers } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  const { list: integrations, status: integrationsStatus } = useSelector(
    (state: ApplicationState) => state.integrations
  );

  const currentSyncs = integrations
    .filter(
      integration =>
        integration.status === VendorIntegrationModelStatusEnum.Active
    )
    .map(integration =>
      integration.vendorIntegrationSyncs?.filter(sync => hasUserSync(sync))
    )
    .flat();

  const hasInProgress = currentSyncs.some(
    sync => sync.state === VendorIntegrationSyncModelStateEnum.InProgress
  );

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const hasInProgressRef = useRef(hasInProgress);
  hasInProgressRef.current = hasInProgress;

  const timerRef = useRef(timer);
  timerRef.current = timer;

  // polling sync state
  useEffect(() => {
    const clearTimer = () =>
      timerRef.current && clearInterval(timerRef.current);
    const startNewTimer = () => {
      clearTimer();
      const newTimer = setTimeout(async () => {
        if (
          !window.location.href.includes(peopleRoute) ||
          !hasInProgressRef.current // use ref.current instead of hasInProgress to get latest state value in recursive call
        ) {
          clearTimer();
          return;
        }

        await dispatch(fetchIntegrations());
        await dispatch(
          fetchVendorUsers(currentVendor?.id, {
            silent: true,
          })
        );

        // use recursive setTimeout to wait API requests ( setInterval doesn't wait )
        startNewTimer();
      }, 5000);

      setTimer(newTimer);
    };

    hasInProgress && integrationsStatus !== 'loading'
      ? startNewTimer()
      : clearTimer();

    return () => {
      clearTimer();
    };
  }, [hasInProgress, integrationsStatus]);

  const [showLeft, setShowLeft] = useState<boolean>(false);
  const { filterUserTypes, handleChange, filteredOptions, selectedOptions } =
    useFilterByUserTypes(userTypeOptions);
  const [filterUserCompliance, setFilterUserCompliance] =
    useState<string>(null);

  filteredPeople = filterPeople(
    filteredPeople,
    showLeft,
    filterUserTypes,
    filterUserCompliance
  );

  const handleRowClick = (
    e: { stopPropagation: () => void; preventDefault: () => void },
    user: VendorUser
  ) => {
    e.stopPropagation();
    setSelectedUser(user);
    setIsShowingPeopleDrawer(true);
  };

  const handleStatusClick = (
    e: { stopPropagation: () => void; preventDefault: () => void },
    user: VendorUser
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedEditTab(EditTabsEnum.Compliance);
    setSelectedUser(user);
    setIsShowingPeopleDrawer(true);
  };

  return (
    <div className="people">
      <ManageTeamsDrawer
        show={isShowingTeamsDrawer}
        onClose={() => setIsShowingTeamsDrawer(false)}
      />
      {isShowingPeopleDrawer && (
        <ManagePeopleDrawer
          user={selectedUser}
          editTab={selectedEditTab}
          onClose={() => {
            setSelectedUser(null);
            setSelectedEditTab(EditTabsEnum.Profile);
            setIsShowingPeopleDrawer(false);
          }}
        />
      )}

      <SyncUserDrawer
        show={isShowingSyncUserDrawer}
        close={() => {
          setIsShowingSyncUserDrawer(false);
        }}
        inProgress={hasInProgress}
      />
      {isShowingDeleteUserModal && (
        <DeleteUserModal
          vendorId={currentVendor?.id}
          user={selectedUser}
          close={() => {
            setSelectedUser(null);
            setIsShowingDeleteUserModal(false);
          }}
        />
      )}
      <div className="people--sticky">
        <PeopleTabs currentTab="compliance" />
        <div className="people--header">
          <div className="people--title">
            People
            <Lozenge intent={Intent.None} value={filteredPeople.length} />
          </div>
          <div className="people--divider" />
          <AdoptechCheckbox
            color="white"
            checked={showLeft}
            id="checkbox-people-show-left"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowLeft(e.target.checked)
            }
            label="Show left users"
            className="checkbox-show-left"
          />
          <div className="people--divider" />
          <div className="people--userType">
            <div className="people--userTypeLabel">User type</div>
            <div className="people--userTypeSelector">
              <AdoptechReactSelect
                id="userTypeSelect"
                isMulti
                isCheckbox
                options={filteredOptions}
                onChange={handleChange}
                value={selectedOptions}
                components={{
                  ClearIndicator: (): JSX.Element => null,
                }}
                placeholder="No selection"
                additionalStyling={reactSelectLightCheckboxStyle}
              />
            </div>
          </div>
          <div className="people--divider" />
          <div className="people--userType">
            <div className="people--userTypeLabel">Compliance</div>
            <div className="people--userTypeSelector">
              <AdoptechReactSelect
                id="userComplianceSelect"
                options={
                  // don't select not selected =>
                  // both filter's placeholder's by user type(isMultiple) & compliance(single)
                  // looks and works the same by default
                  filterUserCompliance === null
                    ? userComplianceOptions.filter(
                        option => option.value !== null
                      )
                    : userComplianceOptions
                }
                onChange={e => {
                  e.value === null
                    ? setFilterUserCompliance(null)
                    : setFilterUserCompliance(e.value);
                }}
                value={
                  filterUserCompliance === null
                    ? null
                    : userComplianceOptions.find(
                        o => o.value === filterUserCompliance
                      )
                }
                placeholder="No selection"
                additionalStyling={reactSelectLightCheckboxStyle}
              />
            </div>
          </div>
        </div>
        <div className="people--search">
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
            type="text"
            placeholder="Search"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
      </div>

      <div className={baseCss + ' ' + baseCss + '--5-columns'}>
        <AdoptechGridTable
          header={
            <div className={baseTableCss + '--header'}>
              <SortableTableHeader<VendorUser>
                // @ts-ignore
                columnName="compliant"
                grid={Grid.Users}
                label="Compliant"
                notInTable
              />
              <SortableTableHeader<VendorUser>
                columnName="fullName"
                grid={Grid.Users}
                label="Name"
                notInTable
              />
              <SortableTableHeader<VendorUser>
                columnName="position"
                grid={Grid.Users}
                label="Job title"
                notInTable
              />
              <SortableTableHeader<VendorUser>
                columnName="userType"
                grid={Grid.Users}
                label="User type"
                notInTable
              />
              <div>Policies</div>
            </div>
          }
        >
          {isFetchingVendorUsers || fetchComplianceUsersStatus === 'loading' ? (
            <div className={baseCss + ' ' + baseTableCss + '--row d-flex'}>
              <LoadingSpinner />
            </div>
          ) : (
            filteredPeople.map(user => {
              return (
                <PeopleComplianceRow
                  key={user.id}
                  user={user}
                  handleRowClick={handleRowClick}
                  handleStatusClick={handleStatusClick}
                />
              );
            })
          )}
        </AdoptechGridTable>
      </div>
    </div>
  );
};
