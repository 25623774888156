import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { SortSetting } from '../types/sortSetting';
import { DataRoomEntityModel } from '../swagger';
import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../types/applicationState';
import sort from '../functions/dataRoomEntitySort';

export default createSelector(
  (state: ApplicationState) => state.dataRoom.files,
  selectSortSettings(Grid.DataRoom),
  (files: DataRoomEntityModel[], sorting: SortSetting): DataRoomEntityModel[] =>
    [...files].sort(sort(sorting))
);
