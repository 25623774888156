import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTrustHubSettings,
  fetchTrusthubAdminCurrentVendorUser,
} from '../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { ApplicationState } from '../../../../types/applicationState';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useDisplayTrusthubSettingsApiError } from '../../user/TrustHubUserPage/TrustHubUserPage';
import adoptechLogoDark from 'images/adoptech-logo-dark.svg';
import './TrustHubAdminLayout.scss';
import { portalRoutes } from '../../../../components/Routes/Routes';
import { ColorHeader } from '../../ColorHeader/ColorHeader';
import { TrustHubAdminPageHeaderButtons } from '../TrustHubAdminPageHeaderButtons/TrustHubAdminPageHeaderButtons';
import { setPageTitle } from '../../../../components/PageHeader/PageHeader';

interface TrusthubAdminLayoutProps {
  showHeaderBackButton?: boolean;
}
export const TrustHubAdminLayout: React.FC<
  PropsWithChildren<TrusthubAdminLayoutProps>
> = props => {
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);
  const { adminAuth, settings, fetchingSettingsStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const baseCss = 'trusthubAdminPage';
  useEffect(() => {
    setPageTitle('TrustHub');

    dispatch(
      fetchTrusthubAdminCurrentVendorUser({ id: vendor.id }, () => {
        dispatch(
          fetchTrustHubSettings({
            vendorIdOrDomain: vendor.id,
          })
        );
      })
    );
  }, [vendor.id]);
  const { apiError, hasApiError } = useDisplayTrusthubSettingsApiError();

  const hasApiAuthError = adminAuth.status === 'failed';
  const apiAuthError =
    'TrustHub admin login failed. Please contact to the admin to enable TrustHub.';
  if (['loading', 'idle'].includes(adminAuth.status)) {
    return <LoadingSpinner />;
  }

  const isFetchingSettings = fetchingSettingsStatus === 'loading';
  const isFetchedSettings = settings && Object.keys(settings).length > 0;

  const headerButtons = () => {
    if (!props.showHeaderBackButton || isFetchingSettings) return null;
    if (isFetchedSettings) return <TrustHubAdminPageHeaderButtons />;
    return null;
  };

  return (
    <div className={baseCss}>
      <ColorHeader
        showBackButton={props.showHeaderBackButton}
        buttons={headerButtons()}
      />
      <div className={baseCss + '--content'}>
        {hasApiError || hasApiAuthError ? (
          <div className="mt-4">{apiError || apiAuthError}</div>
        ) : (
          <>{props.children}</>
        )}

        <div className={baseCss + '--contentFooter'}>
          <div onClick={() => (window.location.href = portalRoutes.home)}>
            Powered by <img src={adoptechLogoDark} />
          </div>

          {/* force browser to always load favicon and cache => no need to call get /settings on drawer open*/}
          {settings.favicon && (
            <img src={settings.favicon.urls.full} className="d-none" />
          )}
        </div>
      </div>
    </div>
  );
};
