import { DataRoomEntityModel } from '../swagger';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';
import { dateSort } from './dateSort';
import { SortSetting } from '../types/sortSetting';

export default ({ columnSort }: SortSetting) =>
  (a: DataRoomEntityModel, b: DataRoomEntityModel) => {
    const direction = columnSort.direction === SortDirection.Ascending ? -1 : 1;

    switch (columnSort.name) {
      case 'name':
        return sort(a.name.toLowerCase(), b.name.toLowerCase(), direction);
      case 'fileType':
        return sort(a.fileType || '', b.fileType || '', direction);
      case 'updatedAt':
        return dateSort(a.updatedAt, b.updatedAt, direction);
      case 'createdAt':
        return dateSort(a.createdAt, b.createdAt, direction);
      case 'creator':
        return sort(
          a.creator?.fullName?.toLowerCase() || '',
          b.creator?.fullName?.toLowerCase() || '',
          direction
        );
    }
  };
