import { createSelector } from '@reduxjs/toolkit';
import { VendorUser } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectJobTitles = createSelector(
  (state: ApplicationState) => state.vendors.users,
  (users: VendorUser[]) =>
    users.reduce((acc, user) => {
      if (user.position) {
        acc.push(user.position);
      }

      return acc;
    }, [])
);
