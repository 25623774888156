import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechReactSelect } from '../../AdoptechReactSelect/AdoptechReactSelect';
import './DataRoomFolderSelect.scss';
import { FolderModel } from '../../../functions/getFlatFoldersList';
import { DataRoomSelectOption as Option } from '../DataRoomSelectOption/DataRoomSelectOption';

interface DataRoomFolderSelectProps {
  selectedDir: SelectionOption;
  setSelectedDir: (parent: SelectionOption) => void;
  folders: FolderModel[];
}

export const DataRoomFolderSelect: React.FC<DataRoomFolderSelectProps> = ({
  selectedDir,
  setSelectedDir,
  folders,
}) => {
  const baseCss = 'dataRoomFolderSelect';
  const [folderOptions, setFolderOptions] = useState<SelectionOption[]>();

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  useEffect(() => {
    const options = folders?.map(f => {
      return { value: f.id, label: f.name, level: f.level };
    });

    setFolderOptions(options);
  }, [vendorId]);

  return (
    <div className={baseCss}>
      <div className={baseCss + '--label'}>Select a directory</div>
      <AdoptechReactSelect
        components={{ Option }}
        hasError={!selectedDir}
        id={baseCss + '--folderSelect'}
        options={folderOptions}
        onChange={(e: SelectionOption) => {
          setSelectedDir(e);
        }}
        value={folderOptions?.find(fo => fo.value === selectedDir?.value)}
        placeholder="Please select a directory"
        noOptionsText="There are no directories to select from"
        maxMenuHeight={280}
        additionalStyling={{
          option: {
            paddingBottom: '3px',
            paddingTop: '3px',
            paddingLeft: '8px',
          },
        }}
      ></AdoptechReactSelect>
    </div>
  );
};

export default DataRoomFolderSelect;
