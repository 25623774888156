import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import './TrustHubUserAdminAddCompanyDrawer.scss';
import {
  TrusthubCompanyModel,
  TrusthubCompanyStatus,
} from '../../../../../swagger/trusthub';
import { AdoptechReactSelect2 } from '../../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { SelectionOption } from '../../../../../types/selectionOption';
import {
  fetchTrustHubVendorUsers,
  postTrusthubCompany,
} from '../../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechTextInput2 } from '../../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import classNames from 'classnames';
import { agreementsStartRoute } from '../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { ApplicationState } from '../../../../../types/applicationState';
import { showGlobalToast } from '../../../../../store/global/globalSlice';

interface AddCompanyFormData {
  companyName?: TrusthubCompanyModel['name'];
  companyOwnerId?: TrusthubCompanyModel['createdBy']['id'];
  companyNda?: boolean;
}

interface TrustHubUserAdminAddCompanyDrawerProps {
  onClose: () => void;
}

export const TrustHubUserAdminAddCompanyDrawer: React.FC<
  TrustHubUserAdminAddCompanyDrawerProps
> = ({ onClose }) => {
  const baseCss = 'trustHubUserAddCompanyDrawer';
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<AddCompanyFormData>({});
  const { createCompanyStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const currentVendor = useSelector(selectCurrentVendor);
  const dispatchSubmit = () => {
    dispatch(
      postTrusthubCompany(
        {
          vendorIdOrDomain: currentVendor?.id,
          body: {
            trusthubCompany: {
              name: formData.companyName,
              relationshipOwnerIds: [formData.companyOwnerId],
              status: formData.companyNda
                ? TrusthubCompanyStatus.Approved
                : TrusthubCompanyStatus.WaitingForNda,
            },
          },
        },
        _company => {
          dispatch(showGlobalToast('Company has been successfully created'));
          onClose();
        }
      )
    );
  };
  const { fetchingVendorUsersStatus, vendorUsers } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const ownerOptions = (vendorUsers || []).map(user => {
    return {
      label: user.fullName,
      value: user.id,
    };
  });
  const ndaOptions = [
    {
      label: 'Yes',
      value: 'yes',
    },
    { label: 'No', value: 'no' },
  ];
  // step 1 - user fill company name / owner
  // step 2 - ask nda for new company and submit
  const [currentStep, setCurrentStep] = useState<number>(1);
  useEffect(() => {
    dispatch(
      fetchTrustHubVendorUsers(
        { vendorIdOrDomain: currentVendor?.id },
        () => {}
      )
    );
  }, []);
  return (
    <AdoptechHS
      title="Add New Company"
      show
      showConfirmationWarning={false}
      onClose={() => onClose()}
      footer={
        <>
          <AdoptechButton
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          {currentStep === 1 ? (
            <AdoptechButton
              onClick={() => setCurrentStep(prevStep => prevStep + 1)}
              variant={AdoptechButtonVariant.Primary}
              disabled={
                ![formData.companyName, formData.companyOwnerId].every(
                  value => value
                )
              }
            >
              Next
            </AdoptechButton>
          ) : (
            <AdoptechButton
              onClick={dispatchSubmit}
              variant={AdoptechButtonVariant.Primary}
              busy={createCompanyStatus === 'loading'}
              disabled={
                ![formData.companyName, formData.companyOwnerId].every(
                  value => value
                ) || formData.companyNda === undefined
              }
            >
              Approve
            </AdoptechButton>
          )}
        </>
      }
      extraClass="adoptechHS--medium addCompanyHS"
    >
      {fetchingVendorUsersStatus === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <div className={baseCss + '--formContainer'}>
          {currentStep === 1 && (
            <>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--label'}>
                  Provide the following company information.
                </div>
                <div className={baseCss + '--field'}>
                  <AdoptechTextInput2
                    rounded
                    type="text"
                    id="companyTitle"
                    label="Company"
                    placeholder="Enter company name"
                    value={formData.companyName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        companyName: e.currentTarget.value,
                      })
                    }
                    icon={faPen}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  baseCss + '--fieldRow',
                  baseCss + '--ownerRow'
                )}
              >
                <div className={baseCss + '--field'}>
                  <AdoptechReactSelect2
                    id="addNewCompanyOwner"
                    options={ownerOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        companyOwnerId: option.value,
                      });
                    }}
                    value={ownerOptions.find(
                      option => option.value === formData.companyOwnerId
                    )}
                    placeholder="Select owner"
                    label="Owner of relationship"
                    showUserAvatar
                  />
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--label'}>
                  Is a NDA/Confidentiality Agreement in place with this company?
                </div>
                <div className={baseCss + '--field'}>
                  <AdoptechReactSelect2
                    id="approveCompanyNda"
                    options={ndaOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        companyNda: option.value === 'yes',
                      });
                    }}
                    value={
                      formData.companyNda === undefined
                        ? null
                        : ndaOptions.find(
                            option =>
                              option.value ===
                              (formData.companyNda ? 'yes' : 'no')
                          )
                    }
                    placeholder="Please select"
                    label="NDA/Confidentiality Agreement"
                  />
                </div>
              </div>
              {formData.companyNda === false && (
                <div className={baseCss + '--fieldRow'}>
                  <div className={baseCss + '--label'}>
                    The company TrustHub account created will be disabled until
                    an NDA/Confidentiality Agreement is in place, you can set
                    one up{' '}
                    <a
                      className={baseCss + '--ndaLink'}
                      onClick={() => dispatch(push(agreementsStartRoute))}
                    >
                      here.
                    </a>{' '}
                    Once an NDA is in place, select the Company in TrustHub and
                    Edit the record accordingly.
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </AdoptechHS>
  );
};
