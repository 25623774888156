import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import {
  outdatedActiveSubscriptionFailure,
  setSubscriptionDetails,
} from '../store/pricingSlice';
import { outdatedActiveSubscription } from '../../../selectors/selectActiveSubscription';
import { selectPaymentPlans } from '../../../selectors/selectPaymentPlans';
import { SharedPaymentPeriod } from '../../../swagger/models/SharedPaymentPeriod';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';

export const ManageSubscriptionPageDetails: React.FC = () => {
  const baseCss = 'manageSubscriptionPage';

  const dispatch = useDispatch();
  const { isUpdatingSubscription, subscriptionDetails } = useSelector(
    (state: ApplicationState) => state.pricing
  );

  const { isDeletingSubscription } = useSelector(
    (state: ApplicationState) => state.user
  );
  const paymentPlans = useSelector(selectPaymentPlans);
  const outdatedSubscription = useSelector(outdatedActiveSubscription);
  const [showAddons, setShowAddons] = useState<boolean>(false);
  const { paymentPeriod } = useSelector(
    (state: ApplicationState) => state.pricing.subscriptionDetails
  );

  useEffect(() => {
    paymentPlans.length > 0 &&
      outdatedSubscription &&
      dispatch(
        outdatedActiveSubscriptionFailure(
          'Warning: You have an outdated payment plan not listed on the page. If you want to unsubscribe from it, please contact the admin.'
        )
      );
  }, [paymentPlans.length]);

  if (isUpdatingSubscription || isDeletingSubscription) return null;

  return (
    <div className={baseCss + '--details'}>
      <div className={baseCss + '--addonsSwitcher'}>
        <div
          onClick={() => {
            setShowAddons(false);
          }}
          className={!showAddons ? 'active' : ''}
        >
          SUBSCRIPTION PLANS
        </div>
        <AdoptechTooltip
          showTooltip
          text="Add-ons are coming soon"
          identifier="#addons-switch"
          placement="top"
        >
          <div
            className={showAddons ? 'active' : ''}
            onClick={() => {
              // TODO in phase 2
              // setShowAddons(true);
            }}
          >
            ADD-ONS
          </div>
        </AdoptechTooltip>
      </div>

      <div className={baseCss + '--periodSwitcher'}>
        <div
          onClick={() => {
            dispatch(
              setSubscriptionDetails({
                ...subscriptionDetails,
                paymentPeriod: SharedPaymentPeriod.Monthly,
              })
            );
          }}
          className={
            paymentPeriod === SharedPaymentPeriod.Monthly ? 'active' : ''
          }
        >
          Month
        </div>
        <div
          className={
            paymentPeriod === SharedPaymentPeriod.Annually ? 'active' : ''
          }
          onClick={() => {
            dispatch(
              setSubscriptionDetails({
                ...subscriptionDetails,
                paymentPeriod: SharedPaymentPeriod.Annually,
              })
            );
          }}
        >
          Year
        </div>
      </div>
    </div>
  );
};
