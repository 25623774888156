import React from 'react';
import { Route, Switch } from 'react-router';
import {
  peopleComplianceRoute,
  peopleChartRoute,
  peopleRoute,
} from '../../../components/Routes/Routes';
import { People } from '../People/People';
import './PeoplePage.scss';
import { PeopleCompliance } from '../PeopleCompliance/PeopleCompliance';
import { PeopleChart } from '../PeopleChart/PeopleChart';
import { useBackground } from '../../../hooks/useBackground';

const PeoplePage: React.FC = () => {
  useBackground('grey');
  return (
    <div className="companyDetailsTeam">
      <React.Fragment>
        <Switch>
          <Route path={peopleComplianceRoute} component={PeopleCompliance} />
          <Route path={peopleChartRoute} component={PeopleChart} />
          <Route path={peopleRoute} component={People} />
        </Switch>
      </React.Fragment>
    </div>
  );
};

export default PeoplePage;
