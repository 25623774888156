import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faArrowAltSquareRight } from '@fortawesome/pro-light-svg-icons/faArrowAltSquareRight';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faFolderPlus } from '@fortawesome/pro-light-svg-icons/faFolderPlus';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeatballMenu } from '../../MeatballMenu/MeatballMenu';
import { Dropdown } from 'react-bootstrap';
import { downloadFile } from '../../../store/dataRoom/dataRoomThunks';
import {
  Access,
  DataRoomEntityModelTypeEnum,
  ShareableType,
  ShareAccessLevel,
} from '../../../swagger';
import {
  dataRoomShowDeleteModal,
  dataRoomShowEditEntityModal,
  dataRoomShowMoveModal,
  setEntityToEdit,
  setEntitiesToMove,
  setEntitiesToDelete,
  uploadToFolderRequest,
} from '../../../store/dataRoom/dataRoomSlice';
import { showShareModal } from '../../../store/vendors/vendorsSlice';
import { AccessObject } from '../../../types/accessObject';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';
import { selectAvailableFolders } from '../../../selectors/selectAvailableFolders';
import { moveRights } from '../DataRoomMoveEntitiesModal/DataRoomMoveEntitiesModal';
import { dataRoomRoute } from '../../Routes/Routes';

export interface DataRoomEntityMeatballMenuProps {
  entity: EntityDetails;
  startUpload?: () => void;
}

export interface EntityDetails {
  entityId: string;
  entityType: DataRoomEntityModelTypeEnum;
  entityName: string;
  entityAccess?: Access;
  entityParentId?: string;
  fileType: string;
}

export const DataRoomEntityMeatballMenu: React.FC<
  DataRoomEntityMeatballMenuProps
> = ({ entity, startUpload }) => {
  const dispatch = useDispatch();

  const handleUpload = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(uploadToFolderRequest([entity.entityId]));
    startUpload && startUpload();
  };

  const handleAddFolder = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(
      setEntityToEdit({
        entityId: '',
        entityName: '',
        entityType: DataRoomEntityModelTypeEnum.Folder,
        entityParentId: entity.entityId,
        fileType: entity.fileType,
      })
    );
    dispatch(dataRoomShowEditEntityModal());
  };

  const handleRename = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(setEntityToEdit(entity));
    dispatch(dataRoomShowEditEntityModal());
  };

  const handleShare = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(
      showShareModal({
        shareableId: entity.entityId,
        shareableType: ShareableType.DataRoomEntity,
        accessLevels: [ShareAccessLevel.Viewer, ShareAccessLevel.Manager],
        relativePath: dataRoomRoute,
      })
    );
  };

  const handleMove = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(setEntitiesToMove([entity]));
    dispatch(dataRoomShowMoveModal());
  };

  const handleDownload = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(downloadFile(entity.entityId));
  };

  const handleDelete = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(setEntitiesToDelete([entity]));
    dispatch(dataRoomShowDeleteModal());
  };

  const foldersAvailableToMoveTo = useSelector(
    selectAvailableFolders(moveRights)
  );

  const isFile = entity.entityType === DataRoomEntityModelTypeEnum.File;
  const access = entity.entityAccess;
  const showMove =
    access?.feature?.some(f => f === AccessObject.move) &&
    foldersAvailableToMoveTo.length > 0; // If there are no available folders, we hide the menu option

  const items = [
    access?.update && isFile,
    access?.update,
    access?.share,
    access?.show && isFile,
    access?.destroy,
    showMove,
  ];
  const menuIsEmpty = items.filter(item => item).length === 0;
  if (menuIsEmpty) return null;

  return (
    <MeatballMenu popperStrategy="absolute">
      {access.create && !isFile && (
        <Dropdown.Item onClick={handleAddFolder}>
          <FontAwesomeIcon className="meatballMenu--icon" icon={faFolderPlus} />
          New folder
        </Dropdown.Item>
      )}
      {access.create && !isFile && (
        <>
          <Dropdown.Item onClick={handleUpload}>
            <FontAwesomeIcon
              className="meatballMenu--icon"
              icon={faCloudArrowUp}
            />
            Upload
          </Dropdown.Item>
        </>
      )}
      {access.update && entity.entityName !== '/' && (
        <Dropdown.Item onClick={handleRename}>
          <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
          Rename
        </Dropdown.Item>
      )}
      {access.share && !isFile && (
        <Dropdown.Item onClick={handleShare}>
          <FontAwesomeIcon className="meatballMenu--icon" icon={faUserPlus} />
          Share
        </Dropdown.Item>
      )}
      {showMove && (
        <Dropdown.Item onClick={handleMove}>
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faArrowAltSquareRight}
          />
          Move
        </Dropdown.Item>
      )}
      {access.show && isFile && (
        <Dropdown.Item onClick={handleDownload}>
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faCloudDownload}
          />
          Download
        </Dropdown.Item>
      )}

      {access.destroy && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleDelete}>
            <FontAwesomeIcon className="meatballMenu--icon" icon={faTrashAlt} />
            Delete
          </Dropdown.Item>
        </>
      )}
    </MeatballMenu>
  );
};
