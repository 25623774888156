/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionFrequency,
  AssertionFrequencyFromJSON,
  AssertionFrequencyFromJSONTyped,
  AssertionFrequencyToJSON,
  AssertionSource,
  AssertionSourceFromJSON,
  AssertionSourceFromJSONTyped,
  AssertionSourceToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionModel
 */
export interface AssertionModel {
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  identifier: string;
  /**
   *
   * @type {boolean}
   * @memberof AssertionModel
   */
  autorun: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssertionModel
   */
  available?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  type: AssertionModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  status: AssertionModelStatusEnum;
  /**
   *
   * @type {AssertionFrequency}
   * @memberof AssertionModel
   */
  frequency: AssertionFrequency;
  /**
   *
   * @type {AssertionSource}
   * @memberof AssertionModel
   */
  source: AssertionSource;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  lastRunAt: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  nextRunAfter: string;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AssertionModel
   */
  updatedAt: string;
}

export function AssertionModelFromJSON(json: any): AssertionModel {
  return AssertionModelFromJSONTyped(json, false);
}

export function AssertionModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: json['identifier'],
    autorun: json['autorun'],
    available: !exists(json, 'available') ? undefined : json['available'],
    type: json['type'],
    description: json['description'],
    status: json['status'],
    frequency: AssertionFrequencyFromJSON(json['frequency']),
    source: AssertionSourceFromJSON(json['source']),
    lastRunAt: json['last_run_at'],
    nextRunAfter: json['next_run_after'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function AssertionModelToJSON(value?: AssertionModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    autorun: value.autorun,
    available: value.available,
    type: value.type,
    description: value.description,
    status: value.status,
    frequency: AssertionFrequencyToJSON(value.frequency),
    source: AssertionSourceToJSON(value.source),
    last_run_at: value.lastRunAt,
    next_run_after: value.nextRunAfter,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AssertionModelTypeEnum {
  Manual = 'Assertion::Manual',
  Automatic = 'Assertion::Automatic',
}
/**
 * @export
 * @enum {string}
 */
export enum AssertionModelStatusEnum {
  Pending = 'pending',
  InProgress = 'in_progress',
  Compliant = 'compliant',
  Failed = 'failed',
}
