/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorAccess
 */
export interface VendorAccess {
  /**
   *
   * @type {Array<string>}
   * @memberof VendorAccess
   */
  create?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof VendorAccess
   */
  index?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof VendorAccess
   */
  feature?: Array<VendorAccessFeatureEnum>;
  /**
   *
   * @type {boolean}
   * @memberof VendorAccess
   */
  show?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorAccess
   */
  update?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorAccess
   */
  destroy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorAccess
   */
  share?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorAccess
   */
  tables?: boolean;
}

export function VendorAccessFromJSON(json: any): VendorAccess {
  return VendorAccessFromJSONTyped(json, false);
}

export function VendorAccessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorAccess {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    create: !exists(json, 'create') ? undefined : json['create'],
    index: !exists(json, 'index') ? undefined : json['index'],
    feature: !exists(json, 'feature') ? undefined : json['feature'],
    show: !exists(json, 'show') ? undefined : json['show'],
    update: !exists(json, 'update') ? undefined : json['update'],
    destroy: !exists(json, 'destroy') ? undefined : json['destroy'],
    share: !exists(json, 'share') ? undefined : json['share'],
    tables: !exists(json, 'tables') ? undefined : json['tables'],
  };
}

export function VendorAccessToJSON(value?: VendorAccess | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    create: value.create,
    index: value.index,
    feature: value.feature,
    show: value.show,
    update: value.update,
    destroy: value.destroy,
    share: value.share,
    tables: value.tables,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorAccessFeatureEnum {
  VendorDashboard = 'vendor_dashboard',
  UserDashboard = 'user_dashboard',
  CompanyDashboard = 'company_dashboard',
  Company = 'company',
  CompanyManage = 'company_manage',
  Frameworks = 'frameworks',
  FrameworksManage = 'frameworks_manage',
  Controls = 'controls',
  ControlsCreate = 'controls_create',
  ControlsUpdate = 'controls_update',
  Tasks = 'tasks',
  TasksCreate = 'tasks_create',
  TasksUpdate = 'tasks_update',
  CompanyDocuments = 'company_documents',
  Checklists = 'checklists',
  ChecklistsManage = 'checklists_manage',
  Agreements = 'agreements',
  AgreementsManage = 'agreements_manage',
  People = 'people',
  PeopleManage = 'people_manage',
  LegalRegister = 'legal_register',
  LegalRegisterManage = 'legal_register_manage',
  VendorRegister = 'vendor_register',
  VendorRegisterManage = 'vendor_register_manage',
  Pestel = 'pestel',
  PestelManage = 'pestel_manage',
  Escrow = 'escrow',
  Audits = 'audits',
  AuditsManage = 'audits_manage',
  RiskRegister = 'risk_register',
  RiskRegisterCreate = 'risk_register_create',
  RiskRegisterUpdate = 'risk_register_update',
  Calendar = 'calendar',
  CalendarManage = 'calendar_manage',
  Reports = 'reports',
  ReportsManage = 'reports_manage',
  DataroomRootTreeManage = 'dataroom_root_tree_manage',
  DataroomControlEvidenceManage = 'dataroom_control_evidence_manage',
  Assessments = 'assessments',
  CompanyCertificates = 'company_certificates',
  Integrations = 'integrations',
  Trusthub = 'trusthub',
  Dashboard = 'dashboard',
}
