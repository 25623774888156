import React, { useEffect, useState } from 'react';
import './StartCompany.scss';
import { StartLayout } from '../StartLayout/StartLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../../unauthenticated/src/store/signup/signupSlice';
import { SignupPage } from '../../../../unauthenticated/src/types/signupPage';
import { AdoptechCountrySelect } from '../../AdoptechCountrySelect/AdoptechCountrySelect';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import {
  clearRegisteredCompany,
  setCountry,
  setUkBased,
} from '../../../store/welcome/welcomeSlice';
import { push } from 'connected-react-router';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import UkCompanySearch from '../../UkCompanySearch/UkCompanySearch';
import CompanyDetailsEntry from '../../CompanyDetailsEntry/CompanyDetailsEntry';
import { CompanyValidationFailureModal } from '../../CompanyValidationFailureModal/CompanyValidationFailureModal';
import { isGBCountry } from '../../../functions/isGBCountry';
import { StartCompanyRightMenu } from './StartCompanyRightMenu';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { postVendor } from '../../../store/welcome/welcomeThunks';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../ConfirmationModal/ConfirmationModal';

const StartCompany: React.FC = () => {
  const country = useSelector(
    (state: ApplicationState) => state.welcome.country
  );

  const errorMessage = useSelector(
    (state: ApplicationState) => state.welcome.postVendorError
  );

  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(country);

  const dispatch = useDispatch();

  const { postVendorStatus, registeredCompany, redirectingAfterSignup } =
    useSelector((state: ApplicationState) => state.welcome);

  useEffect(() => {
    dispatch(setCurrentPage(SignupPage.CompanyProfile));
  }, []);

  useEffect(() => {
    if (isGBCountry(selectedCountry)) {
      setIsValid(!!registeredCompany);
      return;
    }
  }, [selectedCountry, registeredCompany]);

  const handleChange = (option: SelectionOption) => {
    setIsTouched(false);
    setSelectedCountry(option.value);
    dispatch(setUkBased(isGBCountry(option.value)));
    dispatch(setCountry(option.value));
  };

  const handleIsValidChanged = (value: boolean) => {
    setIsValid(value);
  };

  const baseCss = 'startCompany';
  const isPostingVendor = postVendorStatus === 'loading';
  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const errorTitle =
    errorMessage && errorMessage.includes('already')
      ? 'This company already exists in the Adoptech portal'
      : errorMessage || 'Error';
  const showWarningModal = () =>
    command({
      title: 'Warning',
      description: `${errorTitle}. Please contact your Administrator to send you an invitation. If you do not know who your Administrator is, please use the help function to speak to Adoptech support.`,
      buttonText: 'OK',
      showCancelButton: false,
      buttonVariant: AdoptechButtonVariant.Primary,
    });

  useEffect(() => {
    postVendorStatus === 'failed' && showWarningModal();
  }, [postVendorStatus]);
  return (
    <>
      <StartLayout rightHandPanel={<StartCompanyRightMenu />}>
        <div className={baseCss}>
          <>
            <div className={baseCss + '--title'}>
              Create your company profile
            </div>
            <div className={baseCss + '--title-summary'}>
              This information is used in the documents you generate.
            </div>

            <div className={baseCss + '--prompt'}>
              What is your company's country of incorporation?
            </div>
            <div className={baseCss + '--countrySelect'}>
              <AdoptechCountrySelect
                onChange={handleChange}
                value={selectedCountry}
              />
            </div>
            <div className={baseCss + '--detail'}>
              {isGBCountry(selectedCountry) && (
                <>
                  <UkCompanySearch showError={isTouched && !isValid} />
                </>
              )}
              {!isGBCountry(selectedCountry) && (
                <CompanyDetailsEntry
                  showError={isTouched && !isValid}
                  isValidChanged={handleIsValidChanged}
                />
              )}
            </div>
            <div className={baseCss + '--actions'}>
              {isPostingVendor && (
                <div style={{ display: 'flex' }}>
                  <div>Creating your company profile ...</div>
                  <LoadingSpinner inlineSmall />
                </div>
              )}
              {!isPostingVendor && (
                <>
                  <AdoptechButton
                    onClick={() => {
                      dispatch(clearRegisteredCompany());
                      dispatch(push('/r/welcome/profile'));
                    }}
                  >
                    Back
                  </AdoptechButton>
                  <div onClick={() => setIsTouched(true)}>
                    <AdoptechButton
                      disabled={!isValid}
                      onClick={() => dispatch(postVendor())}
                      variant={AdoptechButtonVariant.Primary}
                      // show busy before redirect will finish at window.location.href = ''
                      busy={isPostingVendor || redirectingAfterSignup}
                      uppercase
                    >
                      Confirm
                    </AdoptechButton>
                  </div>
                </>
              )}
            </div>
          </>
        </div>
      </StartLayout>

      <CompanyValidationFailureModal />
      <ConfirmationModal
        command={currentCommand}
        onCancel={_ => command(null)}
      />
    </>
  );
};

export default StartCompany;
