import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { scrollCardIntoView } from '../../store/global/globalSlice';
import { Question, VendorPolicyAnswer } from '../../swagger';
import { PolicyQuestion } from '../PolicyQuestion/PolicyQuestion';
import './PolicyQuestions.scss';

interface PolicyQuestionsProps {
  answers: VendorPolicyAnswer[];
  clauseText: string;
  leaveOut: boolean;
  ownClause: boolean;
  questions: Question[];
  vendorPolicyClauseId: string;
  vendorPolicyId: string;
  viewModelLocal?: boolean;
}

const PolicyOwnClause = lazy(
  () => import('../PolicyOwnClause/PolicyOwnClause')
);

export const PolicyQuestions: React.FC<PolicyQuestionsProps> = props => {
  const dispatch = useDispatch();

  useDidMountEffect(() => {
    setTimeout(
      () => dispatch(scrollCardIntoView(props.vendorPolicyClauseId)),
      150
    );
  }, [props.ownClause]);

  return (
    <React.Fragment>
      {!props.ownClause &&
        props.questions.length > 0 &&
        props.questions.map(q => (
          <PolicyQuestion
            key={q.propertyName}
            answers={props.answers}
            disabled={props.leaveOut || props.ownClause}
            question={q}
            questions={props.questions}
            vendorPolicyId={props.vendorPolicyId}
          />
        ))}
      {props.questions.length === 0 && !props.ownClause && (
        <PolicyOwnClause
          key="ownClause"
          {...props}
          viewMode
          viewModeLocal={props.viewModelLocal}
        />
      )}
      {props.ownClause && (
        <PolicyOwnClause
          key="ownClause"
          {...props}
          viewModeLocal={props.viewModelLocal}
        />
      )}
    </React.Fragment>
  );
};
