import React from 'react';
import { SelectionOption } from '../../../types/selectionOption';
import './DataRoomSelectFolderTab.scss';
import { FolderModel } from '../../../functions/getFlatFoldersList';
import DataRoomFolderSelect from '../DataRoomFolderSelect/DataRoomFolderSelect';

interface DataRoomSelectFolderTabProps {
  selectedDir: SelectionOption;
  setSelectedDir: (parent: SelectionOption) => void;
  folders?: FolderModel[];
  description: string;
}

export const DataRoomSelectFolderTab: React.FC<
  DataRoomSelectFolderTabProps
> = ({ selectedDir, setSelectedDir, folders, description }) => {
  const baseCss = 'dataRoomSelectFolderTab';
  return (
    <div className={baseCss}>
      <div className={baseCss + '--row'}>{description}</div>
      <div className={baseCss + '--row'}>
        <DataRoomFolderSelect
          selectedDir={selectedDir}
          setSelectedDir={setSelectedDir}
          folders={folders}
        />
      </div>
    </div>
  );
};

export default DataRoomSelectFolderTab;
