import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCheckCircle } from '@fortawesome/pro-thin-svg-icons/faCheckCircle';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import './ExportEvidenceModal.scss';

import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';

interface ExportEvidenceModalProps {
  onClose: () => void;
}

export const ExportEvidenceModal: React.FC<
  ExportEvidenceModalProps
> = props => {
  const baseCss = 'exportEvidenceModal';
  return (
    <>
      <AdoptechModal show onHide={props.onClose} className={baseCss}>
        <div className={baseCss + '--icon'}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className={baseCss + '--title'}>Success</div>
        <div className={baseCss + '--description'}>
          Evidence export process has started. An email wil be sent to you when
          the file is ready for download.
        </div>
        <div className={baseCss + '--footer'}>
          <AdoptechButton
            variant={AdoptechButtonVariant.Primary}
            onClick={props.onClose}
          >
            Close
          </AdoptechButton>
        </div>
      </AdoptechModal>
    </>
  );
};
