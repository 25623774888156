import React, { useEffect, useState } from 'react';
import './TeamsPicker.scss';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { ApplicationState } from '../../types/applicationState';
import { useSelector } from 'react-redux';
import { SelectionOption } from '../../types/selectionOption';
import { Lozenge } from '../Lozenge/Lozenge';
import { AddTabsEnum } from '../../features/people/ManagePeopleDrawer/ManagePeopleDrawer';

interface TeamsPickerProps {
  onChange: (vendorTeamIds: string[]) => void;
  selectedTab: AddTabsEnum;
  value: string[];
}

const TeamsPicker: React.FC<TeamsPickerProps> = props => {
  const teams = useSelector(
    (state: ApplicationState) => state.vendors.teamsState.vendorTeams
  );

  const allTeams: SelectionOption[] = teams.map(t => ({
    label: t.name,
    value: t.id,
  }));

  const [availableTeams, setAvailableTeams] =
    useState<SelectionOption[]>(allTeams);
  const [selectedTeams, setSelectedTeams] = useState<SelectionOption[]>([]);

  const handleChange = (selectedOption: SelectionOption) => {
    const newSelectedTeams = [...selectedTeams, selectedOption];
    setSelectedTeams(newSelectedTeams);
    setAvailableTeams(at => [
      ...at.filter(t => t.value !== selectedOption.value),
    ]);
    props.onChange(newSelectedTeams.map(st => st.value));
  };

  const handleRemove = (selectedOption: SelectionOption) => {
    const newSelectedTeams = selectedTeams.filter(
      st => st.value !== selectedOption.value
    );
    setSelectedTeams(newSelectedTeams);
    setAvailableTeams(at => [...at, selectedOption]);
    props.onChange(newSelectedTeams.map(st => st.value));
  };

  useEffect(() => {
    setSelectedTeams(
      allTeams.filter(t => props.value?.some(v => v == t.value))
    );
  }, [props]);

  return (
    <div className="teamsPicker">
      {props.selectedTab === AddTabsEnum.Bulk && (
        <div className="teamsPicker--bulkAddMessage">
          Selected Groups will be applied to all users added in this bulk add.
          <br />
          <br />
          Alternatively leave this blank and edit the users' profiles to select
          their group.
        </div>
      )}
      <AdoptechReactSelect
        id="adoptechTeamSelect"
        onChange={handleChange}
        options={availableTeams}
      />
      {selectedTeams.length > 0 && (
        <>
          <div className="teamsPicker--info">
            The following groups have been applied:
          </div>
          <div className="teamsPicker--selectedTeams">
            {selectedTeams.map(st => (
              <Lozenge
                key={st.value}
                onDelete={() => handleRemove(st)}
                value={st.label}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TeamsPicker;
