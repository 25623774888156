import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import { VendorTeamModel } from '../../swagger';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TeamListItem.scss';

interface TeamListItemProps {
  team: VendorTeamModel;
  onChange(name: string): void;
  onRemove(team: VendorTeamModel): void;
}

export const TeamListItem: React.FC<TeamListItemProps> = ({
  team,
  onChange,
  onRemove,
}) => {
  const teamNameRef = useRef<HTMLInputElement>();
  const [teamName, setTeamName] = useState<string>(team.name);

  const { isDuplicateTeamName } = useSelector(
    (state: ApplicationState) => state.vendors.teamsState
  );

  const handleTeamEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setTeamName(value);
  };

  return (
    <div className="teamListItem">
      <AdoptechTextInput
        id="teamNameInput"
        hasError={isDuplicateTeamName}
        onBlur={() => onChange(teamName)}
        onChange={handleTeamEdit}
        ref={teamNameRef}
        type="text"
        placeholder="Add group"
        value={teamName}
      />
      <FontAwesomeIcon
        className="teamListItem--trashIcon"
        onClick={() => onRemove(team)}
        icon={faTrashAlt}
      />
    </div>
  );
};
