import moment from 'moment';
import { TaskModel } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const complianceTasksSort = (
  a: TaskModel,
  b: TaskModel,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof TaskModel = columnSort.name as keyof TaskModel;

  switch (typedColumn) {
    case 'reviewDate':
      return sort(moment(a.reviewDate), moment(b.reviewDate), direction);
    case 'assignee':
      return sort(
        a.assignee?.fullName || a.assignee?.email || '',
        b.assignee?.fullName || b.assignee?.email || '',
        direction
      );
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
