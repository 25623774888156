import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { dateSort } from './dateSort';
import { sort } from './sort';
import { ComplianceFrameworkPolicy } from '../selectors/selectComplianceFrameworkPolicies';

export const compliancePoliciesSort = (
  a: ComplianceFrameworkPolicy,
  b: ComplianceFrameworkPolicy,
  columnSort: ColumnSort
): number => {
  const direction = columnSort.direction === SortDirection.Ascending ? -1 : 1;

  const typedColumn: keyof ComplianceFrameworkPolicy =
    columnSort.name as keyof ComplianceFrameworkPolicy;

  switch (typedColumn) {
    case 'live':
      return sort(a.live, b.live, direction);
    case 'name':
      return sort(a.name.toLowerCase(), b.name.toLowerCase(), direction);
    case 'owner':
      return sort(a.owner?.toLowerCase(), b.owner?.toLowerCase(), direction);
    case 'approvedAt':
      return dateSort(a.approvedAt, b.approvedAt, direction);
  }
};
