import { useMemo } from 'react';
import { ControlsFilters } from '../../store/compliance/complianceState';
import { ControlModel } from '../../swagger';

export const useFrameworkFilter = (
  items: ControlModel[],
  filters: ControlsFilters
) => {
  const frameworkSelectOptions = useMemo(() => {
    const uniqFrameworksIds: string[] = [];
    const usedFrameworks = items.reduce((acc, item) => {
      item.frameworksRelations
        .map(fr => fr.framework)
        .forEach(f => {
          if (!uniqFrameworksIds.includes(f.id)) {
            acc.push(f);
            uniqFrameworksIds.push(f.id);
          }
        });
      return acc;
    }, []);

    return usedFrameworks.map(f => ({
      label: f.name,
      value: f.identifier,
    }));
  }, [items]);

  const currentFramework = filters.frameworkIdentifier
    ? frameworkSelectOptions.find(
        (option: any) => option.value === filters.frameworkIdentifier
      )
    : null;
  return { currentFramework, frameworkSelectOptions };
};
