import React from 'react';
import {
  getMoreRolesLabel,
  rolesViewConfig,
} from '../../../constants/rolesViewConfig';
import { VendorUserRolesValues } from '../EditRoles/EditRoles';
import './RoleTag.scss';

type RoleTagProps =
  | {
      role: VendorUserRolesValues;
      count?: number;
    }
  | {
      role?: VendorUserRolesValues;
      count: number;
    };

export const RoleTag = ({ role, count }: RoleTagProps) => {
  const roleConfig = count ? getMoreRolesLabel(count) : rolesViewConfig[role];

  if (!roleConfig) return null;
  return (
    <div
      style={{ backgroundColor: roleConfig.color }}
      className="roleTag"
      title={roleConfig.description}
    >
      {roleConfig.label}
    </div>
  );
};
