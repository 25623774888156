import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { VendorUserCreatePayloadVendorUsers } from '../../swagger';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import { EditProfileFields } from '../EditProfileFields/EditProfileFields';
import { EditRoles } from '../../features/people/EditRoles/EditRoles';
import {
  AddTabsEnum,
  PeopleDrawerPayload,
} from '../../features/people/ManagePeopleDrawer/ManagePeopleDrawer';
import { RoleInformation } from '../RoleInformation/RoleInformation';
import TeamsPicker from '../TeamsPicker/TeamsPicker';
import { UserDataPermissions } from '../UserDataPermissions/UserDataPermissions';
import './UserForm.scss';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { UserExternalAccounts } from '../UserExternalAccounts/UserExternalAccounts';

interface userFormProps {
  user: PeopleDrawerPayload;
  userId: string;
  selectedTab?: AddTabsEnum;
  formValid?: boolean;
  formTouched?: boolean;
  onChange: (data: PeopleDrawerPayload) => void;
}

export const UserForm: React.FC<userFormProps> = ({
  user,
  userId,
  selectedTab = AddTabsEnum.Single,
  formValid,
  formTouched,
  onChange,
}) => {
  const [data, setData] = useState<PeopleDrawerPayload>();

  useEffect(() => {
    setData(user);
  }, [user]);

  useEffect(() => {
    setData(null);
    onChange(null);
  }, [selectedTab]);

  const disabledFields = userId ? ['email', 'firstName', 'lastName'] : [];

  return (
    <div className="userForm">
      {selectedTab === AddTabsEnum.Single ? (
        <Accordion defaultActiveKey="0">
          <AdoptechAccordionCard
            index="0"
            title="Profile"
            cardBodyClass="card-body--centered withBorderBottom"
            headerClass="noBorderBottom positionInitial"
            iconSize="small"
            noMargin
          >
            {!userId && (
              <div className="userForm--internal-description">
                To add a new person, complete the mandatory field * and
                information below then click on Add to send them an invitation
                invitation by email.
              </div>
            )}
            <EditProfileFields
              userDetails={user}
              formValid={formValid}
              formTouched={formTouched}
              onChange={({ email, firstName, lastName }) => {
                const updatedData = {
                  ...data,
                  email,
                  firstName,
                  lastName,
                };
                setData(updatedData);
                onChange(updatedData);
              }}
              inDrawer
              disabledFields={disabledFields}
            />
          </AdoptechAccordionCard>
        </Accordion>
      ) : (
        <Accordion defaultActiveKey="0">
          <AdoptechAccordionCard
            index="0"
            title="Email address"
            cardBodyClass="card-body--centered withBorderBottom"
            headerClass="noBorderBottom positionInitial"
            iconSize="small"
            noMargin
          >
            <div className="userForm--internal-description">
              Please enter a list of peoples' email addresses to be added using
              a comma delimiter. Click on Add to send them an invitation by
              email.
            </div>
            <AdoptechTextArea
              onChange={e => {
                const updatedData = {
                  ...data,
                  email: e.currentTarget.value,
                };
                setData(updatedData);
                onChange(updatedData);
              }}
              value={(data as VendorUserCreatePayloadVendorUsers)?.email}
              hasError={formTouched && !formValid}
              id="emails"
              label="Email addresses"
            />
            {formTouched && !formValid && (
              <div className="userForm--error mt-2">
                One of the emails is invalid, please separate each email by a
                comma.
              </div>
            )}
          </AdoptechAccordionCard>
        </Accordion>
      )}
      <Accordion>
        <AdoptechAccordionCard
          index="0"
          title="Groups"
          cardBodyClass="card-body--centered withBorderBottom"
          headerClass="noBorderBottom positionInitial"
          iconSize="small"
          noMargin
        >
          <TeamsPicker
            onChange={vendorTeamsIds => {
              const updatedData = { ...data, vendorTeamsIds };
              setData(updatedData);
              onChange(updatedData);
            }}
            selectedTab={selectedTab}
            value={data?.vendorTeamsIds}
          />
        </AdoptechAccordionCard>
      </Accordion>

      <Accordion>
        <AdoptechAccordionCard
          index="0"
          title="Role permissions"
          cardBodyClass="card-body--centered"
          headerClass="noBorderBottom positionInitial"
          iconSize="small"
          noMargin
        >
          <EditRoles
            onChange={(userRoles: PeopleDrawerPayload['roles']) => {
              const updatedData = {
                ...data,
                roles: [...userRoles],
              };

              setData(updatedData);
              onChange(updatedData);
            }}
            selectedTab={selectedTab}
            user={user}
          />
        </AdoptechAccordionCard>
      </Accordion>
      {selectedTab === AddTabsEnum.Single && (
        <Accordion>
          <AdoptechAccordionCard
            index="0"
            title="Role information"
            cardBodyClass="card-body--centered userForm--manageSection-controls withBorderBottom"
            headerClass="positionInitial"
            iconSize="small"
            noMargin
          >
            <RoleInformation
              user={user}
              onChange={({
                position,
                employmentStartedAt,
                employmentEndedAt,
                lineManagerId,
                employmentStatus,
                userType,
              }) => {
                const updatedData = {
                  ...data,
                  position,
                  employmentStartedAt,
                  employmentEndedAt,
                  lineManagerId,
                  employmentStatus,
                  userType,
                };
                setData(updatedData);
                onChange(updatedData);
              }}
            />
          </AdoptechAccordionCard>
        </Accordion>
      )}
      {userId && (
        <Accordion>
          <AdoptechAccordionCard
            index="0"
            title="Document permissions"
            cardBodyClass="card-body--centered withBorderBottom"
            headerClass="positionInitial"
            iconSize="small"
            noMargin
          >
            <UserDataPermissions userId={userId} />
          </AdoptechAccordionCard>
        </Accordion>
      )}
      {user && (
        <Accordion>
          <AdoptechAccordionCard
            index="0"
            title="External accounts"
            cardBodyClass="card-body--centered withBorderBottom"
            headerClass="positionInitial"
            iconSize="small"
            noMargin
          >
            <UserExternalAccounts
              user={user}
              onChange={externalIdentifiers => {
                const updatedData = {
                  ...data,
                  externalIdentifiers,
                };
                setData(updatedData);
                onChange(updatedData);
              }}
            />
          </AdoptechAccordionCard>
        </Accordion>
      )}
    </div>
  );
};
