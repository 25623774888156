import React, { useEffect, useState } from 'react';
import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserUnlock } from '@fortawesome/pro-light-svg-icons/faUserUnlock';
import { ToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechTextInput } from '../../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { NoDataText } from '../../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../../../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../../../../types/grid';
import { AccessRequestTableRow } from './AccessRequestTableRow/AccessRequestTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccessRequests } from '../../../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum } from '../../../../../../swagger/trusthub/apis/DefaultApi';
import { ApplicationState } from '../../../../../../types/applicationState';
import classNames from 'classnames';
import { selectAccessRequests } from '../../selectAccessRequests';
import {
  setTrustHubAccessRequestsSearch,
  setTrustHubShowCompletedAccessRequests,
  updateShowCompletedAccessRequests,
} from '../../../../store/trusthubSlice';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { TrusthubAccessRequestModel } from '../../../../../../swagger/trusthub/models/TrusthubAccessRequestModel';
import { TrustHubUserAdminDenyRequestDrawer } from '../../TrustHubUserAdminDenyRequestDrawer/TrustHubUserAdminDenyRequestDrawer';
import { TrustHubUserAdminApproveRequestDrawer } from '../../TrustHubUserAdminApproveRequestDrawer/TrustHubUserAdminApproveRequestDrawer';
import { TrustHubLatestActivity } from './TrustHubLatestActivity/TrustHubLatestActivity';

export const pendingAccessRequestStatuses = [
  VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum.Pending,
];

export const completedAccessRequestStatuses = [
  VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum.Approved,
  VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum.Denied,
];

export const useFetchAdminRequests = () => {
  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);
  return () =>
    dispatch(
      fetchAccessRequests({
        vendorIdOrDomain: currentVendor.id,
        statuses: pendingAccessRequestStatuses,
      })
    );
};

export const TrustHubUserAdminRequestsPage: React.FC = () => {
  const baseCss = 'trusthubUserAdminPage';
  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);

  const fetchAdminRequests = useFetchAdminRequests();

  useEffect(() => {
    dispatch(updateShowCompletedAccessRequests(false));

    currentVendor && fetchAdminRequests();
  }, [currentVendor?.id]);
  const requests = useSelector(selectAccessRequests);
  const {
    accessRequestsSearch,
    fetchingAccessRequestsStatus,
    showCompletedRequests,
  } = useSelector((state: ApplicationState) => state.trustHub);

  const [showDenyRequestDrawer, setShowDenyRequestDrawer] = useState<
    false | TrusthubAccessRequestModel
  >(false);

  const [showApproveRequestDrawer, setShowApproveRequestDrawer] = useState<
    false | TrusthubAccessRequestModel
  >(false);

  const handleApprove = (request: TrusthubAccessRequestModel) => {
    setShowApproveRequestDrawer(request);
  };

  return (
    <>
      <div className={baseCss + '--panel'}>
        <AdoptechPanel>
          <div className={baseCss + '--panelTitle'}>
            <div className={baseCss + '--panelInfo'}>
              <FontAwesomeIcon icon={faUserUnlock} />
              Active Access Requests
              <div className={baseCss + '--panelBadge'}>{requests.length}</div>
            </div>
            <div className={baseCss + '--panelSwitcher'}>
              Show Completed Requests
              <ToggleSwitch
                handlerStyle="small"
                switchStyles={{
                  width: 35,
                  height: 18,
                  offHandleColor: '#fff',
                  onColor: '#666665',
                }}
                onChange={() => {
                  const newValue = !showCompletedRequests;

                  dispatch(setTrustHubShowCompletedAccessRequests(newValue));

                  dispatch(
                    fetchAccessRequests({
                      vendorIdOrDomain: currentVendor.id,
                      statuses: newValue
                        ? completedAccessRequestStatuses
                        : pendingAccessRequestStatuses,
                    })
                  );
                }}
                checked={showCompletedRequests}
              />
            </div>
          </div>

          <div className={baseCss + '--panelSearch'}>
            <AdoptechTextInput
              id="search"
              value={accessRequestsSearch}
              onChange={e =>
                dispatch(setTrustHubAccessRequestsSearch(e.currentTarget.value))
              }
              type="text"
              placeholder="Search requests"
              icon={faSearch}
              additionalClass="adoptechTextInput-search"
            />
          </div>

          {fetchingAccessRequestsStatus === 'loading' ? (
            <LoadingSpinner />
          ) : (
            <div className={baseCss + '--panelTable'}>
              <div
                className={classNames(
                  `${baseCss}--6-columns-first-column-short`
                )}
              >
                {requests.length <= 0 ? (
                  <NoDataText
                    extraClass="pt-4"
                    text="No access requests to show"
                  />
                ) : (
                  <AdoptechGridTable
                    header={
                      <div className="panelTableHeader">
                        <SortableTableHeader<TrusthubAccessRequestModel>
                          columnName={
                            'company' as keyof TrusthubAccessRequestModel
                          }
                          grid={Grid.TrustHubUserAdminAccessRequests}
                          label="Company"
                          notInTable
                        />
                        <SortableTableHeader<TrusthubAccessRequestModel>
                          columnName="trusthubVendorUser"
                          grid={Grid.TrustHubUserAdminAccessRequests}
                          label="User"
                          notInTable
                        />
                        <SortableTableHeader<TrusthubAccessRequestModel>
                          columnName={
                            'asset' as keyof TrusthubAccessRequestModel
                          }
                          grid={Grid.TrustHubUserAdminAccessRequests}
                          label="Asset"
                          notInTable
                        />
                        <SortableTableHeader<TrusthubAccessRequestModel>
                          columnName="createdAt"
                          grid={Grid.TrustHubUserAdminAccessRequests}
                          label="Request On"
                          notInTable
                        />
                        <SortableTableHeader<TrusthubAccessRequestModel>
                          columnName="status"
                          grid={Grid.TrustHubUserAdminAccessRequests}
                          label="Status"
                          notInTable
                        />
                        <div>Action</div>
                      </div>
                    }
                  >
                    {requests.map(request => {
                      const { id } = request;
                      return (
                        <AccessRequestTableRow
                          request={request}
                          key={id}
                          setShowDenyRequestDrawer={setShowDenyRequestDrawer}
                          handleApprove={handleApprove}
                        />
                      );
                    })}
                  </AdoptechGridTable>
                )}
              </div>
            </div>
          )}
          {showDenyRequestDrawer && (
            <TrustHubUserAdminDenyRequestDrawer
              onClose={() => setShowDenyRequestDrawer(false)}
              request={showDenyRequestDrawer}
            />
          )}

          {showApproveRequestDrawer && (
            <TrustHubUserAdminApproveRequestDrawer
              onClose={() => setShowApproveRequestDrawer(false)}
              request={showApproveRequestDrawer}
            />
          )}
        </AdoptechPanel>
      </div>
      <TrustHubLatestActivity />
    </>
  );
};
