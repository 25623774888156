import React, { useEffect, useState } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  dataRoomShowEditEntityModal,
  hideModals,
} from '../../../store/dataRoom/dataRoomSlice';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import './DataRoomEditEntityModal.scss';
import {
  createFolder,
  updateEntity,
} from '../../../store/dataRoom/dataRoomThunks';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { DataRoomEntityModelTypeEnum } from '../../../swagger';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { dataRoomPathExists } from '../../../functions/dataRoomPathExists';
import { dataRoomGetParentFolderId } from '../../../functions/dataRoomGetParentFolderId';
import { getEntityName } from '../../../selectors/getEntityName';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';

interface DataRoomEditEntityModalProps {
  show: boolean;
}

export const DataRoomEditEntityModal: React.FC<
  DataRoomEditEntityModalProps
> = ({ show }) => {
  const baseCss = 'dataRoomEditEntityModal';
  const dispatch = useDispatch();
  const [newName, setNewName] = useState('');

  const hideModal = () => {
    dispatch(hideModals());
  };

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const folders = useSelector(
    (state: ApplicationState) => state.dataRoom.folders
  );

  const entitiesTree = useSelector(
    (state: ApplicationState) => state.dataRoom.entitiesTree
  );

  const { entityId, entityName, entityType, entityParentId, fileType } =
    useSelector((state: ApplicationState) => state.dataRoom.entityToEdit);

  const canManageRootTree = canFeature(AccessObject.dataroom_root_tree_manage);

  const handleRename = () => {
    if (
      dataRoomPathExists(
        entitiesTree,
        folders,
        getEntityName({
          entityName: newName,
          entityType,
          fileType,
        }),
        entityId
          ? dataRoomGetParentFolderId(entitiesTree, entityId)
          : entityParentId
      )
    ) {
      dispatch(
        showGlobalToast(
          'A file or folder named "' +
            newName +
            '" already exists in this directory'
        )
      );
    } else {
      if (entityId)
        dispatch(
          updateEntity(vendorId, entityId, newName, '', canManageRootTree)
        );
      else
        dispatch(
          createFolder(vendorId, {
            name: newName,
            parentFolderId: entityParentId,
          })
        );
      dispatch(dataRoomShowEditEntityModal());
    }
  };

  const isDisabled = () => {
    return newName.length < 1 || inProgress;
  };

  useEffect(() => setNewName(entityName || ''), [entityName, show]);

  const entityTypeName =
    entityType === DataRoomEntityModelTypeEnum.Folder ? 'folder' : 'file';
  const titleText = !entityId ? 'Add new' : 'Rename';
  const instructionText = !entityId ? 'Set' : 'Edit';

  const inProgress = useSelector(
    (state: ApplicationState) =>
      state.dataRoom.currentEntityIdProcessing === entityId
  );

  return (
    <AdoptechModal onHide={hideModal} show={show} className={baseCss}>
      <Modal.Header>
        <div>{`${titleText} ${entityTypeName}`}</div>
      </Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--row'}>
          <div className={baseCss + '--label'}>
            {`${instructionText} ${entityTypeName} name`}
          </div>
          <AdoptechTextInput
            errorClass="adoptech-error"
            hasError={!newName}
            id="newFolderName"
            onChange={e => {
              setNewName(e.target.value);
            }}
            autoFocus
            value={newName}
            type="text"
          />
        </div>
      </Modal.Body>
      <ModalFooter>
        <div className={baseCss + '--buttonRow'}>
          <AdoptechButton onClick={hideModal} shadow>
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleRename}
            variant={AdoptechButtonVariant.Primary}
            disabled={isDisabled()}
            type="submit"
            shadow
          >
            Save
          </AdoptechButton>
        </div>
      </ModalFooter>
    </AdoptechModal>
  );
};
