import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../../functions/formatShortDate';
import { fetchFiles } from '../../../store/dataRoom/dataRoomThunks';
import { DataRoomEntityModel } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../../Lozenge/Lozenge';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { DataRoomEntityMeatballMenu } from '../DataRoomEntityMeatballMenu/DataRoomEntityMeatballMenu';
import selectFiles from '../../../selectors/selectFiles';

import { dataRoomCss } from '../DataRoom/DataRoom';
import './DataRoomFiles.scss';
import '../DataRoom/DataRoom.scss';

interface DataRoomFilesProps {
  vendorId: string;
}
export const DataRoomFiles: React.FC<DataRoomFilesProps> = ({ vendorId }) => {
  const baseCss = 'dataRoomFiles';
  const dispatch = useDispatch();

  const currentEntityIdProcessing = useSelector(
    (state: ApplicationState) => state.dataRoom.currentEntityIdProcessing
  );

  const files = useSelector(selectFiles);

  useEffect(() => {
    dispatch(fetchFiles(vendorId));
  }, [vendorId]);

  return (
    <>
      <div className={dataRoomCss}>
        <div className={dataRoomCss + '--header'}>
          <div className={dataRoomCss + '--title'}>
            Files shared with me
            <Lozenge intent={Intent.None} value={files?.length} />
          </div>
        </div>
        <div className={dataRoomCss + '--table'}>
          <div className={dataRoomCss + '--tableHeader'}>
            <SortableTableHeader<DataRoomEntityModel>
              columnName="name"
              grid={Grid.DataRoom}
              label="Name"
              className={dataRoomCss + '--nameHeader'}
              notInTable
            />
            <SortableTableHeader<DataRoomEntityModel>
              columnName="fileType"
              grid={Grid.DataRoom}
              label="File"
              className={dataRoomCss + '--fileTypeHeader'}
              notInTable
            />
            <SortableTableHeader<DataRoomEntityModel>
              columnName="createdAt"
              grid={Grid.DataRoom}
              label="Upload Date"
              className={dataRoomCss + '--uploadDateHeader'}
              notInTable
            />
            <SortableTableHeader<DataRoomEntityModel>
              columnName="creator"
              grid={Grid.DataRoom}
              label="Created by"
              className={dataRoomCss + '--createdByHeader'}
              notInTable
            />
            <div className={dataRoomCss + '--actionsHeader'}>Actions</div>
          </div>
          {files ? (
            <div className={baseCss + '--files'}>
              {files?.map(f => {
                return (
                  <div
                    key={f.id}
                    className={dataRoomCss + '--fileRow'}
                    onClick={() => {}}
                  >
                    <div className={dataRoomCss + '--name'}>
                      <FontAwesomeIcon icon={faFile} />
                      &nbsp;&nbsp;{f.name}
                    </div>
                    <div className={dataRoomCss + '--fileType'}>
                      {f.fileType || '-'}
                    </div>
                    <div className={dataRoomCss + '--createdAt'}>
                      {formatShortDate(f.createdAt)}
                    </div>
                    <div className={dataRoomCss + '--creator'}>
                      <UserAvatar user={f.creator} size="small" />
                    </div>
                    <div className={dataRoomCss + '--actions'}>
                      {currentEntityIdProcessing === f.id ? (
                        <LoadingSpinner inlineSmall />
                      ) : (
                        <DataRoomEntityMeatballMenu
                          entity={{
                            entityId: f.id,
                            entityType: f.type,
                            entityName: f.name,
                            entityAccess: f?.access,
                            fileType: f.fileType,
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={dataRoomCss + '--label'}>No files to display</div>
          )}
        </div>
      </div>
    </>
  );
};
