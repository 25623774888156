import { controlIsCompliant } from '../store/compliance/complianceSlice';
import { ControlCategoryExtended } from '../swagger/models/ControlCategoryExtended';
import { TaskModelStatusEnum } from '../swagger/models/TaskModel';

export const getCategoryCompletionRate = (cat: ControlCategoryExtended) => {
  const controlCount = cat.controls.length;
  let compliantControlCount = 0;

  cat.controls.forEach(con => {
    if (controlIsCompliant(con.tasks)) compliantControlCount++;
  });
  return controlCount === 0 ? 0 : (compliantControlCount / controlCount) * 100;
};
