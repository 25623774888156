import React from 'react';
import './ComplianceFrameworkIcon.scss';
import iso_27001 from 'images/ISO27001.svg';
import iso_9001 from 'images/ISO9001.svg';
import soc_2 from 'images/SOC2.svg';
import demo from 'images/DEMO.svg';
import dora from 'images/DORA.svg';
import cyber_essentials from 'images/CYBERESSENTIALS.svg';
import adoptech_data_privacy from 'images/ADOPTECH_DATA_PRIVACY.svg';

interface ComplianceFrameworkIconProps {
  icon: string;
}

export const ComplianceFrameworkIcon: React.FC<
  ComplianceFrameworkIconProps
> = props => {
  const baseCss = 'complianceFrameworkIcon';

  const icons: Record<string, any> = {
    iso_27001_2022: iso_27001,
    iso_27001,
    iso_9001_2015: iso_9001,
    iso_9001,
    soc_2,
    demo,
    dora,
    cyber_essentials,
    adoptech_data_privacy,
  };

  const iconImage: any | undefined = icons[props.icon];

  if (iconImage === undefined) {
    return <div>default icon</div>;
  }

  return (
    <div className={baseCss}>
      <img className={baseCss + '--icon'} src={iconImage} alt={props.icon} />
    </div>
  );
};
