import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../store/vendors/vendorsThunks';
import { VendorUser } from '../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './DeleteUserModal.scss';

interface DeleteUserModalProps {
  close: () => void;
  user: VendorUser;
  vendorId: string;
}
// TODO: remove this, use confirm modal
export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  close,
  user,
  vendorId,
}: DeleteUserModalProps) => {
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(deleteUser({ user, vendorId }));
    close();
  };

  return (
    <AdoptechModal className="deleteUserModal" onHide={() => {}} show>
      <Modal.Header>Delete user</Modal.Header>
      <Modal.Body>
        <div>Are you sure you wish to delete the following user:</div>
        <div className="deleteUserModal--emailAddress">{user?.email}</div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => {
            close();
          }}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={submit}
          uppercase
          variant={AdoptechButtonVariant.Warning}
        >
          confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
