import { LegislationExtended } from '../../swagger';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechTextArea2 } from '../AdoptechTextArea2/AdoptechTextArea2';
import { useAutoGrowTextArea } from '../../hooks/useAutoGrowTextArea';

export const validation = (
  data: LegislationExtended
): Record<string, string[]> => ({
  description:
    (data.description || '').trim().length > 0
      ? null
      : ['Description must be present'],
});

interface Props {
  formData: LegislationExtended;
  setFormData: Dispatch<SetStateAction<LegislationExtended>>;
}

export const DetailsSection: React.FC<Props> = ({ formData, setFormData }) => {
  const baseCss = 'editLegislationDrawer';

  const hasError = (field: string) => !!validation(formData)[field];

  const id = formData.id;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutoGrowTextArea(textAreaRef.current, formData?.description);

  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Details"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <AdoptechTextArea2
              label="Description"
              id={`legislation-${id}-descriptionInput`}
              value={formData?.description}
              rows={3}
              ref={textAreaRef}
              placeholder="Enter description text"
              hasError={hasError('description')}
              onChange={e => {
                setFormData({
                  ...formData,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}></div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
