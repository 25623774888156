import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTrustHubSection,
  updateTrustHubSection,
} from '../store/trusthubThunks';
import { ApplicationState } from '../../../types/applicationState';
import {
  TrusthubSectionModel,
  TrusthubDocumentModel,
  SectionsSectionIdPatchRequest,
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesDocumentTypeEnum,
} from '../../../swagger/trusthub';

export interface EditSectionProps {
  section: TrusthubSectionModel;
  onClose: () => void;
  baseCss?: string;
}
// Logic for fetching and saving current section ( after checkboxes items selected ) in Edit mode

export const useEditTrustHubSection = ({
  section,
  onClose,
}: EditSectionProps) => {
  const dispatch = useDispatch();
  // don't use TrusthubDocumentModel['id'] because it can be empty if it's template
  const [checkedIds, setCheckedDocsIds] = useState<
    TrusthubDocumentModel['documentId'][]
  >([]);
  const { updateSectionStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const [fetchingCurrentSection, setFetchingCurrentSection] = useState(false);
  const [sectionDescription, setSectionDescription] = useState(
    section.description
  );
  useEffect(() => {
    setFetchingCurrentSection(true);
    dispatch(
      fetchTrustHubSection(
        {
          includeTemplates: true,
          sectionId: section.id,
        },
        (sectionPayload: TrusthubSectionModel) => {
          setFetchingCurrentSection(false);
          setCheckedDocsIds(
            sectionPayload.trusthubDocuments
              .filter(doc => doc.id)
              .map(doc => doc.documentId)
          );
        }
      )
    );
  }, []);

  const handleCheckboxChange = (
    checked: boolean,
    doc: TrusthubDocumentModel
  ) => {
    setCheckedDocsIds(prevCheckedDocsIds => {
      if (checked) {
        return [...prevCheckedDocsIds, doc.documentId];
      } else {
        return prevCheckedDocsIds.filter(
          prevCheckedDocId => prevCheckedDocId !== doc.documentId
        );
      }
    });
  };

  const handleSave = () => {
    const trusthubDocumentsAttributes = section.trusthubDocuments.map(doc => {
      return {
        id: doc.id,
        destroy: !checkedIds.includes(doc.documentId),
        name: doc.name,
        documentId: doc.documentId,
        documentType:
          doc.documentType as TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesDocumentTypeEnum,
        restricted: true,
      };
    });
    const payload: SectionsSectionIdPatchRequest = {
      sectionId: section.id,
      body: {
        trusthubSection: {
          description: sectionDescription,
          trusthubDocumentsAttributes: trusthubDocumentsAttributes,
        },
      },
    };

    dispatch(updateTrustHubSection(payload, () => onClose()));
  };

  const docsToEdit = section?.trusthubDocuments || [];
  const sortedDocsToEdit = [...docsToEdit].sort(
    (a, b) => a.rowOrder - b.rowOrder
  );

  return {
    fetchingCurrentSection,
    sectionDescription,
    setSectionDescription,
    handleCheckboxChange,
    updateSectionStatus,
    checkedIds,
    sortedDocsToEdit,
    handleSave,
  };
};
