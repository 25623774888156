import React, { useEffect, useState } from 'react';
import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechTextInput } from '../../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { NoDataText } from '../../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../../../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../../../../types/grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrustHubCompanies } from '../../../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { ApplicationState } from '../../../../../../types/applicationState';
import classNames from 'classnames';
import { setTrustHubCompaniesSearch } from '../../../../store/trusthubSlice';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import {
  TrusthubCompanyModel,
  TrusthubCompanyStatus,
} from '../../../../../../swagger/trusthub';
import { CompanyTableRow } from './CompanyTableRow/CompanyTableRow';
import { selectCompanies } from '../../selectCompanies';
import { TrustHubUserAdminAddCompanyDrawer } from '../../TrustHubUserAdminAddCompanyDrawer/TrustHubUserAdminAddCompanyDrawer';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../components/AdoptechButton/AdoptechButton';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

export const TrustHubUserAdminCompaniesPage: React.FC = () => {
  const baseCss = 'trusthubUserAdminPage';
  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);

  const statuses = [
    TrusthubCompanyStatus.Approved,
    TrusthubCompanyStatus.Suspended,
    TrusthubCompanyStatus.WaitingForNda,
  ];
  useEffect(() => {
    currentVendor &&
      dispatch(
        fetchTrustHubCompanies(
          {
            vendorIdOrDomain: currentVendor.id,
            statuses,
          },
          () => {}
        )
      );
  }, [currentVendor?.id]);
  const companies = useSelector(selectCompanies);
  const { companiesSearch, fetchingCompaniesStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const [showAddCompanyDrawer, setShowAddCompanyDrawer] = useState(false);

  return (
    <div className={baseCss + '--panel'}>
      <AdoptechPanel>
        <div className={baseCss + '--panelTitle'}>
          <div className={baseCss + '--panelInfo'}>
            <FontAwesomeIcon icon={faBuilding} />
            Companies
            <div className={baseCss + '--panelBadge'}>{companies.length}</div>
          </div>

          <div className={baseCss + '--panelTitleButtons'}>
            <AdoptechButton
              variant={AdoptechButtonVariant.PrimaryOrange}
              icon={faPlus}
              iconSize="large"
              onClick={() => setShowAddCompanyDrawer(true)}
            >
              Add Company
            </AdoptechButton>
          </div>
        </div>

        <div className={baseCss + '--panelSearch'}>
          <AdoptechTextInput
            id="search"
            value={companiesSearch}
            onChange={e =>
              dispatch(setTrustHubCompaniesSearch(e.currentTarget.value))
            }
            type="text"
            placeholder="Search companies"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>

        {fetchingCompaniesStatus === 'loading' ? (
          <LoadingSpinner />
        ) : (
          <div className={baseCss + '--panelTable'}>
            <div
              className={classNames(
                `${baseCss}--6-columns`,
                `${baseCss}--6-columns-noMaxHeight`
              )}
            >
              {companies.length <= 0 ? (
                <NoDataText extraClass="pt-4" text="No companies to show" />
              ) : (
                <AdoptechGridTable
                  header={
                    <div className="panelTableHeader">
                      <SortableTableHeader<TrusthubCompanyModel>
                        columnName="name"
                        grid={Grid.TrustHubUserAdminCompanies}
                        label="Company"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubCompanyModel>
                        columnName={'ndaStatus' as keyof TrusthubCompanyModel}
                        grid={Grid.TrustHubUserAdminCompanies}
                        label="NDA status"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubCompanyModel>
                        columnName={'owner' as keyof TrusthubCompanyModel}
                        grid={Grid.TrustHubUserAdminCompanies}
                        label="Owner"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubCompanyModel>
                        columnName="createdAt"
                        grid={Grid.TrustHubUserAdminCompanies}
                        label="Created at"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubCompanyModel>
                        columnName="createdBy"
                        grid={Grid.TrustHubUserAdminCompanies}
                        label="Created by"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubCompanyModel>
                        columnName="status"
                        grid={Grid.TrustHubUserAdminCompanies}
                        label="Status"
                        notInTable
                      />
                    </div>
                  }
                >
                  {companies.map(company => {
                    const { id } = company;
                    return <CompanyTableRow company={company} key={id} />;
                  })}
                </AdoptechGridTable>
              )}
            </div>
          </div>
        )}
        {showAddCompanyDrawer && (
          <TrustHubUserAdminAddCompanyDrawer
            onClose={() => setShowAddCompanyDrawer(false)}
          />
        )}
      </AdoptechPanel>
    </div>
  );
};
