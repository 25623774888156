import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Office, RegisteredCompany } from '../../swagger/models';
import { WebDetails } from '../../types/webDetails';
import { Sector, UserType, WelcomeState } from './welcomeState';
import { postVendor } from './welcomeThunks';

const welcomeSlice = createSlice({
  initialState: {
    companySectors: [],
    searchCompaniesResults: null,
    isShowingCompanyNotFoundModal: false,
    isShowingCompanyProfileCreatedToast: false,
    isShowingUserProfileCreatedToast: false,
    isShowingCompanyValidationFailureModal: false,
    ukBased: true,
    country: 'GB',
    companyIdentifierType: '',
    searchCompaniesError: null,
    companyValidationError: null,
  } as unknown as WelcomeState,
  name: 'welcomeSlice',
  reducers: {
    clearRegisteredCompany: state => {
      state.registeredCompany = undefined;
    },
    hideCancelSignUpModal: state => {
      state.isCancelSignUpModalShowing = false;
    },
    hideCompanyNotFoundModal: state => {
      state.isShowingCompanyNotFoundModal = false;
    },
    searchCompaniesRequest: state => {
      state.isSearchingCompanies = true;
      state.searchCompaniesError = undefined;
    },
    searchCompaniesSuccess: (
      state,
      action: PayloadAction<RegisteredCompany[]>
    ) => {
      state.isSearchingCompanies = false;
      state.searchCompaniesResults = action.payload;

      if (action.payload.length === 0) {
        state.isShowingCompanyNotFoundModal = true;
      }
    },
    searchCompaniesFailure: (state, action: PayloadAction<string>) => {
      state.isSearchingCompanies = false;
      state.searchCompaniesError = action.payload;
    },
    companyValidationFailure: (state, action: PayloadAction<string>) => {
      state.companyValidationError = action.payload;
    },
    setRegisteredCompany: (state, action: PayloadAction<RegisteredCompany>) => {
      state.registeredCompany = action.payload;
    },
    setUkBased: (state, action: PayloadAction<boolean>) => {
      state.ukBased = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setUserType: (state, action: PayloadAction<UserType>) => {
      state.userType = action.payload;
    },
    setWebDetails: (state, action: PayloadAction<WebDetails>) => {
      state.linkedInUrl = action.payload.linkedInUrl;
      state.twitterUrl = action.payload.twitterUrl;
      state.websiteUrl = action.payload.websiteUrl;
    },
    showCancelSignUpModal: state => {
      state.isCancelSignUpModalShowing = true;
    },
    toggleSector: (state, action: PayloadAction<Sector>) => {
      const index = state.companySectors.indexOf(action.payload);
      if (index === -1) {
        state.companySectors.push(action.payload);
      } else {
        state.companySectors.splice(index, 1);
      }
    },
    setOfficeAddress: (state, action: PayloadAction<Office>) => {
      state.office = action.payload;
    },
    setCompanyIdentifierType: (state, action: PayloadAction<string>) => {
      state.companyIdentifierType = action.payload;
    },
    showCompanyValidationFailureModal: state => {
      state.isShowingCompanyValidationFailureModal = true;
    },
    hideCompanyValidationFailureModal: state => {
      state.isShowingCompanyValidationFailureModal = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postVendor.pending, state => {
        state.postVendorStatus = 'loading';
      })
      .addCase(postVendor.fulfilled, state => {
        state.postVendorStatus = 'succeeded';
        state.redirectingAfterSignup = true;
      })
      .addCase(postVendor.rejected, (state, action) => {
        state.postVendorError = action?.error?.message;
        state.postVendorStatus = 'failed';
      });
  },
});

export const {
  clearRegisteredCompany,
  companyValidationFailure,
  hideCancelSignUpModal,
  hideCompanyNotFoundModal,
  searchCompaniesRequest,
  searchCompaniesSuccess,
  searchCompaniesFailure,
  setRegisteredCompany,
  setUkBased,
  setCountry,
  setUserType,
  setWebDetails,
  showCancelSignUpModal,
  toggleSector,
  setOfficeAddress,
  setCompanyIdentifierType,
  showCompanyValidationFailureModal,
  hideCompanyValidationFailureModal,
} = welcomeSlice.actions;

export default welcomeSlice.reducer;
