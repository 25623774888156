import React, { useEffect, useState } from 'react';
import {
  VendorUserCreatePayloadVendorUsers,
  VendorUserUpdatePayloadVendorUser,
} from '../../swagger';
import { UserForm } from '../UserForm/UserForm';

interface EditUserProps {
  user: VendorUserCreatePayloadVendorUsers | VendorUserUpdatePayloadVendorUser;
  userId: string;
  onChangeUser: (
    data: VendorUserCreatePayloadVendorUsers | VendorUserUpdatePayloadVendorUser
  ) => void;
}

export const EditUser: React.FC<EditUserProps> = ({
  user,
  userId,
  onChangeUser,
}) => {
  const [data, setData] = useState<
    VendorUserCreatePayloadVendorUsers | VendorUserUpdatePayloadVendorUser
  >();

  useEffect(() => {
    setData(user);
  }, [user]);

  return <UserForm user={data} userId={userId} onChange={onChangeUser} />;
};
