import { createSelector } from '@reduxjs/toolkit';

import Fuse from 'fuse.js';
import { ComplianceFilterPayload } from '../components/compliance/Types/complianceTypes';
import { ControlCategoryExtended } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const searchOptions = {
  ignoreLocation: true,
  includeScore: true,
  keys: ['name'],
  threshold: 0,
};

const filteredByName = (
  categories: ControlCategoryExtended[],
  search: string
) => {
  const controls = categories.map(category => category.controls).flat();
  const fuseTask = new Fuse(controls, searchOptions);
  const filteredControls = fuseTask.search(search).map(x => x.item);

  return categories
    .map(category => {
      const intersection = category.controls.filter(c =>
        filteredControls.includes(c)
      );
      if (intersection.length === 0) return null;

      return { ...category, controls: intersection };
    })
    .filter(category => category);
};

const filteredByAssignee = (
  categories: ControlCategoryExtended[],
  assigneeId: string
) => {
  const newCategories = categories
    .map(category => {
      const newControls = category.controls.filter(
        control => control.assignee?.id === assigneeId
      );
      return { ...category, controls: newControls };
    })
    .filter(category => category.controls.length > 0);
  return newCategories;
};

export const selectFilteredCategories = (search: string) =>
  createSelector(
    (state: ApplicationState) => state.compliance.categories,
    (state: ApplicationState) => state.compliance.categoriesFilters,
    (
      categories: ControlCategoryExtended[],
      filters: ComplianceFilterPayload
    ) => {
      const filteredCategories = search
        ? filteredByName(categories, search)
        : categories;
      return filters.assigneeId
        ? filteredByAssignee(filteredCategories, filters.assigneeId)
        : filteredCategories;
    }
  );
