import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Image,
  Invoice,
  PaymentMethod,
  Subscription,
  UserDetails,
} from '../../swagger';
import { UserState, PaymentMethodModalMode } from './userState';

const userSlice = createSlice({
  initialState: {
    invoices: [],
    isChangingPassword: false,
    isDeletePaymentMethodModalShowing: false,
    isDeleteUserModalShowing: false,
    isDeletingPaymentMethod: false,
    isDeletingSubscription: false,
    isDeletingUser: false,
    isFetchingPaymentMethods: false,
    isFetchingUserDetails: false,
    isPasswordChangeSuccessfulToastShowing: false,
    isPasswordModalShowing: false,
    isProfileDeletedToastShowing: false,
    isProfileMenuShowing: false,
    isProfileModalShowing: false,
    isProfileSavedToastShowing: false,
    isUpdatingUserDetails: false,
    is2FAEnabledShowing: false,
    isTwoFactorSavedToastShowing: false,
    paymentMethods: [],
    currentPaymentMethodId: null,
    subscriptions: [], // TODO: move subscription related stuff to pricingState
    userDetails: {},
  } as unknown as UserState,
  name: 'userSlice',
  reducers: {
    changePasswordRequest: state => {
      state.changePasswordError = undefined;
      state.isChangingPassword = true;
    },
    changePasswordSuccess: state => {
      state.isChangingPassword = false;
    },
    changePasswordFailure: (state, action: PayloadAction<string>) => {
      state.changePasswordError = action.payload;
      state.isChangingPassword = false;
    },
    clearPasswordChangeSuccessfulToast: state => {
      state.isPasswordChangeSuccessfulToastShowing = false;
    },
    clearProfileDeletedToast: state => {
      state.isProfileDeletedToastShowing = false;
    },
    clearProfileSavedToast: state => {
      state.isProfileSavedToastShowing = false;
    },
    closeDeleteProfileModal: state => {
      state.isDeleteUserModalShowing = false;
    },
    closePasswordModal: state => {
      state.isPasswordModalShowing = false;
    },
    closeProfileModal: state => {
      state.isProfileModalShowing = false;
    },
    deletePaymentMethodFailure: (state, action: PayloadAction<string>) => {
      state.deletePaymentMethodError = action.payload;
      state.isDeletingPaymentMethod = false;
    },
    deletePaymentMethodRequest: state => {
      state.deletePaymentMethodError = undefined;
      state.isDeletingPaymentMethod = true;
    },
    deletePaymentMethodSuccess: state => {
      state.isDeletingPaymentMethod = false;
    },
    deleteSubscriptionRequest: state => {
      state.deleteSubscriptionError = undefined;
      state.isDeletingSubscription = true;
    },
    deleteSubscriptionSuccess: state => {
      state.isDeletingSubscription = false;
    },
    deleteSubscriptionFailure: (state, action: PayloadAction<string>) => {
      state.deleteSubscriptionError = action.payload;
      state.isDeletingSubscription = false;
    },
    deleteUserRequest: state => {
      state.deleteUserError = undefined;
      state.isDeletingUser = true;
    },
    deleteUserSuccess: state => {
      state.isDeletingUser = false;
      state.isDeleteUserModalShowing = false;
      state.isProfileDeletedToastShowing = true;
    },
    deleteUserFailure: (state, action: PayloadAction<string>) => {
      state.deleteUserError = action.payload;
      state.isDeletingUser = false;
    },
    changePassword: state => {
      state.isPasswordModalShowing = true;
    },
    editProfile: state => {
      state.isProfileModalShowing = true;
    },
    fetchPaymentMethodsFailure: (state, action: PayloadAction<string>) => {
      state.fetchPaymentMethodsError = action.payload;
      state.isFetchingPaymentMethods = false;
    },
    fetchPaymentMethodsRequest: state => {
      state.fetchPaymentMethodsError = undefined;
      state.isFetchingPaymentMethods = true;
    },
    fetchPaymentMethodsSuccess: (
      state,
      action: PayloadAction<PaymentMethod[]>
    ) => {
      state.paymentMethods = action.payload;
      state.isFetchingPaymentMethods = false;
      state.isDeletePaymentMethodModalShowing = false;
    },
    addPaymentMethodFailure: (state, action: PayloadAction<string>) => {
      state.addPaymentMethodError = action.payload;
      state.isAddingPaymentMethod = false;
    },
    addPaymentMethodRequest: state => {
      state.addPaymentMethodError = undefined;
      state.isAddingPaymentMethod = true;
    },
    updatePaymentMethodRequest: state => {
      state.updatePaymentMethodError = undefined;
      state.isUpdatingPaymentMethod = true;
    },
    addPaymentMethodSuccess: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethods = [...state.paymentMethods, action.payload];
      state.isAddingPaymentMethod = false;
      state.isDeletePaymentMethodModalShowing = false;
    },

    updatePaymentMethodFailure: (state, action: PayloadAction<string>) => {
      state.updatePaymentMethodError = action.payload;
      state.isAddingPaymentMethod = false;
      state.isUpdatingPaymentMethod = false;
    },
    updatePaymentMethodSuccess: (
      state,
      action: PayloadAction<PaymentMethod>
    ) => {
      const { payload } = action;
      const newPaymentMethods = state.paymentMethods.map(item => {
        if (item.id !== payload.id) {
          return item;
        }
        return { ...item, ...payload };
      });
      state.paymentMethods = newPaymentMethods;

      state.isUpdatingPaymentMethod = false;
      state.isDeletePaymentMethodModalShowing = false;
    },
    fetchSubscriptionsRequest: state => {
      state.fetchSubscriptionsError = undefined;
      state.isFetchingSubscriptions = true;
    },
    fetchSubscriptionsSuccess: (
      state,
      action: PayloadAction<Subscription[]>
    ) => {
      state.isFetchingSubscriptions = false;
      state.subscriptions = action.payload;
    },
    fetchSubscriptionsFailure: (state, action: PayloadAction<string>) => {
      state.fetchSubscriptionsError = action.payload;
      state.isFetchingSubscriptions = false;
    },
    fetchUserDetailsRequest: state => {
      state.fetchingUserDetailsError = undefined;
      state.isFetchingUserDetails = true;
    },
    fetchUserDetailsSuccess: (state, action: PayloadAction<UserDetails>) => {
      state.isFetchingUserDetails = false;
      state.userDetails = action.payload;
    },
    fetchUserDetailsFailure: (state, action: PayloadAction<string>) => {
      state.fetchingUserDetailsError = action.payload;
      state.isFetchingUserDetails = false;
    },
    initiateUserDeletion: state => {
      state.isDeleteUserModalShowing = true;
      state.isProfileModalShowing = false;
    },
    showPasswordChangeSuccessfulToast: state => {
      state.isPasswordChangeSuccessfulToastShowing = true;
    },
    showProfileSavedToast: state => {
      state.isProfileSavedToastShowing = true;
    },
    toggleProfileMenu: state => {
      state.isProfileMenuShowing = !state.isProfileMenuShowing;
    },
    closeProfileMenu: state => {
      state.isProfileMenuShowing = false;
    },
    updateProfileRequest: state => {
      state.updatingUserDetailsError = undefined;
      state.isUpdatingUserDetails = true;
    },
    updateProfileSuccess: state => {
      state.isProfileModalShowing = false;
      state.isUpdatingUserDetails = false;
    },
    updateProfileFailure: (state, action: PayloadAction<string>) => {
      state.updatingUserDetailsError = action.payload;
      state.isUpdatingUserDetails = false;
    },
    addTwoFactorAuthentication: state => {
      state.is2FAEnabledShowing = true;
    },
    addTwoFactorAuthenticationRequest: state => {
      state.addingTwoFactorError = undefined;
    },
    addTwoFactorAuthenticationFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.addingTwoFactorError = action.payload;
    },
    closeTwoFactorAuthentication: state => {
      state.is2FAEnabledShowing = false;
      state.addingTwoFactorError = undefined;
    },
    showTwoFactorSavedToast: state => {
      state.isTwoFactorSavedToastShowing = true;
    },
    clearTwoFactorSavedToast: state => {
      state.isTwoFactorSavedToastShowing = false;
    },
    showDeletePaymentMethodModal: state => {
      state.isDeletePaymentMethodModalShowing = true;
    },
    hideDeletePaymentMethodModal: state => {
      state.isDeletePaymentMethodModalShowing = false;
    },
    showAddPaymentMethodModal: (
      state,
      action: PayloadAction<PaymentMethodModalMode>
    ) => {
      state.isAddPaymentMethodModalShowing = true;
      state.paymentMethodModalMode = action.payload;
    },

    setCurrentPaymentMethodId: (state, action: PayloadAction<string>) => {
      state.currentPaymentMethodId = action.payload;
    },

    hideAddPaymentMethodModal: state => {
      state.isAddPaymentMethodModalShowing = false;
    },
    fetchInvoicesRequest: state => {
      state.fetchInvoicesError = undefined;
      state.isFetchingInvoices = true;
    },
    fetchInvoicesSuccess: (state, action: PayloadAction<Invoice[]>) => {
      state.isFetchingInvoices = false;
      state.invoices = action.payload;
    },
    fetchInvoicesFailure: (state, action: PayloadAction<string>) => {
      state.fetchInvoicesError = action.payload;
      state.isFetchingInvoices = false;
    },
    userSignatureRequest: state => {
      state.isRequestingUserSignature = true;
    },
    userSignatureRequestSuccess: (state, action: PayloadAction<Image>) => {
      state.isRequestingUserSignature = false;
      state.userSignature = action.payload;
    },
    userSignatureRequestFailure: (state, action: PayloadAction<string>) => {
      state.userSignatureRequestFailure = action.payload;
      state.isRequestingUserSignature = false;
    },
    cleanupUserSignature: state => {
      state.userSignature = null;
    },
  },
});

export const {
  changePassword,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  clearPasswordChangeSuccessfulToast,
  clearProfileDeletedToast,
  clearProfileSavedToast,
  closeDeleteProfileModal,
  closePasswordModal,
  closeProfileModal,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  editProfile,
  fetchUserDetailsRequest,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  initiateUserDeletion,
  showPasswordChangeSuccessfulToast,
  showProfileSavedToast,
  toggleProfileMenu,
  closeProfileMenu,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  addTwoFactorAuthentication,
  closeTwoFactorAuthentication,
  showTwoFactorSavedToast,
  clearTwoFactorSavedToast,
  addTwoFactorAuthenticationRequest,
  addTwoFactorAuthenticationFailure,
  fetchSubscriptionsRequest,
  fetchSubscriptionsSuccess,
  fetchSubscriptionsFailure,
  deleteSubscriptionRequest,
  deleteSubscriptionSuccess,
  deleteSubscriptionFailure,
  fetchPaymentMethodsFailure,
  fetchPaymentMethodsRequest,
  fetchPaymentMethodsSuccess,
  deletePaymentMethodFailure,
  deletePaymentMethodRequest,
  deletePaymentMethodSuccess,
  showDeletePaymentMethodModal,
  hideDeletePaymentMethodModal,
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  fetchInvoicesFailure,
  userSignatureRequest,
  userSignatureRequestSuccess,
  userSignatureRequestFailure,
  showAddPaymentMethodModal,
  setCurrentPaymentMethodId,
  hideAddPaymentMethodModal,
  addPaymentMethodFailure,
  addPaymentMethodRequest,
  updatePaymentMethodRequest,
  addPaymentMethodSuccess,
  updatePaymentMethodSuccess,
  updatePaymentMethodFailure,
  cleanupUserSignature,
} = userSlice.actions;

export default userSlice.reducer;
