import React from 'react';
import { VendorProductIndexFeatures } from '../../../swagger/models/VendorProductIndexFeatures';
import { PaymentLandingPage } from '../../../components/payment/PaymentLandingPage/PaymentLandingPage';
import { push } from 'connected-react-router';
import { manageSubscriptionRoute } from '../../../components/Routes/Routes';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { useDispatch, useSelector } from 'react-redux';
import { isProductPaid } from '../../../components/MainMenuItems/mainMenuConfig';
import { selectVendorProductsFeatures } from '../../../selectors/pricing/selectVendorProductsFeatures';
import NoValidRoleText from '../NoValidRoleText/NoValidRoleText';

interface PaymentLandingPageDisplayerProps {
  type: keyof VendorProductIndexFeatures;
}

const mapFeatureToAccessObject = (
  type: keyof VendorProductIndexFeatures
): keyof typeof AccessObject => {
  const map: Partial<
    Record<keyof VendorProductIndexFeatures, keyof typeof AccessObject>
  > = {
    pestel: 'pestel',
    legalRegister: 'legal_register',
    vendorRegister: 'vendor_register',
    riskRegister: 'risk_register',
    assessments: 'assessments',
    audits: 'audits',
    complianceFrameworks: 'frameworks',
    escrow: 'escrow',
    profile: 'company_manage',
    agreements: 'agreements',
    dataRoom: 'data_room_entities',
    checklists: 'checklists',
    policies: 'vendor_policies',
    people: 'people',
    calendar: 'calendar',
    reports: 'reports',
    integrations: 'integrations',
    companyDocuments: 'company_documents',
    controls: 'controls',
    tasks: 'tasks',
    dashboard: 'dashboard',
  };

  return map[type];
};
// TODO: use this component only in Routes except complicated cases like assessments
// to avoid "page logic/api requests" on landing page
export const PaymentLandingPageChecker: React.FC<
  PaymentLandingPageDisplayerProps
> = props => {
  const dispatch = useDispatch();
  const features = useSelector(selectVendorProductsFeatures);
  const accessObjectKey = mapFeatureToAccessObject(props.type);
  const skipAccessChecks = [
    'assessments',
    'vendor_policies',
    'integrations',
    'data_room_entities',
    'company_manage',
    'companyDocuments',
    'calendar',
    'controls',
    'tasks',
    'dashboard',
  ];

  const hasAccess = canFeature(AccessObject[accessObjectKey]);
  if (!skipAccessChecks.includes(accessObjectKey) && !hasAccess) {
    return (
      <>
        <NoValidRoleText />
      </>
    );
  }

  return isProductPaid(props.type, features) ? (
    <>{props.children}</>
  ) : (
    <PaymentLandingPage
      fromProduct={props.type}
      onClick={() => dispatch(push(manageSubscriptionRoute))}
    />
  );
};
