import { VendorIntegrationDecorated } from './integrationsTypes';
import aws_logo from 'images/integrations/aws_logo.png';
import azure_logo from 'images/integrations/azure_logo.png';
import github_logo from 'images/integrations/github_logo.png';
import gitlab_logo from 'images/integrations/gitlab_logo.png';
import google_logo from 'images/integrations/google_logo.png';
import googleCloud_logo from 'images/integrations/googleCloud_logo.png';
import jumpCloud_logo from 'images/integrations/jumpCloud_logo.png';
import microsoft_logo from 'images/integrations/microsoft_logo.png';
import jira_logo from 'images/integrations/jira_logo.png';
import notion_logo from 'images/integrations/notion_logo.png';
import knowbe4_logo from 'images/integrations/KnowBe4.png';
import curricula_logo from 'images/integrations/curricula_logo.png';
import breathHR_logo from 'images/integrations/breathHR_logo.png';
import bambooHR_logo from 'images/integrations/bambooHR_logo.png';
import msTeams_logo from 'images/integrations/msTeams_logo.png';
import slack_logo from 'images/integrations/slack_logo.png';
import veremark_logo from 'images/integrations/veremark_logo.png';

export enum IntegrationCategory {
  All = 'All',
  DeveloperTools = 'Developer Tools',
  HumanResourcesSystem = 'Human Resources System',
  VulnerabilityScanners = 'Vulnerability Scanners',
  MobileDeviceManagement = 'Mobile Device Management',
  CloudProviders = 'Cloud Providers',
  MonitoringServices = 'Monitoring Services',
  IdentityProviders = 'Identity Providers',
  TaskTracker = 'Task Tracker',
  SecurityAwarenessTraining = 'Security Awareness Training',
  MessagingApplications = 'Messaging Applications',
}

export const integrationsStatic: VendorIntegrationDecorated[] = [
  {
    name: 'AWS CloudWatch',
    type: 'aws_cloud',
    icon: aws_logo,
    category: IntegrationCategory.MonitoringServices,
  },
  {
    name: 'AWS IAM',
    type: 'aws_iam',
    icon: aws_logo,
    category: IntegrationCategory.IdentityProviders,
  },
  {
    name: 'AWS GuardDuty',
    type: 'aws_guard_duty',
    icon: aws_logo,
    category: IntegrationCategory.VulnerabilityScanners,
  },
  {
    name: 'AWS Inspector',
    type: 'aws_inspector',
    icon: aws_logo,
    category: IntegrationCategory.VulnerabilityScanners,
  },
  {
    name: 'AWS S3',
    type: 'aws_s3',
    icon: aws_logo,
    category: IntegrationCategory.CloudProviders,
  },
  {
    name: 'AWS SSO',
    type: 'aws_sso',
    icon: aws_logo,
    category: IntegrationCategory.IdentityProviders,
  },
  {
    name: 'Azure Active Directory',
    type: 'azure',
    icon: azure_logo,
    category: IntegrationCategory.IdentityProviders,
  },
  {
    name: 'Bamboo HR',
    type: 'bambooHR',
    icon: bambooHR_logo,
    category: IntegrationCategory.HumanResourcesSystem,
  },
  {
    name: 'BreathHR',
    type: 'breathHR',
    icon: breathHR_logo,
    category: IntegrationCategory.HumanResourcesSystem,
  },
  {
    name: 'Curricula',
    type: 'curricula',
    icon: curricula_logo,
    category: IntegrationCategory.SecurityAwarenessTraining,
  },
  {
    name: 'GCP',
    type: 'gcp',
    icon: googleCloud_logo,
    category: IntegrationCategory.CloudProviders,
  },
  {
    name: 'GCP Container Analysis',
    type: 'gcp_container',
    icon: google_logo,
    category: IntegrationCategory.VulnerabilityScanners,
  },
  {
    name: 'GitHub',
    type: 'git_hub',
    icon: github_logo,
    category: IntegrationCategory.DeveloperTools,
  },
  {
    name: 'GitLab',
    type: 'git_lab',
    icon: gitlab_logo,
    category: IntegrationCategory.DeveloperTools,
  },
  {
    name: 'Google Operations',
    type: 'google_operations',
    icon: google_logo,
    category: IntegrationCategory.MonitoringServices,
  },
  {
    name: 'Google Workspace',
    type: 'google_workspace',
    icon: google_logo,
    category: IntegrationCategory.IdentityProviders,
  },
  {
    name: 'Jira',
    type: 'jira',
    icon: jira_logo,
    category: IntegrationCategory.TaskTracker,
  },
  {
    name: 'JumpCloud',
    type: 'jump_cloud',
    icon: jumpCloud_logo,
    category: IntegrationCategory.MobileDeviceManagement,
  },
  {
    name: 'KnowBe4',
    type: 'knowbe4',
    icon: knowbe4_logo,
    category: IntegrationCategory.SecurityAwarenessTraining,
  },
  {
    name: 'Microsoft Intune',
    type: 'ms_intune',
    icon: microsoft_logo,
    category: IntegrationCategory.MobileDeviceManagement,
  },
  {
    name: 'MS Teams',
    type: 'ms_teams',
    icon: msTeams_logo,
    category: IntegrationCategory.MessagingApplications,
  },
  {
    name: 'Notion',
    type: 'notion',
    icon: notion_logo,
    category: IntegrationCategory.TaskTracker,
  },
  {
    name: 'Slack',
    type: 'slack',
    icon: slack_logo,
    category: IntegrationCategory.MessagingApplications,
  },
  {
    name: 'Veremark',
    type: 'veremark',
    icon: veremark_logo,
    category: IntegrationCategory.HumanResourcesSystem,
  },
];
