import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { ApplicationState } from '../../../../../types/applicationState';
import './TrustHubUserAdminApproveRequestDrawer.scss';
import {
  TrusthubCompanyModel,
  TrusthubCompanyStatus,
  TrusthubVendorUserStatus,
  TrusthubAccessRequestModel,
  TrusthubDocumentModel,
} from '../../../../../swagger/trusthub';
import { AdoptechReactSelect2 } from '../../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { SelectionOption } from '../../../../../types/selectionOption';
import {
  approveAccessRequest,
  fetchTrustHubCompanies,
  fetchTrustHubVendorUsers,
  patchTrusthubVendorUser,
  postTrusthubCompany,
} from '../../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechTextInput2 } from '../../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import classNames from 'classnames';
import { agreementsStartRoute } from '../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { showGlobalToast } from '../../../../../store/global/globalSlice';
import { useFetchAdminRequests } from '../TrustHubUserAdminPage/TrustHubUserAdminRequestsPage/TrustHubUserAdminRequestsPage';
import { TrusthubUserAdminDocumentsList } from './TrusthubUserAdminDocumentsList/TrusthubUserAdminDocumentsList';

interface ApproveRequestFormData {
  companyId: TrusthubCompanyModel['id'];

  companyName?: TrusthubCompanyModel['name'];
  companyOwnerId?: TrusthubCompanyModel['createdBy']['id'];
  companyNda?: boolean;

  selectedDocumentsIds?: TrusthubDocumentModel['id'][];
}

interface ApproveRequestFormProps {
  request: TrusthubAccessRequestModel;
  onClose: () => void;
}

export const TrustHubUserAdminApproveRequestDrawer: React.FC<
  ApproveRequestFormProps
> = ({ request, onClose }) => {
  const baseCss = 'trustHubUserAdminApproveRequestDrawer';

  const dispatch = useDispatch();

  const initialCompanyId = request.trusthubVendorUser.trusthubCompany?.id;
  const [formData, setFormData] = useState<ApproveRequestFormData>({
    companyId: initialCompanyId,
    selectedDocumentsIds: [],
  });
  const {
    approvingAccessRequestStatus,
    patchVendorUserStatus,
    createCompanyStatus,
  } = useSelector((state: ApplicationState) => state.trustHub);

  const fetchAdminRequests = useFetchAdminRequests();
  const handleSubmit = async () => {
    const createVendorUserAndApprove = async (
      company: TrusthubCompanyModel
    ) => {
      await dispatch(
        patchTrusthubVendorUser({
          vendorUserId: request.trusthubVendorUser?.id,
          body: {
            trusthubVendorUser: {
              trusthubCompanyId: company.id,
              status:
                company.status === TrusthubCompanyStatus.Approved
                  ? TrusthubVendorUserStatus.Approved
                  : TrusthubVendorUserStatus.WaitingForNda,
            },
          },
        })
      );
      await dispatch(
        approveAccessRequest({
          accessRequestId: request.id,
          body: {
            trusthubAccessRequest: {
              documentToApproveIds: formData.selectedDocumentsIds,
            },
          },
        })
      );

      // After approve fetch all admin requests.
      // Ex: if we assigned or created company for the user in the first access request
      // => all next requests with this user will have trusthubCompanyId
      fetchAdminRequests();
      dispatch(
        showGlobalToast(
          `You have approved ${request.trusthubVendorUser.fullName}. View approved users by selecting the Users tab at the top of the page. View completed access requests by selecting the Show Completed Requests toggle in the Access Requests table.`
        )
      );
      onClose && onClose();
    };

    if (!isCreateMode)
      return await createVendorUserAndApprove(
        companies.find(company => company.id === formData.companyId)
      );
    dispatch(
      postTrusthubCompany(
        {
          vendorIdOrDomain: currentVendor?.id,
          body: {
            trusthubCompany: {
              name: formData.companyName,
              relationshipOwnerIds: [formData.companyOwnerId],
              status: formData.companyNda
                ? TrusthubCompanyStatus.Approved
                : TrusthubCompanyStatus.WaitingForNda,
            },
          },
        },
        company => createVendorUserAndApprove(company)
      )
    );
  };

  const {
    companies,
    fetchingCompaniesStatus,
    fetchingVendorUsersStatus,
    vendorUsers,
  } = useSelector((state: ApplicationState) => state.trustHub);

  const companyOptions = (companies || []).map(company => {
    return {
      label: company.name,
      value: company.id,
    };
  });

  const createCompanyValue = 'create';

  const isCreateMode = formData.companyId === createCompanyValue;
  companyOptions.unshift({
    label: '+ Create New Company',
    value: createCompanyValue,
  });

  const ownerOptions = (vendorUsers || []).map(user => {
    return {
      label: user.fullName,
      value: user.id,
    };
  });

  const ndaOptions = [
    {
      label: 'Yes',
      value: 'yes',
    },
    { label: 'No', value: 'no' },
  ];

  const currentVendor = useSelector(selectCurrentVendor);

  // optional step 'select-company' user select company => isCreateCompanyMode true ( else skip if company exist)
  // optional step 'select-nda' ask nda if new company ( else skip if company exist)
  // step 'docs-list' show docs list

  type Step = 'select-company' | 'select-nda' | 'docs-list';
  const [currentStep, setCurrentStep] = useState<Step>(
    initialCompanyId ? 'docs-list' : 'select-company'
  );

  useEffect(() => {
    const companyStatuses = [TrusthubCompanyStatus.Approved];
    dispatch(
      fetchTrustHubVendorUsers(
        { vendorIdOrDomain: currentVendor?.id, onlyAdmin: true },
        () => {}
      )
    );
    dispatch(
      fetchTrustHubCompanies(
        { vendorIdOrDomain: currentVendor?.id, statuses: companyStatuses },
        () => {}
      )
    );
  }, []);

  return (
    <AdoptechHS
      title="Approve Access Request"
      show
      showConfirmationWarning={false}
      onClose={() => onClose()}
      footer={
        <>
          <AdoptechButton
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>

          {currentStep === 'select-company' && !isCreateMode && (
            <AdoptechButton
              onClick={() => setCurrentStep('docs-list')}
              variant={AdoptechButtonVariant.Primary}
              disabled={!formData.companyId}
            >
              Next
            </AdoptechButton>
          )}

          {currentStep === 'select-company' && isCreateMode && (
            <AdoptechButton
              onClick={() => setCurrentStep('select-nda')}
              variant={AdoptechButtonVariant.Primary}
              disabled={
                ![formData.companyName, formData.companyOwnerId].every(
                  value => value
                )
              }
            >
              Next
            </AdoptechButton>
          )}

          {currentStep === 'select-nda' && (
            <AdoptechButton
              onClick={() => setCurrentStep('docs-list')}
              variant={AdoptechButtonVariant.Primary}
              disabled={
                ![formData.companyName, formData.companyOwnerId].every(
                  value => value
                ) || formData.companyNda === undefined
              }
            >
              Next
            </AdoptechButton>
          )}

          {currentStep === 'docs-list' && (
            <AdoptechButton
              variant={AdoptechButtonVariant.Primary}
              disabled={formData.selectedDocumentsIds.length < 1}
              onClick={handleSubmit}
              busy={
                approvingAccessRequestStatus === 'loading' ||
                patchVendorUserStatus === 'loading' ||
                createCompanyStatus === 'loading'
              }
            >
              Approve
            </AdoptechButton>
          )}
        </>
      }
      extraClass="adoptechHS--medium approveAccessRequestHS"
    >
      {fetchingCompaniesStatus === 'loading' ||
      fetchingVendorUsersStatus === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <div className={baseCss + '--formContainer'}>
          {currentStep === 'select-company' && (
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--label'}>
                {`Which company would you like to associate ${request.trusthubVendorUser?.fullName} with?`}
              </div>
              <AdoptechReactSelect2
                id="company-approve"
                options={companyOptions}
                onChange={(option: SelectionOption) => {
                  setFormData({ ...formData, companyId: option.value });
                }}
                value={companyOptions.find(
                  option => option.value === formData.companyId
                )}
                placeholder="Please select"
                label="Company"
              />
            </div>
          )}
          {isCreateMode && currentStep === 'select-company' && (
            <>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--label'}>
                  Provide the following company information.
                </div>
                <div className={baseCss + '--field'}>
                  <AdoptechTextInput2
                    rounded
                    type="text"
                    id="companyTitle"
                    label="Company"
                    placeholder="E.g. Hairy Bikers Ltd. "
                    value={formData.companyName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        companyName: e.currentTarget.value,
                      })
                    }
                    icon={faPen}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  baseCss + '--fieldRow',
                  baseCss + '--ownerRow'
                )}
              >
                <div
                  className={baseCss + '--field'}
                  title="The individual within your organisation who owns the relationship with the company"
                >
                  <AdoptechReactSelect2
                    id="approveCompanyOwner"
                    options={ownerOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        companyOwnerId: option.value,
                      });
                    }}
                    value={ownerOptions.find(
                      option => option.value === formData.companyOwnerId
                    )}
                    placeholder="Select owner"
                    label="Owner of relationship"
                    showUserAvatar
                  />
                </div>
              </div>
            </>
          )}

          {isCreateMode && currentStep === 'select-nda' && (
            <>
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--label'}>
                  Is a NDA/Confidentiality Agreement in place with this company?
                </div>
                <div className={baseCss + '--field'}>
                  <AdoptechReactSelect2
                    id="approveCompanyNda"
                    options={ndaOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        companyNda: option.value === 'yes',
                      });
                    }}
                    value={
                      formData.companyNda === undefined
                        ? null
                        : ndaOptions.find(
                            option =>
                              option.value ===
                              (formData.companyNda ? 'yes' : 'no')
                          )
                    }
                    placeholder="Please select"
                    label="NDA/Confidentiality Agreement"
                  />
                </div>
              </div>
              {formData.companyNda === false && (
                <div className={baseCss + '--fieldRow'}>
                  <div className={baseCss + '--label'}>
                    The company TrustHub account created will be disabled until
                    an NDA/Confidentiality Agreement is in place, you can set
                    one up{' '}
                    <a
                      className={baseCss + '--ndaLink'}
                      onClick={() => dispatch(push(agreementsStartRoute))}
                    >
                      here.
                    </a>{' '}
                    Once an NDA is in place, select the Company in TrustHub and
                    Edit the record accordingly.
                  </div>
                </div>
              )}
            </>
          )}

          {currentStep === 'docs-list' && (
            <TrusthubUserAdminDocumentsList
              request={request}
              formData={formData}
              setFormData={setFormData}
              documents={request.trusthubDocuments}
            />
          )}
        </div>
      )}
    </AdoptechHS>
  );
};
