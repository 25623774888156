import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/pro-light-svg-icons/faLeaf';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechCheckbox } from '../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechTextInput } from '../../../../components/AdoptechTextInput/AdoptechTextInput';
import { policiesPageRoute } from '../../../../components/Routes/Routes';
import {
  EditSectionProps,
  useEditTrustHubSection,
} from '../useEditTrustHubSection';
import './PoliciesEditSection.scss';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';

export const PoliciesEditSection: React.FC<EditSectionProps> = ({
  section,
  onClose,
  baseCss,
}) => {
  const {
    fetchingCurrentSection,
    sectionDescription,
    setSectionDescription,
    handleCheckboxChange,
    updateSectionStatus,
    checkedIds,
    sortedDocsToEdit,
    handleSave,
  } = useEditTrustHubSection({
    section,
    onClose,
  });
  return (
    <div className={baseCss + '--panel' + ' editMode'}>
      <div className={baseCss + '--descriptionRowInput'}>
        <AdoptechTextInput
          value={sectionDescription}
          id={`${baseCss}-description`}
          type="text"
          placeholder={section.description}
          onChange={e => {
            setSectionDescription(e.currentTarget.value);
          }}
        />
        <div className={baseCss + '--companyActions'}>
          <AdoptechButton
            disabled={updateSectionStatus === 'loading'}
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={updateSectionStatus === 'loading'}
            disabled={!sectionDescription}
            onClick={handleSave}
            variant={AdoptechButtonVariant.White}
          >
            Save
          </AdoptechButton>
        </div>
      </div>

      {fetchingCurrentSection && <LoadingSpinner />}
      {!fetchingCurrentSection && (
        <>
          {section.trusthubDocuments.length === 0 && (
            <div className={baseCss + '--noContent'}>
              No policy templates to display. Please create at least one live
              policy
              <a href={policiesPageRoute}> here.</a>
            </div>
          )}

          <div className={baseCss + '--documents'}>
            {sortedDocsToEdit.map(doc => {
              return (
                <div key={doc.name} className={baseCss + '--document'}>
                  <AdoptechCheckbox
                    id={doc.name}
                    checked={checkedIds.includes(doc.documentId)}
                    onChange={e =>
                      handleCheckboxChange(e.currentTarget.checked, doc)
                    }
                    label={<div className="emptyLabel">-</div>}
                  />
                  <div className={baseCss + '--documentLogoWithTitle'}>
                    <FontAwesomeIcon
                      icon={
                        section.identifier === 'compliance'
                          ? faCheckCircle
                          : faLeaf
                      }
                    />
                    <div className={baseCss + '--documentTitle'}>
                      {doc.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
