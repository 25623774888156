import React, { useEffect, useState } from 'react';
import { AdoptechPanel } from '../../../../../components/AdoptechPanel/AdoptechPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechTextInput } from '../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { NoDataText } from '../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../../../types/grid';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import { ApplicationState } from '../../../../../types/applicationState';
import classNames from 'classnames';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { fetchTrustHubVendorUsers } from '../../../store/trusthubThunks';
import { selectTrusthubVendorUsers } from '../selectTrusthubVendorUsers';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
import { setTrustHubVendorUsersSearch } from '../../../store/trusthubSlice';
import { TrustHubVendorUserTableRow } from './TrustHubVendorUserTableRow/TrustHubVendorUserTableRow';
import { TrustHubUserAdminAddUserDrawer } from '../TrustHubUserAdminAddUserDrawer/TrustHubUserAdminAddUserDrawer';
import { TrusthubVendorUserModel } from '../../../../../swagger/trusthub/models/TrusthubVendorUserModel';
import { CommandConfirmation } from '../../../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';

export const TrustHubUserAdminUsersPage: React.FC = () => {
  const baseCss = 'trusthubUserAdminPage';
  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);

  useEffect(() => {
    currentVendor &&
      dispatch(
        fetchTrustHubVendorUsers(
          {
            vendorIdOrDomain: currentVendor.id,
          },
          () => {}
        )
      );
  }, [currentVendor?.id]);
  const users = useSelector(selectTrusthubVendorUsers);
  const { vendorUsersSearch, fetchingVendorUsersStatus, settings } =
    useSelector((state: ApplicationState) => state.trustHub);

  const [showAddVendorUserDrawer, setShowAddVendorUserDrawer] = useState(false);
  const canAddUser = settings?.published;
  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const showWarningModal = () =>
    command({
      title: 'Warning',
      description: `You cannot add a user until the TrustHub page has been published.`,
      buttonText: 'OK',
      showCancelButton: false,
      buttonVariant: AdoptechButtonVariant.Primary,
    });

  return (
    <div className={baseCss + '--panel'}>
      <AdoptechPanel>
        <div className={baseCss + '--panelTitle'}>
          <div className={baseCss + '--panelInfo'}>
            <FontAwesomeIcon icon={faUserFriends} />
            Users
            <div className={baseCss + '--panelBadge'}>{users.length}</div>
          </div>

          <div className={baseCss + '--panelTitleButtons'}>
            <AdoptechButton
              variant={AdoptechButtonVariant.PrimaryOrange}
              icon={faPlus}
              iconSize="large"
              onClick={() =>
                canAddUser
                  ? setShowAddVendorUserDrawer(true)
                  : showWarningModal()
              }
            >
              Add User
            </AdoptechButton>
          </div>
        </div>

        <div className={baseCss + '--panelSearch'}>
          <AdoptechTextInput
            id="search"
            value={vendorUsersSearch}
            onChange={e =>
              dispatch(setTrustHubVendorUsersSearch(e.currentTarget.value))
            }
            type="text"
            placeholder="Search users"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>

        {fetchingVendorUsersStatus === 'loading' ? (
          <LoadingSpinner />
        ) : (
          <div className={baseCss + '--panelTable'}>
            <div
              className={classNames(
                `${baseCss}--5-columns`,
                `${baseCss}--5-columns-maxHeight50vh`
              )}
            >
              {users.length <= 0 ? (
                <NoDataText extraClass="pt-4" text="No users to show" />
              ) : (
                <AdoptechGridTable
                  header={
                    <div className="panelTableHeader panelTableHeader-sticky">
                      <SortableTableHeader<TrusthubVendorUserModel>
                        columnName="fullName"
                        grid={Grid.TrustHubUserAdminVendorUsers}
                        label="Name"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserModel>
                        columnName="trusthubCompany"
                        grid={Grid.TrustHubUserAdminVendorUsers}
                        label="Company"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserModel>
                        columnName="email"
                        grid={Grid.TrustHubUserAdminVendorUsers}
                        label="Email"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserModel>
                        columnName="status"
                        grid={Grid.TrustHubUserAdminVendorUsers}
                        label="Status"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserModel>
                        // @ts-ignore
                        columnName="lastLoginAt"
                        grid={Grid.TrustHubUserAdminVendorUsers}
                        label="Last Login"
                        notInTable
                      />
                    </div>
                  }
                >
                  {users.map(user => {
                    const { id } = user;
                    return <TrustHubVendorUserTableRow user={user} key={id} />;
                  })}
                </AdoptechGridTable>
              )}
            </div>
          </div>
        )}
        {showAddVendorUserDrawer && (
          <TrustHubUserAdminAddUserDrawer
            onClose={() => setShowAddVendorUserDrawer(false)}
          />
        )}

        <ConfirmationModal
          command={currentCommand}
          onCancel={_ => command(null)}
        />
      </AdoptechPanel>
    </div>
  );
};
