import React, { SetStateAction } from 'react';
import {
  VendorUserCreatePayloadVendorUsers,
  VendorUserExternalIdentifier,
  VendorUserUpdatePayloadVendorUser,
} from '../../swagger';
import { AdoptechTextInput2 } from '../AdoptechTextInput2/AdoptechTextInput2';
import { PeopleDrawerPayload } from '../../features/people/ManagePeopleDrawer/ManagePeopleDrawer';

interface Props {
  user: PeopleDrawerPayload;
  onChange: React.Dispatch<SetStateAction<any>>;
}

export const UserExternalAccounts: React.FC<Props> = ({ user, onChange }) => {
  const identifiers = user.externalIdentifiers || [];
  const githubValue = identifiers.find(
    identifier => identifier['name'] === 'github'
  )?.value;

  const setIdentifier = (
    name: VendorUserExternalIdentifier['name'],
    value: VendorUserExternalIdentifier['value']
  ) => {
    const identifier: VendorUserExternalIdentifier = {
      name,
      value,
    };
    // FIXME: support more than one identifier
    onChange([identifier]);
  };

  return (
    <div>
      <br />
      <AdoptechTextInput2
        id="github_identifier"
        onChange={e => setIdentifier('github', e.currentTarget.value)}
        value={githubValue || ''}
        label="GitHub login"
        type="text"
      />
    </div>
  );
};
