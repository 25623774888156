import React from 'react';
import formulateLogo from 'images/trustedCompanies/formulate.svg';
import connectedDataLogo from 'images/trustedCompanies/connected-data.svg';
import fruitfulLogo from 'images/trustedCompanies/fruitful.svg';
import kaizenLogo from 'images/trustedCompanies/kaizen.svg';
import raiseLogo from 'images/trustedCompanies/raise.svg';
import './StartCompanyRightMenu.scss';

export const StartCompanyRightMenu: React.FC = () => {
  const baseCss = 'startCompanyRightMenu';
  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>
        Companies already trusting Adoptech
      </div>

      <div className={baseCss + '--companyLogos'}>
        <div className={baseCss + '--companyLogosRow'}>
          <img src={kaizenLogo} />
          <img src={fruitfulLogo} />
        </div>
        <div className={baseCss + '--companyLogosRow'}>
          <img src={formulateLogo} />
          <img src={connectedDataLogo} />
        </div>
        <div className={baseCss + '--companyLogosRow'}>
          <img src={raiseLogo} />
        </div>
      </div>

      <div className={baseCss + '--joinFooter'}>
        Join the 4000+ business professionals using Adoptech to efficiently
        maintain security and governance best practices.
      </div>
    </div>
  );
};
