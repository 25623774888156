import React, { useState } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModals } from '../../../store/dataRoom/dataRoomSlice';
import { moveEntities } from '../../../store/dataRoom/dataRoomThunks';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import './DataRoomMoveEntitiesModal.scss';
import { DataRoomMoveEntitiesTabsInfo as TabsInfo } from './DataRoomMoveEntitiesModalTabsInfo';
import DataRoomSelectFolderTab from '../DataRoomSelectFolderTab/DataRoomSelectFolderTab';
import DataRoomSelectedEntitiesList from '../DataRoomSelectedEntitiesList/DataRoomSelectedEntitiesList';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { dataRoomPathExists } from '../../../functions/dataRoomPathExists';
import { getEntityName } from '../../../selectors/getEntityName';
import { selectAvailableFolders } from '../../../selectors/selectAvailableFolders';

interface DataRoomMoveEntitiesModalProps {
  show: boolean;
}
export const moveRights = ['share__manager', 'admin'];

export const DataRoomMoveEntitiesModal: React.FC<
  DataRoomMoveEntitiesModalProps
> = ({ show }) => {
  const baseCss = 'dataRoomMoveEntitiesModal';
  const dispatch = useDispatch();
  const [selectedDir, setSelectedDir] = useState<SelectionOption>(undefined);

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const selectedTabId = useSelector(
    (state: ApplicationState) => state.dataRoom.selectedModalTabId
  );

  const folders = useSelector(selectAvailableFolders(moveRights));

  const isFetching = useSelector(
    (state: ApplicationState) => state.dataRoom.isFetchingEntitiesTree
  );

  const entitiesTree = useSelector(
    (state: ApplicationState) => state.dataRoom.entitiesTree
  );

  const entities = useSelector(
    (state: ApplicationState) => state.dataRoom.entitiesToMove
  );

  const entityIds: string[] = useSelector((state: ApplicationState) =>
    state.dataRoom.entitiesToMove.map(e => e.entityId)
  );

  const hideModal = () => {
    dispatch(hideModals());
    setSelectedDir(undefined);
  };

  const handleMove = () => {
    const filesExist = entities.filter(e =>
      dataRoomPathExists(
        entitiesTree,
        folders,
        getEntityName(e),
        selectedDir.value
      )
    );

    if (filesExist.length != 0) {
      dispatch(
        showGlobalToast(
          'The following files or folders with the same name already exists in the target location:' +
            filesExist.map(x => x.entityName).join(',')
        )
      );
    } else {
      dispatch(moveEntities(vendorId, selectedDir.value, entityIds));
    }
  };

  const inProgress = useSelector(
    (state: ApplicationState) =>
      state.dataRoom.currentEntityIdProcessing === selectedDir?.value
  );

  const isDisabled = () => {
    switch (selectedTabId) {
      case TabsInfo.selectFolder.id:
        return !selectedDir || inProgress;
      default:
        return inProgress;
    }
  };

  const content = isFetching ? (
    <LoadingSpinner />
  ) : (
    <div className={baseCss + '--tabContent'}>
      <div className={baseCss + '--tabContentLeft'}>
        {selectedTabId === TabsInfo.selectFolder.id && (
          <DataRoomSelectFolderTab
            selectedDir={selectedDir}
            setSelectedDir={setSelectedDir}
            folders={folders}
            description={TabsInfo.selectFolder.description}
          />
        )}
      </div>
      <div className={baseCss + '--tabContentRight'}>
        <DataRoomSelectedEntitiesList entities={entities} />
      </div>
    </div>
  );

  return (
    <AdoptechModal onHide={hideModal} show={show} className={baseCss}>
      <Modal.Header>
        <div>{TabsInfo[selectedTabId]?.title}</div>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <ModalFooter>
        <div className={baseCss + '--buttonRow'}>
          <AdoptechButton onClick={hideModal}>Cancel</AdoptechButton>
          <AdoptechButton
            onClick={handleMove}
            variant={AdoptechButtonVariant.Primary}
            disabled={isDisabled()}
          >
            Move
          </AdoptechButton>
        </div>
      </ModalFooter>
    </AdoptechModal>
  );
};
