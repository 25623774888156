import React, { useEffect, useState } from 'react';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import './StartHeader.scss';
import adoptechLogoDark from 'images/adoptech-logo-dark.svg';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../../../unauthenticated/src/types/applicationState';
import { useSelector } from 'react-redux';
import { SignupPage } from '../../../../unauthenticated/src/types/signupPage';
import { logout } from '../../../functions/logout';
import { signupRoute, welcomeRoute } from '../../Routes/Routes';

interface StartHeaderProps {
  showLogin: boolean;
  showProgress: boolean;
}

export const StartHeader: React.FC<StartHeaderProps> = props => {
  const currentPage = useSelector(
    (state: ApplicationState) => state.signup.currentPage
  );

  const numberOfStages = Object.keys(SignupPage).length / 2;

  const [progress, setProgress] = useState(0);
  const [previousProgress, setPreviousProgress] = useState(0);
  const handleCancel = () => logout();

  useEffect(() => {
    setTimeout(() => {
      setProgress((currentPage + 1) * (100 / numberOfStages));
      setPreviousProgress(currentPage * (100 / numberOfStages));
    }, 100);
  }, [currentPage]);

  const isSignupRoute =
    window.location.pathname.includes(welcomeRoute) ||
    window.location.pathname.includes(signupRoute);
  return (
    <div className="startHeader">
      {props.showProgress && (
        <div className="startHeader--progressBar">
          {progress > 0 && (
            <ProgressBar
              progress={progress}
              previousProgress={previousProgress}
            />
          )}
        </div>
      )}

      <div className="startHeader--logoAndLink">
        <div className="startHeader--logo">
          <img src={adoptechLogoDark} />
        </div>
        {isSignupRoute && (
          <div className="startHeader--signIn">
            <div className="startHeader--signInText">
              Have an account?&nbsp;
            </div>
            <div className="startHeader--signInLink">
              <Link to="#" onClick={handleCancel}>
                Sign in here
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StartHeader;
