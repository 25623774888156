import { useState } from 'react';
import { SharedUserType } from '../../../swagger/models/SharedUserType';
import { userTypeOption } from '../PeopleFilters/options';

export const useFilterByUserTypes = (userTypeOptions: userTypeOption[]) => {
  const [filterUserTypes, setFilterUserTypes] = useState<SharedUserType[]>([]);

  const handleChange = (selectedOptions: { value: SharedUserType }[]) => {
    if (
      selectedOptions && // if no selection selected => cleanup all values
      selectedOptions.some(option => option.value === null)
    ) {
      setFilterUserTypes([]);
    } else {
      const selectedValues = selectedOptions
        ? selectedOptions.map(option => option.value)
        : [];
      setFilterUserTypes(selectedValues);
    }
  };

  const filteredOptions =
    filterUserTypes.length === 0
      ? userTypeOptions.filter(option => option.value !== null)
      : userTypeOptions;
  const selectedOptions = userTypeOptions.filter(option =>
    filterUserTypes.includes(option.value as SharedUserType)
  );
  return {
    filterUserTypes,
    handleChange,
    filteredOptions,
    selectedOptions,
  };
};

export default useFilterByUserTypes;
