import { VendorUserRoles } from '../swagger';

export const rolesViewConfig = {
  [VendorUserRoles.Admin]: {
    active: true,
    label: 'Admin',
    color: '#999995',
    description:
      'Manage People, Subscriptions & Billing, plus everything Security Manager can do.',
    optional: false,
  },
  [VendorUserRoles.SecurityManager]: {
    active: true,
    label: 'Security Manager',
    color: '#F1881F',
    description:
      'Create, edit and approve Policies, create and edit Assessments Actions and Events, manage all Risk and Compliance functionality, full access to Data Room, plus everything User can do.',
    optional: false,
  },
  [VendorUserRoles.SecurityEditor]: {
    active: true,
    label: 'Security Editor',
    color: '#d31431',
    description:
      'Set as owner of Controls, Checks and Risks and fully manage them, plus everything ‘User’ can do.',
    optional: false,
  },
  [VendorUserRoles.Employee]: {
    active: true,
    label: 'User',
    color: '#666665',
    description:
      'View all Live Policies, be requested to attest Policies, create Agreements and Checklists, create and be set as owner of Actions and Events.',
    optional: false,
  },
  [VendorUserRoles.ExternalConsultant]: {
    active: true,
    label: 'External Consultant',
    color: '#009819',
    description:
      'Provides the same permissions as the security manager role (but cannot be selected for Policy approval or read requests). External consultants also receive alerts and updates when framework project milestones are met.',
    optional: false,
  },
  [VendorUserRoles.Auditor]: {
    active: true,
    label: 'Auditor',
    color: '#e2d05e',
    description:
      'View all Live Policies, view all Registers and Compliance functionality, view all Actions and Events.',
    optional: false,
  },
  [VendorUserRoles.Signatory]: {
    active: true,
    label: 'Signatory',
    color: '#009883',
    description: 'Sign Agreements on behalf of the company.',
    optional: true,
  },
  [VendorUserRoles.TrusthubAdmin]: {
    active: true,
    label: 'TrustHub',
    color: '#81838a',
    description:
      'Set up and configure the TrustHub page, manage users and document requests.',
    optional: true,
  },
  // these 2 roles are hidden from the UI now
  [VendorUserRoles.PolicyEditor]: {
    active: false,
    label: 'Policy Editor',
    color: '#A8D63F',
    description: 'Create, approve & manage company policies.',
    enabled: false,
    optional: false,
  },

  [VendorUserRoles.AssessmentManager]: {
    active: false,
    label: 'Assessment Mgr',
    enabled: false,
    color: '#75BC46',
    description:
      'Manage assessments received & send assessments to third parties.',
    optional: false,
  },
};

export const getMoreRolesLabel = (count: number) => ({
  label: `+${count} more`,
  color: '#EFA82C',
  description: '',
});
