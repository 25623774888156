import { useMemo } from 'react';
import { ComplianceFilterPayload } from '../../components/compliance/Types/complianceTypes';
import { ControlExtended, TaskExtended } from '../../swagger';

export const useAssigneeFilter = (
  items: TaskExtended[] | ControlExtended[],
  filters: ComplianceFilterPayload
) => {
  const assigneeSelectOptions = useMemo(() => {
    const assignees = items
      .map(item => item.assignee)
      .filter(assignee => assignee);

    const ids: string[] = [];
    const uniqueLabels: any = [];

    assignees.forEach(assignee => {
      if (ids.includes(assignee.id)) return;
      ids.push(assignee.id);

      uniqueLabels.push({
        value: assignee.id,
        label: assignee.fullName,
      });
    });

    return uniqueLabels;
  }, [items]);

  const currentAssignee = filters.assigneeId
    ? assigneeSelectOptions.find(
        (option: any) => option.value === filters.assigneeId
      )
    : null;
  return { currentAssignee, assigneeSelectOptions };
};
