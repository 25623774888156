import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import { ApplicationState } from '../../../types/applicationState';
import {
  fetchVendorTeams,
  upsertVendorTeams,
} from '../../../store/vendors/vendorsThunks';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechDrawer } from '../../../components/AdoptechDrawer/AdoptechDrawer';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { TeamListItem } from '../../../components/TeamListItem/TeamListItem';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  VendorTeamModel,
  VendorTeamUpsertPayloadVendorTeams,
} from '../../../swagger';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import './ManageTeamsDrawer.scss';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';

interface ManageTeamsDrawerProps {
  show: boolean;
  onClose(): void;
}

export const ManageTeamsDrawer: React.FC<ManageTeamsDrawerProps> = ({
  show,
  onClose,
}) => {
  const dispatch = useDispatch();
  const teamNameRef = useRef<HTMLInputElement>();

  const teams = useSelector(
    (state: ApplicationState) => state.vendors.teamsState.vendorTeams
  );
  const currentVendor = useSelector(selectCurrentVendor);
  const vendorId = currentVendor?.id;

  const [teamsForEditing, setTeamsForEditing] = useState<
    Array<VendorTeamUpsertPayloadVendorTeams>
  >([]);
  const [currentTeam, setCurrentTeam] = useState<VendorTeamModel>(null);
  const [teamName, setTeamName] = useState<string>('');
  const isDuplicateTeamName = useSelector(
    (state: ApplicationState) => state.vendors.teamsState.isDuplicateTeamName
  );
  const isUpsertingVendorTeams = useSelector(
    (state: ApplicationState) => state.vendors.teamsState.isUpsertingVendorTeams
  );

  const [currentCommand, command] = useState<CommandConfirmation>(null);

  useEffect(() => {
    const data = teams.map(({ id, name }) => ({
      id,
      name,
    }));

    setTeamsForEditing(data);
  }, [teams, show]);

  const upsertTeams = () => {
    dispatch(
      upsertVendorTeams({
        vendorId,
        vendorTeams: teamsForEditing,
        onSuccess: () => {
          dispatch(showGlobalToast('Groups were successfully updated.'));
          dispatch(fetchVendorTeams(true));
          onClose();
        },
      })
    );
  };

  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        onClick={upsertTeams}
        busy={isUpsertingVendorTeams}
        variant={AdoptechButtonVariant.Primary}
      >
        DONE
      </AdoptechButton>
      <ConfirmationModal
        command={currentCommand}
        onCancel={() => command(null)}
      />
    </>
  );

  return (
    <AdoptechHS
      noFormPadding
      title="Manage group (s)"
      show={show}
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass="manageTeamsDrawer"
    >
      <div className="manageTeamsDrawer--formContainer">
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              bsPrefix="manageTeamsDrawer--addSection"
              eventKey="0"
            >
              <span className="manageTeamsDrawer--addSection-title">
                Add new group
              </span>
              <FontAwesomeIcon
                className="manageTeamsDrawer--angleIcon"
                icon={faAngleDown}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body bsPrefix="manageTeamsDrawer--addSection-controls">
                <AdoptechTextInput
                  id="teamNameInput"
                  hasError={isDuplicateTeamName}
                  onChange={e => setTeamName(e.currentTarget.value)}
                  ref={teamNameRef}
                  type="text"
                  placeholder="Add group"
                  value={teamName}
                />
                <AdoptechButton
                  disabled={
                    !teamName || teamsForEditing.some(t => t.name === teamName)
                  }
                  onClick={() => {
                    const data = [...teamsForEditing];
                    data.push({ name: teamName });

                    setTeamsForEditing(data);
                    setTeamName('');
                  }}
                  variant={AdoptechButtonVariant.Default}
                >
                  + Add
                </AdoptechButton>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              bsPrefix="manageTeamsDrawer--manageSection"
              eventKey="0"
            >
              <div className="manageTeamsDrawer--manageSection-description">
                <span className="manageTeamsDrawer--manageSection-title">
                  Manage groups
                </span>
                <span className="manageTeamsDrawer--manageSection-subtitle">
                  Edit group names by clicking inside the text field, or click
                  the delete button to remove
                </span>
              </div>
              <FontAwesomeIcon
                className="manageTeamsDrawer--angleIcon"
                icon={faAngleDown}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body bsPrefix="manageTeamsDrawer--manageSection-controls">
                <div className="manageTeamsDrawer--manageSection-list">
                  {teamsForEditing?.map(team => (
                    <TeamListItem
                      key={team.name}
                      team={team}
                      onChange={name => {
                        const data = [...teamsForEditing];
                        const element = teamsForEditing.find(
                          t => t.name === team.name
                        );
                        const index = teamsForEditing.indexOf(element);
                        data.splice(index, 1, {
                          ...element,
                          name,
                        });

                        setTeamsForEditing(data);
                      }}
                      onRemove={vendorTeam => {
                        command({
                          title: 'Delete group',
                          description: `
                            Are you sure you wish to delete the following group?
                          `,
                          action: 'Delete',
                          subject: {
                            name: vendorTeam.name,
                            type: '',
                          },
                          buttonVariant: AdoptechButtonVariant.Warning,
                          onConfirm: () => {
                            const data = teamsForEditing.filter(
                              teamForEdit =>
                                teamForEdit.name !== vendorTeam.name
                            );

                            setTeamsForEditing(data);
                            setCurrentTeam(null);
                          },
                        });
                      }}
                    />
                  ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </AdoptechHS>
  );
};
