import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import './PageTabs.scss';
import classNames from 'classnames';
import {
  dataRoomEvidenceRoute,
  dataRoomRoute,
  reportsRoute,
} from '../../../../components/Routes/Routes';
import { capitalize } from '../../../../functions/capitalize';
import { canFeature } from '../../../../functions/access';
import { AccessObject } from '../../../../types/accessObject';

interface Props {
  currentTab: 'repository' | 'evidence' | 'reports';
}

export const DataRoomTabs: React.FC<Props> = ({ currentTab }) => {
  const dispatch = useDispatch();

  const goToRepository = () => dispatch(push(dataRoomRoute));
  const goToEvidence = () => dispatch(push(dataRoomEvidenceRoute));
  const goToReports = () => dispatch(push(reportsRoute));
  const canExportEvidences = canFeature(
    AccessObject.dataroom_control_evidence_manage
  );
  return (
    <>
      <div className="pageTabs">
        <div
          className={classNames('pageTabs--tab', {
            active: currentTab === 'repository',
          })}
          onClick={goToRepository}
        >
          Repository
        </div>
        {canExportEvidences && (
          <div
            className={classNames('pageTabs--tab', {
              active: currentTab === 'evidence',
            })}
            onClick={goToEvidence}
          >
            Evidence
          </div>
        )}
        <div
          className={classNames('pageTabs--tab', {
            active: currentTab == 'reports',
          })}
          onClick={goToReports}
        >
          Reports
        </div>
      </div>
      <div className="pageTabs--actions"></div>
    </>
  );
};
