import React, { useEffect } from 'react';
import './TrustHubLatestActivity.scss';
import { AdoptechPanel } from '../../../../../../../components/AdoptechPanel/AdoptechPanel';
import { faUserClock } from '@fortawesome/pro-light-svg-icons/faUserClock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdoptechTextInput } from '../../../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../../../../../../selectors/selectCurrentVendor';
import { fetchTrustHubActivities } from '../../../../../store/trusthubThunks';
import { ApplicationState } from '../../../../../../../types/applicationState';
import { setTrustHubActivitiesSearch } from '../../../../../store/trusthubSlice';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';
import { NoDataText } from '../../../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { selectActivities } from '../../../selectActivities';
import { SortableTableHeader } from '../../../../../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../../../../../types/grid';
import { TrusthubVendorUserActivityModel } from '../../../../../../../swagger/trusthub/models/TrusthubVendorUserActivityModel';
import { formatShortDate } from '../../../../../../../functions/formatShortDate';
import { push } from 'connected-react-router';
import { trusthubAdminPageUserRoute } from '../../../../../../../components/Routes/Routes';
import AdoptechOverflowLine from '../../../../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';

export const TrustHubLatestActivity: React.FC = () => {
  const baseCss = 'trusthubLatestActivity';
  const { activitiesSearch, fetchingActivitiesStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const activities = useSelector(selectActivities);
  const vendor = useSelector(selectCurrentVendor);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchTrustHubActivities(
        {
          vendorIdOrDomain: vendor.id,
        },
        () => {}
      )
    );
  }, [vendor.id]);

  return (
    <div className={baseCss + '--panel'}>
      <AdoptechPanel>
        <div className={baseCss + '--panelTitle'}>
          <div className={baseCss + '--panelInfo'}>
            <FontAwesomeIcon icon={faUserClock} />
            Latest Activity
            <div className={baseCss + '--panelBadge'}>{activities.length}</div>
          </div>
        </div>

        <div className={baseCss + '--panelSearch'}>
          <AdoptechTextInput
            id="search"
            value={activitiesSearch}
            onChange={e =>
              dispatch(setTrustHubActivitiesSearch(e.currentTarget.value))
            }
            type="text"
            placeholder="Search activity"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>

        {fetchingActivitiesStatus === 'loading' ? (
          <LoadingSpinner />
        ) : (
          <div className={baseCss + '--panelTable'}>
            <div className={classNames(`${baseCss}--4-columns`)}>
              {activities.length <= 0 ? (
                <NoDataText extraClass="pt-4" text="No activities to show" />
              ) : (
                <AdoptechGridTable
                  header={
                    <div className={baseCss + '--tableHeader'}>
                      <SortableTableHeader<TrusthubVendorUserActivityModel>
                        columnName={
                          'company' as keyof TrusthubVendorUserActivityModel
                        }
                        grid={Grid.TrustHubUserAdminActivities}
                        label="Company"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserActivityModel>
                        columnName="trusthubVendorUser"
                        grid={Grid.TrustHubUserAdminActivities}
                        label="User"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserActivityModel>
                        columnName="name"
                        grid={Grid.TrustHubUserAdminActivities}
                        label="Accessed documents"
                        notInTable
                      />
                      <SortableTableHeader<TrusthubVendorUserActivityModel>
                        columnName="createdAt"
                        grid={Grid.TrustHubUserAdminActivities}
                        label="Viewed at"
                        notInTable
                      />
                    </div>
                  }
                >
                  {activities.map(activity => {
                    const { id } = activity;
                    return (
                      <div
                        className={baseCss + '--tableRow'}
                        key={id}
                        onClick={() => {
                          const url = trusthubAdminPageUserRoute.replace(
                            ':id',
                            activity.trusthubVendorUser?.id
                          );
                          dispatch(push(url));
                        }}
                      >
                        <AdoptechOverflowLine>
                          {activity.trusthubVendorUser?.trusthubCompany?.name ||
                            '-'}
                        </AdoptechOverflowLine>
                        <AdoptechOverflowLine>
                          {activity.trusthubVendorUser?.fullName || '-'}
                        </AdoptechOverflowLine>
                        <AdoptechOverflowLine className={baseCss + '--name'}>
                          {activity.name}
                        </AdoptechOverflowLine>

                        <div className={baseCss + '--createdAt'}>
                          {formatShortDate(activity.createdAt)}
                        </div>
                      </div>
                    );
                  })}
                </AdoptechGridTable>
              )}
            </div>
          </div>
        )}
      </AdoptechPanel>
    </div>
  );
};
