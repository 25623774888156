import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectUsers } from '../../selectors/selectUsers';
import {
  hideConfirmResendInvitationModal,
  showAddUserModal,
  showConfirmSendInvitationModal,
} from '../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ConfirmResendInvitationModal.scss';
// TODO: remove this and reuse ConfirmModal
export const ConfirmResendInvitationModal = () => {
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    emailAddress,
    isConfirmResendInvitationModalShowing,
  } = useSelector((state: ApplicationState) => state.vendors.inviteState);
  const users = useSelector((state: ApplicationState) =>
    selectUsers(state, { excludeLeft: false })
  );

  const formattedDate = () => {
    const date = users.find(user => user.email === emailAddress)?.lastInvitedAt;
    return formatShortDate(date);
  };

  const handleCancel = () => {
    dispatch(showAddUserModal());
    dispatch(hideConfirmResendInvitationModal());
  };

  return (
    <AdoptechModal
      className="confirmResendInvitationModal"
      onHide={handleCancel}
      show={isConfirmResendInvitationModalShowing}
    >
      <Modal.Header>User already invited</Modal.Header>
      <Modal.Body>
        {`${firstName} ${lastName} has been invited to the portal on `}
        <b>{formattedDate()}</b>
        {` but has not yet logged in. Would you like to resend the invite?`}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          onClick={() => {
            dispatch(showConfirmSendInvitationModal(true));
            dispatch(hideConfirmResendInvitationModal());
          }}
          variant={AdoptechButtonVariant.Primary}
        >
          Resend Invite
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
