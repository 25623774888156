import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';
import {
  VendorIntegrationCreatePayloadVendorIntegrationTypeEnum,
  VendorIntegrationExtended,
  VendorIntegrationModel,
  VendorIntegrationSyncCreatePayloadVendorIntegrationSync,
  VendorIntegrationSyncModel,
} from '../../swagger';
import IntegrationsState from './integrationsState';
import { ApplicationState } from '../../types/applicationState';
import { VoidThunk } from '../../types/voidThunk';
import { api } from '../../api/api';
import { getErrorMessage } from '../helpers/thunkHelpers';
import { IntegrationCategory, integrationsStatic } from './integrationsStatic';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    searchText: '',
    filterCategory: IntegrationCategory.All,
    status: 'idle',
    list: [] as VendorIntegrationModel[],
    upIntegration: null,
    learnMoreIntegration: null,
  } as IntegrationsState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setFilterCategory: (state, action: PayloadAction<IntegrationCategory>) => {
      state.filterCategory = action.payload;
    },
    setStatus: (state, action: PayloadAction<IntegrationsState['status']>) => {
      state.status = action.payload;
    },
    setList: (state, action: PayloadAction<VendorIntegrationModel[]>) => {
      state.list = action.payload;
    },
    setUpIntegration: (
      state,
      action: PayloadAction<VendorIntegrationExtended>
    ) => {
      state.upIntegration = action.payload;
    },
    setLearnMoreIntegration: (
      state,
      action: PayloadAction<VendorIntegrationExtended>
    ) => {
      state.learnMoreIntegration = action.payload;
    },
  },
});

export const {
  setSearchText,
  setFilterCategory,
  setStatus,
  setList,
  setUpIntegration,
  setLearnMoreIntegration,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;

export const fetchIntegrations =
  (): VoidThunk => async (dispatch, getState) => {
    const vendorId = getState().vendors.currentVendor?.id;
    if (!vendorId) {
      dispatch(setStatus('failed'));
      return;
    }
    try {
      dispatch(setStatus('loading'));
      const response = await api().vendorsVendorIdVendorIntegrationsGet({
        vendorId,
      });
      dispatch(setList(response));
      dispatch(setStatus('succeeded'));
    } catch (err) {
      if (err instanceof Response) {
        dispatch(setStatus('failed'));
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching integrations. Please try again or contact support.'
        );
        // dispatch(setError(apiErrorMessage));
      }
    }
  };

export const submitIntegration =
  (onSuccess = () => {}, onFail = () => {}): VoidThunk =>
  async (dispatch, getState) => {
    const vendorId = getState().vendors.currentVendor?.id;
    if (!vendorId) {
      return;
    }
    try {
      const integration = getState().integrations.upIntegration;
      const settings = integration.settings;
      const apiKey = settings.find(s => s.key === 'api_key')?.value;
      const apiSecret = settings.find(s => s.key === 'api_secret')?.value;
      const region = settings.find(s => s.key === 'region')?.value;
      const tenant = settings.find(s => s.key === 'tenant')?.value;
      const detectorId = settings.find(s => s.key === 'detector_id')?.value;
      if (integration.id) {
        await api().vendorIntegrationsVendorIntegrationIdPatch({
          vendorIntegrationId: integration.id,
          body: {
            vendorIntegration: {
              apiKey,
              apiSecret,
              region,
              detectorId,
              tenant,
            },
          },
        });
      } else {
        await api().vendorsVendorIdVendorIntegrationsPost({
          vendorId,
          body: {
            vendorIntegration: {
              type: integration.type as VendorIntegrationCreatePayloadVendorIntegrationTypeEnum,
              apiKey,
              apiSecret,
              region,
              detectorId,
              tenant,
            },
          },
        });
      }
      onSuccess();
    } catch (err) {
      onFail();
      if (err instanceof Response) {
        await getErrorMessage(
          err,
          'An error occurred while connecting integration. Please try again or contact support.'
        );
      }
    }
  };

export const removeIntegration =
  (
    integration: VendorIntegrationModel,
    onSuccess = () => {},
    onFail = () => {}
  ): VoidThunk =>
  async (dispatch, getState) => {
    const vendorId = getState().vendors.currentVendor?.id;
    if (!vendorId) {
      return;
    }
    try {
      await api().vendorIntegrationsVendorIntegrationIdDelete({
        vendorIntegrationId: integration.id,
      });
      onSuccess();
    } catch (err) {
      onFail();
      if (err instanceof Response) {
        await getErrorMessage(
          err,
          'An error occurred while disconnecting integration. Please try again or contact support.'
        );
      }
    }
  };

export const createIntegrationSync =
  (
    integrationId: VendorIntegrationModel['id'],
    vendorIntegrationSync: VendorIntegrationSyncCreatePayloadVendorIntegrationSync,
    onSuccess: () => void,
    onFailure: () => void
  ): VoidThunk =>
  async (dispatch, getState) => {
    const vendorId = getState().vendors.currentVendor?.id;
    if (!vendorId) return;

    try {
      await api().vendorIntegrationsVendorIntegrationIdVendorIntegrationSyncsPost(
        {
          vendorIntegrationId: integrationId,
          body: {
            vendorIntegrationSync,
          },
        }
      );
      dispatch(fetchIntegrations());
      onSuccess();
    } catch (err) {
      onFailure();
      if (err instanceof Response) {
        await getErrorMessage(
          err,
          'An error occurred while disabling sync. Please try again or contact support.'
        );
      }
    }
  };

export const removeIntegrationSync =
  (
    integrationSyncId: VendorIntegrationSyncModel['id'],
    onSuccess: () => void,
    onFailure: () => void
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      await api().vendorIntegrationSyncsVendorIntegrationSyncIdDelete({
        vendorIntegrationSyncId: integrationSyncId,
      });
      dispatch(fetchIntegrations());
      onSuccess();
    } catch (err) {
      onFailure();
      if (err instanceof Response) {
        await getErrorMessage(
          err,
          'An error occurred while disabling sync. Please try again or contact support.'
        );
      }
    }
  };

export const selectIntegrationsStatus = (state: ApplicationState) => {
  return state.integrations.status;
};

export const selectSearchText = (state: ApplicationState) => {
  return state.integrations.searchText;
};

export const selectFilterCategory = (state: ApplicationState) => {
  return state.integrations.filterCategory;
};

export const selectUpIntegration = (state: ApplicationState) => {
  return state.integrations.upIntegration;
};

export const selectLearnMoreIntegration = (state: ApplicationState) => {
  return state.integrations.learnMoreIntegration;
};

export const selectFilteredIntegrations = createSelector(
  (state: ApplicationState) => state.integrations.list,
  (state: ApplicationState) => state.integrations.searchText,
  (state: ApplicationState) => state.integrations.filterCategory,
  (list, searchText, category) => {
    const integrations = [...integrationsStatic];
    list.forEach(listIntegration => {
      const index = integrations.findIndex(
        integration => integration.type === listIntegration.type
      );
      if (index === -1) return;
      integrations[index] = {
        ...integrations[index],
        ...listIntegration,
        available: true,
      };
    });

    const fuse = new Fuse(integrations, {
      ignoreLocation: true,
      includeScore: true,
      keys: ['name'],
      threshold: 0,
    });
    let filteredIntegrations = searchText
      ? fuse.search(searchText).map(x => x.item)
      : integrations;
    if (category != IntegrationCategory.All) {
      filteredIntegrations = filteredIntegrations.filter(
        i => i.category == category
      );
    }

    return filteredIntegrations;
  }
);
