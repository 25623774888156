import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { canFeature } from '../../functions/access';
import { capitaliseFirst } from '../../functions/capitaliseFirst';
import { capitalize } from '../../functions/capitalize';
import { formatShortDate } from '../../functions/formatShortDate';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import {
  VendorSupplierModel,
  VendorSupplierAccessControl,
  VendorSupplierStatus,
} from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import AdoptechOverflowLine from '../AdoptechOverflowLine/AdoptechOverflowLine';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import {
  RiskInfo,
  RiskLevel,
  VendorRegisterRiskProfile,
} from '../VendorRegisterRiskProfile/VendorRegisterRiskProfile';
import './VendorRegisterRow.scss';
import { VendorSupplierLogo } from '../../features/risk/vendor/VendorSupplierLogo';
import moment from 'moment';

interface VendorRegisterRowProps {
  vendorSupplier: VendorSupplierModel;
  onClick?(): void;
}

export const getVendorRegistryRiskInfo = (severity: number): RiskInfo => {
  const risk: RiskInfo = { riskSeverity: severity };
  switch (severity) {
    case 1:
    case 2:
      risk.riskLevel = 'Very Low' as keyof RiskLevel;
      break;
    case 3:
    case 4:
      risk.riskLevel = 'Low' as keyof RiskLevel;
      break;
    case 5:
    case 6:
      risk.riskLevel = 'Medium' as keyof RiskLevel;
      break;
    case 7:
    case 8:
      risk.riskLevel = 'High' as keyof RiskLevel;
      break;
    case 9:
    case 10:
      risk.riskLevel = 'Critical' as keyof RiskLevel;
      break;
    default:
      return risk;
  }
  return risk;
};

export const VendorRegisterRow: React.FC<VendorRegisterRowProps> = ({
  vendorSupplier,
  onClick,
}) => {
  const baseCss = 'vendorRegisterRow';
  const baseTableCss = 'adoptechGridTable';
  const canManageRegister = canFeature(AccessObject.vendor_register_manage);
  const isActive = vendorSupplier.status === VendorSupplierStatus.Active;
  const isOverdue =
    vendorSupplier.reviewDate &&
    moment(vendorSupplier.reviewDate).isBefore(new Date());

  return (
    <div
      className={classNames(
        `${baseCss} ${baseTableCss}--row`,
        `${baseTableCss}--slim`,
        { disabled: !canManageRegister, [`${baseTableCss}--alert`]: isOverdue }
      )}
      onClick={onClick}
    >
      <div className={baseCss + '--name'}>
        <VendorSupplierLogo name={vendorSupplier.name} />
      </div>
      <VendorRegisterRiskProfile
        riskInfo={getVendorRegistryRiskInfo(vendorSupplier.riskProfile)}
      />
      <div>
        {vendorSupplier.accessControl === VendorSupplierAccessControl.Sso ||
        vendorSupplier.accessControl === VendorSupplierAccessControl._2fa
          ? vendorSupplier.accessControl.toUpperCase()
          : capitaliseFirst(vendorSupplier.accessControl)}
      </div>
      <AdoptechOverflowLine className={baseCss + '--owner'}>
        <UserAvatar
          user={vendorSupplier.owner}
          size="medium"
          hideText
          showTooltip
        />
      </AdoptechOverflowLine>
      <div>
        {isOverdue ? (
          <span className={baseCss + '--overdueAlert'}>
            <FontAwesomeIcon icon={faClock} /> Overdue
          </span>
        ) : (
          formatShortDate(vendorSupplier.reviewDate.toString())
        )}
      </div>
      <div>{capitaliseFirst(vendorSupplier.contractRecurring)}</div>
      <div className={baseCss + '--status'}>
        <FontAwesomeIcon
          icon={isActive ? faCheckCircle : faCircleXmark}
          className={
            isActive ? baseCss + '--greenStatus' : baseCss + '--greyStatus'
          }
        />
        <div className={baseCss + '--statusText'}>
          {capitalize(vendorSupplier.status)}
        </div>
      </div>
    </div>
  );
};
