import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIdentifierTypesOptions } from '../../selectors/selectIdenifierTypesOptions';
import { fetchIdentifierTypes } from '../../store/vendors/vendorsThunks';
import {
  setCompanyIdentifierType,
  setOfficeAddress,
  setRegisteredCompany,
} from '../../store/welcome/welcomeSlice';
import { Office, RegisteredCompany } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechStateSelect } from '../AdoptechStateSelect/AdoptechStateSelect';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './CompanyDetailsEntry.scss';

interface CompanyDetailsEntryProps {
  isValidChanged: (valid: boolean) => void;
  showError: boolean;
}

const CompanyDetailsEntry: React.FC<CompanyDetailsEntryProps> = props => {
  const country = useSelector(
    (state: ApplicationState) => state.welcome.country
  );

  const currentOffice = useSelector(
    (state: ApplicationState) => state.welcome.office
  );

  const registeredCompany = useSelector(
    (state: ApplicationState) => state.welcome.registeredCompany
  );

  const identifierTypesOptions = useSelector(selectIdentifierTypesOptions);

  const [company, setCompany] = useState<RegisteredCompany>(
    registeredCompany || {
      name: '',
      identifier: '',
      registeredAddress: '',
    }
  );

  const [identifierType, setIdentifierType] = useState<SelectionOption>(
    useSelector((state: ApplicationState) => {
      const current_value = state.welcome.companyIdentifierType;
      return identifierTypesOptions?.find(ito => ito.value === current_value);
    })
  );

  const [office, setOffice] = useState<Office>(
    currentOffice || {
      address1: '',
      address2: '',
      city: '',
      postCode: '',
    }
  );

  const setCompanyDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setCompany((companyToSet: RegisteredCompany) => {
      return {
        ...companyToSet,
        [id]: value,
      };
    });
  };

  const dispatch = useDispatch();

  const handleIdentifierTypeChange = (option: SelectionOption) => {
    setIdentifierType(option);
    dispatch(setCompanyIdentifierType(option.value));
  };

  const setAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const newOffice = {
      ...office,
      [id]: value,
    };
    setOffice(newOffice);
    dispatch(setOfficeAddress(newOffice));
  };

  const setState = (option: SelectionOption) => {
    const newOffice = {
      ...office,
      state: option.value,
    };
    setOffice(newOffice);
    dispatch(setOfficeAddress(newOffice));
  };

  const isValid = (): boolean => {
    let valid = false;
    if (company?.name && office?.address1 && office?.postCode) {
      valid = Boolean(
        (company?.identifier && identifierType) ||
          (!company?.identifier && !identifierType)
      );
    }

    if (valid) {
      dispatch(setRegisteredCompany(company));
    }

    return valid;
  };

  useEffect(() => {
    props.isValidChanged(isValid());
  }, [company, identifierType, office]);

  useEffect(() => {
    if (!identifierTypesOptions) {
      dispatch(fetchIdentifierTypes());
    }
  }, []);
  const baseCss = 'companyDetailsEntry';
  const postcodeError = props.showError && !office?.postCode;
  const addressError = props.showError && !office?.address1;
  const companyNameError = props.showError && !company.name;
  const companyIdentifierError =
    props.showError && !company.identifier && identifierType;
  const companyIdentifierTypeError =
    props.showError && company.identifier && !identifierType;

  return (
    <div className="companyDetailsEntry">
      <div className="companyDetailsEntry--prompt">
        Please provide the following information:
      </div>
      <div className="companyDetailsEntry--form">
        <AdoptechTextInput
          type="text"
          id="name"
          placeholder="Company legal name"
          autoFocus
          value={company.name}
          onChange={setCompanyDetail}
          additionalClass={companyNameError ? 'red-field' : ''}
          bottomElement={
            companyNameError ? (
              <div className="error-badge">
                You must provide a company legal name.
              </div>
            ) : null
          }
        />
        <div className="companyDetailsEntry--identifierBlock">
          <AdoptechTextInput
            type="text"
            id="identifier"
            placeholder="Company number"
            value={company.identifier}
            onChange={setCompanyDetail}
            additionalClass={companyIdentifierError ? 'red-field' : ''}
          />
          <AdoptechReactSelect
            id="identifierType"
            options={identifierTypesOptions}
            value={identifierType}
            onChange={handleIdentifierTypeChange}
            placeholder="Identifier type"
            hasError={companyIdentifierTypeError}
          />
        </div>
        <div className={baseCss + '--registeredAddress'}>
          Registered address:
        </div>
        <AdoptechTextInput
          type="text"
          id="address1"
          placeholder="Address line 1"
          value={office?.address1}
          onChange={setAddress}
          additionalClass={addressError ? 'red-field' : ''}
          bottomElement={
            addressError ? (
              <div className="error-badge">
                You must provide the first line of your registered company
                address.
              </div>
            ) : null
          }
        />
        <AdoptechTextInput
          type="text"
          id="address2"
          placeholder="Address line 2"
          value={office?.address2}
          onChange={setAddress}
        />
        {country === 'US' && (
          <AdoptechStateSelect
            placeholder="State"
            value={office?.state}
            onChange={setState}
          />
        )}
        {country !== 'US' && (
          <AdoptechTextInput
            type="text"
            id="state"
            placeholder="County / State"
            value={office?.state}
            onChange={setAddress}
          />
        )}

        <AdoptechTextInput
          type="text"
          id="postCode"
          placeholder="Postcode / Zip Code"
          value={office?.postCode}
          onChange={setAddress}
          additionalClass={postcodeError ? 'red-field' : ''}
          bottomElement={
            postcodeError ? (
              <div className="error-badge">
                You must provide a postcode/zip code.
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default CompanyDetailsEntry;
