import { DataRoomEntityModelTypeEnum } from '../swagger';

export const getEntityName = (entity: {
  entityName: string;
  entityType: DataRoomEntityModelTypeEnum;
  fileType: string;
}) => {
  return entity.entityType === DataRoomEntityModelTypeEnum.Folder
    ? entity.entityName
    : `${entity.entityName}.${entity.fileType}`;
};
