import { createSelector } from '@reduxjs/toolkit';
import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { ApplicationState } from '../types/applicationState';
import Fuse from 'fuse.js';
import { controlSort } from '../functions/controlSort';
import { ControlModelStatusEnum } from '../swagger';

export const selectControls = createSelector(
  (state: ApplicationState) => state.compliance.controls,
  (state: ApplicationState) => state.compliance.controlsSearch,
  (state: ApplicationState) => state.compliance.controlsFilters,
  selectSortSettings(Grid.Controls),
  (controls, search, filters, sortSettings) => {
    const searchOptions = {
      ignoreLocation: true,
      includeScore: true,
      keys: ['name', 'assignee.name'],
      threshold: 0,
    };

    let filteredControls = controls;

    const fuse = new Fuse(controls, searchOptions);
    filteredControls = search
      ? fuse.search(search).map(x => x.item)
      : filteredControls;

    if (filters.frameworkIdentifier) {
      filteredControls = filteredControls.filter(control =>
        control.frameworksRelations
          .map(fr => fr.framework.identifier)
          .includes(filters.frameworkIdentifier)
      );
    }

    if (filters.assigneeId) {
      filteredControls = filteredControls.filter(
        control => control.assignee?.id == filters.assigneeId
      );
    }

    if (filters.status) {
      const compliant = filters.status === ControlModelStatusEnum.Compliant;
      filteredControls = filteredControls.filter(control =>
        compliant
          ? control.status === ControlModelStatusEnum.Compliant
          : control.status !== ControlModelStatusEnum.Compliant
      );
    }

    if (filters.applicable) {
      filteredControls = filteredControls.filter(
        control => control.applicable === (filters.applicable == 'applicable')
      );
    }

    return [...filteredControls].sort((a, b) =>
      controlSort(a, b, sortSettings.columnSort)
    );
  }
);
