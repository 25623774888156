import React, { Dispatch, SetStateAction, useState } from 'react';
import { trusthubAdminPageRequestsRoute } from '../../../../components/Routes/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faUserAlt } from '@fortawesome/pro-light-svg-icons/faUserAlt';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { ToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { updateTrustHubSettings } from '../../store/trusthubThunks';
import { ApplicationState } from '../../../../types/applicationState';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { useAdoptechDebounce } from '../../../../hooks/useAdoptechDebounce';
import { CommandConfirmation } from '../../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import { setShowSettingsDrawer } from '../../store/trusthubSlice';

export const TrustHubAdminPageHeaderButtons: React.FC = props => {
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);
  const checkWithDebounce = useAdoptechDebounce();
  const { settings, updateSettingsStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const [published, setPublished] = useState(settings.published);

  const handlePublish = () => {
    const value = !published;
    setPublished(value);
    checkWithDebounce(() => {
      const payload = {
        published: value,
        vendorIdOrDomain: vendor.id,
      };
      dispatch(updateTrustHubSettings(payload, () => {}, false));
    });
  };

  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const publishActionCommand: CommandConfirmation = {
    title: 'Confirm Publish',
    description:
      'Your TrustHub webpage will be made public. Anyone that wishes to view the contents of your documents will need to request access.',
    buttonVariant: AdoptechButtonVariant.Primary,
    onConfirm: () => {
      handlePublish();
    },
  };

  return (
    <>
      <AdoptechButton
        icon={faGear}
        variant={AdoptechButtonVariant.White}
        onClick={() => dispatch(setShowSettingsDrawer(true))}
        busy={updateSettingsStatus === 'loading'}
      >
        Settings
      </AdoptechButton>
      <AdoptechButton
        onClick={() => dispatch(push(trusthubAdminPageRequestsRoute))}
        icon={faUserAlt}
        variant={AdoptechButtonVariant.White}
      >
        Admin
      </AdoptechButton>
      <AdoptechButton
        onClick={() =>
          published ? handlePublish() : command(publishActionCommand)
        }
        icon={faEye}
        variant={AdoptechButtonVariant.White}
      >
        Publish
        <ToggleSwitch
          handlerStyle="small"
          switchStyles={{
            width: 35,
            height: 18,
            offHandleColor: '#fff',
            onColor: '#75BC46',
          }}
          onChange={() =>
            published ? handlePublish() : command(publishActionCommand)
          }
          checked={published}
        />
      </AdoptechButton>
      <ConfirmationModal
        command={currentCommand}
        onCancel={() => command(null)}
      />
    </>
  );
};
