import './CompanyBrandingTable.scss';

import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideLogoUploadSuccessToast,
  showUploadLogoModal,
} from '../../store/vendors/vendorsSlice';
import {
  fetchCompanyLogo,
  updateCompanyProfile,
} from '../../store/vendors/vendorsThunks';
import { AccessObject } from '../../types/accessObject';
import { ApplicationState } from '../../types/applicationState';
import { MessageToast } from '../MessageToast/MessageToast';
import { UploadLogoModal } from '../UploadLogoModal/UploadLogoModal';
import { canFeature } from '../../functions/access';
import { IconButton } from '../IconButton/IconButton';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColourPalette,
  defaultColoursList,
} from '../ColourPalette/ColourPalette';
import { Company } from '../../swagger';
import { showGlobalToast } from '../../store/global/globalSlice';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { Panel } from '../Panel/Panel';
import adoptechA from '../../../../assets/images/icons/adoptech-a.svg';

export interface CompanyBrandingTableProps {
  vendorId: string;
  company: Company;
}

export const isDisplayableColour = (newColour: string) => {
  if (defaultColoursList.find(c => c === newColour)) return true;

  const regx = /^#([0-9a-f]{3}){1,2}$/i;
  const result = regx.test(newColour);
  return result;
};

export const CompanyBrandingTable: React.FC<CompanyBrandingTableProps> = ({
  vendorId,
  company,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyLogo({ vendorId }));
  }, [vendorId]);

  const logo = useSelector(
    (state: ApplicationState) => state.vendors.companyDetailsState.logo
  );
  const isLogoUploadSuccessToastShowing = useSelector(
    (state: ApplicationState) =>
      state.vendors.companyDetailsState.isLogoUploadSuccessToastShowing
  );
  const currentVendor = useSelector(selectCurrentVendor);
  const canEdit = canFeature(AccessObject.company_manage);
  const baseCss = 'companyBrandingTable';
  const [colour, setColour] = useState<string>(company.brandingColor || '');

  useEffect(() => {
    setColour(company.brandingColor || '');
  }, [currentVendor]);

  const checkValidColour = (newColour: string) => {
    if (!newColour) return true;

    return isDisplayableColour(newColour);
  };

  const saveCompanyDetails = (updatedCompany: Company) => {
    const text =
      updatedCompany.brandingColor !== company.brandingColor
        ? 'Your company colours have been ' +
          `${updatedCompany.brandingColor ? 'applied' : 'cleared'}` +
          '.'
        : 'Your company settings has been updated';
    dispatch(
      updateCompanyProfile({
        company: updatedCompany,
        onSuccess: () => {
          dispatch(showGlobalToast(text));
        },
      })
    );
  };
  const handleColourChange = (selectedColour: string) => {
    setColour(selectedColour);
    if (checkValidColour(selectedColour)) {
      saveCompanyDetails({
        ...company,
        brandingColor: selectedColour,
      });
    }
  };

  return (
    <div className={baseCss}>
      <Panel>
        <div className={baseCss + '--header'}>
          <img alt="Adoptech small logo" src={adoptechA} />
          <div className={baseCss + '--title'}>Company branding</div>
        </div>

        <div className={baseCss + '--row'}>
          <div>Company logo</div>
          <div>
            {logo?.filename ? (
              <img src={logo.urls.thumb} />
            ) : (
              'no logo uploaded'
            )}
          </div>
          <div className={baseCss + '--actions'}>
            {canEdit && (
              <IconButton
                ariaLabel="Upload company logo"
                className={baseCss + '--icon'}
                icon={faCloudUpload}
                onClick={() => dispatch(showUploadLogoModal())}
                tooltip="Upload"
              />
            )}
          </div>
        </div>

        <div className={baseCss + '--row'}>
          <div>Company colour</div>
          <div className={baseCss + '--colourCell'}>
            <div className={baseCss + '--colourSample'}>
              {isDisplayableColour(colour) ? (
                <FontAwesomeIcon
                  className={baseCss + '--iconShadow'}
                  fontSize={20}
                  color={colour}
                  icon={faCircle}
                />
              ) : (
                'no colour selected'
              )}
            </div>
            {canEdit && (
              <>
                <AdoptechTextInput
                  errorClass={baseCss + '--error'}
                  hasError={!checkValidColour(colour)}
                  id="colourInput"
                  onChange={e => {
                    handleColourChange(e.target.value);
                  }}
                  value={colour}
                  type="text"
                  placeholder="#FFaa33"
                  additionalClass={baseCss + '--colourInput'}
                />
                <ColourPalette
                  onSelect={(selectedColour: string) => {
                    handleColourChange(selectedColour);
                  }}
                  gap="10px"
                />
              </>
            )}
          </div>
        </div>
        <UploadLogoModal vendorId={vendorId} />
        <MessageToast
          autohide
          delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
          onClose={() => dispatch(hideLogoUploadSuccessToast())}
          show={isLogoUploadSuccessToastShowing}
        >
          Your logo has been successfully uploaded.
        </MessageToast>
      </Panel>
    </div>
  );
};
