import React, { useEffect, useState } from 'react';
import './ExportEvidenceDrawer.scss';
import AdoptechHS from '../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { postFrameworkEvidenceExport } from '../../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../../types/applicationState';
import { useSelector } from 'react-redux';
import { AdoptechDatePicker2 } from '../../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import { getDate } from '../../../../functions/getDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import moment from 'moment';

interface ExportEvidenceDrawerProps {
  onClose: () => void;
  frameworkId: string;
  setShowExportEvidenceModal: (show: boolean) => void;
}

interface ExportEvidenceFormData {
  startDate: string;
  endDate: string;
}
export const ExportEvidenceDrawer: React.FC<
  ExportEvidenceDrawerProps
> = props => {
  const [formData, setFormData] = useState<ExportEvidenceFormData>({
    startDate: null,
    endDate: null,
  });
  const { postFrameworkEvidenceExportStatus } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const handleClose = () => props.onClose();
  const dispatch = useAppDispatch();
  const handleExportClick = async () => {
    await dispatch(
      postFrameworkEvidenceExport({
        id: props.frameworkId,
        body: formData,
      })
    );
    handleClose();
    props.setShowExportEvidenceModal(true);
  };
  const baseCss = 'exportEvidenceDrawer';

  const handleStartDateChange = (value: Date) => {
    setFormData({
      ...formData,
      startDate: moment(value).endOf('day').toJSON().split('T')[0],
    });
  };

  const handleEndDateChange = (value: Date) => {
    setFormData({
      ...formData,
      endDate: moment(value).endOf('day').toJSON().split('T')[0],
    });
  };

  const startDate = formData.startDate ? new Date(formData.startDate) : null;
  const endDate = formData.endDate ? new Date(formData.endDate) : null;

  useEffect(() => {
    if (endDate && endDate < startDate)
      setFormData({ ...formData, endDate: null });
  }, [startDate]);

  return (
    <AdoptechHS
      extraClass="exportEvidenceHS"
      title="Export evidence"
      show
      noFormPadding
      onClose={handleClose}
      showConfirmationWarning={false}
      footer={
        <>
          <AdoptechButton
            onClick={handleClose}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleExportClick}
            variant={AdoptechButtonVariant.Primary}
            disabled={!formData.startDate || !formData.endDate}
            busy={postFrameworkEvidenceExportStatus === 'loading'}
          >
            Export
          </AdoptechButton>
        </>
      }
    >
      <div className={baseCss}>
        <div className={baseCss + '--formContainer'}>
          <div className={baseCss + '--fieldBlock'}>
            <div className={baseCss + '--fieldTitle'}>Select date range</div>
            <div className={baseCss + '--fieldLabel'}>
              Please select the specific date range for your evidence export.
            </div>
            <div className={baseCss + '--2-columns'}>
              <AdoptechDatePicker2
                hasError={!formData.startDate}
                id={`${baseCss}FromAt`}
                labelText="From"
                labelStyle={baseCss + '--date-label'}
                minDate={null}
                deadLines={[]}
                maxDate={new Date()}
                rounded
                outputDate={startDate}
                onDateSelect={handleStartDateChange}
                placeHolderText="Select date"
              />
              <AdoptechDatePicker2
                hasError={!formData.endDate}
                id={`${baseCss}ToAt`}
                labelText="To"
                labelStyle={baseCss + '--date-label'}
                minDate={startDate}
                rounded
                deadLines={[]}
                maxDate={new Date()}
                outputDate={endDate}
                onDateSelect={handleEndDateChange}
                placeHolderText="Select date"
              />
            </div>
          </div>
          <div className={baseCss + '--divider'}></div>
          <div className={baseCss + '--fieldInfoBlock'}>
            <div className={baseCss + '--fieldBlockIcon'}>
              <FontAwesomeIcon icon={faServer} />
            </div>
            <div className={baseCss + '--fieldBlockText'}>
              <b>Please note</b>
              <div>
                Compiling evidence can take some time. We will send you an email
                to let you know that you can down load the file from the{' '}
                <b>Data Room.</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdoptechHS>
  );
};
