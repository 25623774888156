import classNames from 'classnames';
import React, { useRef } from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import AdoptechHS from '../AdoptechHS/AdoptechHS';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './AddLegislationDrawer.scss';
import { useAddLegislationController } from './useAddLegislationController';

interface FooterProps {
  onClose: () => void;
  onSubmit: () => void;
  disabled: boolean;
  isSubmitting: boolean;
}

const Footer: React.FC<FooterProps> = ({
  onClose,
  disabled,
  onSubmit,
  isSubmitting,
}) => {
  return (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={disabled}
        onClick={onSubmit}
        variant={AdoptechButtonVariant.Primary}
        busy={isSubmitting}
      >
        Add
      </AdoptechButton>
    </>
  );
};
interface AddLegislationDrawerProps {
  onClose: () => void;
}
export const AddLegislationDrawer: React.FC<AddLegislationDrawerProps> = ({
  onClose,
}) => {
  const {
    onJurisdictionChange,
    onSelectAllTemplatesChange,
    onSelectTemplateChange,
    selectOptions,
    submitButtonDisabled,
    onSubmit,
    isLoading,
    isSubmitting,
    form,
    selectedEmptyJurisdictionsIds,
    jurisdictions,
    onCloseAndCleanup,
  } = useAddLegislationController({ onClose });

  const baseCss = 'addLegislationDrawer';
  const hasJurisdictions = jurisdictions.length > 0;
  const drawerBackgroundRef = useRef<HTMLDivElement>();

  return (
    <AdoptechHS
      title="Select Legislation"
      show
      ref={drawerBackgroundRef}
      showConfirmationWarning={form.isTouched}
      onClose={onCloseAndCleanup}
      footer={
        <Footer
          onClose={() => drawerBackgroundRef.current.click()}
          disabled={submitButtonDisabled}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      }
      extraClass="adoptechHS--medium"
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className="addLegislationDrawer">
          {hasJurisdictions && (
            <>
              <div className={baseCss + '--label'}>
                Select the jurisdiction(s) of the legislation to be displayed
              </div>
              <div className={classNames(`${baseCss}--fieldRow`)}>
                <div
                  className={classNames(
                    baseCss + '--field',
                    baseCss + '--add-jurisdictions'
                  )}
                >
                  {selectOptions.map(option => {
                    return (
                      <AdoptechCheckbox
                        key={option.value}
                        color="white"
                        checked={(form.selectedJurisdictionsIds || []).includes(
                          option.value
                        )}
                        id={`checkbox-${option.value}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const ids = form.selectedJurisdictionsIds || [];
                          const newIds = e.target.checked
                            ? [...ids, option.value]
                            : ids.filter(id => id !== option.value);
                          onJurisdictionChange(newIds);
                        }}
                        label={option.label}
                        className="add-jurisdictions-checkbox"
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
          <div className={baseCss + '--fieldRow'}>
            {!hasJurisdictions &&
              'All jurisdictions are already used. Please remove legislation in the table or contact to Administrator'}

            {(form.selectedJurisdictionsIds || []).map(jurisdictionId => {
              const jurisdiction = jurisdictions.find(
                entity => entity.id === jurisdictionId
              );
              const jurisdictionTemplates =
                form.legislationTemplates?.filter(
                  template => template.jurisdiction?.id === jurisdiction.id
                ) || [];

              return (
                <Accordion
                  key={jurisdiction.id}
                  defaultActiveKey={jurisdiction.id}
                >
                  <AdoptechAccordionCard
                    title={jurisdiction.name}
                    index={jurisdiction.id}
                    cardBodyClass="card-body--centered pt-2"
                    headerClass="positionInitial"
                    iconSize="small"
                    hasError={
                      form.isTouched &&
                      selectedEmptyJurisdictionsIds.includes(jurisdiction.id)
                    }
                    noMargin
                  >
                    {(form.legislationTemplates || []).length <= 0 &&
                      `There is no available Legislations in ${jurisdiction.name}`}

                    {(form.legislationTemplates || []).length >= 0 && (
                      <AdoptechCheckbox
                        key={jurisdiction.id + 'select-all'}
                        color="white"
                        checked={jurisdictionTemplates.every(template =>
                          (form.selectedLegislationTemplatesIds || []).includes(
                            template.id
                          )
                        )}
                        id={jurisdiction.id + 'select-all'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onSelectAllTemplatesChange(e, jurisdictionTemplates)
                        }
                        disabled={false}
                        label="Select All"
                        className="select-all-jurisdictions"
                      />
                    )}
                    {jurisdictionTemplates.map(template => {
                      return (
                        <AdoptechCheckbox
                          key={template.id}
                          color="white"
                          checked={(
                            form.selectedLegislationTemplatesIds || []
                          ).includes(template.id)}
                          id={`checkbox-${template.id}`}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onSelectTemplateChange(e, template)
                          }
                          label={template.name}
                          className="select-jurisdictions"
                        />
                      );
                    })}
                  </AdoptechAccordionCard>
                </Accordion>
              );
            })}
          </div>
        </div>
      )}
    </AdoptechHS>
  );
};
