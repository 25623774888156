import React from 'react';
import { PaymentPlanCard } from './PaymentPlanCard/PaymentPlanCard';
import { portalRoutes } from '../../../components/Routes/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectActiveNotOutdatedSubscription } from '../../../selectors/selectActiveSubscription';
import { selectPaymentPlans } from '../../../selectors/selectPaymentPlans';

export const PaymentPlanCards: React.FC = () => {
  const baseCss = 'manageSubscriptionPage';
  const paymentPlans = useSelector(selectPaymentPlans);
  const activeSubscription = useSelector(selectActiveNotOutdatedSubscription);
  return (
    <>
      <div className={baseCss + '--plansCards'}>
        {[...paymentPlans]
          .sort((a, b) => a.rank - b.rank)
          .map(paymentPlan => {
            return (
              <PaymentPlanCard
                key={paymentPlan?.id}
                isActive={
                  activeSubscription &&
                  activeSubscription.paymentPlanId === paymentPlan.id
                }
                plan={paymentPlan}
              />
            );
          })}
      </div>
      <div className={baseCss + '--plansDetailsLinks'}>
        <a href={portalRoutes.prices} target="_blank" rel="noreferrer">
          View full description of each plan
          <FontAwesomeIcon icon={faExternalLink} />
        </a>
      </div>
    </>
  );
};
