import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import { getMetaContent } from '../../functions/getMetaContent';
import {
  CompanyIdentifierModelTypeEnum,
  RegisteredCompany,
  Vendor,
  VendorsPostRequest,
} from '../../swagger';
import { UNKNOWN_ERROR } from '../../types/constants';
import { VoidThunk } from '../../types/voidThunk';
import { setCurrentVendor } from '../vendors/vendorsSlice';
import { fetchVendors } from '../vendors/vendorsThunks';
import {
  companyValidationFailure,
  searchCompaniesFailure,
  searchCompaniesRequest,
  searchCompaniesSuccess,
  showCompanyValidationFailureModal,
} from './welcomeSlice';
import { push } from 'connected-react-router';
import { getErrorMessage, handleThunkError } from '../helpers/thunkHelpers';
import {
  dashboardRoute,
  manageSubscriptionRoute,
} from '../../components/Routes/Routes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { WelcomeState } from './welcomeState';
import { ApplicationState } from '../../types/applicationState';

const companyParams = (state: any) => {
  const { registeredCompany, companyIdentifierType, country, ukBased, office } =
    state;

  const offices = ukBased
    ? undefined
    : [
        {
          ...office,
          country,
          name: 'Registered address',
        },
      ];

  const company: any = {
    name: registeredCompany.name,
    legalName: registeredCompany.name,
    registeredAddress: registeredCompany.registeredAddress,
    countryOfIncorporation: country,
    offices,
  };
  if (registeredCompany.identifier) {
    company.companyIdentifiers = [
      {
        identifier: registeredCompany.identifier,
        type: ukBased
          ? CompanyIdentifierModelTypeEnum.Ukcrn
          : companyIdentifierType,
      },
    ];
  }
  return company;
};

type CompanyValidationError = string | false;

const checkCompany = async (company: any): Promise<CompanyValidationError> => {
  try {
    await api().companiesValidatePost({
      id: '',
      body: company,
    });
  } catch (err) {
    if (err instanceof Response) {
      const error = await getApiErrorMessage(err);
      console.log(error);
      if (err.status === 422) {
        return error;
      }
    }
  }
  return false;
};

export const checkCompanyAndDispatch =
  (callback: any): VoidThunk =>
  async (dispatch, getState) => {
    const company = companyParams(getState().welcome);
    const error: CompanyValidationError = await checkCompany(company);
    if (error) {
      dispatch(companyValidationFailure(error));
      dispatch(showCompanyValidationFailureModal());
    } else {
      dispatch(callback);
    }
  };

export const postVendor = createAsyncThunk(
  'welcome/postVendor',
  async (_params, { dispatch, getState }) => {
    try {
      const welcomeState = (getState() as ApplicationState).welcome;

      const {
        registeredCompany: { name },
        linkedInUrl: linkedin,
        twitterUrl: twitter,
        websiteUrl: website,
      } = welcomeState;

      const vendor: Vendor = {
        name,
        linkedin,
        twitter,
        website,
      };

      const { companyIdentifiers, ...company } = companyParams(welcomeState);

      const params: VendorsPostRequest = {
        body: {
          vendor,
          company,
          companyIdentifiers,
        },
      };
      const rawResponse = await api().vendorsPostRaw(params);
      const vendorResponse = await rawResponse.value();
      const redirectLocation = rawResponse.raw.headers.get('Location');

      dispatch(setCurrentVendor(vendorResponse));
      window.location.href = redirectLocation || manageSubscriptionRoute;
      // deprecated
      // `${manageSubscriptionRoute}?showEnter=1`;
    } catch (error) {
      await handleThunkError(
        'An error occurred creating your profile. Please try again or contact support.',
        { dispatch, error, showError: false }
      );
    }
  }
);

export const searchCompanies =
  (query: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(searchCompaniesRequest());
      const response = await Promise.race([
        api().searchRegisteredCompaniesqqGet({ q: query }),
        new Promise<RegisteredCompany[]>(resolve =>
          setTimeout(
            () => resolve([]),
            +process.env.REACT_APP_COMPANIES_HOUSE_SEARCH_TIMEOUT
          )
        ),
      ]);
      dispatch(searchCompaniesSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(searchCompaniesFailure(apiErrorMessage));
        } else {
          dispatch(
            searchCompaniesFailure(
              'An error occurred searching companies. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(searchCompaniesFailure(UNKNOWN_ERROR));
      }
    }
  };
