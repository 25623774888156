import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JurisdictionModel, LegislationTemplateModel } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { updateAddLegislationForm } from '../../store/legalRegister/legalRegisterSlice';
import {
  createLegislationFromTemplates,
  fetchJurisdictions,
  fetchLegalRegister,
  fetchLegislationTemplates,
} from '../../store/legalRegister/legalRegisterThunks';

export interface AddLegislationForm {
  jurisdictions: JurisdictionModel[];
  selectedJurisdictionsIds: Array<JurisdictionModel['id']>;
  isTouched: boolean;
  legislationTemplates: LegislationTemplateModel[];
  selectedLegislationTemplatesIds: Array<LegislationTemplateModel['id']>;
}

type Field = {
  [key in keyof AddLegislationForm]?: AddLegislationForm[keyof AddLegislationForm];
};

export const useAddLegislationController = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  useEffect(() => {
    dispatch(fetchJurisdictions());
    dispatch(fetchLegislationTemplates());
  }, []);

  const dispatch = useDispatch();

  const {
    addLegislationForm: form,
    isRequestingFetchLegislationTemplates,
    isRequestingFetchJurisdictions,
    isRequestingCreateLegislationFromTemplates: isSubmitting,
  } = useSelector((state: ApplicationState) => state.legalRegister);

  const isLoading =
    isRequestingFetchLegislationTemplates || isRequestingFetchJurisdictions;

  const updateFields = (fields: Field[]) => {
    const payload = {
      ...form,
      isTouched: true,
      ...Object.assign({}, ...fields),
    };

    dispatch(updateAddLegislationForm(payload));
  };

  const onJurisdictionChange = (
    selectedJurisdictionsIds: JurisdictionModel['id'][]
  ) => {
    // For new jurisdiction select all jurisdiction templates.
    // For existing jurisdiction select existing templates
    const selectedLegislationTemplatesIds = selectedJurisdictionsIds
      .map(jurisdictionId => {
        const alreadySelected = (form.selectedJurisdictionsIds || []).includes(
          jurisdictionId
        );

        if (alreadySelected) {
          const selectedIdsForJurisdiction =
            form.selectedLegislationTemplatesIds.filter(templateId => {
              return (
                form.legislationTemplates.find(
                  template => template.id === templateId
                ).jurisdiction?.id === jurisdictionId
              );
            });

          return selectedIdsForJurisdiction;
        }

        const allTemplatesIds = form.legislationTemplates
          .filter(template => template.jurisdiction?.id === jurisdictionId)
          .map(template => template.id);

        return allTemplatesIds;
      })
      .flat();

    updateFields([
      { selectedJurisdictionsIds },
      { selectedLegislationTemplatesIds },
    ]);
  };

  const onSelectAllTemplatesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    jurisdictionTemplates: LegislationTemplateModel[]
  ) => {
    {
      const jurisdictionTemplatesIds = jurisdictionTemplates.map(
        template => template.id
      );
      const ids =
        form.selectedLegislationTemplatesIds?.filter(
          templateId => !jurisdictionTemplatesIds.includes(templateId)
        ) || [];
      const updatedIds = e.target.checked
        ? ids.concat(jurisdictionTemplatesIds)
        : ids;

      updateFields([
        {
          selectedLegislationTemplatesIds: updatedIds,
        },
      ]);
    }
  };

  const onSelectTemplateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    template: LegislationTemplateModel
  ) => {
    const { checked } = e.target;
    const ids = (form.selectedLegislationTemplatesIds || []).filter(
      templateId => templateId !== template.id
    );
    const updatedIds = checked ? ids.concat(template.id) : ids;

    updateFields([{ selectedLegislationTemplatesIds: updatedIds }]);
  };

  const jurisdictionsIds = form.selectedJurisdictionsIds || [];

  const selectedEmptyJurisdictionsIds = jurisdictionsIds.filter(
    jurisdictionId => {
      const templates =
        form.selectedLegislationTemplatesIds?.filter(
          templateId =>
            form.legislationTemplates.find(
              template => template.id === templateId
            ).jurisdiction?.id === jurisdictionId
        ) || [];
      return templates.length === 0;
    }
  );

  const submitButtonDisabled =
    !form.isTouched ||
    jurisdictionsIds.length === 0 ||
    selectedEmptyJurisdictionsIds.length > 0;

  const onCloseAndCleanup = () => {
    onClose();
    dispatch(updateAddLegislationForm({} as AddLegislationForm));
  };
  const updateStats = () => {
    dispatch(fetchLegalRegister());
  };
  const onSubmit = () => {
    dispatch(
      createLegislationFromTemplates(
        {
          legislationTemplateIds: form.selectedLegislationTemplatesIds,
        },
        () => {
          updateStats();
          onCloseAndCleanup();
        }
      )
    );
  };

  const notEmptyJurisdictions =
    form.jurisdictions?.filter(jurisdiction => {
      return (form.legislationTemplates || []).some(
        template => template.jurisdiction?.id === jurisdiction.id
      );
    }) || [];

  const selectOptions = notEmptyJurisdictions.map(
    (jurisdiction: JurisdictionModel) => {
      return {
        value: jurisdiction.id,
        label: jurisdiction.name,
      };
    }
  );

  return {
    onJurisdictionChange,
    onSelectAllTemplatesChange,
    onSelectTemplateChange,
    selectOptions,
    submitButtonDisabled,
    onSubmit,
    isLoading,
    isSubmitting,
    form,
    onCloseAndCleanup,
    selectedEmptyJurisdictionsIds,
    jurisdictions: notEmptyJurisdictions,
  };
};
