import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/pro-light-svg-icons/faLeaf';
import './PoliciesSection.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { faLockAlt, faPencil } from '@fortawesome/pro-light-svg-icons';
import { TrustHubSectionProps } from '../CompanyDetailsSection/CompanyDetailsSection';
import classNames from 'classnames';
import { useTrustHubSection } from '../useTrustHubSection';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { PoliciesEditSection } from '../admin/PoliciesEditSection/PoliciesEditSection';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';

export const PoliciesSection: React.FC<TrustHubSectionProps> = ({
  isEditable,
  showDocumentDownloadIcon,
  sections,
  onDocumentClick,
  identifier,
}) => {
  const { isEditMode, setIsEditMode, section, publicDocs, titleBar, baseCss } =
    useTrustHubSection({
      identifier,
      sections,
      isEditable,
    });

  const { fetchVendorDocumentFileStatus, fetchingVendorDocumentIdFile } =
    useSelector((state: ApplicationState) => state.trustHub);
  if (!section) return null;

  return (
    <div id={baseCss} className={classNames(baseCss)}>
      {titleBar}
      {isEditMode ? (
        <PoliciesEditSection
          baseCss={baseCss}
          section={section}
          onClose={() => setIsEditMode(false)}
        />
      ) : (
        <div className={baseCss + '--panel'}>
          <div className={baseCss + '--descriptionRow'}>
            <div className={baseCss + '--description'}>
              {section.description}
            </div>
            {isEditable && (
              <div>
                <AdoptechButton
                  extraClass="editButton"
                  icon={faPencil}
                  onClick={() => setIsEditMode(true)}
                  variant={AdoptechButtonVariant.White}
                >
                  Edit
                </AdoptechButton>
              </div>
            )}
          </div>
          {publicDocs.length == 0 && (
            <div className={baseCss + '--noContent'}>
              No policies selected to display.
            </div>
          )}
          {publicDocs.length > 0 && (
            <div className={baseCss + '--documents'}>
              {publicDocs.map(doc => {
                return (
                  <div
                    key={doc.name}
                    className={baseCss + '--document'}
                    onClick={() => onDocumentClick(doc)}
                  >
                    <FontAwesomeIcon
                      icon={
                        identifier === 'compliance' ? faCheckCircle : faLeaf
                      }
                    />

                    <div className={baseCss + '--documentTitle'}>
                      {doc.name}
                    </div>
                    {fetchVendorDocumentFileStatus === 'loading' &&
                    fetchingVendorDocumentIdFile === doc.id ? (
                      <LoadingSpinner inlineSmall />
                    ) : (
                      <FontAwesomeIcon
                        icon={doc.restricted ? faLockAlt : faCloudDownloadAlt}
                        className={classNames({
                          invisible: !showDocumentDownloadIcon,
                        })}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
