import React, { useEffect, useState } from 'react';
import './StartProfile.scss';
import { StartLayout } from '../StartLayout/StartLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../../unauthenticated/src/store/signup/signupSlice';
import { SignupPage } from '../../../../unauthenticated/src/types/signupPage';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { UserDetails } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { isValidEmailAddress } from '../../../functions/isValidEmailAddress';
import {
  fetchUserDetails,
  updateProfile,
} from '../../../store/user/userThunks';
import { showCancelSignUpModal } from '../../../store/welcome/welcomeSlice';
import { CancelSignUpModal } from '../../CancelSignUpModal/CancelSignupModal';
import { EditProfileFields } from '../../EditProfileFields/EditProfileFields';
import { StartTestimonialsPanel } from '../StartTestimonialsPanel/StartTestimonialsPanel';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

const StartProfile: React.FC = () => {
  const [userDetails, setUserDetails] = useState<UserDetails>();

  const userState = useSelector((state: ApplicationState) => state.user);

  const isUpdating = useSelector(
    (state: ApplicationState) => state.user.isUpdatingUserDetails
  );

  const isFetchingUserDetails = useSelector(
    (state: ApplicationState) => state.user.isFetchingUserDetails
  );

  const isValid = userDetails && isValidEmailAddress(userDetails.email);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(SignupPage.CreateProfile));
    dispatch(fetchUserDetails());
  }, []);

  useEffect(() => {
    setUserDetails(
      userState.userDetails ? userState.userDetails : { email: '' }
    );
  }, [userState]);

  return (
    <>
      <StartLayout rightHandPanel={<StartTestimonialsPanel />}>
        <div className="startProfile">
          <div className="startProfile--title">Create your user profile</div>
          <div className="startProfile--prompt">
            Let's set up your profile as you want it to appear on the documents
            you create.
          </div>
          {isFetchingUserDetails && <LoadingSpinner />}
          {!isFetchingUserDetails && (
            <>
              <div className="startProfile--profileFields">
                {userDetails && (
                  <EditProfileFields
                    onChange={setUserDetails}
                    userDetails={userDetails}
                    disabledFields={['email']}
                  />
                )}
              </div>
              <div className="startProfile--actions">
                <AdoptechButton
                  disabled={isUpdating}
                  onClick={() => dispatch(showCancelSignUpModal())}
                >
                  Cancel
                </AdoptechButton>
                <AdoptechButton
                  busy={isUpdating}
                  disabled={!isValid}
                  onClick={() => dispatch(updateProfile(userDetails, true))}
                  variant={AdoptechButtonVariant.Primary}
                  uppercase
                >
                  Next
                </AdoptechButton>
              </div>
            </>
          )}
        </div>
      </StartLayout>

      <CancelSignUpModal />
    </>
  );
};

export default StartProfile;
