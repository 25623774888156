import React from 'react';
import { PoliciesTabs } from '../PoliciesTabs/PoliciesTabs';
import { DataRoomSelectFolderTab } from '../../../components/dataRoom/DataRoomSelectFolderTab/DataRoomSelectFolderTab';
import PoliciesPageTables from '../PoliciesPageTables/PoliciesPageTables';

export const DraftPoliciesPage: React.FC = () => {
  return (
    <div>
      <PoliciesTabs currentTab="draft" />
      <PoliciesPageTables />
    </div>
  );
};
