import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canFeature, canIndex } from '../../../functions/access';
import commaSeparate from '../../../functions/commaSeparate';
import { sortVendorUserRoles } from '../../../functions/sortVendorUserRoles';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { selectUsers } from '../../../selectors/selectUsers';
import {
  fetchVendorTeams,
  fetchVendorUsers,
  sendInvitation,
} from '../../../store/vendors/vendorsThunks';
import {
  SharedUserType,
  SharedVendorUserStatus,
  VendorIntegrationModelStatusEnum,
  VendorIntegrationSyncModelStateEnum,
  VendorUser,
} from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { DeleteUserModal } from '../../../components/DeleteUserModal/DeleteUserModal';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../../../components/Lozenge/Lozenge';
import { ManagePeopleDrawer } from '../ManagePeopleDrawer/ManagePeopleDrawer';
import { ManageTeamsDrawer } from '../ManageTeamsDrawer/ManageTeamsDrawer';
import { RoleTag } from '../RoleTag/RoleTag';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import './People.scss';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { fetchIntegrations } from '../../../store/integrations/integrationsSlice';
import { selectActiveSubscription } from '../../../selectors/selectActiveSubscription';
import { showSubscriptionRequiredModal } from '../../../store/global/globalSlice';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons/faUsersClass';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck';
import { SyncUserDrawer } from '../SyncUserDrawer/SyncUserDrawer';
import { hasUserSync } from '../../../selectors/selectCurrentUsersSync';
import { peopleRoute } from '../../../components/Routes/Routes';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { reactSelectLightCheckboxStyle } from '../../../functions/reactSelectCustomTheme';
import { PeopleTabs } from '../PeopleTabs/PeopleTabs';
import { userTypeOptions } from '../PeopleFilters/options';
import { filterPeople } from '../PeopleFilters/functions';
import useFilterByUserTypes from './useFilterByUserType';
import { capitalize } from '../../../functions/capitalize';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';

const options = {
  ignoreLocation: true,
  includeScore: true,
  keys: ['fullName', 'position', 'roles', 'vendorTeams.name'],
  threshold: 0,
};

export const People: React.FC = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: ApplicationState) =>
    selectUsers(state, { excludeLeft: false })
  );
  const canManageUsers = canFeature(AccessObject.people_manage);
  const currentVendor = useSelector(selectCurrentVendor);
  const [selectedUser, setSelectedUser] = useState<VendorUser>(null);
  const [search, setSearch] = useState('');
  const [isShowingTeamsDrawer, setIsShowingTeamsDrawer] = useState(false);
  const [isShowingPeopleDrawer, setIsShowingPeopleDrawer] = useState(false);
  const [isShowingDeleteUserModal, setIsShowingDeleteUserModal] =
    useState(false);
  const [isShowingSyncUserDrawer, setIsShowingSyncUserDrawer] = useState(false);
  const canIndexTeams = canIndex(AccessObject.vendor_teams);

  useEffect(() => {
    canIndexTeams && dispatch(fetchVendorTeams(true));
    dispatch(fetchIntegrations());
  }, [currentVendor?.id]);

  let filteredPeople: VendorUser[] = users;
  const fuseAp = new Fuse(filteredPeople, options);
  filteredPeople = search
    ? fuseAp.search(search).map(x => x.item)
    : filteredPeople;

  const { isFetchingVendorUsers } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  const { list: integrations, status: integrationsStatus } = useSelector(
    (state: ApplicationState) => state.integrations
  );

  const loadingIntegrations = integrations.length === 0;

  const currentSyncs = integrations
    .filter(
      integration =>
        integration.status === VendorIntegrationModelStatusEnum.Active
    )
    .map(integration =>
      integration.vendorIntegrationSyncs?.filter(sync => hasUserSync(sync))
    )
    .flat();

  const noSyncs = currentSyncs.length < 1;
  const hasIdle = currentSyncs.some(
    sync => sync.state === VendorIntegrationSyncModelStateEnum.Idle
  );
  const hasInProgress = currentSyncs.some(
    sync => sync.state === VendorIntegrationSyncModelStateEnum.InProgress
  );
  const stateColour = noSyncs ? '#CCCCCC' : hasIdle ? '#A8D63F' : '#EA5542';

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const hasInProgressRef = useRef(hasInProgress);
  hasInProgressRef.current = hasInProgress;

  const timerRef = useRef(timer);
  timerRef.current = timer;

  // polling sync state
  useEffect(() => {
    const clearTimer = () =>
      timerRef.current && clearInterval(timerRef.current);
    const startNewTimer = () => {
      clearTimer();
      const newTimer = setTimeout(async () => {
        if (
          !window.location.href.includes(peopleRoute) ||
          !hasInProgressRef.current // use ref.current instead of hasInProgress to get latest state value in recursive call
        ) {
          clearTimer();
          return;
        }

        await dispatch(fetchIntegrations());
        await dispatch(
          fetchVendorUsers(currentVendor?.id, {
            silent: true,
          })
        );

        // use recursive setTimeout to wait API requests ( setInterval doesn't wait )
        startNewTimer();
      }, 5000);

      setTimer(newTimer);
    };

    hasInProgress && integrationsStatus !== 'loading'
      ? startNewTimer()
      : clearTimer();

    return () => {
      clearTimer();
    };
  }, [hasInProgress, integrationsStatus]);

  const onFreePlan = !useSelector(selectActiveSubscription);
  const userSynchronisationClick = () => {
    if (onFreePlan) {
      dispatch(showSubscriptionRequiredModal());
    } else {
      setIsShowingSyncUserDrawer(true);
    }
  };

  const [showLeft, setShowLeft] = useState<boolean>(false);
  const { filterUserTypes, handleChange, filteredOptions, selectedOptions } =
    useFilterByUserTypes(userTypeOptions);

  const baseCss = 'peopleTable';
  const baseTableCss = 'adoptechGridTable';

  filteredPeople = filterPeople(filteredPeople, showLeft, filterUserTypes);
  const handleRowClick = (
    e: { stopPropagation: () => void },
    user: VendorUser
  ) => {
    e.stopPropagation();
    setSelectedUser(user);
    setIsShowingPeopleDrawer(true);
  };
  return (
    <div className="people">
      <ManageTeamsDrawer
        show={isShowingTeamsDrawer}
        onClose={() => setIsShowingTeamsDrawer(false)}
      />
      {isShowingPeopleDrawer && (
        <ManagePeopleDrawer
          user={selectedUser}
          onClose={() => {
            setSelectedUser(null);
            setIsShowingPeopleDrawer(false);
          }}
        />
      )}
      <SyncUserDrawer
        show={isShowingSyncUserDrawer}
        close={() => {
          setIsShowingSyncUserDrawer(false);
        }}
        inProgress={hasInProgress}
      />
      {isShowingDeleteUserModal && (
        <DeleteUserModal
          vendorId={currentVendor?.id}
          user={selectedUser}
          close={() => {
            setSelectedUser(null);
            setIsShowingDeleteUserModal(false);
          }}
        />
      )}
      <div className="people--sticky">
        <PeopleTabs currentTab="profile" />
        <div className="people--header">
          <div className="people--title">
            People
            <Lozenge intent={Intent.None} value={filteredPeople.length} />
          </div>
          <div className="people--divider" />
          <AdoptechCheckbox
            color="white"
            checked={showLeft}
            id="checkbox-people-show-left"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowLeft(e.target.checked)
            }
            label="Show left users"
            className="checkbox-show-left"
          />
          <div className="people--divider" />
          <div className="people--userType">
            <div className="people--userTypeLabel">User type</div>
            <div className="people--userTypeSelector">
              <AdoptechReactSelect
                id="userTypeSelect"
                isMulti
                isCheckbox
                options={filteredOptions}
                onChange={handleChange}
                value={selectedOptions}
                components={{
                  ClearIndicator: (): JSX.Element => null,
                }}
                placeholder="No selection"
                additionalStyling={reactSelectLightCheckboxStyle}
              />
            </div>
          </div>
          {canManageUsers && (
            <div className="people--controls">
              <AdoptechButton
                onClick={() => setIsShowingTeamsDrawer(true)}
                variant={AdoptechButtonVariant.Default}
                rounded
                iconSize="large"
                icon={faUsersClass}
              >
                Manage groups
              </AdoptechButton>
              <AdoptechButton
                onClick={userSynchronisationClick}
                variant={AdoptechButtonVariant.Default}
                icon={faUserCheck}
                iconSize="large"
                rounded
              >
                User sync
                {loadingIntegrations || hasInProgress ? (
                  <LoadingSpinner inlineSmall />
                ) : (
                  <FontAwesomeIcon
                    fontSize="12px"
                    color={stateColour}
                    icon={faCircle}
                  />
                )}
              </AdoptechButton>
              <AdoptechButton
                onClick={() => setIsShowingPeopleDrawer(true)}
                variant={AdoptechButtonVariant.PrimaryOrange}
                icon={faPlus}
                iconSize="large"
                rounded
              >
                Add people
              </AdoptechButton>
            </div>
          )}
        </div>
        <div className="people--search">
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
            type="text"
            placeholder="Search"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
      </div>

      <div className={baseCss + ' ' + baseCss + '--6-columns'}>
        <AdoptechGridTable
          header={
            <div className={baseTableCss + '--header'}>
              <SortableTableHeader<VendorUser>
                columnName="fullName"
                grid={Grid.Users}
                label="Name"
                notInTable
              />
              <SortableTableHeader<VendorUser>
                columnName="position"
                grid={Grid.Users}
                label="Job title"
                notInTable
              />
              <SortableTableHeader<VendorUser>
                columnName="userType"
                grid={Grid.Users}
                label="User type"
                notInTable
              />
              <div>Groups</div>
              {canManageUsers && <div>Permissions</div>}
              <SortableTableHeader<VendorUser>
                columnName="employmentStatus"
                grid={Grid.Users}
                label="Status"
                notInTable
              />
            </div>
          }
        >
          {isFetchingVendorUsers ? (
            <div className={baseCss + ' ' + baseTableCss + '--row d-flex'}>
              <LoadingSpinner />
            </div>
          ) : (
            filteredPeople.map(user => {
              const {
                id,
                employmentStatus,
                status,
                roles,
                position,
                firstName,
                lastName,
                email,
                userType,
              } = user;
              const sortedRoles = [...roles].sort(sortVendorUserRoles);

              return (
                <div
                  key={id}
                  onClick={e => {
                    handleRowClick(e, user);
                  }}
                  className={baseCss + ' ' + baseTableCss + '--row '}
                >
                  <AdoptechOverflowLine>
                    <UserAvatar user={user} size="large" />
                  </AdoptechOverflowLine>
                  <AdoptechOverflowLine>{position}</AdoptechOverflowLine>
                  <div>{capitalize(userType)}</div>
                  <AdoptechOverflowLine>
                    {commaSeparate(user.vendorTeams, 'name')}
                  </AdoptechOverflowLine>
                  {canManageUsers && (
                    <AdoptechOverflowLine className="people--rolesContainer">
                      {sortedRoles?.length > 3 ? (
                        <>
                          {sortedRoles.slice(0, 2).map(role => (
                            <RoleTag key={role} role={role} />
                          ))}
                          <RoleTag key="more" count={sortedRoles.length - 2} />
                        </>
                      ) : (
                        sortedRoles.map(role => (
                          <RoleTag key={role} role={role} />
                        ))
                      )}
                    </AdoptechOverflowLine>
                  )}
                  <div>
                    {user.userType === SharedUserType.SystemUser ? (
                      ''
                    ) : canManageUsers &&
                      status == SharedVendorUserStatus.Invited ? (
                      <AdoptechButton
                        onClick={e => {
                          e.stopPropagation();
                          dispatch(
                            sendInvitation({
                              firstName,
                              lastName,
                              emailAddress: email,
                              vendorId: currentVendor?.id,
                            })
                          );
                        }}
                        variant={AdoptechButtonVariant.Default}
                        icon={faEnvelope}
                      >
                        Reminder
                      </AdoptechButton>
                    ) : (
                      `${employmentStatus ? capitalize(employmentStatus) : ''}`
                    )}
                  </div>
                </div>
              );
            })
          )}
        </AdoptechGridTable>
      </div>
    </div>
  );
};
