import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { SortSetting } from '../types/sortSetting';
import {
  Access,
  DataRoomEntityModel,
  DataRoomEntityModelTypeEnum,
  UserDetails,
} from '../swagger';
import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../types/applicationState';
import sort from '../functions/dataRoomEntitySort';

export interface EntityRow {
  id: string;
  level: number;
  path: string[];
  expanded: boolean;
  type: DataRoomEntityModelTypeEnum;
  icon: IconDefinition;
  name: string;
  fileType?: string;
  createdAt: string;
  updatedAt: string;
  creator: UserDetails;
  access: Access;
}

const getIcon = (type: DataRoomEntityModelTypeEnum): IconDefinition => {
  switch (type) {
    case 'DataRoomEntity::File':
      return faFile;
    case 'DataRoomEntity::Folder':
      return faFolder;
  }
};

const entityRowsRecur = (
  entities: DataRoomEntityModel[],
  cursor: string[],
  sorting: SortSetting,
  topAccess: Access,
  parentPath: string[] = [],
  level = 0
): EntityRow[] => {
  const [head, ...tail] = [...entities].sort(sort(sorting));

  if (!head) return [];

  const id = head.id;
  const path = [...parentPath, id];
  const [cursorHead, ...cursorTail] = cursor;
  const expanded = cursorHead == id;

  topAccess = head.access ? head.access : topAccess;

  const first = {
    id,
    level,
    path,
    expanded,
    type: head.type,
    icon: getIcon(head.type),
    name: head.name,
    creator: head.creator,
    fileType: head.fileType,
    createdAt: head.createdAt,
    updatedAt: head.updatedAt,
    access: topAccess,
  };

  const nested = entityRowsRecur(
    head.children,
    cursorTail,
    sorting,
    topAccess,
    path,
    level + 1
  );
  const rest = entityRowsRecur(
    tail,
    cursor,
    sorting,
    topAccess,
    parentPath,
    level
  );

  if (expanded) {
    return [first, ...nested, ...rest];
  }

  return [first, ...rest];
};

export const selectEntityRows = (customCursor?: string[]) =>
  createSelector(
    (state: ApplicationState) =>
      state.dataRoom.entitiesTree ? [state.dataRoom.entitiesTree] : [],
    (state: ApplicationState) => customCursor || state.dataRoom.cursor,
    selectSortSettings(Grid.DataRoomTree),
    (
      entities: DataRoomEntityModel[],
      cursor: string[],
      sorting: SortSetting
    ): EntityRow[] =>
      entityRowsRecur(entities, cursor, sorting, entities[0]?.access)
  );
