import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import React from 'react';
import './InfoCard.scss';

interface InfoCardProps {
  onClose?: () => void;
  description: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  onClose = () => {},
  description = '',
}) => {
  const baseCss = 'infoCard';
  return (
    <>
      <div className={baseCss}>
        <div className={baseCss + '--icons'}>
          <FontAwesomeIcon
            size="lg"
            icon={faInfoCircle}
            className={baseCss + '--infoIcon'}
          />
        </div>
        <div className={baseCss + '--description'}>{description}</div>
        <div className={baseCss + '--buttonContainer'}>
          {' '}
          <AdoptechButton
            onClick={() => {
              if (onClose) onClose();
            }}
            extraClass={baseCss + '--button'}
            variant={AdoptechButtonVariant.White}
          >
            Got it
          </AdoptechButton>
        </div>
      </div>
    </>
  );
};
