import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ComplianceFrameworkPolicy,
  selectComplianceFrameworkPolicies,
} from '../../../selectors/selectComplianceFrameworkPolicies';
import { fetchComplianceFrameworkPolicies } from '../../../store/compliance/complianceThunks';
import { fetchPolicies } from '../../../store/policies/policiesThunks';
import { FrameworkModel } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import AdoptechTable from '../../AdoptechTable/AdoptechTable';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { governancePoliciesLibraryRoute } from '../../Routes/Routes';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import './ComplianceFrameworkPoliciesDrawer.scss';
import AdoptechHS from '../../AdoptechHS/AdoptechHS';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';

interface ComplianceFrameworkPoliciesDrawerProps {
  show: boolean;
  framework: FrameworkModel;
  onClose(): void;
}

interface ComplianceFrameworkPoliciesTableProps {
  policies: ComplianceFrameworkPolicy[];
  navigateTo(policy: ComplianceFrameworkPolicy): void;
}

const ComplianceFrameworkPoliciesTable: React.FC<
  ComplianceFrameworkPoliciesTableProps
> = ({ policies, navigateTo }) => (
  <AdoptechTable>
    <thead>
      <tr>
        <SortableTableHeader<ComplianceFrameworkPolicy>
          columnName="live"
          grid={Grid.FrameworkPolicies}
          label={'\u25EF'} // Unicode Regular Circle
        />
        <SortableTableHeader<ComplianceFrameworkPolicy>
          columnName="name"
          grid={Grid.FrameworkPolicies}
          label="Policy Name"
        />
        <SortableTableHeader<ComplianceFrameworkPolicy>
          columnName="approvedAt"
          grid={Grid.FrameworkPolicies}
          label="Approved On"
        />
        <SortableTableHeader<ComplianceFrameworkPolicy>
          columnName="owner"
          grid={Grid.FrameworkPolicies}
          label="Owner"
        />
      </tr>
    </thead>
    <tbody>
      {policies.map((policy, index) => (
        <tr
          key={index}
          onClick={() => navigateTo(policy)}
          className="clickable"
        >
          <td>
            <FontAwesomeIcon
              icon={policy.overdue ? faClock : faCheckCircle}
              className={
                policy.overdue
                  ? 'redTick'
                  : policy.live
                    ? 'greenTick'
                    : 'greyTick'
              }
              title={policy.overdue ? 'Overdue' : null}
            />
          </td>
          <td>{policy.name}</td>
          <td>
            {policy.overdue || !policy.approvedAt ? '-' : policy.approvedAt}
          </td>
          <td>
            {policy.ownerObject ? (
              <UserAvatar user={policy.ownerObject} size="small" />
            ) : (
              '-'
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </AdoptechTable>
);

export const ComplianceFrameworkPoliciesDrawer: React.FC<
  ComplianceFrameworkPoliciesDrawerProps
> = ({ show, framework, onClose }) => {
  const dispatch = useDispatch();

  const policies = useSelector(selectComplianceFrameworkPolicies);
  const allPolicies = useSelector(
    (state: ApplicationState) => state.policies.policies
  );

  const isFetching = useSelector(
    (state: ApplicationState) =>
      state.compliance.isFetchingFrameworkPolicies && !allPolicies.length
  );

  const navigateToPolicy = (policy: ComplianceFrameworkPolicy) => {
    if (policy.path) {
      dispatch(push(policy.path));
    } else {
      dispatch(
        push(`${governancePoliciesLibraryRoute}?policyCode=${policy.code}`)
      );
    }
  };

  useEffect(() => {
    if (!show) return;
    if (!allPolicies.length) dispatch(fetchPolicies());
    dispatch(fetchComplianceFrameworkPolicies(framework.id));
  }, [show, framework?.id]);

  const baseCss = 'complianceFrameworkPoliciesDrawer';

  let title;
  let content;
  if (isFetching) {
    title = 'Loading';
    content = <LoadingSpinner />;
  } else {
    title = framework?.name;
    content = (
      <ComplianceFrameworkPoliciesTable
        policies={policies}
        navigateTo={navigateToPolicy}
      />
    );
  }

  return (
    <AdoptechHS
      noFormPadding
      title={title}
      show={show}
      onClose={onClose}
      showConfirmationWarning={false}
      footer={
        <>
          <AdoptechButton
            onClick={onClose}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <div></div>
        </>
      }
      extraClass={baseCss + '--drawer'}
    >
      {content}
    </AdoptechHS>
  );
};
