import classNames from 'classnames';
import React, { useEffect, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedFramework } from '../../../selectors/selectSelectedFramework';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { ApplicationState } from '../../../types/applicationState';
import { FrameworkModel } from '../../../swagger';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import AdoptechProgressRing from '../../AdoptechProgressRing/AdoptechProgressRing';
import { ComplianceFrameworkIcon } from '../ComplianceFrameworkIcon/ComplianceFrameworkIcon';
import './ComplianceFrameworks.scss';
import { ComplianceFrameworkPoliciesDrawer } from '../ComplianceFrameworkPoliciesDrawer/ComplianceFrameworkPoliciesDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSelectedFramework } from '../../../store/compliance/complianceSlice';
import {
  complianceControlsRoute,
  complianceFrameworkTasksRoute,
  frameworkRequirementsRoute,
  manageSubscriptionRoute,
} from '../../Routes/Routes';
import { push } from 'connected-react-router';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { fetchComplianceFrameworks } from '../../../store/compliance/complianceThunks';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { PaymentLandingPageChecker } from '../../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';
import { selectVendorProducts } from '../../../selectors/pricing/selectVendorProducts';
import { isFrameworkProductPaid } from '../../MainMenuItems/mainMenuConfig';
import { AdoptechTooltip } from '../../AdoptechTooltip/AdoptechTooltip';

export const ComplianceFrameworks: React.FC = () => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const products = useSelector(selectVendorProducts);
  const isFetchingFrameworks = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingFrameworks
  );

  const isRequestingVendorProducts = useSelector(
    (state: ApplicationState) => state.vendors.isRequestingVendorProducts
  );

  const frameworks = useSelector(
    (state: ApplicationState) => state.compliance.frameworks
  );

  const selectedFramework = useSelector(selectSelectedFramework);

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const vendorUser = useSelector(selectCurrentVendorUser);

  const [isShowingPoliciesDrawer, setIsShowingPoliciesDrawer] = useState(false);

  const canSeeFrameworks = canFeature(AccessObject.frameworks);

  const closeDrawer = () => {
    setIsShowingPoliciesDrawer(false);
  };

  const isFrameworkCurrent = (fw: FrameworkModel) => {
    return fw.id === selectedFramework?.id;
  };

  const frameworkCardClasses = (fw: FrameworkModel) => {
    return classNames(baseCss + '--card', {
      current: isFrameworkCurrent(fw),
    });
  };
  const baseCss = 'complianceFrameworks';

  const navigateToFrameworkRequirements = (fw: FrameworkModel) => {
    return (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(setSelectedFramework(fw));
      if (!isFrameworkProductPaid(fw, products)) {
        dispatch(push(manageSubscriptionRoute));
        return;
      }
      dispatch(push(frameworkRequirementsRoute.replace(':id', fw.id)));
    };
  };

  const navigateToFrameworkControls = (fw: FrameworkModel) => {
    return (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(setSelectedFramework(fw));
      if (!isFrameworkProductPaid(fw, products)) {
        dispatch(push(manageSubscriptionRoute));
        return;
      }
      dispatch(push(complianceControlsRoute.replace(':id', fw.id)));
    };
  };

  const navigateToFrameworkPolicies = (fw: FrameworkModel) => {
    return (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(setSelectedFramework(fw));
      if (!isFrameworkProductPaid(fw, products)) {
        dispatch(push(manageSubscriptionRoute));
        return;
      }
      setIsShowingPoliciesDrawer(true);
    };
  };

  const convertFloatToPercents = (float: number) => {
    return +(float * 100).toFixed(2);
  };

  const navigateToFrameworkTasks = (fw: FrameworkModel) => {
    return (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(setSelectedFramework(fw));
      if (!isFrameworkProductPaid(fw, products)) {
        dispatch(push(manageSubscriptionRoute));
        return;
      }
      dispatch(push(complianceFrameworkTasksRoute.replace(':id', fw.id)));
    };
  };

  useEffect(() => {
    if (!canSeeFrameworks) return;

    dispatch(fetchComplianceFrameworks(vendorId, () => null, true));
  }, [vendorId, vendorUser.roles]);

  if (isFetchingFrameworks || isRequestingVendorProducts) {
    return <LoadingSpinner />;
  }

  return (
    <PaymentLandingPageChecker type="complianceFrameworks">
      <div className={baseCss}>
        <div className={baseCss + '--list'}>
          {frameworks.length > 0 ? (
            frameworks.map(fw => {
              if (!fw.stats) return null;
              return (
                <div className={frameworkCardClasses(fw)} key={fw.id}>
                  <ComplianceFrameworkIcon icon={fw.identifier} />
                  <div className={baseCss + '--details'}>
                    <h3>{fw.name}</h3>
                    <p>{fw.description}</p>
                    <div className={baseCss + '--controls-count'}>
                      <div className={baseCss + '--controls-count--icon'}>
                        <FontAwesomeIcon icon={faSlidersH} />
                      </div>
                      &nbsp; {fw.stats.controls.count}
                      &nbsp; Controls
                    </div>
                  </div>
                  {fw.stats.requirements.count > 0 ? (
                    <div
                      className={baseCss + '--stat requirements'}
                      onClick={navigateToFrameworkRequirements(fw)}
                    >
                      <AdoptechProgressRing
                        progress={convertFloatToPercents(
                          fw.stats.requirements.progress
                        )}
                      />
                      Requirements
                    </div>
                  ) : (
                    <AdoptechTooltip
                      text="This workflow is coming soon"
                      identifier={`${fw.id}-requirements`}
                      showTooltip
                    >
                      <div
                        className={baseCss + '--stat requirements-disabled'}
                        onClick={e => e.stopPropagation()}
                      >
                        <AdoptechProgressRing progress={0} />
                        Requirements
                      </div>
                    </AdoptechTooltip>
                  )}
                  <div className="divider" />
                  <div
                    className={baseCss + '--stat policies'}
                    onClick={navigateToFrameworkPolicies(fw)}
                  >
                    <AdoptechProgressRing
                      progress={convertFloatToPercents(
                        fw.stats.policies.progress
                      )}
                    />
                    Policies
                  </div>
                  <div
                    className={baseCss + '--stat controls'}
                    onClick={navigateToFrameworkControls(fw)}
                  >
                    <AdoptechProgressRing
                      progress={convertFloatToPercents(
                        fw.stats.controls.progress
                      )}
                    />
                    Controls
                  </div>
                  <div
                    className={baseCss + '--stat tasks'}
                    onClick={navigateToFrameworkTasks(fw)}
                  >
                    <AdoptechProgressRing
                      progress={convertFloatToPercents(fw.stats.tasks.progress)}
                    />
                    Checks
                  </div>
                </div>
              );
            })
          ) : (
            <div>No frameworks to display</div>
          )}
        </div>
        <ComplianceFrameworkPoliciesDrawer
          show={isShowingPoliciesDrawer}
          framework={selectedFramework}
          onClose={closeDrawer}
        />
      </div>
    </PaymentLandingPageChecker>
  );
};
