import { push } from 'connected-react-router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getPdfDocumentPath,
  PdfPreviewEntityPaths,
} from '../../../functions/routePathsHelpers';
import {
  asyncFetchVendorPolicy,
  fetchVendorPolicy,
  patchVendorPolicyProgress,
} from '../../../store/policies/policiesThunks';
import { vendorPolicyCalculations } from '../../../functions/vendorPolicyCalculations';
import {
  VendorPolicyClause,
  VendorPolicyDetails,
  VendorPolicyDetailsStatusEnum,
  Question,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import { AdoptechAccordionCard } from '../../../components/AdoptechAccordionCard/AdoptechAccordionCard';
import { AutoSaveToast } from '../../../components/AutoSaveToast/AutoSaveToast';
import { GenericModal } from '../../../components/GenericModal/GenericModal';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { PolicyCategoryRow } from '../../../components/PolicyCategoryRow/PolicyCategoryRow';
import { PolicyClauseHeader } from '../../../components/PolicyClauseHeader/PolicyClauseHeader';
import { PolicyDrawer } from '../../../components/PolicyDrawer/PolicyDrawer';
import { PolicySummaryTile } from '../../../components/PolicySummaryTile/PolicySummaryTile';
import './PolicyEditor.scss';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { usePrevious } from 'react-use';
import { ToggleRefDiv, useExpandNextClause } from './useExpandNextClause';

export const PolicyEditor: React.FC = () => {
  const params = useParams() as { vendorPolicyId: string };
  const [cookies] = useCookies(['firstPolicyOpened']);

  const [currentVendorPolicy, setCurrentVendorPolicy] =
    useState<VendorPolicyDetails>();

  const vendorPolicy = useSelector((state: ApplicationState) =>
    state.policies.vendorPolicies.find(vp => vp.id === params.vendorPolicyId)
  );

  const currentUsername = useSelector(
    (state: ApplicationState) => state.user.userDetails.fullName
  );

  const currentVendor = useSelector(selectCurrentVendor);

  const [isPolicyEditingEnabled, setIsPolicyEditingEnabled] = useState(false);
  const [showAnotherUserEditingModal, setShowAnotherUserEditingModal] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // check if the target policy is already loaded extensively (with answers)
    if (!vendorPolicy?.vendorPolicyAnswers) {
      dispatch(fetchVendorPolicy(params.vendorPolicyId));
    } else if (!isPolicyEditingEnabled && vendorPolicy) {
      asyncFetchVendorPolicy(params.vendorPolicyId).then(policy => {
        if (
          policy.updatedBy !== currentUsername &&
          !moment(policy.updatedAt).isSame(moment(vendorPolicy.updatedAt))
        ) {
          setShowAnotherUserEditingModal(true);
        } else {
          setCurrentVendorPolicy(policy);
          setIsPolicyEditingEnabled(true);
        }
      });
    } else {
      setCurrentVendorPolicy(vendorPolicy);
    }
  }, [vendorPolicy]);

  useEffect(() => {
    if (
      currentVendorPolicy?.status === VendorPolicyDetailsStatusEnum.Completed &&
      currentVendorPolicy?.vendorDocumentId
    ) {
      dispatch(
        push(
          getPdfDocumentPath({
            vendorDocumentId: currentVendorPolicy.vendorDocumentId,
            mode: PdfOpenMode.Preview,
            basePath: PdfPreviewEntityPaths.policies,
          })
        )
      );
    }
  }, [currentVendorPolicy?.status]);

  const previousVendorRef = usePrevious(currentVendor);
  useEffect(() => {
    if (previousVendorRef && previousVendorRef.id !== currentVendor.id) {
      dispatch(push('/r/governance/policies'));
    }
  }, [currentVendor]);

  const getActiveKey = () => {
    if (!cookies.firstPolicyOpened) {
      return '';
    }

    return getFirstClauseWithQuestions();
  };
  const clauses = (currentVendorPolicy?.vendorPolicyClauses || []).filter(
    clause => {
      return clause.questions.length === 0 && !clause.allowCustomText // Need condition for Infosec Manual > Corrective actions clause
        ? false
        : true;
    }
  );
  const getFirstClauseWithQuestions = () => {
    return (
      clauses
        ?.find(
          clause => clause.questions?.length > 0 && clauseProgress(clause) !== 1
        )
        ?.id.toString() || ''
    );
  };

  const { stats, visibleQuestionIds } =
    vendorPolicyCalculations(currentVendorPolicy);

  const visibleQuestion = (questions: Question[]) =>
    questions.filter(q => visibleQuestionIds.includes(q.id));

  const clauseProgress = ({ id }: VendorPolicyClause) =>
    stats.find(s => s[0] == id)[1];

  const overallProgress =
    stats.reduce((acc, s) => acc + s[1], 0) / stats.length;

  const { toggleRefs, handleDone } = useExpandNextClause(
    clauses,
    clauseProgress
  );

  useEffect(() => {
    dispatch(patchVendorPolicyProgress(params.vendorPolicyId, overallProgress));
  }, [params.vendorPolicyId, overallProgress]);

  return isPolicyEditingEnabled ? (
    <div>
      <PolicyDrawer />
      <div className="policyEditor">
        <div className="policyEditor--summary">
          <div className="policyEditor--summaryTile">
            <PolicySummaryTile
              vendorPolicyId={params.vendorPolicyId}
              progress={overallProgress}
            />
          </div>
        </div>
        <div className="policyEditor--categoryGrid">
          <Accordion defaultActiveKey={getActiveKey()}>
            {clauses.map((vpc, index) => (
              <AdoptechAccordionCard
                index={vpc.id}
                key={vpc.id}
                ref={(ref: ToggleRefDiv) => (toggleRefs.current[vpc.id] = ref)}
                title={
                  <PolicyClauseHeader
                    policyClause={vpc}
                    progress={clauseProgress(vpc)}
                  />
                }
                hasDoneButton
                nested
                nestedVerticalOffset={-180}
                headerOffset={101}
                shouldShowCoachmark={index === 0}
                handleDone={() => handleDone(vpc.id)}
              >
                <PolicyCategoryRow
                  allowCustomText={vpc.allowCustomText}
                  answers={currentVendorPolicy.vendorPolicyAnswers}
                  categoryName={vpc.name}
                  clauseText={vpc.clauseText}
                  key={vpc.id}
                  leaveOut={vpc.leaveOut}
                  ownClause={vpc.clauseTextIsCustom}
                  updatedAt={vpc.updatedAt}
                  updatedBy={vpc.updatedBy}
                  questions={visibleQuestion(vpc.questions)}
                  vendorPolicyClauseId={vpc.id}
                  vendorPolicyId={params.vendorPolicyId}
                  progress={clauseProgress(vpc)}
                />
              </AdoptechAccordionCard>
            ))}
          </Accordion>
        </div>
      </div>
      <AutoSaveToast />
    </div>
  ) : (
    <>
      <GenericModal
        className="policyEditor--lockedModal"
        show={showAnotherUserEditingModal}
        onClose={() => {
          dispatch(push('/r/governance/policies'));
        }}
        title="Policy currently being edited"
      >
        <p>
          This policy that you are trying to update is currently being edited by{' '}
          <b>{vendorPolicy?.updatedBy}</b>.
        </p>
        <p>Please try again later.</p>
      </GenericModal>
      {!showAnotherUserEditingModal && (
        <div className="policyEditor--spinner">
          <LoadingSpinner delayed />
        </div>
      )}
    </>
  );
};
