import { getMetaContent } from './getMetaContent';

export const logout = () => {
  fetch('/users/sign_out', {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      authenticity_token: getMetaContent('csrf-token'),
      _method: 'delete',
    }),
  }).then(() => {
    window.location.href = '/';
  });
};
