import { forEach } from 'react-bootstrap/ElementChildren';

const commaSeparate = <T>(list: Array<T>, property: keyof T): string => {
  if (!list || list.length === 0) return '';

  if (list.length === 1) {
    return list[0][property].toString();
  }

  let result = '';

  for (let i = 0; i < list.length - 1; i++) {
    result = result + `${list[i][property]}, `;
  }

  result = result + list[list.length - 1][property];

  return result;
};

export default commaSeparate;
